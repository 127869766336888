import React from 'react';

import TableFilter, { ColumnsSearchConfig } from '@components/Table/components/TableFilter';
import SortTable, { SortConfig } from '@components/Table/components/SortTable';
import TableView from '@components/Table/components/TableView';

interface TableWrapperProps {
  defaultSortConfigs?: SortConfig[];
  getOverlayMessage?(options: any): JSX.Element;
  headers: string[];
  minColumnWidths?: number[];
  onRowDoubleClick?(options: any): void;
  renderCell?(cell: any, table?: any): JSX.Element;
  rowClassName?(rowIndex: number): string;
  rowGetter?(rowIndex: number, table?: any): string[];
  searchConfig: ColumnsSearchConfig;
  table: any;
}

export default class TableWrapper extends React.Component<TableWrapperProps, any> {
  getOverlayMessage = (options) => {
    const { getOverlayMessage: propsGetOverlayMessage, table } = this.props;
    if (propsGetOverlayMessage) {
      return propsGetOverlayMessage(options);
    }

    return options.filterNotice ? <div className="overlay">{options.filterNotice}</div>
      : options.sortNotice ? <div className="overlay">{options.sortNotice}</div>
        : !table.length ? <div className="overlay">File is empty</div>
          : null;
  }

  render() {
    const {
      defaultSortConfigs,
      headers,
      minColumnWidths,
      onRowDoubleClick,
      renderCell,
      rowGetter,
      searchConfig,
      table,
    } = this.props;

    return (
      <TableFilter table={table} searchConfig={searchConfig}>
        {(filteredTable, isCellHighlighted, filterNotice) => (
          <SortTable table={filteredTable} searchConfig={searchConfig} defaultSortConfigs={defaultSortConfigs}>
            {(sortedTable , sortConfigs, sort, sortNotice) => ( // tslint:disable-line:jsx-no-multiline-js
              <React.Fragment>
                {this.getOverlayMessage({ filterNotice, sortNotice })}
                <TableView
                  headers={headers}
                  isCellHighlighted={isCellHighlighted}
                  hideColumns={searchConfig.hideColumns || []}
                  onRowDoubleClick={onRowDoubleClick}
                  renderCell={renderCell}
                  rowGetter={rowGetter}
                  minColumnWidths={minColumnWidths || sortedTable.minColumnWidths}
                  sortConfigs={sortConfigs}
                  table={sortedTable}
                  sort={sort}
                />
              </React.Fragment>
            )}
          </SortTable>
        )}
      </TableFilter>
    );
  }
}
