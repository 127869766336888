import React from 'react';
import cx from 'classnames';

import './FeedsStatus.scss';
import JsonEditor from '@components/ToolComponents/simpleUIs/JsonEditor';
import { getCellClassesFromStatus } from '../lib/styleHelpers';

const colWidthToPx = colWidth => colWidth * 10 + 30;
function camelCaseKeyToSpacedTitle(camelCaseKey) {
  return camelCaseKey
    .replace(/^[a-z]/g, $1 => `${$1.toUpperCase()}`)
    .replace(/([a-z])([A-Z])/g, (match, $1, $2) => `${$1} ${$2.toUpperCase()}`);
}

interface RealtimeStatusTableProps {
  statuses: any[];
}

interface RealtimeStatusTableState {
  expandedRows: boolean[];
}

class RealtimeStatusTable extends React.Component<RealtimeStatusTableProps, RealtimeStatusTableState> {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: props.statuses.map(() => false),
    };
  }

  renderTableHeader = (column) => {
    return (
      <div className="rt-status-table-column">{column.label}</div>
    );
  }

  computeTableWidths = () => {
    const sampleFeedStatus = this.props.statuses[0];
    const headerWidths = RealtimeStatusTable.dataKeys.map((dataKey) => {
      const headerLength = dataKey.length;
      const rowLength = sampleFeedStatus[dataKey]
        ? sampleFeedStatus[dataKey].length
        : 0;

      return rowLength >= headerLength
        ? rowLength
        : headerLength;
    });

    const columnWidthPx = headerWidths.map(colWidthToPx);
    const tableWidthPx = columnWidthPx.reduce((acc, cur) => {
      return acc + cur;
    }, 0);

    return {
      columnWidthPx,
      tableWidthPx,
    };
  }

  expandRtRow = (rtStatus: any, rowIndex: number) => {
    const { expandedRows } = this.state;

    const isOldCheckerRtStatusDown = rtStatus.status === 'DOWN'
      && rtStatus.source.toLowerCase() !== 'old checker';
    if (!isOldCheckerRtStatusDown) {
      return;
    }

    const currentToggle = expandedRows[rowIndex];
    expandedRows[rowIndex] = !currentToggle;

    this.setState({
      expandedRows,
    });
  }

  renderColoredRtStatus(cell) {
    const dateClassName = getCellClassesFromStatus(cell.cellData, 'colored-cell realtime-cell');

    return (
      <span className={dateClassName}>{cell.cellData}</span>
    );
  }

  renderActionCell(cell) {
    const buttons = [];

    buttons.push((
      <div className="table-row-action-button">
        See Report
      </div>
    ));

    return (
      <React.Fragment>
        {buttons}
      </React.Fragment>
    );
  }

  renderCell = (cell) => {
    switch (cell.dataKey) {
      case 'actions': return this.renderActionCell(cell);
      case 'status': return this.renderColoredRtStatus(cell);
      default: return cell.cellData || '-';
    }
  }

  static dataKeys = [
    'recipeId',
    'status',
    'requestType',
    'source',
    'updatedAt',
  ];

  render() {
    const { statuses } = this.props;
    const { expandedRows } = this.state;
    const { columnWidthPx, tableWidthPx } = this.computeTableWidths();

    return (
      <div className="rt-status-table rt-status-table">
        {<div className="rt-status-header-row" style={{ width: tableWidthPx }}>
          {RealtimeStatusTable.dataKeys.map((dataKey, columnIndex) => ( // tslint:disable-line jsx-no-multiline-js
            <div key={dataKey} className="rt-status-table-column" style={{ width: columnWidthPx[columnIndex] }}>
              {camelCaseKeyToSpacedTitle(dataKey)}
            </div>
          ))}
        </div>}
        {statuses.map((rtStatus, rowIndex) => {

          const isRowExpanded = expandedRows[rowIndex];
          const rowClassName = cx('rt-status-row', {
            'is-toggled': isRowExpanded,
          });

          return ( // tslint:disable-line jsx-no-multiline-js
            <div
              key={JSON.stringify(rtStatus)}
              className={rowClassName}
              style={{ width: tableWidthPx }}
              onClick={() => this.expandRtRow(rtStatus, rowIndex)/* tslint:disable-line jsx-no-lambda */}
            >
             {RealtimeStatusTable.dataKeys.map((prop, columnIndex) => {  // tslint:disable-line jsx-no-multiline-js
               const cell = {
                 dataKey: prop,
                 cellData: rtStatus[prop],
               };

               return (
                <div
                  key={rtStatus[prop]}
                  className="rt-status-table-column"
                  style={{ width: columnWidthPx[columnIndex] }}
                >
                  {this.renderCell(cell)}
                </div>
               );
             })}

             {isRowExpanded && ( // tslint:disable-line jsx-no-multiline-js
              <div className="rt-debug">
                {rtStatus.report && rtStatus.report.map(report => ( // tslint:disable-line jsx-no-multiline-js
                  <JsonEditor value={JSON.parse(report) || 'no debug available'}/>
                ))}
              </div>
             )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default RealtimeStatusTable;
