import React from 'react';

interface PrometheusAlertsState {}

interface PrometheusAlertsProps {}

class PrometheusAlerts extends React.Component<PrometheusAlertsProps, PrometheusAlertsState> {
  renderIFrame() {
    return (
      <iframe
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        src="/api/admin/prometheus/new" // /new/ url renders a more stylish UI for prometheus
      />
    );
  }

  render() {
    return (
      <div style={{ width: '100%', height: '95vh' }}>
        {this.renderIFrame()}
      </div>
    );
  }
}

export default PrometheusAlerts;
