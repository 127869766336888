import cx from 'classnames';
import debounce from 'debounce-promise';
import { isEqual } from 'lodash';
import React, { ChangeEvent } from 'react';
import { CSSTransition } from 'react-transition-group';

import { FOCUS_ON, KEYBOARD_KEYS } from '@constants';
import store from '@stores';
import { withStore } from '@stores/withStore';
import KeyboardActiveList from '@components/KeyboardActiveList/KeyboardActiveList';

import BasicFunctionalities from './BasicFunctionalities';
import AutocompleteDropdown from './AutocompleteDropdown';

import '../../styles/common.scss';

export interface GenericToolUiProps<T> {
  store?: typeof store;
  keyName?: string;
  template: any;
  index?: [number, number] | number | string;
  value: undefined | T;
  ref?: string;
  errorIfEmpty?: boolean;
  onChange?(value, index): void;
  onComplete?(index, value): void;
  onDelete?(index, config?): void;
  onBlur?(): void;
  onPaste?(event: React.ClipboardEvent<HTMLInputElement>): void;
  onChangeFocus?(index: GenericToolUiProps<T>['index'], direction: string): void;
  enableAutocomplete?: boolean;
  alwaysShowPlaceholder?: boolean;
  renderAutocomplete?: (inputRef: React.RefObject<KeyboardActiveList>, isDropdownVisible: boolean) => JSX.Element;
}

export interface GenericToolUiState<T> {
  isDropdownVisible: boolean;
  value: T | '';
}

export class GenericToolUI<T>
  extends React.Component<GenericToolUiProps<T>, GenericToolUiState<T>>
  implements BasicFunctionalities {

  constructor(props) {
    super(props);

    this.state = {
      isDropdownVisible: false,
      value: this.getDefaultValue(),
    };
  }

  isDefined() {
    const { value } = this.state;
    return typeof value !== 'undefined' && String(value).length > 0;
  }

  getDefaultValue(): any {
    const { value } = this.props;
    return value || '';
  }

  cancelFunctions;
  componentWillUnmount() {
    this.cancelFunctions = true;
  }

  emitOnChange = debounce((value: any) => {
    if (!this.props.onChange) {
      return;
    }

    if (this.cancelFunctions) {
      return;
    }

    if (value === this.props.value) {
      return;
    }

    this.props.onChange(value, this.props.index);
  }, 100);

  setNewValue = (newValue) => {
    this.setState({ value: newValue });
    this.emitOnChange(newValue);
  }

  handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setNewValue(value);
  }

  handleOnKeyDown = (event: React.KeyboardEvent) => {
    const { autocomplete } = store;
    const { index, onComplete, onDelete, template } = this.props;
    const { value } = this.state;

    let shouldShowDropdown = true;
    const isAutocompleteAvailable = autocomplete.isAutocompleteAvailable(template);

    switch (event.keyCode){
      case KEYBOARD_KEYS.ENTER:
        if (shouldShowDropdown && !isAutocompleteAvailable) {
          onComplete(index, value);
        }

        if (this.keyboardRef.current) {
          this.keyboardRef.current.handleEnterKeyPress();
        }

        shouldShowDropdown = false;

        break;

      case KEYBOARD_KEYS.BACKSPACE:
        if (String(value).length === 0) {
          event.preventDefault();
          onDelete(index, { focusPrevious: true });
        }

        break;

      case KEYBOARD_KEYS.ARROW_UP:
        event.preventDefault();
        this.handleArrowKeyUp();

        if (this.keyboardRef.current) {
          this.keyboardRef.current.handleUpDownKeyPress(FOCUS_ON.UP);
        }

        break;

      case KEYBOARD_KEYS.ARROW_DOWN:
        event.preventDefault();
        this.handleArrowKeyDown();

        if (this.keyboardRef.current) {
          this.keyboardRef.current.handleUpDownKeyPress(FOCUS_ON.DOWN);
        }

        break;
    }

    this.setState({ isDropdownVisible: shouldShowDropdown });
  }

  handleArrowKeyUp = () => {
    const { index, onChangeFocus } = this.props;
    onChangeFocus(index, FOCUS_ON.UP);
  }

  handleArrowKeyDown = () => {
    const { index, onChangeFocus } = this.props;
    onChangeFocus(index, FOCUS_ON.DOWN);
  }

  componentDidUpdate(prevProps) {
    const stateNotSynced = !isEqual(this.state.value, this.props.value);
    const propsChanged = !isEqual(prevProps.value, this.props.value);
    const shouldSyncState = propsChanged && stateNotSynced;

    if (shouldSyncState) {
      this.setState({ value: this.props.value || '' });
    }
  }

  keyboardRef: React.RefObject<KeyboardActiveList> = React.createRef();
  renderAutocompleteWrapper(template: string, value: string) {
    const { autocomplete } = store;
    const { isDropdownVisible } = this.state;

    if (this.props.renderAutocomplete) {
      return this.props.renderAutocomplete(this.keyboardRef, isDropdownVisible);
    }

    const isAutocompleteAvailable = autocomplete.isAutocompleteAvailable(template);

    return isAutocompleteAvailable
      ? (
        <CSSTransition
          in={isDropdownVisible}
          timeout={200}
          classNames="autocomplete-wrapper"
        >
          <AutocompleteDropdown
            fieldType={template}
            query={value}
            onSelect={value => this.props.onComplete(this.props.index, value)} // tslint:disable-line jsx-no-lambda
            onCloseDropdown={() => this.setState({ isDropdownVisible: false })} // tslint:disable-line jsx-no-lambda
            inputRef={this.keyboardRef}
          />
        </CSSTransition>
      )
      : null;
  }

  isValid = (value: string) => {
    const { errorIfEmpty } = this.props;
    const isEmptyError = errorIfEmpty && !value;
    return isEmptyError;
  }

  handleOnBlur = () => {
    this.setState({ isDropdownVisible: false });

    this.props.onBlur && this.props.onBlur();
  }

  render() {
    const { alwaysShowPlaceholder = true, keyName, template, enableAutocomplete } = this.props;

    const value = this.state.value !== undefined
      ? String(this.state.value)
      : '';

    const showPlaceholder = this.isDefined() && alwaysShowPlaceholder;
    const classNames = cx('string-tool', {
      'error': this.isValid(value),
      'is-defined': showPlaceholder,
    });

    return (
      <React.Fragment>
        <div className={classNames}>
          {showPlaceholder && <div className="placeholder">{keyName || template}</div>}
          <div className="string-tool-input">
            <input
              placeholder={keyName || template}
              type="text"
              value={value}
              autoComplete="off"
              onChange={this.handleOnChangeInput}
              onKeyDown={this.handleOnKeyDown}
              onFocus={() => this.setState({ isDropdownVisible: true })} // tslint:disable-line jsx-no-lambda
              onBlur={this.handleOnBlur}
              onPaste={this.props.onPaste}
            />
          </div>
        </div>
        {enableAutocomplete && this.renderAutocompleteWrapper(template, value)}
      </React.Fragment>
    );
  }
}

export default withStore(GenericToolUI);
