import React from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { debounce } from 'lodash';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/display/placeholder';
import './NotesEditor.scss';

const codeMirrorConfig = {
  lineNumbers: true,
  lineWrapping: false,
  indentWithTabs: false,
  tabSize: 2,
  autoRefresh:true,
  placeholder: 'add notes ....',
};

interface NotesEditorProps {
  title?: string;
  initialValue: string;
  isActive?: boolean;
  hasChanged?: boolean;
  onChange(note:string): void;
  onSave?(note: string): Promise<boolean>;
}

interface NotesEditorState {
  note: string;
  cursorPos?: any;
}

export default class NotesEditor extends React.PureComponent<NotesEditorProps, NotesEditorState> {

  constructor(props) {
    super(props);

    this.state = {
      note: props.initialValue,
    };
  }

  onChange = debounce((value: string) => {
    this.props.onChange(value.trim());
  }, 300, {
    leading: false,
    trailing: true,
  });

  private handleChangeInEditor = async (editor: any, change: any, value: string) => {
    await this.setState({ cursorPos: editor.getCursor() });
    await this.setState({ note: value });

    this.onChange(value);
  }

  private renderToolBar() {
    const { title, isActive, hasChanged } = this.props;

    return (
      <div className="note-editor-toolbar">
        <span>{title || 'Notes'}</span>

        <div>
          {isActive ? null : <span className="editor-badge badge-error">not active</span>}
          {!hasChanged ? null : <span className="editor-badge badge-warning">updated</span>}
        </div>
      </div>
    );
  }

  private renderEditor() {
    const { note, cursorPos } = this.state;

    return (
      <CodeMirror
        cursor={cursorPos}
        onChange={this.handleChangeInEditor}
        value={note.trim()}
        options={codeMirrorConfig}
      />
    );
  }

  render() {
    return (
      <div className="note-editor">
        {this.renderToolBar()}
        {this.renderEditor()}
      </div>
    );
  }
}
