import { observer } from 'mobx-react';
import React from 'react';
import { BarLoader } from 'react-spinners';
import { isEqual, merge } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ENVIRONMENTS, STORAGE_TYPES } from '@constants';
import store from '@stores';
import router from '@stores/router';
import { FeedInfo } from '@stores/transit';
import { withStore } from '@stores/withStore';
import RoyaleEmailConfig from '@utils/RoyaleEmailConfig';

import { StationSearch } from '@pages/feed/components/PathwaysPage/StationSearch';
import PathwaysUI from '@pages/feed/components/PathwaysPage/PathwaysUI';
import TransfersUI from '@pages/feed/components/PathwaysPage/TransfersUI';
import { ContactUsForm } from '@pages/feed/components/PathwaysPage/ContactUsForm';
import {
  ObjectById,
  PATHWAY_ERROR_CATEGORIES,
  PathwayErrors, PathwayFieldError,
  PathwayInterface,
  StationMetadataByStationStableIdIdInterface,
  StationMetadataInterface,
  StopInterface,
} from '@pages/feed/components/PathwaysPage/PathwayTypes';

import './PathwaysPage.scss';
import LoaderButton from '@components/LoaderButton/LoaderButton';
import StorageManager from '@utils/StorageManager';
import { sendFeedSlackMessage } from '@utils/slack_helper';
import variables from '@styles/variables';
import TransitLogo from '@components/TransitLogo/TransitLogo';

interface PathwaysPageProps {
  onFileChange(filename: string) : void;
  store: typeof store;
  feed: FeedInfo;
  environment: ENVIRONMENTS;
}

const useStationMetadataByStationStableId = () => {
  const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);
  const [
    stationMetadataByStationStableId,
    setStationMetadataByStationStableId,
  ] = React.useState<StationMetadataByStationStableIdIdInterface>(null);

  return [
    stationMetadataByStationStableId,
    (stationMetadataByStationStableId: StationMetadataByStationStableIdIdInterface) => {
      setStationMetadataByStationStableId(stationMetadataByStationStableId);
      forceUpdate();
    },
  ] as const;
};

const useStationStableId = (feedId) => {
  const { params: { station_stable_id } } = router.getUrlData();

  const [stationStableId, setStationStableId] = React.useState<number>(
    station_stable_id
      ? parseInt(station_stable_id, 10)
      : null
  );

  return [
    stationStableId,
    (stationStableId) => {
      setStationStableId(stationStableId);

      const route = router.getCurrentRouteConfig();
      if (stationStableId == null) {
        router.redirect(`${route.basePath}/${feedId}/pathways`);
      } else {
        router.redirect(`${route.basePath}/${feedId}/pathways/${stationStableId}`);
      }
    },
  ] as const;
};

const PathwaysPage: React.FC<PathwaysPageProps> = (props) => { // tslint:disable-line:variable-name
  const { t, i18n } = useTranslation('common');

  const [localStorage] = React.useState(new StorageManager(STORAGE_TYPES.LOCAL, 'stationMetadataByStationStableId'));
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [stationMetadataByStationStableId, setStationMetadataByStationStableId] = useStationMetadataByStationStableId();
  const [stationStableId, setStationStableId] = useStationStableId(props.feed.feed_id);
  const [errors, setErrors] = React.useState<PathwayErrors>({ warning: {}, error: {} });

  const getLocalStorage = () => {
    return localStorage.get(`${props.environment}-${props.feed.feed_id}`) || {};
  };

  const setLocalStorage = (stationMetadataByStableStationId: StationMetadataByStationStableIdIdInterface) => {
    localStorage.set(`${props.environment}-${props.feed.feed_id}`, stationMetadataByStableStationId);
  };

  const getStopDiff = (prevStop, nextStop) => {
    const stopDiff = {
      stopStableId: nextStop.stopStableId,
      stopId: prevStop.stopId,
    };

    let stopHasChanged = false;
    Object.entries(nextStop).forEach(([propertyKey, updatedProperty]) => {
      const isPropertyChanged = prevStop[propertyKey] !== updatedProperty
        && !(updatedProperty === '' && prevStop[propertyKey] === null);
      if (isPropertyChanged) {
        stopHasChanged = true;
        stopDiff[propertyKey] = updatedProperty;
      }
    });

    if (!stopHasChanged) {
      return null;
    }

    return stopDiff;
  };

  const registerChangeInStop = (gtfsStopByStableId, updateStopByStableId) => {
    const stopChanges = Object.values(updateStopByStableId).reduce((acc, updatedStop: StopInterface) => {
      const gtfsStop = gtfsStopByStableId[updatedStop.stopStableId];
      const isStopInGtfs = !!gtfsStop;
      if (!isStopInGtfs) {
        acc[updatedStop.stopStableId] = updatedStop;
        return acc;
      }

      // if stop is already in the GTFS, update only the changed properties;
      const stopChanges = getStopDiff(gtfsStop, updatedStop);
      if (stopChanges) {
        acc[updatedStop.stopStableId] = stopChanges;
      }
      return acc;
    }, {});

    const stopsChanged = Object.keys(stopChanges).length !== 0;
    return stopsChanged
      ? stopChanges
      : null;
  };

  const registerChangeInPathways = (
    stationData: StationMetadataInterface,
    stationPathways: ObjectById<PathwayInterface>,
  ) => {
    const stopsbyStopId = Object.values(stationData.stopByStopStableId || {})
      .concat(Object.values(stationData.entranceByEntranceStableId || {}))
      .reduce((acc, current) => {
        acc[current.stopId] = current;
        return acc;
      }, {});

    const pathwaysChanges = Object.values(stationPathways)
      .reduce((acc, updatedPathway: PathwayInterface) => {
        // if a pathways holds non-default values, keep it.
        const traversalTimeasChanged = updatedPathway.traversalTime !== '';
        const isAccessibleChanged = !updatedPathway.isAccessible;
        const pathwayModeChanged = updatedPathway.pathwayMode !== '2';

        const destinationStop = stopsbyStopId[updatedPathway.toStopId];
        const signpostedAsChanged = !destinationStop
          || updatedPathway.signpostedAs !== destinationStop.stopName;

        const isPathwayUpdated = isAccessibleChanged
          || traversalTimeasChanged
          || signpostedAsChanged
          || pathwayModeChanged;

        if (isPathwayUpdated) {
          acc[updatedPathway.pathwayId] = updatedPathway;
        }

        return acc;
      }, {} as ObjectById<PathwayInterface>);

    const pathwaysChanged = Object.keys(pathwaysChanges).length !== 0;
    return pathwaysChanged
      ? pathwaysChanges
      : null;
  };

  const getStationMetadataByStationStableIdDiff = async () => {
    const currentFeedId = props.feed.feed_id;

    const gtfsStationMetadataByStationStableId = await store.api.gtfs.getPathwaysDataForFeed(currentFeedId);
    return Object.entries(stationMetadataByStationStableId).reduce((acc, [stationStableId, stationMetadata]) => {
      const gtfsStationMetadata = gtfsStationMetadataByStationStableId[stationStableId];

      const pathwayByPathwayIdDiff = registerChangeInPathways(
        stationMetadata,
        stationMetadata.pathwayByPathwayId || {},
      );
      const transferByPathwayIdDiff = registerChangeInPathways(
        stationMetadata,
        stationMetadata.transferByPathwayId || {},
      );
      const entranceByEntranceStableIdDiff = registerChangeInStop(
        gtfsStationMetadata ? gtfsStationMetadata.entranceByEntranceStableId : {},
        stationMetadata.entranceByEntranceStableId || {},
      );

      const newStationMetadata = {
        pathwayByPathwayId: {},
        transferByPathwayId: {},
        entranceByEntranceStableId: {},
      };

      if (pathwayByPathwayIdDiff) {
        newStationMetadata.pathwayByPathwayId = pathwayByPathwayIdDiff;
      }

      if (transferByPathwayIdDiff) {
        newStationMetadata.transferByPathwayId = transferByPathwayIdDiff;
      }

      if (entranceByEntranceStableIdDiff) {
        newStationMetadata.entranceByEntranceStableId = entranceByEntranceStableIdDiff;
      }

      const somethingHasChanged = pathwayByPathwayIdDiff
        || transferByPathwayIdDiff
        || entranceByEntranceStableIdDiff;
      if (somethingHasChanged) {
        acc[stationStableId] = newStationMetadata;
      }

      return acc;
    }, {});
  };

  const fetchJsonExtension = async () => {
    const currentFeedId = props.feed.feed_id;

    const jsonExtension = await store.pages.gtfs.jsonExtension.getJsonExtensionOfFeed(currentFeedId, props.environment);
    jsonExtension.enhance = jsonExtension.enhance || {};

    const stationMetadataByStationStableIdDiff = await getStationMetadataByStationStableIdDiff();
    if (isEqual(stationMetadataByStationStableIdDiff, jsonExtension.enhance.enhanceEntrancesAndPathways)) {
      return null;
    }

    jsonExtension.enhance.enhanceEntrancesAndPathways = stationMetadataByStationStableIdDiff;
    return jsonExtension;
  };

  const getHumanReadableWarnings = (): string => {
    let warnings = '';
    Object.entries(errors.warning).forEach(([stationStableId, stationWarnings]) => {
      const stationMetadata: StationMetadataInterface = stationMetadataByStationStableId[stationStableId];
      const stationName = stationMetadata.stopName;
      warnings += `\n\t${stationName} Station\n`;

      Object.entries(stationWarnings).forEach(([fromStopId, pathwayWarnings]) => {
        let fromStop = Object.values(stationMetadata.stopByStopStableId).find(stop => stop.stopId === fromStopId);
        if (!fromStop) {
          fromStop = Object.values(stationMetadata.entranceByEntranceStableId)
            .find(entrance => entrance.stopId === fromStopId);
          warnings += `\t\tFrom Entrance ${fromStop.stopName}\n`;
        } else {
          warnings += `\t\tFrom Stop ${fromStop.stopName}\n`;
        }

        Object.entries(pathwayWarnings).forEach(([toStopId, fields]) => {
          let toStop = Object.values(stationMetadata.stopByStopStableId).find(stop => stop.stopId === toStopId);
          if (!toStop) {
            toStop = Object.values(stationMetadata.entranceByEntranceStableId)
              .find(entrance => entrance.stopId === toStopId);
            warnings += `\t\t\tTo Entrance ${toStop.stopName}\n`;
          } else {
            warnings += `\t\t\tTo Stop ${toStop.stopName}\n`;
          }

          Object.entries(fields).forEach(([field, { message, value }]: [string, PathwayFieldError]) => {
            warnings += `\t\t\t\t${field}\n`;
            warnings += `\t\t\t\t\tmessage: ${message}\n`;
            warnings += `\t\t\t\t\tvalue: ${value}\n`;
          });
        });
      });
    });

    return warnings;
  };

  const sendSlackNotification = async (url) => {
    const { feed, store } = props;

    const routeParams = store.router.getUrlData().params;
    const routePath = store.router.getCurrentRouteConfig().path;
    let pathwayUrls = '';

    Object.entries(getLocalStorage()).forEach((
      [stationStableId, stationMetadata]: [string, StationMetadataInterface],
      index,
    ) => {
      if (index !== 0) {
        pathwayUrls += '\n';
      }

      const pathwayUrl = routePath
        .replace(':feed_id', routeParams.feed_id)
        .replace(':station_stable_id', stationStableId);

      pathwayUrls += `\t-<${window.location.origin}${pathwayUrl}|${stationMetadata.stopName}>`;
    });

    const message = `${store.auth.user.displayName} - ${store.auth.user.email}
commit: <${url}|Click here> to view changes in github
warnings: ${getHumanReadableWarnings()}
stations:
${pathwayUrls}`;

    const payload = {
      channel: '#station-entrance-alerts',
      username: 'Station Audit Trail',
      icon_url: 'https://transitapp.com/img/transit-logo@2x.png',
      unfurl_links: true,
      attachments: [
        {
          color: 'up',
          fallback: message,
          title: feed.feed_code,
          text: message,
        },
      ],
    };

    await sendFeedSlackMessage(payload);
  };

  const sendRoyaleEmail = async () => {
    const emailConfig = RoyaleEmailConfig(t, i18n);
    return props.store.api.emails.send(emailConfig);
  };

  const handleOnChangeStation = (stationMetadata: StationMetadataInterface) => {
    const editedStationMetadataByStationStableId = getLocalStorage();

    editedStationMetadataByStationStableId[stationMetadata.stopStableId] = stationMetadata;
    setLocalStorage(editedStationMetadataByStationStableId);

    stationMetadataByStationStableId[stationMetadata.stopStableId] = stationMetadata;
    setStationMetadataByStationStableId(stationMetadataByStationStableId);
  };

  const setError = (
    stationStableId: number,
    fromStopId: string,
    toStopId: string,
    field: string,
    errorMessage: string,
    errorValue: any,
    errorCategory: PATHWAY_ERROR_CATEGORIES,
  ) => {
    if (!errorMessage) {
      if (errors[errorCategory]?.[stationStableId]?.[fromStopId]?.[toStopId]?.[field]) {
        delete errors[errorCategory][stationStableId][fromStopId][toStopId][field];

        if (errors[errorCategory]?.[stationStableId]?.[fromStopId]?.[toStopId]) {
          delete errors[errorCategory][stationStableId][fromStopId][toStopId];

          if (errors[errorCategory]?.[stationStableId]?.[fromStopId]) {
            delete errors[errorCategory][stationStableId][fromStopId];

            if (errors[errorCategory]?.[stationStableId]) {
              delete errors[errorCategory][stationStableId];
            }
          }
        }
      }
    } else {
      merge(
        errors,
        {
          [errorCategory]: {
            [stationStableId]: {
              [fromStopId]: {
                [toStopId]: {
                  [field]: {
                    category: errorCategory,
                    message: errorMessage,
                    value: errorValue,
                  },
                },
              },
            },
          },
        },
      );
    }

    setErrors(errors);
  };

  const handleOnSave = async () => {
    const jsonExtension = await fetchJsonExtension();
    if (jsonExtension === null) {
      return true;
    }

    const hasErrors = Object.values(errors.error).length > 0;
    if (hasErrors) {
      return false;
    }

    const toolValueByToolName: any = Object.values(jsonExtension).reduce((accumulator, currentToolValueByToolName) => {
      return Object.assign({}, accumulator, currentToolValueByToolName);
    }, {});

    const config = {
      toolValueByToolName,
      commitEnv: props.environment,
      feedId: String(props.feed.feed_id),
      commitTitle: 'update pathways and entrances',
    };

    const response = await store.pages.gtfs.jsonExtension.commitJsonExtension(config);
    if (response) {
      await sendSlackNotification(response.data.data.commit.html_url);
      setLocalStorage({});
      await sendRoyaleEmail();
    }

    return !!response;
  };

  React.useEffect(() => {
    if (!isLoading) {
      return;
    }

    const setStationMetadataByStationStableIdForCurrentFeed = async () => {
      const currentFeedId = props.feed.feed_id;

      const editedStationMetadataByStationStableId = getLocalStorage();

      const gtfsStationMetadataByStationStableId = await store.api.gtfs.getPathwaysDataForFeed(currentFeedId);
      const jsonExtension = await store.pages.gtfs.jsonExtension.getJsonExtensionOfFeed(
        currentFeedId,
        props.environment,
      );

      jsonExtension.enhance = jsonExtension.enhance || {};
      jsonExtension.enhance.enhanceEntrancesAndPathways = jsonExtension.enhance.enhanceEntrancesAndPathways || {};

      const currentStationMetadataByStationStableId = jsonExtension.enhance.enhanceEntrancesAndPathways || {};

      if (String(currentFeedId) !== String(props.feed.feed_id)) { // handle race condition;
        return;
      }

      let shouldUseEditedStationMetadataByStationStableId = false;
      if (Object.keys(editedStationMetadataByStationStableId).length > 0) {
        shouldUseEditedStationMetadataByStationStableId = window.confirm(t('pathways.recover_edit'));
      }

      if (shouldUseEditedStationMetadataByStationStableId) {
        Object.entries(editedStationMetadataByStationStableId).forEach(([stationStableId, editedStationMetadata]) => {
          currentStationMetadataByStationStableId[stationStableId] = editedStationMetadata;
        });
      } else {
        setLocalStorage({});
      }

      const mergedStationMetadataByStationStableId: StationMetadataByStationStableIdIdInterface = merge(
        {},
        gtfsStationMetadataByStationStableId,
        currentStationMetadataByStationStableId,
      );

      const cleanedStationMetadataByStationStableId = Object.values(mergedStationMetadataByStationStableId).reduce((
        acc,
        stationMetadata: StationMetadataInterface
      ) => {
        if (stationMetadata.stopByStopStableId) {
          acc[stationMetadata.stopStableId] = stationMetadata;
        }

        return acc;
      }, {});

      setStationMetadataByStationStableId(cleanedStationMetadataByStationStableId);

      if (!cleanedStationMetadataByStationStableId[stationStableId]) {
        setStationStableId(null);
      }

      setIsLoading(false);
    };

    setStationMetadataByStationStableIdForCurrentFeed();
  }, [isLoading]);

  React.useEffect(() => {
    document.title = 'Pathways Dashboard';

    setIsLoading(true);
  }, [props.feed.feed_id]);

  if (isLoading) {
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ height: '100vh', paddingBottom: '80px' }}
      >
        <div className="w-100 d-flex justify-content-center" style={{ marginBottom: '30px' }}>
          <TransitLogo/>
        </div>
        <BarLoader color="#30b566"/>
      </div>
    );
  }

  if (!stationMetadataByStationStableId) {
    return (
      <span className="d-flex justify-center">
        {t('pathways.content_unavailable.rerun_feed')}
      </span>
    );
  }

  if (Object.keys(stationMetadataByStationStableId).length === 0) {
    return (
      <span className="d-flex justify-center">
         {t('pathways.content_unavailable.no_stations_exist')}
      </span>
    );
  }

  if (!stationStableId) {
    return (
      <div className="pathways-page">
        <div className="d-flex justify-content-center">
          <StationSearch
            feed={props.feed}
            handleOnSelectStation={stationMetadata => setStationStableId(stationMetadata.stopStableId)} // tslint:disable-line:jsx-no-lambda max-line-length
            stationMetadataByStationStableId={stationMetadataByStationStableId}
          />
        </div>
      </div>
    );
  }

  const stationMetadata = stationMetadataByStationStableId[stationStableId];
  if (stationMetadata.pathwaysExist) {
    return (
      <div className="pathways-page">
        <div className="d-flex justify-content-center">
          <span>
            {t('pathways.uneditable_station')}
          </span>
          <StationSearch
            feed={props.feed}
            handleOnSelectStation={stationMetadata => setStationStableId(stationMetadata.stopStableId)} // tslint:disable-line:jsx-no-lambda max-line-length
            stationMetadataByStationStableId={stationMetadataByStationStableId}
          />
        </div>
      </div>
    );
  }

  const renderTransfers = () => {
    if (Object.values(stationMetadata.stopByStopStableId).length <= 1) {
      return null;
    }

    return (
      <TransfersUI
        key={`transfers-${stationMetadata.stopId}`}
        onChange={handleOnChangeStation}
        stationMetadata={stationMetadata}
        setError={setError}
      />
    );
  };

  return (
    <div className="pathways-page">
      <div className="pathways-page-header">
       <div className="d-flex align-items-center">
          <span className="title station-title" style={{ marginRight: '20px' }}>
            {t(
              'pathways.station_name',
              { station_name: stationMetadata.stopName.replace(/ ?station/i, '') },
            )}
          </span>
         <StationSearch
           handleOnSelectStation={stationMetadata => setStationStableId(stationMetadata.stopStableId)} // tslint:disable-line:jsx-no-lambda max-line-length
           stationMetadataByStationStableId={stationMetadataByStationStableId}
           stationMetadata={stationMetadata}
           feed={props.feed}
         />
       </div>
       <div className="d-flex items-center" style={{ marginLeft: '20px' }}>
         <ContactUsForm feed={props.feed} stationMetadataByStationStableId={getLocalStorage()}/>
         <LoaderButton value={t('button.save')} onClickWithStatus={handleOnSave} status={t('')} style={{ marginLeft: '20px' }}/>
       </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '8px',
          margin: '0 0 40px 0',
          backgroundColor: variables.transitmediumgrey,
        }}
      />
      <PathwaysUI
        key={`pathways-${stationMetadata.stopId}`}
        onChange={handleOnChangeStation}
        stationMetadata={stationMetadata}
        setError={setError}
      />
      {renderTransfers()}
    </div>
  );
};

export default observer(withStore(PathwaysPage));
