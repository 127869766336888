import React from 'react';
import { Formik, Form, FormikProps, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

import { withStore } from '@stores/withStore';
import { CommonFormProps } from './CommonFormInterfaces';

import './CommonForm.scss';

type CommonFormik = FormikProps<any> & { validation: Yup.ObjectSchema<object> };
export const formContext = React.createContext<CommonFormik>(undefined);
class CommonFormWrapper extends React.Component<CommonFormProps> {
  render() {
    const { formInfo, children, onSubmit, validationSchema } = this.props;

    const validation = Yup.object(validationSchema);

    return (
      <Formik
        initialValues={formInfo}
        onSubmit={onSubmit}
        /*
          Use validate prop instead of validationSchema prop because we can pass the entire formInfo as a context param
          (see validateYupSchema API regarding the context param).
          This is useful for testing fields that are dependent on others
          (i.e. banner.title is dependent on banner.support_layouts)
         */
        validate={(values) => { // tslint:disable-line:jsx-no-lambda
          try {
            validateYupSchema(values, validation, true, values);
          } catch (err) {
            return yupToFormErrors(err); // for rendering validation errors
          }

          return {};
        }}
      >
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {(formik: CommonFormik) => {
          formik.validation = validation;
          return (
            <div className="common-form-page-container">
              <div className="common-form-container">
                <formContext.Provider value={formik}>
                  <Form translate={'yes'}>
                    {children(formik)}
                  </Form>
                </formContext.Provider>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default withStore<CommonFormProps>(CommonFormWrapper);
