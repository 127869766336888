import React from 'react';
import cx from 'classnames';
import './ToggleButtons.scss';

interface GenericToggleButtonProps {
  active?: boolean;
  className?: string;
  hide?: boolean;
  onChange?(bool: boolean): void;
  children?: any;
}

interface GenericToggleButtonState {
  toggleButton: boolean;
}

class GenericToggleButton extends React.Component<GenericToggleButtonProps, GenericToggleButtonState> {
  constructor(props) {
    super(props);
    this.state = {
      toggleButton: !!props.active,
    };
  }

  handleClick = () => {
    if (this.props.onChange) {
      this.props.onChange(!this.getCheckedValue());
    }
    const isControlled = this.isComponentControlled();
    if (!isControlled) {
      this.setState({ toggleButton: !this.getCheckedValue() });
    }
  }

  private isComponentControlled() {
    const { active } = this.props;
    const isControlled = active !== undefined;
    return isControlled;
  }

  private getCheckedValue() {
    const { active } = this.props;
    const { toggleButton } = this.state;

    const isControlled = this.isComponentControlled();
    if (isControlled) {
      return !!active;
    }
    return toggleButton;
  }

  render() {
    const currentValue = this.getCheckedValue();
    const children = this.props.children(currentValue);

    const toggleClassName = cx('toggle-button-wrapper', {
      'is-active': currentValue,
      'is-hidden': this.props.hide,
      [this.props.className]: this.props.className,
    });

    return (
      <div className={toggleClassName} onClick={this.handleClick}>
        {children}
      </div>
    );
  }
}

export default GenericToggleButton;
