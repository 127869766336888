import { cloneDeep, pick } from 'lodash';
import moment from 'moment';
import React from 'react';
import Geocode from 'react-geocode';
import * as Yup from 'yup';

import store from '@stores';
import { BannerFormInterface, BannerFormContent } from '@stores/banners';
import { withStore } from '@stores/withStore';

import LoaderButton from '@components/LoaderButton/LoaderButton';
import Button from '@components/Button/Button';
import { FormColumn, FormInput, FormRow, FormSection } from '@components/Form/CommonForm';
import CarouselInput from '@components/Carousel/CarouselInput';
import BannerPreview from '@pages/banners/components/BannerForm/BannerPreview';
import DatePicker from 'react-datepicker';
import SelectWithTags from '@components/SelectWithTags/SelectWithTags';
import GeoJsonMap from '@components/MapInput/GeoJsonMap';
import CommonFormWrapper from '@components/Form/CommonFormWrapper';
import BannerCarouselForm from '@pages/banners/components/BannerForm/BannerCarouselForm';

import { HTML_TAGS, REDIRECT_URL, DATE_TIME } from '@constants';
import 'react-tabs/style/react-tabs.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@components/Form/CommonForm.scss';

interface BannerFormWrapperProps {
  store?: typeof store;
  hideModal(): void;
  onChange(): void;
  defaultBanner?: BannerFormInterface;
  svgList: any;
}

interface BannerFormWrapperState {
  bannerIndex: number;
  isBannerInfoValid: boolean;
}

Geocode.setApiKey(process.env.GOOGLE_MAP_API_KEY);

function testTitle(title) {
  const { supported_layouts } = this.options.context;
  if (Array.isArray(supported_layouts) && supported_layouts.includes('royale')) {
    return title.length <= 100;
  }

  return title.length <= 60;
}

class BannerFormWrapper extends React.Component<BannerFormWrapperProps, BannerFormWrapperState> {
  constructor(props) {
    super(props);

    this.state = {
      bannerIndex: 0,
      isBannerInfoValid: true,
    };
  }

  static formValidation = {
    city: Yup.string().required('Description is required'),
    color: Yup.string().required('Color is required'),
    content: Yup.array().of(
      Yup.object().shape({
        body: Yup.string().max(70, 'Body must be at most 70 characters').nullable(),
        locale: Yup.string().required('Language is required'),
        title: Yup
          .string()
          .test('content', 'Title is too long', testTitle),
        url: Yup.string().max(512, 'Url must be at most 512 characters').nullable(),
      })
    ),
    end_at: Yup.date().nullable(),
    in_beta: Yup.number().required('Beta Status is required'),
    radius: Yup.number().min(0).nullable(),
    priority: Yup.number().min(-1).required('Priority is required'),
    start_at: Yup.date().nullable(),
    text_color: Yup.string().required('Text Color is required'),
    uuid_filter: Yup.string().max(32).nullable(),
  };

  getDefaultBanner = (banner: any = {}): BannerFormInterface => {
    banner.priority = banner.priority || 0;
    banner.start_at = banner.start_at || moment().format();
    banner.text_color = banner.text_color || 'FFFFFF';
    banner.color = banner.color || '14446B';
    banner.content = this.getDefaultBannerContent(banner.content);
    banner.end_at = banner.end_at || moment().format();
    banner.in_beta = banner.in_beta === undefined || banner.in_beta === null
      ? 1
      : banner.in_beta;

    return banner;
  }

  getDefaultBannerContent = (bannerContent: any[] = [{}]): BannerFormContent[] => {
    bannerContent.forEach((content) => {
      content.url = content.url
        ? decodeURI(content.url)
        : undefined;

      const isTrackedWithGoogleAnalytics = content.url && content.url.startsWith(REDIRECT_URL.GOOGLE_ANALYTICS);
      if (isTrackedWithGoogleAnalytics) {
        content.url = content.url.slice(REDIRECT_URL.GOOGLE_ANALYTICS.length);
        content.tracking = true;
      }
    });

    return bannerContent;
  }

  addNewTab = (formik) => {
    const emptyContent: BannerFormContent[] = this.getDefaultBannerContent();
    const content = cloneDeep(formik.values.content);
    content.push(...emptyContent);
    formik.setFieldValue('content', content);
  }

  removeEmptyContentObjects = (contentArray: BannerFormContent[]) => {
    return contentArray.filter((contentObject) => {
      return Object.values(contentObject).some(value => value);
    });
  }

  sanitizeBannerData = (banner) => {
    const bannerClone = cloneDeep(banner);

    bannerClone.app_filter = bannerClone.app_filter || 'Flagship';
    bannerClone.priority = parseInt(bannerClone.priority, 10); // input returns string whereas value needs to be an int

    bannerClone.content = this.removeEmptyContentObjects(bannerClone.content);
    bannerClone.content.forEach((content, index) => {
      bannerClone.content[index].url = content.url ? content.url.trim() : ''; // explicitly mutate the object.
      bannerClone.content[index].url = encodeURI(bannerClone.content[index].url)
        .replace(/%5B/g, '[')
        .replace(/%5D/g, ']'); // See https://transit.slack.com/archives/CV8FA7DH9/p1594046715004400
      bannerClone.content[index].title = content.title ? content.title.trim() : '';
      bannerClone.content[index].body = content.body ? content.body.trim() : '';
      // bannerClone.content[index].image = content.image ? content.image.trim() : '';

      const isTrackedWithGoogleAnalytics = content.url.startsWith(REDIRECT_URL.GOOGLE_ANALYTICS);
      if (content.tracking && content.url && !isTrackedWithGoogleAnalytics) {
        bannerClone.content[index].url =
          `${REDIRECT_URL.GOOGLE_ANALYTICS}${content.url}`; // add redirect to start of url
      }

      delete content.tracking;
    });

    delete bannerClone.location;
    return bannerClone;
  }

  onSubmit = async (banner) => {
    const { banners } = this.props.store;
    const sanitizedBanner = this.sanitizeBannerData(banner);

    const shouldAddBanner = !sanitizedBanner.id;
    if (shouldAddBanner) {
      return banners.addBanner(sanitizedBanner);
    }
    return banners.updateBanner(sanitizedBanner);
  }

  handleOnChange = (key, value, formik) => {
    if (this.props.onChange) {
      this.props.onChange();
    }

    formik.setFieldValue(key, value);
  }

  handleOnSubmit = async (banner, formikBag): Promise<boolean> => {
    if (!formikBag.isSubmitting) {
      const updatedBannerInfo: boolean = await this.onSubmit(banner);

      if (updatedBannerInfo) {
        this.props.store.router.redirect('/banners');
        this.props.hideModal();
        return true;
      }

    }

    return false;
  }

  handleDuplicateBanner = async (formik) => {
    formik.values.id = undefined;

    this.props.store.router.redirect('/banners/duplicate');
    alert('Your banner has been duplicated!');
    formik.setValues(formik.values);
  }

  handleArchive = async (formik) => {
    const { banners } = this.props.store;

    const isArchived = await banners.archiveBanner(formik.values);
    if (isArchived) {
      this.props.store.router.redirect('/banners');
      this.props.hideModal();
      return true;
    }
    return false;
  }

  renderBannerContent() {
    const { defaultBanner } = this.props;

    return (
      <CommonFormWrapper
        formInfo={this.getDefaultBanner(defaultBanner)}
        onSubmit={this.handleOnSubmit}
        validationSchema={BannerFormWrapper.formValidation}
      >
        {(formik) => {
          return (
            <React.Fragment>
              {this.renderWriteSection(formik)}
              {this.renderDesignSection(formik)}
              {this.renderClassifySection(formik)}
              {this.renderScheduleSection(formik)}
              {this.renderLocateSection(formik)}
              {this.renderButtons(formik)}
            </React.Fragment>
          );
        }}
      </CommonFormWrapper>
    );
  }

  renderButtons(formik) {
    if (formik.values.id === undefined) {
      return (
        <div className="save-button-container">
          <LoaderButton
            errorMessage="Error occurred! Please update items with errors before submitting"
            value="Save"
            onClickWithStatus={formik.submitForm}
          />
        </div>
      );
    }

    if (formik.values.is_archived === 1) {
      return (
        <div className="save-button-container">
          <LoaderButton
            value="Unarchive"
            onClickWithStatus={() => this.handleArchive(formik)} // tslint:disable-line:jsx-no-lambda
            className="archive"
          />
        </div>
      );
    }

    return (
      <div className="save-button-container">
        <LoaderButton
          errorMessage="Error occurred! Please update items with errors before submitting"
          value="Save"
          onClickWithStatus={formik.submitForm}
        />
        <Button
          value="Duplicate"
          className="copy"
          onClick={() => this.handleDuplicateBanner(formik)} // tslint:disable-line:jsx-no-lambda
        />
        <LoaderButton
          value="Archive"
          onClickWithStatus={() => this.handleArchive(formik)} // tslint:disable-line:jsx-no-lambda
          className="archive"
        />
      </div>
    );
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {this.renderBannerContent()}
      </div>
    );
  }

  renderWriteSection = (formik: any) => {
    const { bannerIndex } = this.state;

    return (
      <FormSection title="Write It">
        <FormRow>
          <FormColumn>
            <FormInput
              formType={HTML_TAGS.CUSTOM}
              /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
              render={() => {
                const handleContentIndex = (index) => {
                  this.setState({
                    bannerIndex: index,
                  });
                };

                return (
                  <CarouselInput
                    carouselSlides={formik.values.content}
                    newItem={() => this.addNewTab(formik)}
                    handleIndex={handleContentIndex}
                    carouselSlidesIndex={bannerIndex}
                    children={content => (
                      <BannerCarouselForm
                        formik={formik}
                        content={content}
                        contentIndex={bannerIndex}
                        svgList={this.props.svgList}
                        onChange={(key, value) => this.handleOnChange(key, value, formik)}
                      />
                    )}
                  />
                );
              }}
            />
          </FormColumn>
        </FormRow>
      </FormSection>
    );
  }

  renderDesignSection = (formik: any) => {
    return (
      <FormSection title="Design It">
        <FormRow>
          <FormColumn>
            <FormInput
              value={formik.values.color.includes('#') ? formik.values.color : `#${formik.values.color}`}
              label="Color"
              field="color"
              formType={HTML_TAGS.COLOR}
              inputType={HTML_TAGS.COLOR}
              inputList="transit-colors"
              /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
              onChange={(key, value) => this.handleOnChange(key, value.slice(1), formik)} // "#abcdeg" -> "abcdef"
            />
          </FormColumn>
          <FormColumn>
            <FormInput
              value={formik.values.text_color.includes('#')
                ? formik.values.text_color
                : `#${formik.values.text_color}`}
              label="Text Color"
              field="text_color"
              formType={HTML_TAGS.COLOR}
              inputType={HTML_TAGS.COLOR}
              inputList="transit-colors"
              /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
              onChange={(key, value) => this.handleOnChange(key, value.slice(1), formik)} // "#abcdeg" -> "abcdef"
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormInput
              formType={HTML_TAGS.CUSTOM}
              /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
              render={() => {
                return (
                  <BannerPreview banner={formik.values} index={0} />
                );
              }}
            />
          </FormColumn>
        </FormRow>
      </FormSection>
    );
  }

  renderClassifySection = (formik: any) => {
    return (
      <FormSection title="Classify It">
        <FormRow>
          <FormColumn>
            <FormInput
              onChange={(key, value) => this.handleOnChange(key, value, formik)} // tslint:disable-line:jsx-no-lambda
              value={formik.values.city}
              label="Description"
              field="city"
            />
          </FormColumn>
          <FormColumn>
            <FormInput
              value={formik.values.priority}
              label="Priority"
              field="priority"
              inputType={HTML_TAGS.NUMBER}
              onChange={(key, value) => this.handleOnChange(key, value, formik)} // tslint:disable-line:jsx-no-lambda
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormInput
              value={formik.values.in_beta}
              label="Beta Status"
              field="in_beta"
              defaultValue={1}
              formType={HTML_TAGS.DROPDOWN}
              /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
              itemLabelByItemValue={{
                0: 'Production (0)',
                1: 'Beta (1)',
              }}
              onChange={(key, value) => this.handleOnChange(key, parseInt(value, 10), formik)}  // tslint:disable-line:jsx-no-lambda max-line-length
            />
          </FormColumn>
          <FormColumn>
            <FormInput
              onChange={(key, value) => this.handleOnChange(key, value, formik)} // tslint:disable-line:jsx-no-lambda
              value={formik.values.uuid_filter}
              label="Amplitude Cohort"
              field="uuid_filter"
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormInput
              label="App Filter"
              formType={HTML_TAGS.CUSTOM}
              /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
              render={() => {
                const options = [
                  { label: 'Flagship', value: 'Flagship' },
                  { label: 'CTPM', value: 'CTPM' },
                  { label: 'SC Transit', value: 'SC Transit' },
                  { label: 'TAT', value: 'TAT' },
                  { label: 'YYC Transit', value: 'YYC Transit' },
                ];
                return (
                  <SelectWithTags
                    inputValue={formik.values.app_filter}
                    name="app_filter"
                    onChange={(key, value) => this.handleOnChange(key, value.join(',') || undefined, formik)} // tslint:disable-line:jsx-no-lambda max-line-length
                    options={options}
                  />
                );
              }}
            />
          </FormColumn>
          <FormColumn>
            <FormInput
              label="Supported Layouts"
              formType={HTML_TAGS.CUSTOM}
              /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
              render={() => {
                const options = [
                  { label: 'Royale', value: 'royale' },
                ];
                return (
                  <SelectWithTags
                    inputValue={formik.values.supported_layouts}
                    name="supported_layouts"
                    onChange={(key, value) => this.handleOnChange(key, value, formik)} // tslint:disable-line:jsx-no-lambda max-line-length
                    options={options}
                  />
                );
              }}
            />
          </FormColumn>
        </FormRow>
      </FormSection>
    );
  }

  renderScheduleSection = (formik: any) => {
    return (
      <FormSection title="Schedule It">
        <FormRow>
          <FormColumn>
            <DatePicker
              selected={moment(formik.values.start_at || Date.now()).valueOf()}
              todayButton={'Current Date'}
              dateFormat="yyyy-MM-dd HH:mm"
              timeFormat="HH:mm"
              timeIntervals={30}
              showTimeSelect={true}
              timeCaption="time"
              onChange={(dateTime) => { // tslint:disable-line:jsx-no-lambda max-line-length
                const dateString = moment(dateTime).format();
                this.handleOnChange('start_at', dateString, formik);
              }}
            />
          </FormColumn>
          <FormColumn>
            <DatePicker
              selected={moment(formik.values.end_at || Date.now()).valueOf()}
              todayButton={'Current Date'}
              dateFormat="yyyy/MM/dd HH:mm"
              timeFormat="HH:mm"
              showTimeSelect={true}
              timeIntervals={30}
              timeCaption="time"
              onChange={(dateTime) => { // tslint:disable-line:jsx-no-lambda max-line-length
                const dateString = moment(dateTime).format();
                this.handleOnChange('end_at', dateString, formik);
              }}
            />
          </FormColumn>
        </FormRow>
      </FormSection>
    );
  }

  renderLocateSection = (formik: any) => {
    return (
      <FormSection title="Locate It">
        <FormRow>
          <Button
            value={'Make Worldwide'}
            onClick={() => { // tslint:disable-line jsx-no-lambda
              formik.setValues({
                ...formik.values,
                radius: 20000000,
                latitude: 89,
                longitude: 0,
                geojson: undefined,
              });
            }}
          />
          <Button
            className="blue"
            value={'Reset'}
            onClick={() => { // tslint:disable-line jsx-no-lambda
              formik.setValues({
                ...formik.values,
                geojson: undefined,
                radius: undefined,
                latitude: undefined,
                longitude: undefined,
                ...pick(formik.initialValues, [
                  'geojson', 'radius', 'latitude', 'longitude',
                ]),
              });
            }}
          />
          <Button
            className="danger"
            value={'Clear'}
            onClick={() => { // tslint:disable-line jsx-no-lambda
              formik.setValues({
                ...formik.values,
                geojson: undefined,
                radius: undefined,
                latitude: undefined,
                longitude: undefined,
              });
            }}
          />
        </FormRow>
        <FormRow>
          <FormColumn>
          <div className="preview-container">
            <GeoJsonMap
              latitude={formik.values.latitude}
              longitude={formik.values.longitude}
              radius={formik.values.radius}
              geojson={formik.values.geojson}
              defaultZoom={12}
              defaultCenter={[43.6532, 79.3832]}
              onChange={({ latitude, longitude, radius, geojson }) => { // tslint:disable-line
                formik.setValues({
                  ...formik.values,
                  latitude,
                  longitude,
                  radius,
                  geojson,
                });
              }}
            />
          </div>
          </FormColumn>
        </FormRow>
      </FormSection>
    );
  }
}

export default withStore<BannerFormWrapperProps>(BannerFormWrapper);
