import React, { useEffect, useState, useRef } from 'react';
import { debounce, memoize } from 'lodash';
import { useTranslation } from 'react-i18next';

import { WINDOW_EVENTS } from '@constants';

import KeyboardActiveList from '@components/KeyboardActiveList/KeyboardActiveList';
import HighlightedText from '@components/HightlightedText/HighlightedText';
import Checkbox from '@components/Checkbox/Checkbox';

import { AutocompleteStore } from '@stores/autocomplete';
import { FeedInfo } from '@stores/transit';

import { StationMetadataInterface, StationMetadataByStationStableIdIdInterface, StopInterface } from './PathwayTypes';
import StationMap from '@pages/pathways/components/StationAccordion/StationMap';

import variables from '@styles/variables';

const searchStation = (
  stationMetadataByStationStableId: StationMetadataByStationStableIdIdInterface,
  searchValue: string,
): StationMetadataInterface[] => {
  return AutocompleteStore.searchStaticIndex(
    Object.values(stationMetadataByStationStableId),
    {
      searchOptions: [{
        normalizeRow: row => `${row.stopId} ${row.stopStableId} ${row.stopName}`,
        searchValues: searchValue.split(' '),
        compareStringOptions: {
          searchNormalizedCharacters: true,
          searchCaseInsensitive: true,
        },
      }],
      getRelevanceOfRow: (row, searchTerm) => {
        const isExactMatch = row.stopId === searchTerm || row.stopName === searchTerm;
        if (isExactMatch) {
          return Infinity;
        }

        let relevanceScore = 0;
        const isStopIdStartingWithTerm = row.stopId.startsWith(searchTerm);
        if (isStopIdStartingWithTerm) {
          relevanceScore += 1;
        }

        const isStopNameStartingWithTerm = row.stopName.startsWith(searchTerm);
        if (isStopNameStartingWithTerm) {
          relevanceScore += 1;
        }

        return relevanceScore;
      },
    },
  );
};

interface StationSearchProps {
  handleOnSelectStation(stationMetadata: StationMetadataInterface): void;
  stationMetadataByStationStableId: StationMetadataByStationStableIdIdInterface;
  stationMetadata?: StationMetadataInterface;
  feed: FeedInfo;
}

const useSearchResults = (stationMetadatas) => {
  const sortStationMetadatas = (stationMetadatas) => {
    return stationMetadatas.sort((stationMetadataA, stationMetadataB) => {
      return stationMetadataA.stopName.localeCompare(stationMetadataB.stopName);
    });
  };

  const [searchResults, setSearchResults] = useState<StationMetadataInterface[]>(sortStationMetadatas(stationMetadatas)); // tslint:disable-line max-line-length

  return [
    searchResults,
    stationMetadatas => setSearchResults(sortStationMetadatas(stationMetadatas)),
  ] as const;
};

interface RouteInfo {
  globalRouteId: string;
  routeShortName?: string;
  routeColor: string;
  routeTextColor: string;
  shouldKeepRoute: boolean;
  stopStableId: string;
}

const useRouteByGlobalRouteId = (stationMetadatas) => {
  const initRouteByGlobalRouteId = memoize(() => {
    const routeByGlobalRouteId = new Map<string, RouteInfo>();
    stationMetadatas.forEach((stationMetadata: StationMetadataInterface) => {
      Object.values(stationMetadata.stopByStopStableId).forEach((stop: StopInterface) => {
        const globalRouteIdRegex = /-r-<(.*)>/;
        const globalRouteId = globalRouteIdRegex.exec(stop.stopId)[1];
        if (!routeByGlobalRouteId.has(globalRouteId)) {
          routeByGlobalRouteId.set(
            globalRouteId,
            {
              globalRouteId,
              routeColor: stop.routeColor,
              routeShortName: stop.routeShortName,
              routeTextColor: stop.routeTextColor,
              shouldKeepRoute: true,
              stopStableId: stop.stopStableId,
            },
          );
        }
      });
    });

    return routeByGlobalRouteId;
  });

  const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);
  const [routeByGlobalRouteId, setRouteByGlobalRouteId] = useState<Map<string, RouteInfo>>(
    initRouteByGlobalRouteId()
  );

  const setRoute = (route) => {
    routeByGlobalRouteId.set(route.globalRouteId, route);
    setRouteByGlobalRouteId(routeByGlobalRouteId);

    forceUpdate();
  };

  return [routeByGlobalRouteId, setRoute, ignored] as const;
};

export const StationSearch: React.FC<StationSearchProps> = (props) => { // tslint:disable-line:variable-name
  const { t } = useTranslation('common');

  const [isMapDropdownOpen, setIsMapDropdownOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [routeByGlobalRouteId, setRoute, forceUpdate] = useRouteByGlobalRouteId(
    Object.values(props.stationMetadataByStationStableId),
  );
  const [searchResults, setSearchResults] = useSearchResults(Object.values(props.stationMetadataByStationStableId));

  const keyboardRef = useRef<KeyboardActiveList>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const stationMapRef = useRef<any>(null);

  React.useEffect(() => {
    const filteredSearchResults = Object.values(props.stationMetadataByStationStableId)
      .reduce((acc, stationMetadata) => {
        const someStopsHaveRoute = Object.values(stationMetadata.stopByStopStableId).some((stop: StopInterface) => {
          const globalRouteIdRegex = /-r-<(.*)>/;
          const globalRouteId = globalRouteIdRegex.exec(stop.stopId)[1];
          const route = routeByGlobalRouteId.get(globalRouteId);
          if (route.shouldKeepRoute) {
            return true;
          }
        });

        if (someStopsHaveRoute) {
          acc.push(stationMetadata);
        }

        return acc;
      }, []);

    setSearchResults(filteredSearchResults);
  }, [forceUpdate]);

  const handleKeyPress = (event) => {
    if (!keyboardRef.current) {
      return;
    }

    const activeIndex = keyboardRef.current.handleKeyPress(event);
    if (activeIndex !== null && stationMapRef.current) {
      stationMapRef.current.updateActiveMarker(searchResults[activeIndex]);
    }
  };

  const debouncedSearchQuery = debounce((searchValue) => {
    const searchResults = searchStation(props.stationMetadataByStationStableId, searchValue);
    setSearchValue(searchValue);
    setSearchResults(searchResults);
  }, 500, {
    leading: true,
    trailing: true,
    maxWait: 300,
  });

  useEffect(() => {
    inputRef.current.focus();
    window.addEventListener(
      WINDOW_EVENTS.KEYDOWN,
        handleKeyPress,
    );

    return () => {
      debouncedSearchQuery.cancel();
      window.removeEventListener(
        WINDOW_EVENTS.KEYDOWN,
        handleKeyPress
      );
    };
  }, []);

  // used a "proxy function" for debouncedGetFeedSuggestion because
  // react is throwing the event object to the garbage collector
  // before it can be used by the debounced function
  const triggerSearchQuery = (event) => {
    const searchValue = event.target.value;

    if (searchValue === '') {
      setSearchValue(searchValue);
      setSearchResults(Object.values(props.stationMetadataByStationStableId));
      return;
    }

    debouncedSearchQuery(searchValue);
  };

  const handleOnSelectStation = (stationMetadata: StationMetadataInterface) => {
    setIsMapDropdownOpen(false);
    setSearchValue('');
    setSearchResults(Object.values(props.stationMetadataByStationStableId));
    props.handleOnSelectStation(stationMetadata);
  };

  const dropdownStyle: React.CSSProperties = {
    backgroundColor: 'white',
    display: 'flex',
    zIndex: 10,
    position: 'absolute',
    width: '500px',
    height: '500px',
    right: '50%',
    top: '100%',
    marginTop: '10px',
    boxShadow: `0 2px 4px 0 ${variables.transitgrey}`,
    color: variables.transitdarkgrey,
  };

  const dropdownRowStyle = (isActive): React.CSSProperties => {
    return {
      display: 'flex',
      position: 'relative',
      cursor: 'pointer',
      padding: '15px 20px',
      backgroundColor: isActive ? variables.transitlightgreen : 'white',
      borderRadius: 0,
      zIndex: 1,
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      color: variables.transitdarkgrey,
      fontWeight: 'bold',
    };
  };

  const renderStationList = () => {
    return (
      <div>
        <div style={{ ...dropdownRowStyle(false), borderBottom: `1px solid ${variables.transitdarkgrey}` }}>
          Stations
        </div>
        <HighlightedText matchString={searchValue} style={{ overflow: 'scroll', height: 'calc(100% - 54px)' }}>
          <KeyboardActiveList
            ref={keyboardRef}
            onSelect={handleOnSelectStation}
            list={searchResults || []}
            renderItem={(stationData: StationMetadataInterface, index, isActive) => { // tslint:disable-line jsx-no-lambda max-line-length
              const platforms = Object.values(stationData.stopByStopStableId);
              const routeStopImageUrls = Array.from(new Set(
                platforms.map(stop => `https://data.transitapp.com/images/svgx/${stop.routeStopImage}-mono.svg`)
              ));

              return (
                <div
                  id={`keyboard-active-list-${index}`}
                  key={stationData.stopId}
                  style={dropdownRowStyle(isActive)}
                  onMouseDownCapture={() => handleOnSelectStation(stationData)}
                  onMouseEnter={() => {
                    keyboardRef.current.handleOnEnter(index);
                    stationMapRef.current.updateActiveMarker(searchResults[index]);
                  }}
                >
                  <span style={{ marginRight: '5px' }}>
                    {
                      t(
                        'pathways.station_name',
                        { station_name: stationData.stopName.replace(/ ?station/i, '') },
                      )
                    }
                  </span>
                  {routeStopImageUrls.map((routeStopImageUrl, index) => (
                    <span key={index} style={{ marginRight: '5px' }}>
                      <img
                        src={routeStopImageUrl}
                        height="20"
                        width="20"
                        alt={t('pathways.route_image')}
                      />
                    </span>
                  ))}
                </div>
              );
            }}
          />
        </HighlightedText>
      </div>
    );
  };

  const renderStationFilter = () => {
    const style = dropdownRowStyle(false);

    const borderStyle = `1px solid ${variables.transitdarkgrey}`;
    return (
      <div style={{ borderRight: borderStyle }}>
        <div style={{ ...style, borderBottom: borderStyle }}>
          Filter by Route
        </div>
        <div style={{ overflow: 'scroll', height: 'calc(100% - 54px)' }}>
          {[...routeByGlobalRouteId.values()].map((route: RouteInfo) => {
            const { globalRouteId, routeShortName, routeColor, routeTextColor, shouldKeepRoute } = route;
            return (
              <div key={String(globalRouteId)} style={{ ...style, backgroundColor: `#${routeColor}`, color: `#${routeTextColor}` }}>
                <span style={{ marginRight: '5px' }}>
                  {routeShortName ? routeShortName : globalRouteId}
                </span>
                <Checkbox
                  label={null}
                  checked={shouldKeepRoute}
                  onChange={((shouldKeep) => {
                    route.shouldKeepRoute = shouldKeep;
                    setRoute(route);
                  })}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderStationSearch = () => {
    return (
      <div
        onMouseLeave={() => setIsMapDropdownOpen(false)} // tslint:disable-line:jsx-no-lambda
        onBlur={(event) => {
          // @ts-ignore
          if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
            setIsMapDropdownOpen(false);
          }
        }} // tslint:disable-line:jsx-no-lambda
        style={dropdownStyle}
      >
        {renderStationFilter()}
        {renderStationList()}
        <StationMap
          feed={props.feed}
          markers={searchResults}
          activeMarkerImageUrl="/images/active-pin.svg"
          disableDefaultUI={true}
          markerImageUrl="/images/pin.svg"
          onMarkerClick={(index: number) => { // tslint:disable-line:jsx-no-lambda
            keyboardRef.current!.handleOnEnter(index);
            keyboardRef.current!.scrollIntoView(index, 'nearest');
          }}
          onRef={ref => stationMapRef.current = ref} // tslint:disable-line:jsx-no-lambda
        />
      </div>
    );
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '350px',
        zIndex: 10,
        borderRadius: '8px',
        backgroundColor: variables.transitmediumgrey,
        color: variables.transitdarkgrey,
        padding: '10px 20px',
      }}
    >
      <div style={{ display: 'flex', position: 'relative', alignItems: 'center', width: '100%' }}>
        <i style={{ fontSize: '20px' }} className="fa fa-search" aria-hidden="true"/>
        <input
          style={{ paddingLeft: '20px', border: 'none', backgroundColor: 'unset', width: '100%' }}
          ref={inputRef}
          type="text"
          placeholder={t('pathways.search_for_station')}
          autoComplete="off"
          onChange={triggerSearchQuery}
          value={searchValue}
          onClick={() => setIsMapDropdownOpen(true)}
          onKeyDown={() => setIsMapDropdownOpen(true)}
        />
      </div>

      {isMapDropdownOpen ? renderStationSearch() : null}
    </div>
  );
};
