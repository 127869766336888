export interface StopInterface {
  stopCode: string;
  stopName: string;
  stopId: string;
  stopStableId: string;
  stopLat: number;
  stopLon: number;
  routeColor?: string;
  routeShortName?: string;
  routeTextColor?: string;
  routeStopImage?: string;
  wheelchairBoarding: '1' | '2';
}

export interface PathwayInterface {
  pathwayId: string;
  fromStopId: string;
  toStopId: string;
  pathwayMode: '1' | '2';
  isBidirectional: '0' | '1';
  traversalTime: string;
  signpostedAs?: string;
  isAccessible: boolean;
}

export interface StationMetadataInterface {
  pathwaysExist: boolean;
  stopName: string;
  stopId: string;
  stopStableId: number;
  stopLat: number;
  stopLon: number;
  stopByStopStableId: ObjectById<StopInterface>;
  entranceByEntranceStableId: ObjectById<StopInterface>;
  pathwayByPathwayId: ObjectById<PathwayInterface>;
  transferByPathwayId: ObjectById<PathwayInterface>;
}

export interface StationMetadataByStationStableIdIdInterface {
  [stationStableId: number]: StationMetadataInterface;
}

export interface ObjectById<T> {
  [key:string] : T;
}

export enum PATHWAY_ERROR_CATEGORIES {
  WARNING = 'warning',
  ERROR = 'error',
}

export interface PathwayFieldError {
  category: PATHWAY_ERROR_CATEGORIES;
  value: any;
  message: string;
}

export interface PathwayError {
  ['station_stable_id']?: {
    ['from_stop_id']?: {
      ['to_stop_id']?: {
        ['field']?: PathwayFieldError;
      }
    }
  };
}
export interface PathwayErrors {
  [PATHWAY_ERROR_CATEGORIES.WARNING]: PathwayError;
  [PATHWAY_ERROR_CATEGORIES.ERROR]: PathwayError;
}
