import React from 'react';
import TransitLogo from '@components/TransitLogo/TransitLogo';
import { BarLoader } from 'react-spinners';

export default class LoadingPage extends React.Component {
  render() {
    return (
      <div className="page-full-screen login-page d-flex align-items-center justify-content-center">
      <div className="login-window">
        <div className="transit-dashboard-logo d-flex flex-wrap align-items-center justify-content-center">
          <div className="form-header d-flex justify-content-center">
            <TransitLogo/>
          </div>

          <BarLoader color="#30b566"/>
        </div>
      </div>
    </div>
    );
  }
}
