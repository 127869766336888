import sliced from 'sliced';

export default class CsvTable implements Iterable<string[]> {
  minColumnWidths = [];
  headers = [];
  rowsConcat = [];

  *[Symbol.iterator]() { // tslint:disable-line function-name
    for (let i = 0; i < this.length; i += 1) {
      yield this.getRow(i);
    }
  }

  get length() {
    return this.headers.length !== 0
      ? this.rowsConcat.length / this.headers.length
      : 0;
  }

  getRow = (rowIndex) : string[] => {
    const rowStartAt = this.headers.length * rowIndex;
    const rowEndAt = rowStartAt + this.headers.length;
    return sliced(this.rowsConcat, rowStartAt, rowEndAt)
     .map(this.decodeValue);
  }

  getCell = (rowIndex: number, columnIndex: number) => {
    const rowStartAt = this.headers.length * rowIndex;
    const encodedCellValue = this.rowsConcat[rowStartAt + columnIndex];
    return this.decodeValue(encodedCellValue);
  }

  getColumn = (columnIndex: number) => {
    const column = [];

    for (let i = 0; i < this.length; i += 1) {
      column.push(this.getCell(i, columnIndex));
    }

    return column;
  }

  push = (row: string[]) => {
    if (!this.headers.length) {
      return row.map((columnValue, columnIndex) => {
        this.registerColumnWidth(columnValue, columnIndex);
        this.headers.push(columnValue);
      });
    }

    if (row.length !== this.headers.length) {
      console.log('Error, bad csv line', row); // tslint:disable-line no-console
      return;
    }

    row.forEach((columnValue, columnIndex) => {
      this.registerColumnWidth(columnValue, columnIndex);
      this.rowsConcat.push(this.encodeValue(columnValue));
    });
  }

  // TODO: prepropulate the index with known
  // repeated data that can impact the compression negatively
  // if the compression is done naively.
  // ex: encode "1" with 1 and not 2984.
  // ex: encode "" with a small integer and not 19027.

  encodeIndex = new Map();
  decodeIndex = [];
  private decodeValue = (value: number) => {
    return this.decodeIndex[value];
  }

  private encodeValue = (value : string) : number => {
    if (!this.encodeIndex.has(value)) {
      this.encodeIndex.set(value, this.encodeIndex.size);
      this.decodeIndex.push(value);
    }

    return this.encodeIndex.get(value);
  }

  private registerColumnWidth = (columnValue, columnIndex) => {
    const shouldOverrideWidth = !this.minColumnWidths[columnIndex]
          || columnValue.length > this.minColumnWidths[columnIndex];

    if (shouldOverrideWidth) {
      this.minColumnWidths[columnIndex] = columnValue.length;
    }
  }
}
