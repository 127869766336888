import { isEqual } from 'lodash';
import React from 'react';
import { SortDirection, SortDirectionType } from 'react-virtualized';

import Table from '@components/Table/lib/Table';

export interface SortConfig {
  sortBy: string;
  sortDirection: SortDirectionType;
  sortFunction?(rowIndexA, rowIndexB): number;
}

interface SortTableProps {
  table: any;
  defaultSortConfigs?: SortConfig[];
  searchConfig: any;
  children(table, sortConfig, toggleSort, sortNotice: string): any;
}

interface SortTableState {
  sortedRowIndexes: number[];
  sortNotice: string;
  sortConfigs: SortConfig[];
}

export default class SortTable extends React.Component<SortTableProps, SortTableState> {
  constructor(props) {
    super(props);

    this.state = {
      sortedRowIndexes: undefined,
      sortNotice: undefined,
      sortConfigs: [],
    };
  }

  componentDidMount() {
    const { defaultSortConfigs } = this.props;

    if (defaultSortConfigs) {
      this.sort(defaultSortConfigs);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { defaultSortConfigs } = this.props;
    const { sortConfigs } = this.state;
    const searchConfigIsEqual = isEqual(prevProps.searchConfig, this.props.searchConfig);

    if (searchConfigIsEqual) {
      return;
    }

    if (sortConfigs.length > 0) {
      this.sort(sortConfigs);
    }

    if (defaultSortConfigs) {
      this.sort(defaultSortConfigs);
    }

    this.sort([{ sortBy: '0', sortDirection: SortDirection.ASC }]);
  }

  sort = (sortConfigs: SortConfig[]) => {
    const { table } = this.props;
    const { sortedRowIndexes } = this.state;

    const isTableAlreadySorted = isEqual(sortConfigs, this.state.sortConfigs) && !!this.state.sortedRowIndexes;

    this.setState({
      sortNotice: 'Sorting Table ...',
    });

    const sortStrategy = isTableAlreadySorted
      // if the table is already sorted, then simply reversing the array will provide better performance;
      ? () => sortedRowIndexes.reverse()
      : () => {
        const collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: 'base',
        });

        const defaultSortFunction = (
          rowIndexA: number,
          rowIndexB: number,
          sortBy: string,
          sortDirection: SortDirectionType,
        ) => {
          const columnIndex = parseInt(sortBy, 10);
          const ordering = (sortDirection === SortDirection.DESC ? -1 : 1);
          return ordering * collator.compare(
            table.getCell(rowIndexA, columnIndex),
            table.getCell(rowIndexB, columnIndex),
          );
        };

        const rowIndexes = Array.from({ length: table.length }).map((value, index) => index);
        return rowIndexes.sort((rowIndexA, rowIndexB) => {
          for (const { sortBy, sortDirection, sortFunction } of sortConfigs) {

            const sortOrder = sortFunction
              ? sortFunction(rowIndexA, rowIndexB)
              : defaultSortFunction(rowIndexA, rowIndexB, sortBy, sortDirection);

            if (sortOrder === 0) {
              continue;
            }

            return sortOrder;
          }

          return 0;
        });
      };

    // doing heavy computation "blocks" the rendering.
    // setImmediate is used to make sure that the notice
    // is displayed before starting the computation
    setImmediate(() => {
      this.setState({
        sortConfigs,
        sortedRowIndexes: sortStrategy(),
        sortNotice: null,
      });
    });
  }

  getSortedTable = () => {
    const { table } = this.props;
    const { sortedRowIndexes, sortConfigs } = this.state;

    if (sortConfigs.length > 0) {
      const sortedTable = new Table();
      sortedTable.push(table.headers);
      sortedRowIndexes.forEach((sortedRow) => {
        sortedTable.push(table.getRow(sortedRow));
      });

      return sortedTable;
    }

    return table;
  }

  render() {
    const { sortConfigs, sortNotice } = this.state;

    const sortedTable = this.getSortedTable();

    return (
      this.props.children(
        sortedTable,
        sortConfigs,
        this.sort,
        sortNotice,
      )
    );
  }
}
