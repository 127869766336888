import React from 'react';
import { Table, Column, SortDirection } from 'react-virtualized';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

import store from '@stores';
import { withStore } from '@stores/withStore';
import SearchBanner, { SearchConfig } from '@pages/banners/components/BannersList/SearchBanners';
import Button from '@components/Button/Button';
import { AutocompleteStore } from '@stores/autocomplete';
import BannerFormWrapper from '@pages/banners/components/BannerForm/BannerFormWrapper';

import '@pages/banners/components/BannersList/BannersList.scss';
import 'react-dates/lib/css/_datepicker.css';
import 'react-virtualized/styles.css';
import { DATE_TIME } from '@constants';
import Tooltip from '@components/Tooltip/Tooltip';
import api from '@stores/api';

interface BannersListProps {
  store: typeof store;
  route: any;
  location: any;
  match: any;
}

class BannersList extends React.Component<BannersListProps, any> {

  state = {
    sortDirection: null,
    sortBy: null,
    areArchivedBannersHidden: false,
    searchInput: '',
    filteredBanners: this.props.store.banners.banners,
    startDate: null,
    endDate: null,
    focusedInput: null,
    svgList: [],
  };

  async componentDidMount() {
    const images = await api.images.getImages();
    this.setState({ svgList: [images] });

    await this.ensureUrlAndBannerModalIsMatch();
  }

  componentWillUnmount() {
    if (this.hideModal) {
      this.hideModal();
    }
  }

  async ensureUrlAndBannerModalIsMatch() {
    const { router, banners, modals } = this.props.store;
    const { params } = router.getUrlData();

    const paramsId = params.banner_id && String(params.banner_id);
    if (!paramsId) {
      return;
    }

    const isOnCreateBannerUrl = paramsId === 'create';
    if (isOnCreateBannerUrl) {
      return this.openCreateBannerModal();
    }

    const banner = await banners.getBanner(paramsId);
    if (banner) {
      await this.openBannerModal(banner.id);
    } else {
      this.hideModal = modals.showModal({
        title: '404: Modal Not Found',
        component: (
          <div className="dialog-modal-wrapper">
            <p>Oops! Banner couldn't be found.</p>
            <div className="dialog-modal-button-list">
              <Button value="Close" className="no-background" onClick={this.hideBannerModal}/>
            </div>
          </div>
        ),
      }, {
        onHide: () => { router.redirect('/banners'); },
      });
    }
  }

  private tableRef: any;
  componentDidUpdate(prevProps: Readonly<BannersListProps>, prevState: Readonly<any>, snapshot?: any): void {
    this.tableRef.forceUpdateGrid();
  }

  async onDatesChange({ startDate, endDate }) {
    const { filteredBanners } = this.state;

    await this.setState({
      startDate,
      endDate,
    });

    if (!startDate && !endDate) {
      return this.setState({
        filteredBanners: this.props.store.banners.banners,
      });
    }

    const filteredBannersUsingDate = filteredBanners.filter((banner) => {
      return moment(startDate._d).isBefore(moment(banner.start_at))
        && moment(endDate._d).isAfter(moment(banner.end_at));
    });

    return this.setState({
      filteredBanners: filteredBannersUsingDate,
    });
  }

  handleOnSearchChange = async (newSearchConfig: SearchConfig) => {
    const { searchInput, areArchivedBannersHidden } = newSearchConfig;

    const additionalCondition = areArchivedBannersHidden
      ? banner => !!banner.is_archived !== areArchivedBannersHidden
      : null;

    if (searchInput || areArchivedBannersHidden) {
      const filteredBannersUsingSearchInput = AutocompleteStore.searchStaticIndex(this.props.store.banners.banners, {
        additionalCondition,
        searchOptions: [{
          normalizeRow: banner => `${banner.title} ${banner.city} ${banner.in_beta} ${banner.id}`,
          searchValues: searchInput.split(' '),
        }],
      });

      return this.setState({
        areArchivedBannersHidden,
        searchInput,
        filteredBanners: filteredBannersUsingSearchInput,
      });
    }

    return this.setState({
      areArchivedBannersHidden,
      searchInput,
      filteredBanners: this.props.store.banners.banners,
    });
  }

  hideModal: any;
  openBannerModal = async (bannerId) => {
    const { modals, router } = this.props.store;

    const modalConfig = !bannerId
      ? { title: 'New Banner', url: '/banners/create' }
      : { title: `Banner (${bannerId})`, url: `/banners/${bannerId}` };

    const banner = bannerId && await store.banners.getBanner(bannerId);

    const modalLifeCycle = {
      confirmOnClose: undefined,
      onShow: () => { router.redirect(modalConfig.url); },
      onHide: () => { router.redirect('/banners'); },
    };

    const openModalWithLifeCycle = (modalLifeCycle) => {
      /*
       * If a user updates a field in the form, a confirm dialogue is added to the modal life cycle configuration.
       * This way, a warning popups if the user closes the modal without having saved the form.
       */
      const onChange = () => openModalWithLifeCycle({
        ...modalLifeCycle,
        confirmOnClose: 'Warning: Your changes will be lost if you don’t save them',
      });

      this.hideModal = modals.showModal({
        title: modalConfig.title,
        component: (
          <BannerFormWrapper
            defaultBanner={banner}
            hideModal={this.hideBannerModal}
            onChange={onChange}
            svgList={this.state.svgList}
          />
        ),
      }, modalLifeCycle);
    };

    openModalWithLifeCycle(modalLifeCycle);
  }

  openCreateBannerModal = async () => {
    await this.openBannerModal(undefined);
  }

  hideBannerModal = () => {
    this.hideModal();
  }

  rowGetter = ({ index }) => {
    const banner = this.state.filteredBanners[index];
    return banner;
  }

  renderTable = () => {
    const { sortBy, filteredBanners } = this.state;

    return (
          <Table
            ref={ref => this.tableRef = ref}
            height={1500}
            headerHeight={24}
            noRowsRenderer={this.noRowsRenderer}
            rowHeight={55}
            rowGetter={this.rowGetter}
            rowCount={filteredBanners.length}
            sort={this.toggleSortDirection}
            sortDirection={SortDirection.ASC}
            sortBy={sortBy}
            width={1000}
            onRowDoubleClick={this.handleClickOnTableRow}
          >
            <Column
              headerRenderer={this.renderTableHeader}
              dataKey="id"
              width={100}
            />
            <Column
              headerRenderer={this.renderLineHeader}
              dataKey="title"
              width={600}
            />
            <Column
              headerRenderer={this.renderDescriptionHeader}
              dataKey="city"
              width={350}
            />
            <Column
              headerRenderer={this.renderTableHeader}
              width={250}
              dataKey="start_at"
              cellRenderer={this.renderStartTime}
            />
            <Column
              headerRenderer={this.renderTableHeader}
              width={250}
              dataKey="end_at"
              cellRenderer={this.renderEndTime}
            />
            <Column
              headerRenderer={this.renderTableHeader}
              width={250}
              dataKey="priority"
            />
            <Column
              headerRenderer={this.renderTableHeader}
              width={250}
              dataKey="in_beta"
              cellRenderer={this.renderBetaStatus}
            />
          </Table>
    );
  }

  renderLineHeader = () => {
    return (
      <div className="banner-table-header">
        TITLE
      </div>
    );
  }

  renderDescriptionHeader = () => {
    return (
      <div className="banner-table-header">
        DESCRIPTION
      </div>
    );
  }

  renderTableHeader = ({ dataKey }) => {
    const isColumnSortedActive = this.state.sortBy === dataKey;
    const isAscending = this.state.sortDirection === SortDirection.ASC;
    const caretByDirection = <i className={`fa caret fa-caret-${ isAscending ? 'up' : 'down'}`} />;

    return (
      <div className="banner-table-header">
        {dataKey}
        <span className="column-header-caret">{isColumnSortedActive && caretByDirection}</span>
      </div>
    );
  }

  renderStartTime = ({ rowIndex }) => {
    const { filteredBanners } = this.state;
    const banner = filteredBanners[rowIndex];
    return moment(banner.start_at).format(`${DATE_TIME.YEAR_MONTH_DAY} ${DATE_TIME.TWENTY_FOUR_HOUR}`);
  }

  renderEndTime = ({ rowIndex }) => {
    const { filteredBanners } = this.state;
    const banner = filteredBanners[rowIndex];
    return moment(banner.end_at).format(`${DATE_TIME.YEAR_MONTH_DAY} ${DATE_TIME.TWENTY_FOUR_HOUR}`);
  }

  renderBetaStatus = ({ rowIndex }) => {
    const { filteredBanners } = this.state;
    const banner = filteredBanners[rowIndex];
    const isArchived = banner.is_archived;
    const isInProduction = banner.in_beta === 0;
    const isInStaging = banner.in_beta === 1;

    if (isInProduction && !isArchived) {
      return 'Production';
    }

    if (isInStaging && !isArchived) {
      return 'Staging';
    }

    return 'Archived';
  }

  handleClickOnTableRow = async ({ index }) => {
    const { filteredBanners } = this.state;
    const banner = filteredBanners[index];
    return this.openBannerModal(banner && banner.id);
  }

  toggleSortDirection = async (options) => {
    const { sortDirection, filteredBanners } = this.state;

    const isAscending = sortDirection === SortDirection.ASC;
    const toggeledSortDirection = isAscending // if ASC
      ? SortDirection.DESC // change it to DESC
      : SortDirection.ASC; // if DESC, change to ASC

    const sortOptions = {
      filteredBanners,
      sortBy: options.sortBy,
      sortDirection: toggeledSortDirection,
    };

    await this.setState({
      ...this.sort(sortOptions),
    });
  }

  noRowsRenderer() {
    return <div className="noRows">No rows</div>;
  }

  sort(options?: { sortBy?: number, sortDirection?: string, rows?: string[] }) {
    const sortDirection = options && options.sortDirection || this.state.sortDirection;
    const rows = options && options.rows || this.state.filteredBanners;
    const sortBy = options && options.sortBy;

    const isSortColumnDefined = typeof sortBy !== 'undefined';
    if (!isSortColumnDefined) {
      return {
        sortDirection,
        sortBy: null,
        filteredBanners: rows,
      };
    }

    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    });

    const ordering = (sortDirection === SortDirection.DESC ? -1 : 1);
    const sortFn = (a, b) => ordering * collator.compare(a[sortBy], b[sortBy]);
    const filteredBanners = [...rows].sort(sortFn);

    return {
      filteredBanners,
      sortDirection,
      sortBy,
    };
  }

  render() {
    const {
      areArchivedBannersHidden,
      searchInput,
    } = this.state;

    return (
      <React.Fragment>
        <div className="banner-page">
          <div className="banner-header">
            <div className="banner-filters-container">
              <SearchBanner
                openDropdownOnHover={true}
                searchInput={searchInput}
                areArchivedBannersHidden={areArchivedBannersHidden}
                onChange={this.handleOnSearchChange}
              />
              <div className="date-range-wrapper">
              <DateRangePicker
                startDate={this.state.startDate}
                startDateId={''}
                endDate={this.state.endDate}
                endDateId={''}
                onDatesChange={({ startDate, endDate }) => this.onDatesChange({ startDate, endDate })} /* tslint:disable-line jsx-no-lambda max-line-length */
                focusedInput={this.state.focusedInput}
                showClearDates={true}
                isOutsideRange={() => false} /* tslint:disable-line jsx-no-lambda */
                onFocusChange={focusedInput => this.setState({ focusedInput })} /* tslint:disable-line jsx-no-lambda */
                displayFormat="YYYY-MM-DD"
              />
              </div>
            </div>
            <div className="create-help-wrapper">
            <Button
              value="Create Banner"
              onClick={this.openCreateBannerModal}
              className="create-button"
            />
            <Tooltip
              message={'Need help? Read the wiki!'}
              theme="light-border"
              placement="top-end"
              /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
              render={() => (
                <div className="question-circle">
                  <a href="https://github.com/TransitApp/Documentation/wiki/In-App-Banners" target="_blank">
                    <i className="question-mark fa fa-question-circle"/>
                  </a>
                </div>
              )}
            />
          </div>
          </div>
          <div>
            {this.renderTable()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withStore(BannersList);
