import cx from 'classnames';
import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from '@components/Button/Button';
import StationMap from './StationMap';

import store from '@stores';
import './StationAccordionList.scss';
import { StationMetadataInterface, StopInterface } from '@pages/feed/components/PathwaysPage/PathwayTypes';
import { EntranceModal } from '@pages/feed/components/PathwaysPage/EntranceModal';
import { withStore } from '@stores/withStore';
import wait from '@utils/wait';

interface StationAccordionListProps {
  store?: typeof store;
  google: any;
  stationMetadata: StationMetadataInterface;
  defaultEntranceIndex: number;
  onActiveCardChange(index: number): void;
  onChange(stationMetadata: StationMetadataInterface): void;
}

// tslint:disable-next-line:variable-name
export const StationAccordionList: React.FC<StationAccordionListProps> = (props) => {
  const { t } = useTranslation('common');

  const [cardIndex, setCardIndex] = React.useState<number>(props.defaultEntranceIndex);

  let hideModal: any;
  React.useEffect(() => {
    return function cleanup() {
      if (hideModal) {
        hideModal();
      }
    };
  }, []);

  const openUpdateEntranceModal = (entranceData?: StopInterface) => {
    const isUpdating = !!entranceData;

    hideModal = store.modals.showModal({
      title: isUpdating
        ? t('pathways.edit_entrance')
        : t('pathways.add_entrance'),
      component: (
        <EntranceModal
          entranceData={entranceData || {
            stopCode: '',
            stopName: '',
            stopStableId: `tmp_${props.stationMetadata.stopStableId}_${Object.keys(props.stationMetadata.entranceByEntranceStableId).length}`, // tslint:disable-line max-line-length
            stopId: `${props.stationMetadata.stopStableId}_${Object.keys(props.stationMetadata.entranceByEntranceStableId).length}`,
            stopLat: props.stationMetadata.stopLat,
            stopLon: props.stationMetadata.stopLon,
            wheelchairBoarding: '2',
          }}
          onChange={async (stationMetadata) => { // tslint:disable-line jsx-no-lambda
            props.onChange(stationMetadata);
            hideModal();

            // wait 100ms in order to achieve staggered animation
            // the goal is to avoid accordion and hideModal animation
            // to be triggered in the same time.
            await wait(100);

            const isCreatingNewEntrance = !isUpdating;
            if (isCreatingNewEntrance) {
              const entrances = Object.values(stationMetadata.entranceByEntranceStableId);
              focusEntrance(entrances.length - 1);
            }
          }}
          stationMetadata={props.stationMetadata}
        />
      ),
    });
  };

  const focusEntrance = (entranceIndex: number) => {
    props.onActiveCardChange(entranceIndex);
    setCardIndex(entranceIndex);
  };

  const renderAccordionCard = (entrance: StopInterface, index: number, entrances: StopInterface[]) => {
    const cardIsActive = (index === cardIndex);
    const cardClassName = cx('accordion-card-wrapper', {
      'active': cardIsActive,
    });

    return (
      <Card
        key={index}
        className={cardClassName}
        onClick={() => setCardIndex(index)} // tslint:disable-line jsx-no-lambda
        onDoubleClick={() => openUpdateEntranceModal(entrance)} // tslint:disable-line jsx-no-lambda
      >
        <div className="code-name-headers" onClick={() => props.onActiveCardChange(index)/* tslint:disable-line jsx-no-lambda max-line-length*/}>
          {cardIsActive && (
            <Accordion.Toggle
              className="accordion-code-header edit-button"
              as={Card.Header}
              eventKey={`${index}`}
              onClick={() => openUpdateEntranceModal(entrance)} // tslint:disable-line:jsx-no-lambda
            >
              {t('button.edit')}
            </Accordion.Toggle>
          )}
          <Accordion.Toggle
            className="accordion-name-header"
            as={Card.Header}
            eventKey={`${index}`}
          >
            {entrance.stopName || entrance.stopId}
          </Accordion.Toggle>
          { entrance.stopCode && (
            <Accordion.Toggle
              className="accordion-code-header"
              as={Card.Header}
              eventKey={`${index}`}
            >
              {entrance.stopCode}
            </Accordion.Toggle>
          )}
        </div>
        <Accordion.Collapse className="collapse-map" eventKey={`${index}`} >
          <Card.Body className="accordion-body">
            <div className="station-map" hidden={!cardIsActive}>
              <StationMap
                disableDefaultUI={true}
                disableDoubleClickZoom={true}
                draggable={false}
                markers={entrances}
                activeMarker={entrance}
                initialCenter={{ lat: entrance.stopLat, lng: entrance.stopLon }}
                center={{ lat: entrance.stopLat, lng: entrance.stopLon }}
                onMarkerClick={focusEntrance}
                activeMarkerImageUrl="/images/entrance-icon.png"
                markerImageUrl="/images/entrance-icon-small.png"
                zoom={16}
              />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  const entrances = Object.values(props.stationMetadata.entranceByEntranceStableId);

  return (
    <div className="accordion-list">
      <Accordion
        defaultActiveKey={`${props.defaultEntranceIndex}`}
        activeKey={`${cardIndex}`}
      >
        {entrances.map((entrance, index, entrances) => renderAccordionCard(entrance, index, entrances))}
      </Accordion>
      {/* tslint:disable-next-line jsx-no-lambda */}
      <Button className="entrance-button" value={t('pathways.add_entrance')} onClick={() => openUpdateEntranceModal()}/>
    </div>
  );
};

export default withStore<StationAccordionListProps>(StationAccordionList);
