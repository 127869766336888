import { BarLoader } from 'react-spinners';
import React from 'react';

import store from '@stores';
import { withStore } from '@stores/withStore';
import ActiveNavList from '@components/ActiveNavList/ActiveNavList';
import UsersPage from './components/UsersPage/UsersPage';
import PrometheusAlerts from './components/PrometheusAlerts/PrometheusAlerts';
import MuninPage from './components/MuninPage/MuninPage';
import DispatcherQueuePage from './components/DispatcherQueuePage/DispatcherQueuePage';
import TransitLogo from '@components/TransitLogo/TransitLogo';

import './AdminPage.scss';

interface AdminPageProps {
  store: typeof store;
  location: any;
  route: any;
  match: any;
}

interface AdminPageState {
  isLoading: boolean;
}

class AdminPage extends React.Component<AdminPageProps, AdminPageState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    const { store } = this.props;

    await store.transit.getFeedList();

    this.setState({
      isLoading: false,
    });
  }

  renderLoading() {
    return (
      <div className="page-full-screen login-page d-flex align-items-center justify-content-center">
        <div className="login-window">
          <div className="transit-dashboard-logo d-flex flex-wrap align-items-center justify-content-center">
            <div className="form-header d-flex justify-content-center">
              <TransitLogo/>
            </div>

            <BarLoader color="#30b566"/>

          </div>
        </div>
      </div>
    );
  }

  renderAdminPage() {
    const { route, store: { router } } = this.props;
    return (
      <div className="page-container globalPage">
        <div className="transit-nav-container">
          <ActiveNavList
            routes={this.props.route.routes}
            className="current-transit-nav"
            linkClassName="current-transit-nav-link"
          />

        </div>
        <div className="transit-page-content">
          {router.renderRoute(route.routes, {})}
        </div>
      </div>
    );
  }

  render() {
    return this.state.isLoading
      ? this.renderLoading()
      : this.renderAdminPage();
  }
}

export default withStore(AdminPage);
export const adminSubPages = {
  DispatcherQueuePage,
  MuninPage,
  PrometheusAlerts,
  UsersPage,
};
