import React from 'react';
import { useTranslation } from 'react-i18next';

import store from '@stores';
import { withStore } from '@stores/withStore';

import { AutosizedBezierCurve } from '@components/BezierCurve/BezierCurve';
import Checkbox from '@components/Checkbox/Checkbox';
import Input from '@components/Input/Input';
import Tooltip from '@components/Tooltip/Tooltip';
import AccessibleButton from '@components/AccessibleButton/AccessibleButton';

import { StationMetadataInterface, PathwayInterface, StopInterface, PATHWAY_ERROR_CATEGORIES } from './PathwayTypes';
import StopIdentifier from '../../../pathways/StopIdentifier/StopIdentifier';

import './PathwaysPage.scss';

interface TransfersUIProps {
  onChange(stationMetadata: StationMetadataInterface): void;
  store: typeof store;
  stationMetadata: StationMetadataInterface;
  setError(
    stationStableId: number,
    fromStopId: string,
    toStopId: string,
    field: string,
    errorMessage: string,
    errorValue: any,
    errorCategory: PATHWAY_ERROR_CATEGORIES,
  ): void;
}

interface TransferData extends PathwayInterface {
  onChange(transfer): void;
}

const getPlatforms = (stationMetadata: StationMetadataInterface): StopInterface[] => {
  return Object.values(stationMetadata.stopByStopStableId);
};

const getTransferIdFromStopToStop = (fromStop, toStop) => {
  return `${fromStop.stopStableId}:${toStop.stopStableId}`;
};

const getTransferFromStopToStop = (stationMetadata, fromStop, toStop): PathwayInterface => {
  const transferIdFromStopToStop = getTransferIdFromStopToStop(fromStop, toStop);
  const transferIdToStopFromStop = getTransferIdFromStopToStop(toStop, fromStop);

  const transferFound = stationMetadata.transferByPathwayId[transferIdFromStopToStop]
    || stationMetadata.transferByPathwayId[transferIdToStopFromStop];

  return transferFound || {
    pathwayId: transferIdFromStopToStop,
    fromStopId: fromStop.stopId,
    toStopId: toStop.stopId,
    pathwayMode: '2', // 1 = walkway (wheelchaire accessible); 2 = stairs (not wheelchaire accessible)
    isBidirectional: '1',
    traversalTime: '120',
    signpostedAs: toStop.stopName,
    isAccessible: true,
  };
};

const enhanceStationMetadata = (stationMetadata: StationMetadataInterface): StationMetadataInterface => {
  getPlatforms(stationMetadata).forEach((fromStop: StopInterface) => {
    getPlatforms(stationMetadata).forEach((toStop: StopInterface) => {
      const fromStopStableId = fromStop.stopStableId;
      const toStopStableId = toStop.stopStableId;

      if (fromStopStableId === toStopStableId) {
        return;
      }

      const transferIdFromStopToStop = getTransferIdFromStopToStop(fromStop, toStop);
      const transferIdToStopFromStop = getTransferIdFromStopToStop(toStop, fromStop);
      const foundTransfer = stationMetadata.transferByPathwayId[transferIdFromStopToStop]
        || stationMetadata.transferByPathwayId[transferIdToStopFromStop];

      if (foundTransfer) {
        return;
      }

      stationMetadata.transferByPathwayId[transferIdFromStopToStop] = getTransferFromStopToStop(
        stationMetadata,
        fromStop,
        toStop,
      );
    });
  });

  return stationMetadata;
};

// tslint:disable-next-line:variable-name
const TransfersUI: React.FC<TransfersUIProps> = (props) => {
  const { t } = useTranslation('common');

  const [stopIndex, setStopIndex] = React.useState<number>(0);
  const [stationMetadata, setStationMetadata] = React.useState<StationMetadataInterface>(props.stationMetadata);

  React.useEffect(() => {
    setStationMetadata(enhanceStationMetadata(stationMetadata));
  }, []);

  React.useEffect(() => {
    setStopIndex(0);
    setStationMetadata(enhanceStationMetadata(props.stationMetadata));
  }, [props.stationMetadata.stopId]);

  const updateTransferFromStopToStop = (oldTransfer: PathwayInterface, transfer) => {
    const newTransfer = Object.assign({}, oldTransfer, transfer);
    const newTransferId = newTransfer.pathwayId;
    stationMetadata.transferByPathwayId[newTransferId] = newTransfer;

    props.onChange(stationMetadata);
    setStationMetadata(stationMetadata);
  };

  const renderBezierCurve = () => {
    const heightOfToStopIdentifier = 50;
    const heightOfFromStopIdentifier = 42;

    return (
      <div className="pathways-bezier-curves">
        {getPlatforms(stationMetadata).map((stop, index, stops) => {
          // we dont create transfers to identical stops. Thus, there will always be one extra stops in the stops array
          if (index === stops.length - 1) {
            return;
          }

          const originY = ((heightOfFromStopIdentifier + 20) * stopIndex) + heightOfFromStopIdentifier / 2;
          const targetY = ((heightOfToStopIdentifier + 20) * index) + heightOfToStopIdentifier / 2;

          return (
            <AutosizedBezierCurve
              key={stop.stopId}
              originY={originY}
              targetY={targetY}
            />
          );
        })}
      </div>
    );
  };

  const renderIsAccessible = ({ isAccessible, onChange }: TransferData) => {
    return (
      <Checkbox
        label={t('pathways.can_transfer_label')}
        checked={isAccessible}
        onChange={isAccessible => onChange({ isAccessible })} // tslint:disable-line:jsx-no-lambda
        style={{ width: '120px' }}
      />
    );
  };

  const renderWalkTime = ({
                            fromStopId,
                            toStopId,
                            isAccessible,
                            traversalTime,
                            onChange,
                          }: TransferData, index?: number) => {
    const validateTraversalTime = (traversalTime) => {
      let warningMessage = '';

      const isTooQuick = parseInt(traversalTime, 10) <= 15;
      if (isTooQuick) {
        warningMessage = t('pathways.fast_traversal_time_warning');
      }

      const isTooSlow = parseInt(traversalTime, 10) >= 240;
      if (isTooSlow) {
        warningMessage = t('pathways.slow_traversal_time_warning');
      }

      props.setError(
        stationMetadata.stopStableId,
        fromStopId,
        toStopId,
        'traversalTime',
        warningMessage,
        traversalTime,
        PATHWAY_ERROR_CATEGORIES.WARNING,
      );

      return { warning: warningMessage };
    };

    const traversalTimeInput = (
      <Input
        key={stopIndex}
        hide={!isAccessible}
        value={traversalTime}
        onChange={(traversalTime: string) => { // tslint:disable-line:jsx-no-lambda
          const isNumberOrEmptyString = /^[0-9]+$|^$/; // only number or empty string;
          const isValid = isNumberOrEmptyString.test(traversalTime);
          if (isValid) {
            onChange({ traversalTime });
          }
        }}
        validate={validateTraversalTime}
      />
    );

    if (index === 0) {
      return (
        <div className="with-column-title margin-gutter">
          <div className="column-title">
            <i className="fa fa-clock-o clock-icon"/>
            <Tooltip
              message={t('pathways.tooltip.walk_time.transfer')}
              placement="top-end"
              /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
              render={() => (
                <div className="question-circle">
                  <i className="question-mark fa fa-question-circle"/>
                </div>
              )}
            />
          </div>
          <div className="walk-time">
            {traversalTimeInput}
          </div>
        </div>
      );
    }

    return (
      <div className="walk-time margin-gutter">
        {traversalTimeInput}
      </div>
    );
  };

  const renderWheelchairAccessible = ({ isAccessible, pathwayMode, onChange }: TransferData) => {
    const isWalkway = pathwayMode === '1';
    return (
      <AccessibleButton
        active={isWalkway}
        hide={!isAccessible}
        onChange={(isActive) => { // tslint:disable-line:jsx-no-lambda
          onChange({
            pathwayMode: isActive ? '1' : '2',
          });
        }}
      />
    );
  };

  const renderTransfersData = (toStop: StopInterface, index: number) => {
    const fromStop = getPlatforms(stationMetadata)[stopIndex];

    const transferFromStopToStop: PathwayInterface = getTransferFromStopToStop(stationMetadata, fromStop, toStop);
    const transferDataFromStopToStop: TransferData = {
      ...transferFromStopToStop,
      onChange: transfer => updateTransferFromStopToStop(transferFromStopToStop, transfer),
    };

    return (
      <div className="pathway-data-row">
        {renderIsAccessible(transferDataFromStopToStop)}
        {renderWheelchairAccessible(transferDataFromStopToStop)}
        {renderWalkTime(transferDataFromStopToStop, index)}
        {renderPlatform(
          toStop,
          index,
          t('pathways.to_platform'),
          {
            style: {
              visibility: transferFromStopToStop.isAccessible ? 'visible' : 'hidden',
              marginLeft: '15px',
            },
          },
        )}
      </div>
    );
  };

  const renderPlatform = (stop, index, title, options: { style?, onClick? } = {}) => {
    const icon = `https://data.transitapp.com/images/svgx/${stop.routeStopImage}-mono.svg`;

    const stationRegex = / ?station/i;
    const stopName = stationRegex.test(stop.stopName)
      ?
        t(
        'pathways.station_name',
        { station_name: stop.stopName.replace(stationRegex, '') },
        )
      : stop.stopName;

    if (index === 0) {
      return (
        <div className="with-column-title">
          <p className="column-title">{title}</p>
          <StopIdentifier
            backgroundColor={`#${stop.routeColor}`}
            textColor={`#${stop.routeTextColor}`}
            name={stopName}
            icon={icon}
            onClick={options.onClick}
            style={options.style}
          />
        </div>
      );
    }

    return (
      <StopIdentifier
        backgroundColor={`#${stop.routeColor}`}
        textColor={`#${stop.routeTextColor}`}
        name={stopName}
        icon={icon}
        onClick={options.onClick}
        style={options.style}
      />
    );
  };

  const renderTransfers = () => {
    return (
      <div className="pathways-page-content is-transfers">
        <div className="platforms-wrapper">
          {getPlatforms(stationMetadata).map((stop, index) => {
            return (
              <div className="platform-pathways-wrapper" key={index}>
                <div className="pathway-data-row">
                  {renderPlatform(
                    stop,
                    index,
                    t('pathways.from_platform'),
                    {
                      onClick: () => setStopIndex(index),
                      style: {
                        opacity: index === stopIndex ? 1 : 0.5,
                      },
                    },
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {renderBezierCurve()}
        <div className="platforms-wrapper">
          {getPlatforms(stationMetadata).filter((stop, index) => stopIndex !== index)
            .map((stop, index) => {
              return (
                <div className="platform-pathways-wrapper" key={index}>
                  <div className="pathway-data-row">
                    {renderTransfersData(stop, index)}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <div className="pathways-page-header">
        <div className="d-flex">
          <h2 className="title">{t('pathways.transfers_title')}</h2>
          <Tooltip
            message={t('pathways.tooltip.transfers_section')}
            placement="top"
            /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
            render={() => (
              <div className="question-circle">
                <i className="question-mark fa fa-question-circle"/>
              </div>
            )}
          />
        </div>
      </div>
      {renderTransfers()}
    </div>
  );
};

export default withStore(TransfersUI);
