import React from 'react';
import cx from 'classnames';
import { SortDirection, Column, AutoSizer, Table } from 'react-virtualized';
import CellCopyButton from '@components/CellCopyButton/CellCopyButton';

interface CsvTableViewProps {
  headers: any;
  rowsLength: number;
  rowGetter(rowIndex: number) : string[];
  isCellHighlighted(cell: any) : boolean;
  minColumnWidths: number[];
  hideColumns: boolean[];

  sortConfig: {
    sortBy: number;
    sortDirection: any;
  };
  toggleSort(sortBy) : void;
}

const colWidthToPx = colWidth => colWidth * 10 + 20;

export default class CsvTableView extends React.Component<CsvTableViewProps, any> {

  renderCell = (cell) => {
    const { cellData } = cell;

    const cellClassName = cx('csv-cell', {
      'is-highlighted': this.props.isCellHighlighted(cell),
    });

    return (
      <span className={cellClassName}>
        {cellData}
        <CellCopyButton value={cellData}/>
      </span>
    );
  }

  renderTableHeader = ({ dataKey }) => {
    const { headers, sortConfig } = this.props;
    const isColumnSortedActive = sortConfig.sortBy === parseInt(dataKey, 10);

    const isAscending = sortConfig.sortDirection === SortDirection.ASC;
    const caretByDirection = <i className={`fa caret fa-caret-${ isAscending ? 'up' : 'down'}`} />;

    const columnClassName = cx('csv-header-cell', {
      'is-sorted-column': isColumnSortedActive,
    });

    return (
      <div className={columnClassName}>
        <span className="csv-column-name">{headers[dataKey]}</span>
        <span className="csv-column-header-caret">{isColumnSortedActive && caretByDirection}</span>
      </div>
    );
  }

  render() {
    const { rowsLength, rowGetter, minColumnWidths, headers, sortConfig, toggleSort, hideColumns = [] } = this.props;

    const tableProps = (height: number) => {
      const newHeight = height - 30;
      const width = minColumnWidths.reduce((acc, cur, i) => {
        return acc + colWidthToPx(cur);
      }, 0);

      return {
        width,
        height: newHeight > 0 ? newHeight : 0,
        headerHeight: 24,
        rowHeight: 22,
        rowCount: rowsLength,
        rowGetter: ({ index }) => rowGetter(index),
        sortDirection: sortConfig.sortDirection,
        sortBy: `${sortConfig.sortBy}`,
        sort: toggleSort,
      };
    };

    const tableColumns = minColumnWidths.map((colWidth, index) => {
      const columnClassName = cx({
        'is-sorted-column': sortConfig.sortBy === index,
      });

      if (hideColumns[index]) {
        return null;
      }

      return (
        <Column
          disableSort={false}
          key={index}
          className={columnClassName}
          dataKey={`${index}`}
          width={colWidthToPx(colWidth)}
          label={headers}
          headerRenderer={this.renderTableHeader}
          cellRenderer={this.renderCell}
        />
      );
    });

    return (
      <AutoSizer
        disableWidth={true}
        children={({ height }) => { // tslint:disable jsx-no-multiline-js jsx-no-lambda
          return (
            <Table {...tableProps(height)} children={tableColumns}/>
          );
        }}
      />
    );
  }
}
