import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as i18n from 'react-i18next';

import { PasswordInput } from '@components/Input/PasswordInput';
import { FormSubmitButton } from '@components/Input/FormSubmitButton';
import { FullScreenForm } from '@components/Form/FullScreenForm';
import { withStore } from '@stores/withStore';
import store from '@stores';

interface ResetPasswordPageProps {
  store: typeof store;
  location: any;
}

interface ResetPasswordData {
  password: string;
  confirm_password: string;
}

const ResetPasswordPage = withStore((props: ResetPasswordPageProps) => {
  const { router, api, auth } = props.store;
  const { params: { passwordResetId }, query } = router.getUrlData();
  const { t } = i18n.useTranslation('common');

  return (
    <FullScreenForm
      title={t('login.reset_your_password').toUpperCase()}
      request={async (data: ResetPasswordData) => {
        const username = await api.auth.resetPassword(passwordResetId, data.password);
        if (username) {
          const { isLoggedIn, error } = await auth.loginWith('local', {
            username,
            password: data.password,
          });

          if (isLoggedIn) {
            router.redirect(query.redirectTo || '/');
            return;
          }

          if (error) {
            return {
              error,
            };
          }
        }

        return {
          error: t('login.error_generic'),
        };
      }}
      renderFooter={() => (
        <a href={'/signin'}>
          <span className="text-sm text-silvergrey font-bold cursor-pointer">
            {t('login.already_have_account')} <a className="underline">{t('login.sign_in')}</a>
          </span>
        </a>
      )}
      renderForm={onSubmit => (
        <ResetPasswordForm
          onSubmit={onSubmit}
        />
      )}
    />
  );
});

interface ResetPasswordFormProps {
  onSubmit?(formData: ResetPasswordData) : void;
}

const ResetPasswordForm : React.FC<ResetPasswordFormProps> = (props) => {
  const { t } = i18n.useTranslation('common');

  return (
    <Formik
      initialValues={{
        password: '',
        confirm_password: '',
      }}
      onSubmit={(values: ResetPasswordData) => {
        props.onSubmit(values);
      }}
      validationSchema={Yup.object({
        password: Yup.string().required(t('login.password_required')),
        confirm_password: Yup.string()
          .oneOf([Yup.ref('password'), null], t('login.password_dont_match')),
      })}
    >
      {(formik: FormikProps<ResetPasswordData>) => {
        const errors = Object.keys(formik.errors)
          .filter(fieldWithError => formik.touched[fieldWithError])
          .map(fieldWithErrorAndTouched => formik.errors[fieldWithErrorAndTouched]);

        return (
          <Form
            className="w-full text-left"
          >
            {
              errors.length === 0 ? null : (
                <div className="bg-red bg-opacity-10 rounded-md text-red text-center text-xs font-bold p-6 mb-8">
                  {errors.map(error => (
                    <div>{error}</div>
                  ))}
                </div>
              )
            }
            <PasswordInput
              name="password"
              placeholder={t('login.password_hint_new')}
              value={formik.values.password}
              onBlur={() => formik.setFieldTouched('password')}
              onFocus={() => formik.setFieldTouched('password', false)}
              onChange={value => formik.setFieldValue('password', value)}
            />
            <PasswordInput
              name="confirm_password"
              placeholder={t('login.confirm_password')}
              forceObfuscation={true}
              value={formik.values.confirm_password}
              onBlur={() => formik.setFieldTouched('confirm_password')}
              onFocus={() => formik.setFieldTouched('confirm_password', false)}
              onChange={value => formik.setFieldValue('confirm_password', value)}
            />
            <FormSubmitButton
              value={t('login.save_password')}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ResetPasswordPage;
