import React from 'react';
import { isEqual } from 'lodash';
import CodeMirror from 'codemirror';
import { Controlled as ReactCodeMirror, IInstance } from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/merge/merge';
import 'codemirror/addon/merge/merge.css';
import '../styles/JsonEditor.scss';

import diffPatchMatch from 'diff_match_patch';

const codeMirrorConfig: CodeMirror.EditorConfiguration = {
  theme: 'material',
  // https://stackoverflow.com/questions/8349571/codemirror-editor-is-not-loading-content-until-clicked
  autoRefresh: true,
  mode: {
    name: 'javascript',
    json: true,
    // @ts-ignore
    statementIndent: 2,
  },
  lineNumbers: true,
  lineWrapping: true,
  indentWithTabs: false,
  tabSize: 2,
};

interface JsonEditorProps {
  value: object;
  name?: string;
  compareWith?: object;
  onChange?(value:object): void;
}

interface JsonEditorState {
  cursorPos: CodeMirror.Position;
  jsonString: string;
}

export default class JsonEditor extends React.PureComponent<JsonEditorProps, JsonEditorState> {

  constructor(props) {
    super(props);

    this.state = {
      jsonString: JSON.stringify(props.value, null, 2),
      cursorPos: undefined,
    };
  }

  private onChange = (valueObject: object) => {
    if (!this.props.onChange) {
      return;
    }

    const value = valueObject[this.props.name];
    this.props.onChange(value);
  }

  /**
   * Emit new object only if it's a new valid object.
   * Keep track of the cursor position, in order to avoid cursor jump when receiving new value.
   */
  private handleChangeInEditor = async (editor: IInstance, change: CodeMirror.EditorChange, valueString: string) => {
    let valueObject;
    try {
      valueObject = JSON.parse(valueString);
    } catch (error) {
      return;
    }

    const isSameAsProp = isEqual(valueObject, this.props.value);
    if (!isSameAsProp) {
      this.onChange(valueObject);
    }
  }

  private handleBeforeChange = async (editor: IInstance, change: CodeMirror.EditorChange, valueString: string) => {
    const isSameObject = isEqual(valueString, this.state.jsonString);
    if (isSameObject) { return; }

    await this.setState({
      cursorPos: editor.getCursor(),
      jsonString: valueString,
    });
  }

  render() {
    const { jsonString, cursorPos } = this.state;

    return (
      <ReactCodeMirror
        onBeforeChange={this.handleBeforeChange}
        onChange={this.handleChangeInEditor}
        cursor={cursorPos}
        value={jsonString}
        // @ts-ignore
        options={codeMirrorConfig}
      />
    );
  }
}

const diffCodeMirrorConfig = {
  ...codeMirrorConfig,
  origLeft: null,
  origRight: null,
  revertButtons: false,
  highlightDifferences: true,
  connect: 'align',
  collapseIdentical: false,
  readOnly: true,
};

interface MergeCodeTextAreaProps {
  compareWith: object;
  value: object;
}

export class MergeCodeTextArea extends React.PureComponent<MergeCodeTextAreaProps, null> {
  editorWrapperRed: any;
  editorMergeView: any;
  componentDidMount() {
    const isDiffAlgorithmDefined = (window as any).diff_match_patch;
    if (!isDiffAlgorithmDefined) {
      // ensure diff algorithm is defined
      // as it's a peer dependency of CodeMirror.MergeView
      Object.entries(diffPatchMatch).forEach(([key, value]) => {
        window[key] = value;
      });
    }

    this.editorMergeView = CodeMirror.MergeView(this.editorWrapperRed, {
      ...diffCodeMirrorConfig,
      value: JSON.stringify(this.props.value || {}, null, 2),
      origRight: JSON.stringify(this.props.compareWith || {}, null, 2),
    } as any);
  }

  render() {
    return (
      <div ref={ref => this.editorWrapperRed = ref} />
    );
  }
}
