import React from 'react';
import { Map, Circle, GoogleApiWrapper, Marker } from 'google-maps-react';
import validator from 'validator';

import variables from '@styles/variables';
import './MapInput.scss';

interface MapInputProps {
  latitude: number| string;
  longitude: number | string;
  onCenterChange(args: { latitude: number, longitude: number }): void;
  showLocationInput?: boolean;
  radius?: number;
  showCircle?: boolean;
  google: any;
  dragToChangeMapCenter?: boolean;
  markers?: { latitude: number, longitude: number }[];
  isSatelliteView?: boolean;
  initialZoomLevel?: number;
}

class MapInput extends React.Component<MapInputProps, {}> {

  async componentDidMount() {
    await this.initializeGoogleAutocomplete();
    this.initializeGoogleMap();
  }

  async componentDidUpdate(prevProps: MapInputProps) {
    const { radius, latitude, longitude } = this.props;
    const propChanged = radius !== prevProps.radius
      || latitude !== prevProps.latitude
      || longitude !== prevProps.longitude;

    if (propChanged) {
      // this.centerMapAroundCircle();
    }
  }

  initializeGoogleMap() {
    const {
      dragToChangeMapCenter,
      isSatelliteView,
      initialZoomLevel,
      markers,
      latitude: lat,
      longitude: lng,
    } = this.props;

    const map = this.mapRef.map;

    if (isSatelliteView) {
      map.setMapTypeId('satellite');
    }

    if (typeof initialZoomLevel === 'number') {
      map.setZoom(initialZoomLevel);
    } else if (markers) {

      // if there are markers and no initialZoomLevel provided
      // we'll make sure to include all markers in the initial map view
      const bounds = new google.maps.LatLngBounds();
      markers.forEach(({ latitude, longitude }) => {
        const latLng = new google.maps.LatLng(latitude, longitude);
        bounds.extend(latLng);
      });
      map.fitBounds(bounds);
      map.setCenter({ lat, lng });

      const newbounds = map.getBounds();
      const areMarkersWithinBounds = markers
        .every(marker => newbounds.contains({
          lat: marker.latitude,
          lng: marker.longitude,
        }));
      if (!areMarkersWithinBounds) {
        map.setZoom(map.getZoom() - 1);
      }
    }

    if (dragToChangeMapCenter) {
      // center can move on drag but also when zooming
      map.addListener('dragend', this.handleMapChangeCenter);
      map.addListener('zoom_changed', this.handleMapChangeCenter);
    }
  }

  initializeGoogleAutocomplete() {
    const { google } = this.props;
    const map = this.mapRef.map;

    const input = document.getElementById('pac-input');
    const autocomplete = new google.maps.places.Autocomplete(input);

    autocomplete.bindTo('bounds', map);
    autocomplete.setFields(['place_id', 'geometry', 'formatted_address']);

    // position the autocomplete input
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    autocomplete.addListener('place_changed', async () => {
      const place = autocomplete.getPlace();
      const isLatLongString = !place.place_id && validator.isLatLong(place.name);
      const newCenterObjectLatLng = isLatLongString
        ? this.transformStringToLatLng(place.name)
        : await this.getCenterOfPlace(place);

      this.handleMapChangeCenter(newCenterObjectLatLng);
    });
  }

  transformStringToLatLng(str: string) {
    const latLng = str.split(/,\s?/);
    const lat = parseFloat(latLng[0]);
    const lng = parseFloat(latLng[1]);
    const mapLatLng = this.createLatLng(lat, lng);
    return mapLatLng;
  }

  createLatLng = (latitude, longitude) => {
    const { google } = this.props;

    const mapLatLng = new google.maps.LatLng(latitude, longitude);
    return mapLatLng;
  }

  getCenterOfPlace(place) {
    const { google } = this.props;
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder;
      geocoder.geocode({ 'placeId': place.place_id }, (results, status) => {
        if (status !== 'OK') {
          window.alert(`Geocoder failed due to:${status}`);
          return reject(status);
        }

        const centerLatLng = results[0].geometry.location;
        resolve(centerLatLng);
      });
    });
  }

  handleMapChangeCenter = (latLng?) => {
    let latitude;
    let longitude;

    if (latLng) {
      const latLngAsJson = this.getLatLngAsJson(latLng);
      latitude = latLngAsJson.latitude;
      longitude = latLngAsJson.longitude;
    } else {
      const map = this.mapRef.map;
      const newCenter = map.getCenter().toJSON();
      latitude = newCenter.lat;
      longitude = newCenter.lng;
    }

    const willUpdatePosition = latitude !== this.props.latitude
      || longitude !== this.props.longitude;

    if (willUpdatePosition) {
      this.props.onCenterChange({
        latitude,
        longitude,
      });
    }
  }

  getLatLngAsJson = (googleLatLng) => {
    return {
      latitude: googleLatLng.lat(),
      longitude: googleLatLng.lng(),
    };
  }

  mapRef: any = React.createRef();
  circleRef: any = React.createRef();

  render() {
    const {
      google,
      radius,
      latitude,
      longitude,
      showLocationInput = true,
      showCircle = true,
      markers,
    } = this.props;

    // FIXME
    const mapCenter: any = {
      lat: latitude,
      lng: longitude,
    };

    return (
      <div>
          {showLocationInput && <input
            id="pac-input"
            className="map-controls"
            type="text"
            placeholder="Enter a location"
          />}
        <Map
          ref={ref => this.mapRef = ref}
          initialCenter={mapCenter}
          center={mapCenter}
          google={google}
          containerStyle={{ height: '100%', width: '100%' }}
          streetViewControl={false}
          fullscreenControl={false}
        >
          {showCircle && <Circle
            ref={ref => this.circleRef = ref}
            radius={radius}
            center={mapCenter}
            clickable={false}
            strokeColor="transparent"
            strokeOpacity={0}
            strokeWeight={5}
            fillColor={variables.transitdarkgreen}
            fillOpacity={0.2}
          />}

          {markers && markers.map(({ latitude, longitude }) => {
            return (
              <Marker
                key={`${latitude}:${longitude}`}
                position={{ lat: latitude, lng: longitude }}
                icon="/images/entrance-icon-small.png"
                clickable={false}
              />
            );
          })}
        </Map>
      </div>
    );
  }
}

const withGoogleMapApi = GoogleApiWrapper({ apiKey: process.env.GOOGLE_MAP_API_KEY });
export default withGoogleMapApi<MapInputProps>(MapInput);
