import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import ActiveNavList from '@components/ActiveNavList/ActiveNavList';
import TransitLogo from '@components/TransitLogo/TransitLogo';
import LanguageSelector from '@components/LanguageSelector/LanguageSelector';

import './Header.scss';

interface HeaderProps {
  routes: any;
}
class Header extends React.Component<HeaderProps>{

  render() {
    const { loginRoutes, otherRoutes } = this.props.routes.reduce((acc, route) => {
      if (route.path.includes('login')) {
        acc.loginRoutes.push(route);
      } else {
        acc.otherRoutes.push(route);
      }

      return acc;
    }, { loginRoutes: [], otherRoutes: [] });
    return (
        <div className="navbar-header">
          <div className="header-wrapper layout-max-width">
            <Link to="/" className="navbar-brand header-logo">
              <TransitLogo/>
            </Link>

            <div className="header-wrapper" style={{ justifyContent: 'flex-end' }}>
              <ActiveNavList
                routes={otherRoutes}
                className="navbar-navlinks-wrapper"
                linkClassName="header-link"
              />
              <LanguageSelector style={{ marginLeft: '51px' }}/>
              <ActiveNavList
                routes={loginRoutes}
                className="navbar-navlinks-wrapper"
                linkClassName="header-link"
                forceShow={true}
              />
            </div>

          </div>
        </div>
    );
  }
}

export default observer(Header);
