import { action, computed, observable, reaction, runInAction } from 'mobx';
import { capitalize, debounce, isEqualWith } from 'lodash';
import moment, { Moment } from 'moment';
import { SortDirectionType } from 'react-virtualized';

import api from '@stores/api';
import transit, { Feed, FeedInfo } from '@stores/transit';
import router from '@stores/router';
import { FOCUS_ON, OPERATION_KEY_WORDS, OPERATION_STEP_STATUSES, STEPS } from '@constants';

import filterConfig from './lib/filterConfig';
import * as filter from './lib/filterHelpers';
import * as sort from './lib/sortHelpers';

export interface Notice {
  category: string;
  content: string;
  feed_id: number;
  id: string;
  ignore_until: string;
  last_handled_at: Date;
  last_handled_by: string;
  modified_at: string;
  operation_id: number;
  severity: string;
  status: string;
  step_code: string;
  title: string;
}

interface OperationSteps {
  feed_id: number;
  id: number;
  started_at: Date;
  status: OPERATION_STEP_STATUSES;
  step: string;
}

export interface Operation {
  feed: FeedInfo;
  feed_id: number;
  isRunning: boolean;
  notices: Notice[];
  operation_id: number;
  operationSteps: OperationSteps[];
  loadedAllNotices: boolean;
  started_by: string;
  started_at: Moment;
}

class OperationAndNoticesStore {
  /* Note: observable needs to be shallow due to MobX https://mobx.js.org/refguide/modifiers.html#deep-observability */
  @observable.shallow public validOperationByOperationId: Map<number, Operation> = new Map();
  @observable public isOperationsContainerInFocus: boolean = true;
  @observable public isNoticesContainerInFocus: boolean = false;
  @observable public noticeInFocusIndex: number = 0;
  @observable public operationInFocusIndex: number = 0;
  @observable public sortBy: string = undefined;
  @observable public sortDirection: string = undefined;
  @observable public gitStatus: any = null;
  @observable public isLoadingOperations: boolean = true;
  @observable public isLoadingNotices: boolean = true;
  @observable public noticeInFocusIsGapsInFeed = false;
  @observable public isOperationsUiMounted = false;
  @observable private operationsToUpdate = [];

  @observable private shouldTriggerComputation = false;

  @observable public filtersObject: any = {
    general: [],
    [OPERATION_KEY_WORDS.OPERATION]: {
      default: {},
      specific: {},
    },
    [OPERATION_KEY_WORDS.NOTICE]: {
      default: {},
      specific: {},
    },
  };

  constructor() {
    reaction(
      () => this.noticeInFocus,
      async (notice) => {
        if (!notice) {
          this.noticeInFocusIsGapsInFeed = false;
          return;
        }

        this.noticeInFocusIsGapsInFeed = this.isGapsInFeedNotice(notice);
        if (!this.noticeInFocusIsGapsInFeed) {
          return;
        }

        await transit.initSourcesOfFeed(notice.feed_id);
        await transit.initCodeHashOfFeed(this.operationInFocus.feed);
      },
    );

    reaction(
      () => this.isLoadingOperations,
      async () => {
        await this.initNotices();
      },
    );

    reaction(
      () => this.operationsToUpdate.length,
      () => this.updateOperations(),
    );
  }

  @computed get operationByOperationId(): Map<number, Operation> {
    /*
     * "this.triggerComputation();" is added so that when the notices finish loading,
     * a re-computation of operationByOperationId will be triggered.
     * Being that validOperationByOperationId is a shallow observable, the updating of the notices will not trigger
     * a re-computation on its own. But by adding the "this.shouldTriggerComputation" here (which is a bit of a hack),
     * we're adding a trigger for operationByOperationId when "this.shouldTriggerComputation" changes value.
     */
    this.shouldTriggerComputation;

    const filteredOperationByOperationId = filter.operationByOperationId(
      this.validOperationByOperationId,
      this.filtersObject,
    );

    return filteredOperationByOperationId;
  }

  @computed get operationIdsByFeedId(): Map<number, number[]> {
    const operationIdsByFeedId = new Map();
    for (const [operationId, operation] of this.validOperationByOperationId) {
      const feedId = operation.feed_id;
      const operationIds = operationIdsByFeedId.get(feedId) || [];
      operationIds.push(operationId);
      operationIdsByFeedId.set(feedId, operationIds);
    }

    return operationIdsByFeedId;
  }

  @computed get operationIds(): number[] {
    if (!this.operationByOperationId.size) {
      return [];
    }

    return sort.operationIds(this.operationByOperationId, this.sortBy, this.sortDirection);
  }

  @computed get operationInFocus(): Operation {
    if (!this.operationIds.length) {
      return;
    }

    const operationId = this.operationIds[this.operationInFocusIndex];
    return this.operationByOperationId.get(operationId);
  }

  @computed get noticeInFocus(): any {
    this.shouldTriggerComputation;
    if (!this.operationInFocus) {
      return;
    }

    const noticesLength = this.operationInFocus.notices.length;
    if (this.noticeInFocusIndex >= noticesLength) {
      return this.operationInFocus.notices[noticesLength - 1];
    }

    return this.operationInFocus.notices[this.noticeInFocusIndex];
  }

  @computed public get feedOperationStatusByFeedId() {
    const feedOperationStatusByFeedId = new Map();

    for (const [feedId, operationIds] of this.operationIdsByFeedId.entries()) {
      const feedStatus = this.getFeedStatus(feedId, operationIds);
      feedOperationStatusByFeedId.set(feedId, feedStatus);
    }

    return feedOperationStatusByFeedId;
  }

  /*
   * Initializes store making a call to UpdateStaticData-Dispatcher API. Will also initialize the socket connection with
   * the dispatcher allowing real-time updates to be received whenever an operation is updated or created.
   */
  @action public init = async (feed?: Feed, shouldSyncUrl: boolean = true): Promise<any> => {
    this.resetStore(feed);

    if (shouldSyncUrl) {
      this.ensureURLSync(feed);
    }

    if (this.validOperationByOperationId.size) {
      return;
    }

    await transit.getFeedList();

    await this.initOperations();
    await this.initGitStatus();
  }

  @action initNotices = async (): Promise<any> => {
    const response = await api.http.authenticatedPost(
      `${process.env.UPDATE_STATIC_DATA_DISPATCHER_URL}/notice`,
      {
        operationIds: [...this.validOperationByOperationId.keys()],
      }
    );
    if (response) {
      this.initNoticesByOperationId(response.data);
    }
  }

  @action initOperations = async (): Promise<any> => {
    await api.httpSocket.init({
      httpUrl: `${process.env.UPDATE_STATIC_DATA_DISPATCHER_URL}/operation`,
      httpCallback: (response) => {
        if (response) {
          this.initOperationByOperationId(response.data);
        }
      },
      socketUrl: process.env.UPDATE_STATIC_DATA_DISPATCHER_URL,
      socketOnEvent: 'updateOperation',
      socketOnEventCallback: operation => runInAction(() => this.operationsToUpdate.push(operation)),
    });
  }

  @action initGitStatus = async (): Promise<any> => {
    await api.httpSocket.init({
      httpUrl: `${process.env.UPDATE_STATIC_DATA_URL}/git/status`,
      httpCallback: (response) => {
        if (response) {
          this.setGitStatus(response.data);
        }
      },
      socketUrl: process.env.UPDATE_STATIC_DATA_URL,
      socketOnEvent: 'gitStatus',
      socketOnEventCallback: gitStatus => this.setGitStatus(gitStatus),
    });
  }

  @action public initOperationByOperationId(operationByOperationIdEntries) {
    this.setOperationByOperationId(operationByOperationIdEntries);
    this.setIsLoadingOperations(false);
  }

  @action public initNoticesByOperationId(noticesByOperationIdEntries) {
    for (const [operationId, notices] of noticesByOperationIdEntries) {
      this.updateNotices(operationId, notices);
    }

    this.setIsLoadingNotices(false);
    this.triggerComputation();
  }

  @action public setOperationByOperationId(operationByOperationIdEntries) {
    this.validOperationByOperationId = filter.toMap(operationByOperationIdEntries);
  }

  @action public setGitStatus(gitStatus) {
    this.gitStatus = gitStatus;
  }

  @action public setIsLoadingOperations(isLoadingOperations) {
    this.isLoadingOperations = isLoadingOperations;
  }

  @action public setIsLoadingNotices(isLoadingNotices) {
    this.isLoadingNotices = isLoadingNotices;
  }

  public getFeedStatus(feedId, operationIds) {
    const feedStatus = {
      message: undefined,
      status: undefined,
    };

    if (!operationIds.length) {
      feedStatus.message = '-';
      return feedStatus;
    }

    let lastCompletedGet = false;
    for (const operationId of operationIds.reverse()) {
      const operation = this.validOperationByOperationId.get(operationId);
      const firstOperationStep = operation.operationSteps[0];

      if (firstOperationStep.step === STEPS.FIC.name) {
        continue; // we discard FIC-only operations. FIC shouldn't be used to determine the feed operation status
      }

      if (firstOperationStep.step === STEPS.GET.name && firstOperationStep.status === OPERATION_STEP_STATUSES.UNCHANGED) { // tslint:disable-line:max-line-length
        lastCompletedGet = true;
        continue; // we discard operations which exited early due to no new source
      }

      for (const [index, operationStep] of operation.operationSteps.entries()) {
        switch (operationStep.status) {
          case OPERATION_STEP_STATUSES.COMPLETED:
            const isLastStepOfOperation = index === (operation.operationSteps.length - 1);
            if (!isLastStepOfOperation) {
              continue;
            }

            let message = 'OK';
            if (operationStep.step !== STEPS.UPF.name) {
              message += `: ${operationStep.step}`;
            }

            feedStatus.message = message;
            feedStatus.status = operationStep.status;

            return feedStatus;

          case OPERATION_STEP_STATUSES.STARTED:
            feedStatus.message = `${capitalize(operationStep.status)}: ${operationStep.step}`;
            feedStatus.status = OPERATION_STEP_STATUSES.STARTED;
            return feedStatus;

          case OPERATION_STEP_STATUSES.FAILED:
          case OPERATION_STEP_STATUSES.KILLED:
          case OPERATION_STEP_STATUSES.REJECTED:
            if (lastCompletedGet && operationStep.step === STEPS.GET.name) { // GET should only resolve GET steps
              feedStatus.message = 'OK';
              feedStatus.status = OPERATION_STEP_STATUSES.COMPLETED;
              return feedStatus;
            }

            feedStatus.message = `${capitalize(operationStep.status)}: ${operationStep.step} (${moment(operationStep.started_at).fromNow(true)})`; // tslint:disable-line:max-line-length
            feedStatus.status = operationStep.status;
            return feedStatus;

          case OPERATION_STEP_STATUSES.WAITING:
            feedStatus.message = `${capitalize(operationStep.status)}: ${operationStep.step}`;
            feedStatus.status = operationStep.status;
            return feedStatus;
        }
      }
    }

    return feedStatus;
  }

  /*
   * Sets the default store values on each observable.
   */
  @action public resetStore = (feed?: Feed) => {
    this.isOperationsContainerInFocus = true;
    this.isNoticesContainerInFocus = false;
    this.noticeInFocusIndex = 0;
    this.operationInFocusIndex = 0;
    this.sortBy = undefined;
    this.sortDirection = undefined;

    this.filtersObject = {
      general: [],
      [OPERATION_KEY_WORDS.OPERATION]: {
        default: {},
        specific: {},
      },
      [OPERATION_KEY_WORDS.NOTICE]: {
        default: {},
        specific: {},
      },
    };

    if (feed) {
      this.filtersObject[OPERATION_KEY_WORDS.OPERATION].default = { 'byFeedIds': [feed.feed_id] };
    }
  }

  @action public setSortOptions = (sortBy?: string, sortDirection?: SortDirectionType) => {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }

  @action public setIsOperationsContainerInFocus = (isOperationsContainerInFocus) => {
    this.isOperationsContainerInFocus = isOperationsContainerInFocus;
  }

  @action public setIsNoticesContainerInFocus = (isNoticesContainerInFocus) => {
    this.isNoticesContainerInFocus = isNoticesContainerInFocus;
  }

  @action public setIsOperationsUiMounted = (isOperationsUiMounted) => {
    this.isOperationsUiMounted = isOperationsUiMounted;
  }

  @action public setFiltersObject = (filtersObject) => {
    this.filtersObject = filtersObject;
  }

  @action public updateOperation = (operation) => {
    const feedId = operation.feed_id;
    const feed = transit.getFeedWithFeedId(feedId);

    if (feed) {
      operation.feed = feed;
      const operationIdsLength = this.operationIds.length;
      const ignoreKeysWith = (obj, other, key) => key === 'last_handled_at' || key === 'modified_at' ? true : undefined;
      if (isEqualWith(this.validOperationByOperationId.get(operation.operation_id), operation, ignoreKeysWith)) {
        return;
      }

      this.validOperationByOperationId.set(operation.operation_id, operation);

      // if the list of operationIds in view hasn't changed, we shouldn't update the operationInFocusIndex
      if (operationIdsLength === this.operationIds.length) {
        return;
      }

      // if the operation in focus is out of bounds, set the new operation in focus to be the last operation in view
      if (this.operationIds.length <= this.operationInFocusIndex) {
        this.operationInFocusIndex = this.operationIds.length - 1;
        return;
      }

      // Only update the index of the operation in focus if the new operation index is before it in the list
      const operationIndex = this.operationIds.findIndex(operationId => operationId === operation.operation_id);
      if (operationIndex >= 0 && operationIndex < this.operationInFocusIndex) {
        this.operationInFocusIndex += 1;
      }
    }
  }

  @action public updateOperations = debounce(() => {
    if (this.operationsToUpdate.length === 0) {
      return;
    }

    let operations = [];
    runInAction(() => operations = this.operationsToUpdate.splice(0));
    operations.forEach((operation) => {
      this.updateOperation(operation);
    });
  }, 500, {
    leading: true,
    maxWait: 5000,
  });

  @action public updateNotices = (operationId, notices, loadedAllNotices = false) => {
    const operation = this.validOperationByOperationId.get(operationId);
    if (!operation) {
      return;
    }

    operation.notices = notices;
    operation.loadedAllNotices = loadedAllNotices;
    this.validOperationByOperationId.set(operationId, operation);
  }

  @action public updateNotice = (operationId, notice) => {
    const operation = this.validOperationByOperationId.get(operationId);
    if (!operation) {
      return;
    }

    const noticeIndex = operation.notices.findIndex((operationNotice => operationNotice.id === notice.id));
    if (noticeIndex === -1) {
      return;
    }

    operation.notices[noticeIndex] = notice;
    this.validOperationByOperationId.set(operationId, operation);
    this.triggerComputation();
  }

  @action updateNoticesOfOperationId = async ({ started_at, operation_id }): Promise<any> => {
    const response = await api.http.authenticatedPost(
      `${process.env.UPDATE_STATIC_DATA_DISPATCHER_URL}/notice`,
      {
        showAll: true,
        operationIds: [operation_id],
      }
    );

    let notices = [];
    if (response && response.data && response.data.length > 0) {
      const noticeByOperationEntry = response.data.find(([noticeOperationId]) => noticeOperationId === operation_id);
      if (noticeByOperationEntry) {
        notices = noticeByOperationEntry[1];
      }
    }

    this.updateNotices(operation_id, notices, true);
    this.triggerComputation();
  }

  @action public changeNoticeInFocus = (focusOn: FOCUS_ON.UP | FOCUS_ON.DOWN | number) => {
    if (focusOn === null) {
      return;
    }

    let newNoticeInFocusIndex;
    switch (focusOn) {
      case FOCUS_ON.UP:
        newNoticeInFocusIndex = this.noticeInFocusIndex - 1;
        break;
      case FOCUS_ON.DOWN:
        newNoticeInFocusIndex = this.noticeInFocusIndex + 1;
        break;
      default:
        newNoticeInFocusIndex = focusOn;
    }

    if (newNoticeInFocusIndex < 0) {
      return this.changeOperationInFocus(FOCUS_ON.UP);
    }

    if (newNoticeInFocusIndex > this.operationInFocus.notices.length - 1) {
      return this.changeOperationInFocus(FOCUS_ON.DOWN);
    }
    this.isOperationsContainerInFocus = false;
    this.isNoticesContainerInFocus = true;
    this.noticeInFocusIndex = newNoticeInFocusIndex;
  }

  @action public changeOperationInFocus = (focusOn: FOCUS_ON.UP | FOCUS_ON.DOWN | number) => {
    if (focusOn === null) {
      return;
    }

    let newOperationInFocusIndex;
    switch (focusOn) {
      case FOCUS_ON.UP:
        newOperationInFocusIndex = this.operationInFocusIndex - 1;
        break;
      case FOCUS_ON.DOWN:
        newOperationInFocusIndex = this.operationInFocusIndex + 1;
        break;
      default:
        newOperationInFocusIndex = focusOn;
        this.isOperationsContainerInFocus = true;
        this.isNoticesContainerInFocus = false;
    }

    if (newOperationInFocusIndex < 0 || newOperationInFocusIndex > this.operationIds.length - 1) {
      return;
    }

    this.operationInFocusIndex = newOperationInFocusIndex;
    this.noticeInFocusIndex = 0;
  }

  @action public changeOperationInFocusViaUrlQuery = (operationIdInFocus, noticeIdInFocus) => {
    const operationInFocusIndex = this.operationIds.findIndex((operationId => operationId === operationIdInFocus));
    if (operationInFocusIndex === -1) {
      return;
    }

    this.operationInFocusIndex = operationInFocusIndex;

    const noticeInFocusIndex = this.operationInFocus.notices.findIndex(notice => notice.id === noticeIdInFocus);
    if (noticeInFocusIndex === -1) {
      this.noticeInFocusIndex = 0;
      return;
    }

    this.noticeInFocusIndex = noticeInFocusIndex;
  }

  @action public ensureURLSync = (feed?: Feed) => {
    const { params: { operation_id: opId, notice_id: nId } } = router.getUrlData();

    const operationId = parseInt(opId, 10);
    const noticeId = parseInt(nId, 10);

    const route = router.getCurrentRouteConfig();

    if (!operationId && !noticeId && !feed) {
      this.filtersObject[OPERATION_KEY_WORDS.OPERATION].specific = { 'byIsRunning': true };
    }

    if (operationId) {
      this.filtersObject[OPERATION_KEY_WORDS.OPERATION].specific = { 'byOperationIds': [operationId] };
    }

    if (noticeId) {
      this.filtersObject[OPERATION_KEY_WORDS.NOTICE].specific = { 'byNoticeIds': [noticeId] };
    }

    router.redirect(route.basePath);
  }

  @action private triggerComputation = () => {
    this.shouldTriggerComputation = !this.shouldTriggerComputation;
  }

  public getFilterConfig() {
    return filterConfig;
  }

  private isGapsInFeedNotice = (notice) => {
    const noticeTitleRegExp = new RegExp('Upcoming gaps in feed');

    return notice.step_code === 'VAL'
      && notice.severity === 'Error'
      && noticeTitleRegExp.test(notice.title);
  }
}

export default new OperationAndNoticesStore();
