import React from 'react';
import store from '@stores';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import '../Button/Button.scss';

interface DropdownButtonInputProps {
  buttonTitle: string;
  dropdownTitles: object[];
  className?: string;
  onSelect(title, value): void;
  store?: typeof store;
}

class DropdownButtonInput extends React.PureComponent<DropdownButtonInputProps> {

  handleOnClick = (titleObj) => {
    const { onSelect } = this.props;
    const { title, value } = titleObj;
    onSelect(title, value);
  }

  renderDropdownItem(titleObj, index) {
    return (
      <Dropdown.Item
        key={index}
        /* tslint:disable-next-line:jsx-no-lambda ter-arrow-parens */
        onClick={(e) => this.handleOnClick(titleObj)}
      >
          {titleObj.title}
      </Dropdown.Item>
    );
  }

  renderDropdownItems(titles) {
    return titles.map((titleObj, index) => this.renderDropdownItem(titleObj, index));
  }

  render() {
    const { buttonTitle, dropdownTitles } = this.props;

    return (
      <DropdownButton
        id="dropdown-button-links"
        size="sm"
        variant="success"
        title={buttonTitle}
      >
        {this.renderDropdownItems(dropdownTitles)}
      </DropdownButton>
    );
  }
}

export default DropdownButtonInput;
