import React from 'react';
import cx from 'classnames';
import { isEqual } from 'lodash';

import '../styles/FeedOperationButton.scss';

interface StepButtonProps {
  activateOnClick?: boolean;
  isActive?: boolean;
  label?: string;
  value: string;
  className?: string;
  onClick?(value: string, isActive: boolean): void;
}

interface StepButtonState {
  active: boolean;
}

class StepButton extends React.PureComponent<StepButtonProps, StepButtonState> {

  constructor(props) {
    super(props);
    this.state = {
      active: this.props.isActive || false,
    };
  }

  async componentDidUpdate(prevProps) {
    const stateNotSynced = !isEqual(this.state.active, this.props.isActive);
    const propsChanged = !isEqual(prevProps.isActive, this.props.isActive);
    const shouldSyncState = propsChanged && stateNotSynced;

    if (shouldSyncState) {
      await this.setState({ active: this.props.isActive || false });
    }
  }

  handleOnClick = async () => {
    if (!this.props.onClick) {
      return;
    }

    if (this.props.activateOnClick) {
      await this.setState({
        active: !this.state.active,
      });
    }

    this.props.onClick(this.props.value, this.state.active);
  }

  render() {
    const className = `step-button ${cx({
      'active': this.state.active,
      [this.props.className]: this.props.className,
    })}`;

    return (
      <div className="step-button-wrapper">
        <div className={className} onClick={this.handleOnClick}>
          {this.props.label || this.props.value}
        </div>
      </div>
    );
  }
}

export default StepButton;
