import React from 'react';
import { useTranslation } from 'react-i18next';

import store from '@stores';
import variables from '@styles/variables';
import { FloatingLabelInput } from '@components/Input/FloatingLabelInput';
import LoaderButton from '@components/LoaderButton/LoaderButton';
import { sendFeedSlackMessage } from '@utils/slack_helper';
import { FeedInfo } from '@stores/transit';

interface ContactUsFormProps {
  stationMetadataByStationStableId: any;
  feed: FeedInfo;
}

interface ContactUsInputProps extends ContactUsFormProps {
  hideModal(): void;
}

const ContactUsInput: React.FC<ContactUsInputProps> = (props) => {
  const { t } = useTranslation('common');
  const [comments, setComments] = React.useState('');

  const helpDocUrl = 'https://help.transitapp.com/article/391-help-us-improve-our-station-entrance-data';
  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: variables.transitlightgrey,
          borderColor: variables.transitsnowgrey,
          borderWidth: '1px',
          borderRadius: '8px',
          padding: '25px',
          color: variables.transitrainygrey,
          whiteSpace: 'pre-line',
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: t('pathways.contact_us.message', { url: helpDocUrl }) }}
        />
        <FloatingLabelInput
          name="notes"
          placeholder={t('pathways.contact_us.placeholder')}
          value={comments}
          type="textarea"
          autocomplete="false"
          onChange={value => setComments(value)}
          style={{
            marginTop: '25px',
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <LoaderButton
          errorMessage={t('login.error_generic')}
          value={t('button.send').toUpperCase()}
          style={{
            marginRight: 0,
            marginTop: '10px',
          }}
          onClickWithStatus={async () => {
            const message = `user: ${store.auth.user.displayName} - ${store.auth.user.email}
message: ${comments}
localStorage: ${JSON.stringify(props.stationMetadataByStationStableId, null, 2)}`;

            const payload = {
              channel: '#station-entrance-alerts',
              username: 'Contact Us Form',
              icon_url: 'https://transitapp.com/img/transit-logo@2x.png',
              unfurl_links: true,
              attachments: [
                {
                  color: 'down',
                  fallback: message,
                  title: props.feed.feed_code,
                  text: message,
                },
              ],
            };

            await sendFeedSlackMessage(payload);
            props.hideModal();
            return true;
          }}
        />
      </div>
    </React.Fragment>
  );
};

export const ContactUsForm: React.FC<ContactUsFormProps> = (props) => {
  const { t } = useTranslation('common');

  let hideModal: any;
  React.useEffect(() => {
    return function cleanup() {
      if (hideModal) {
        hideModal();
      }
    };
  }, []);

  const openContactUsModal = () => {
    hideModal = store.modals.showModal({
      title: t('pathways.contact_us.title'),
      component: <ContactUsInput hideModal={() => hideModal()} {...props} />,
    });
  };

  return (
    <span className="help-button" onClick={openContactUsModal}>
      {t('pathways.contact_us.button')}
    </span>
  );
};
