import React from 'react';
import { FloatingLabelInput, FloatingLabelInputProps } from './FloatingLabelInput';
import { EyeHiddenIcon } from '../icons/EyeHiddenIcon';
import { EyeShownIcon } from '../icons/EyeShownIcon';

interface PasswordInputProps extends FloatingLabelInputProps {
  forceObfuscation?: boolean;
}

import './PasswordInput.scss';

export const PasswordInput : React.FC<PasswordInputProps> = (props) => {
  const [isPasswordObfuscated, setIsPasswordObfuscated] = React.useState(true);

  return (
    <FloatingLabelInput
      {...props}
      required={true}
      type={(props.forceObfuscation || isPasswordObfuscated) ? 'password' : 'text'}
    >
      {props.forceObfuscation ? null : (
        <button
          className="PasswordInputButton"
          type="button"
          onClick={() => setIsPasswordObfuscated(!isPasswordObfuscated)}
        >
          {isPasswordObfuscated ? <EyeHiddenIcon /> : <EyeShownIcon />}
        </button>
      )}
    </FloatingLabelInput>
  );
};
