import { DATA_TYPES } from '@constants';

/**
 * Will convert a string into a regex with appropriate flags
 * @param { String } regexAsString - ex: "/\/([igsmx]{0,5})$/gi"
 * @return { RegExp | undefined }
 */
export function getRegexFromStringWithFlags(regexAsString) {
  const flagsCapturingGroupRegex = /\/([gimuy]*)$/; // will capture flags;
  const regexContentCapturingGroupRegex = /^\/(.*?)\/[gimuy]*$/; // will capture regex content

  if (typeof regexAsString !== DATA_TYPES.STRING) {
    return undefined;
  }

  const flagMatchList = regexAsString.match(flagsCapturingGroupRegex);
  const regexEnd = flagMatchList && flagMatchList[0]; // ie "/gi"

  const isRegex = regexContentCapturingGroupRegex.test(regexAsString);
  if (!isRegex) {
    return undefined;
  }

  const flags = regexEnd.replace(flagsCapturingGroupRegex, '$1');
  const regexContent = regexAsString.replace(regexContentCapturingGroupRegex, '$1');

  try {
    const regExp = new RegExp(regexContent, flags);
    return regExp;
  } catch (err) {
    console.log(err); // tslint:disable-line no-console
    return undefined;
  }
}
