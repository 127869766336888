import React from 'react';

import { getRenderFunctionAndTypeOfValue } from './lib/renderValueWithConfig';
import { getFallbackValueOfTemplate } from './lib/getFallbackValueOfTemplate';

import './styles/JsonUI.scss';
import JsonEditor from '@components/ToolComponents/simpleUIs/JsonEditor';

export interface JsonUIProps {
  value: any;
  template: any;
  isJsonEditorMode: boolean;
  onChange(value: any): void;
  onUnavailableUI?(): void;
}

interface JsonUIState {
}

export default class JsonUI extends React.Component<JsonUIProps, JsonUIState> {
  static getJsonConfig(template) {
    const toolName = Object.keys(template)[0];
    const toolTemplate = template[toolName];

    const fallbackValue = getFallbackValueOfTemplate(toolTemplate);
    const fallbackJson = { [toolName]: fallbackValue };

    return {
      toolName,
      toolTemplate,
      fallbackJson,
      fallbackValue,
    };
  }

  renderJsonEditor() {
    const { value, template } = this.props;
    const { toolName, fallbackJson } = JsonUI.getJsonConfig(template);
    const json = value || fallbackJson;

    return (
      <JsonEditor
        name={toolName}
        value={json}
        onChange={this.onChange}
      />
    );
  }

  renderJsonExtensionTool() {
    const { value, template, isJsonEditorMode } = this.props;

    if (isJsonEditorMode) {
      return this.renderJsonEditor();
    }

    const { toolName, toolTemplate, fallbackValue } = JsonUI.getJsonConfig(template);

    const toolProps = {
      keyName: toolName,
      onChange: this.onChange,
      onUnavailableUI: this.onUnavailableUI,
    };

    const toolValue = typeof value !== 'undefined'
      ? value[toolName]
      : fallbackValue;

    const { renderComponent } = getRenderFunctionAndTypeOfValue(toolTemplate, toolValue, toolProps);
    return renderComponent();
  }

  onChange = (newValue) => {
    const { onChange, template } = this.props;
    const { fallbackValue } = JsonUI.getJsonConfig(template);
    const value = newValue || fallbackValue;

    onChange(value);
  }

  onUnavailableUI = () => {
    if (this.props.onUnavailableUI) {
      this.props.onUnavailableUI();
    }
    return null;
  }

  render() {
    return this.renderJsonExtensionTool();
  }
}
