import React, { MouseEvent } from 'react';
import Switch from 'rc-switch';
import { CSSTransition } from 'react-transition-group';
import { WINDOW_EVENTS } from '@constants';

import TagsInput from '@components/TagsInput/TagsInput';

export interface ColumnsSearchConfig {
  headerCells: string[];
  searchByColumn: string[][];
  searchByAllColumns: string[];
  hideColumns: boolean[];
  searchCaseSensitive: boolean;
  searchNormalizedCharacters: boolean;
}

interface SearchGtfsTableInputProps {
  openDropdownOnHover?: boolean;
  searchConfig: ColumnsSearchConfig;
  headerCells: string[];
  onChange(columnsConfig: ColumnsSearchConfig): void;
}

interface SearchGtfsTableInputState {
  isDropDownVisible: boolean;
}

class SearchGtfsTableInput extends React.Component<SearchGtfsTableInputProps, SearchGtfsTableInputState> {

  state = {
    isDropDownVisible: false,
  };

  getCurrentSearchConfig = () : ColumnsSearchConfig => {
    const { searchConfig, headerCells } = this.props;
    const defaultSearch: ColumnsSearchConfig = {
      headerCells: [],
      searchByAllColumns: [],
      searchByColumn: [],
      hideColumns: [],
      searchNormalizedCharacters: false,
      searchCaseSensitive: false,
    };

    const currentSearchconfig = Object.assign({}, defaultSearch, searchConfig, { headerCells });
    return currentSearchconfig;
  }

  componentDidMount() {
    window.addEventListener(WINDOW_EVENTS.CLICK, this.hideDropDown);
  }

  componentWillUnmount() {
    window.removeEventListener(WINDOW_EVENTS.CLICK, this.hideDropDown);
  }

  hideDropDown = () => {
    this.updateDropdownVisibility(false);
  }

  handleNewGtfsViewConfig = (changedColumnsSearchConfig: Partial<ColumnsSearchConfig>) => {
    const newSearchConfig = Object.assign({},
      { ...this.getCurrentSearchConfig() },
      { ...changedColumnsSearchConfig }
    );

    this.props.onChange(newSearchConfig);
  }

  handleOnSearchChange = (searchByAllColumns: string[]) => {
    this.handleNewGtfsViewConfig({
      searchByAllColumns,
    });
  }

  handleOnSearchByFieldChange(fieldIndex: number, newArrayOfStrings: string[]) {
    const { searchByColumn } = this.getCurrentSearchConfig();

    const newSearchByField = JSON.parse(JSON.stringify(searchByColumn));
    newSearchByField[fieldIndex] = newArrayOfStrings;
    for (let i = 0; i < fieldIndex; i += 1) {
      if (!newSearchByField[i]) {
        newSearchByField[i] = [];
      }
    }

    this.handleNewGtfsViewConfig({
      searchByColumn: newSearchByField,
    });
  }

  toggleColumnVisibility = (columnIndex: number) => {
    const { hideColumns } = this.getCurrentSearchConfig();

    const newHideColumns = JSON.parse(JSON.stringify(hideColumns));
    newHideColumns[columnIndex] = !newHideColumns[columnIndex] ;
    for (let i = 0; i < columnIndex; i += 1) {
      newHideColumns[i] = !!newHideColumns[i];
    }

    this.handleNewGtfsViewConfig({
      hideColumns: newHideColumns,
    });
  }

  toggleSearchNormalizedCharacters = () => {
    const { searchNormalizedCharacters } = this.getCurrentSearchConfig();
    this.handleNewGtfsViewConfig({
      searchNormalizedCharacters: !searchNormalizedCharacters,
    });
  }

  toggleSearchCaseSensitive = () => {
    const { searchCaseSensitive } = this.getCurrentSearchConfig();
    this.handleNewGtfsViewConfig({
      searchCaseSensitive: !searchCaseSensitive,
    });
  }

  updateDropdownVisibility = (visibility: boolean) => {
    this.setState({
      isDropDownVisible: visibility,
    });
  }

  toggleDropdownVisibility = (event: React.MouseEvent) => {
    event.stopPropagation();

    const { isDropDownVisible } = this.state;
    this.updateDropdownVisibility(!isDropDownVisible);
  }

  stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  getNumberOfColumnSpecificConfig = () => {
    const {
      hideColumns,
      searchByColumn,
      searchNormalizedCharacters,
      searchCaseSensitive,
    } = this.getCurrentSearchConfig();

    let configNumber = 0;
    hideColumns.forEach((hideColumn) => {
      if (hideColumn) {
        configNumber += 1;
      }
    });

    searchByColumn.forEach((columnsSearchConfig) => {
      if (columnsSearchConfig && columnsSearchConfig.length) {
        configNumber += 1;
      }
    });

    if (searchNormalizedCharacters) {
      configNumber += 1;
    }

    if (searchCaseSensitive) {
      configNumber += 1;
    }

    return configNumber;
  }

  focusOnInput = (event: MouseEvent) => {
    const inputClassName = 'react-tagsinput-input';
    const target: any = event.target;

    if (target.className === inputClassName) {
      target.focus();
      return;
    }
    // get the wrapped input
    const inputs = target.getElementsByClassName('react-tagsinput-input');
    if (inputs.length) {
      inputs[0].focus();
      return;
    }
  }

  renderDropdownRow = (headerCellName, i) => {
    const { searchByColumn, hideColumns } = this.getCurrentSearchConfig();
    const searchValues = searchByColumn[i] || [];

    const triggerHandleOnSearchByFieldChange = (newArrayOfStrings) => {
      this.handleOnSearchByFieldChange(i, newArrayOfStrings);
    };

    const toggleColumnVisibilityOfColumn = (event: React.MouseEvent) => {
      this.toggleColumnVisibility(i);
    };

    return (
      <div className="gtfs-search-input-wrapper" key={headerCellName} onClick={this.focusOnInput}>
        <TagsInput
          value={searchValues}
          onChange={triggerHandleOnSearchByFieldChange}
          placeholder={headerCellName}
          className={'gtfs-search-input'}
        />
        <div className="gtfs-search-config-wrapper" onClick={toggleColumnVisibilityOfColumn}>
        <span className={`hide-button ${ hideColumns[i] ? 'active' : ''}`}>HIDE</span>
        </div>
    </div>
    );
  }

  renderDropDown = () => {
    const { headerCells } = this.props;
    const { searchCaseSensitive, searchNormalizedCharacters } = this.props.searchConfig;

    return (
      <div className={'gtfs-search-dropdown'}>
        <div className="search-quick-config-wrapper">
          <div
            className={`search-quick-config-row ${searchCaseSensitive ? 'active' : ''}`}
            onClick={this.toggleSearchCaseSensitive}
          >
            <Switch checked={searchCaseSensitive}/>
            Search with case sensitiveness
          </div>
          <div
            className={`search-quick-config-row ${searchNormalizedCharacters ? 'active' : ''}`}
            onClick={this.toggleSearchNormalizedCharacters}
          >
            <Switch checked={searchNormalizedCharacters}/>
            Search using normalized characters
          </div>
        </div>
        <div className="gtfs-search-input-list-wrapper">
          {headerCells && headerCells.map(this.renderDropdownRow)}
        </div>
      </div>
    );
  }

  renderNotificationBadge() {
    const numberOfConfigs = this.getNumberOfColumnSpecificConfig();

    return !numberOfConfigs
      ? null
      : (
      <div className="notification-badge">
        {numberOfConfigs}
      </div>
    );
  }

  render() {
    const { searchByAllColumns } = this.getCurrentSearchConfig();
    const { isDropDownVisible } = this.state;

    return (
      <div className="gtfs-search-wrapper">
        <div className="gtfs-search-input-wrapper" onClick={this.focusOnInput}>
          <TagsInput
            addOnBlur={false}
            alwaysShowPlaceholder={false}
            value={searchByAllColumns}
            onChange={this.handleOnSearchChange}
            placeholder={'search all fields'}
            className={'gtfs-search-input'}
          />
          <div className="gtfs-search-config-wrapper" onClick={this.toggleDropdownVisibility}>
            <i className="fa fa-search" />
            <i className="fa fa-caret-down" />
          </div>
        </div>
        {this.renderNotificationBadge()}

        <CSSTransition in={isDropDownVisible} timeout={200} classNames="gtfs-search-dropdown">
          {this.renderDropDown()}
        </CSSTransition>
      </div>
    );
  }
}

export default SearchGtfsTableInput;
