import React from 'react';
import { cloneDeep, merge, pick } from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import { BarLoader } from 'react-spinners';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';

import { HTML_TAGS } from '@constants';
import store from '@stores';
import { withStore } from '@stores/withStore';
import { FeedInfo } from '@stores/transit';
import LoaderButton from '@components/LoaderButton/LoaderButton';
import getFeedStarsString from '@utils/getFeedStarsString';

import 'react-dates/lib/css/_datepicker.css';
import './FeedInfoForm.scss';

interface FeedInfoFormProps {
  store?: typeof store;
  feedInfo?: FeedInfo;
  onSubmit(feedInfo): Promise<FeedInfo>;
  button: string;
}

interface FeedInfoFormState {
  feedInfo?: FeedInfo;
  isFeedInfoValid?: boolean;
  hubspotFeedData: any;
  isLoadingHubspotData: boolean;
  focused: boolean;
}

interface FeedInfoFormInput {
  label: any;
  field?: string;
  customRender?(): JSX.Element;
  options?: {
    inputType?: HTML_TAGS.TEXT | HTML_TAGS.NUMBER | HTML_TAGS.URL;
    formType?: HTML_TAGS.CHECKBOX | HTML_TAGS.DATALIST | HTML_TAGS.DROPDOWN | HTML_TAGS.INPUT | 'custom';
    customRender?(): JSX.Element;
    datalistItems?(feedList): void;
    onMouseDown?(event): void
    itemLabelbyItemValue?: any;
    dropdownItems?(itemLabelbyItemValue): void;
    defaultValue?: any;
    disabled?: boolean;
    required?: boolean;
    validate?(): boolean;
  };
}

interface FeedInfoFormInputByField {
  [field: string]: FeedInfoFormInput;
}

type FeedInfoFormInputArray = FeedInfoFormInput | FeedInfoFormInput[];
type FeedInfoFormInputObject = {
  title: string;
  rows: FeedInfoFormInputArray[][];
};
type FeedInfoFormTemplate = FeedInfoFormInputObject[];

export class FeedInfoForm extends React.Component<FeedInfoFormProps, FeedInfoFormState> {
  constructor(props) {
    super(props);
    this.state = {
      feedInfo: this.getFeedInfoFormState({ feedInfo: props.feedInfo }),
      isFeedInfoValid: true,
      hubspotFeedData: undefined,
      isLoadingHubspotData: true,
      focused: false,
    };
  }

  async componentDidMount() {
    const { feedInfo } = this.props;

    if (feedInfo) {
      await this.getHubspotDataOfFeed();
    } else {
      await this.setState({
        isLoadingHubspotData: false,
      });
    }
  }

  getHubspotDataOfFeed = async () => {
    const { feedInfo } = this.state;
    const hubspotFeedData = await this.props.store.transit.getHubspotDataOfFeedId(feedInfo.feed_id);

    this.setState({
      hubspotFeedData,
      isLoadingHubspotData: false,
    });
  }

  componentDidUpdate(prevProps) {
    const { feedInfo } = this.props;

    if (prevProps.feedInfo !== feedInfo) {
      const config = {
        feedInfo,
      };

      this.setState({
        feedInfo: this.getFeedInfoFormState(config),
        isFeedInfoValid: true,
      });
    }
  }

  getFeedInfoFormState(config) {
    const feedInfo = pick(config.feedInfo || {}, [
      'country_codes',
      'feed_name',
      'feed_code',
      'feed_id',
      'feed_network_name',
      'feed_location',
      'in_beta',
      'sub_country_codes',
      'stars',
      'timezone',
      'bgtfs_uploaded_at',
      'bgtfs_version',
      'bgtfs_feed_version',
      'main_source_hash',
      'service_change_site_url',
      'service_change_site_from_date',
      'service_change_site_to_date',
    ]);

    return feedInfo;
  }

  isFeedInfoValid = () => {
    const { feedInfo } = this.state;

    const isFeedInfoValid = Object.entries(this.feedInfoFormInputByField).every(([field, feedInfoInput]) => {
      const isRequired = feedInfoInput.options && feedInfoInput.options.required;
      const hasValue = feedInfo[field] !== null && feedInfo[field] !== undefined && feedInfo[field] !== '';
      const isValid = feedInfoInput.options && feedInfoInput.options.validate ? feedInfoInput.options.validate() : true;

      if (isRequired && !hasValue) {
        return false;
      }

      if (!isValid) {
        return false;
      }

      return true;
    });

    if (!isFeedInfoValid) {
      this.setState({
        isFeedInfoValid: false,
      });

      return false;
    }

    return true;
  }

  sanitizeFeedInfoData = (feedInfo) => {

    const feedClone = cloneDeep(feedInfo);
    delete feedClone.uses_prediction_engine;

    if (feedClone.service_change_site_url && !feedClone.service_change_site_from_date) {
      feedClone.service_change_site_from_date = moment().format('YYYY-MM-DD');
    }

    return feedClone;
  }

  handleFormSubmit = async () : Promise<boolean> => {
    const { pages, router, transit } = this.props.store;
    const isFeedInfoValid = this.isFeedInfoValid();
    const { feedInfo } = this.state;
    const sanitizedFeedInfo = this.sanitizeFeedInfoData(feedInfo);

    // skip since saving is disabled
    // if (!isFeedInfoValid) {
    //   return false;
    // }

    const updatedFeedInfo: FeedInfo = await this.props.onSubmit(sanitizedFeedInfo);

    if (updatedFeedInfo === undefined) {
      return false;
    }

    await transit.getFeedList(true);
    pages.gtfs.setCurrentFeed(updatedFeedInfo.feed_id);
    // router.redirect(`/feed/${updatedFeedInfo.feed_id}/feedInfo`); // redirect if creating new feed
    return true;
  }

  validateInBetaStatus = () => {
    const { feedInfo } = this.props;
    const { feedInfo: proposedFeedInfo } = this.state;
    const bgtfsMetadata = [feedInfo?.bgtfs_uploaded_at, feedInfo?.bgtfs_version, feedInfo?.bgtfs_feed_version];
    const bounds = Object.values(feedInfo?.bounds ?? {});

    const hasAllBgtfsMetadata = bgtfsMetadata.every(v => v);
    const hasNoBounds = bounds.every(v => !v);

    // if updated, proposedFeedInfo?.in_beta becomes a string
    const wantsProductionStatus = Number(proposedFeedInfo?.in_beta) === 0;

    if (!wantsProductionStatus || hasAllBgtfsMetadata) {
      return true;
    }
    if (hasNoBounds) {
      return window.confirm('This feed has no uploaded BGTFS. Are you sure you want to set Production status?');
    }

    window.alert('This feed has feed bounds but it has no uploaded BGTFS. It can not be set to Production status.');
    return false;
  }
  handleChangeOnInput = (event) => {
    const feedInfoKey = event.target.name;
    const value = (event.target.type === HTML_TAGS.CHECKBOX) ? event.target.checked : event.target.value;

    if (this.state.feedInfo[feedInfoKey] === value) {
      return;
    }

    const newState = merge({}, this.state, { feedInfo: { [feedInfoKey]: value } });
    this.setState(newState);
  }

  renderDatalistItems(datalistItems: string[]) {
    return datalistItems.map((item, index) => {
      return (
        <option value={item} key={`droplistItem-${index}`}>{item}</option>
      );
    });
  }

  renderDropdownItems(itemLabelbyItemValue: object) {
    return Object.entries(itemLabelbyItemValue).map(([value, label], index) => {
      return (
        <option value={value} key={`dropdownItem-${index}`}>{label}</option>
      );
    });
  }

  renderColumn(column, columnValue) {
    const { store } = this.props;
    const { isFeedInfoValid } = this.state;

    const value = (columnValue === null || columnValue === undefined) ? '' : columnValue;
    const shouldHighlightInputAsInvalid = (!isFeedInfoValid) && column.options.required && (value === '');
    const optionalClassName = cx({
      'error': shouldHighlightInputAsInvalid,
    });

    switch (column.options.formType) {
      case HTML_TAGS.DATALIST:
        const datalistItems = column.options.datalistItems(store.transit.feedList);

        return (
          <div className={`feed-info-input dropdown ${optionalClassName}`}>
            <input
              name={column.field}
              value={value}
              list={HTML_TAGS.DATALIST}
              onChange={this.handleChangeOnInput}
            />
            <datalist id={HTML_TAGS.DATALIST}>
              {this.renderDatalistItems(datalistItems)}
            </datalist>
          </div>
        );
      case HTML_TAGS.DROPDOWN:
        const defaultValue = String(value) ? String(value) : column.options.defaultValue;
        const dropdownItems = column.options.dropdownItems(column.options.itemLabelbyItemValue);
        return (
          <div className={`feed-info-input dropdown ${optionalClassName}`}>
            <select
              name={column.field}
              value={defaultValue}
              onChange={this.handleChangeOnInput}
            >
              {this.renderDropdownItems(dropdownItems)}
            </select>
          </div>
        );
      case HTML_TAGS.CHECKBOX:
        return (
          <div className={optionalClassName}>
            <input
              name={column.field}
              type={HTML_TAGS.CHECKBOX}
              checked={value}
              onChange={this.handleChangeOnInput}
            />
          </div>
        );
      case 'custom':
        return column.options.customRender();
      default:
        return (
          <div className={`feed-info-input ${optionalClassName}`}>
            <input
              name={column.field}
              type={column.options.inputType}
              value={value}
              disabled={column.options.disabled}
              onChange={this.handleChangeOnInput}
            />
          </div>
        );
    }
  }

  renderRow(row) {
    const { feedInfo } = this.state;

    return row.map((column, index) => {
      if (Array.isArray(column)) {
        return (
          <div className="feed-info-column inline-column" key={`column-${index}`}>
            {this.renderRow(column)}
          </div>
        );
      }

      const value = feedInfo[column.field];
      const updatedColumn = merge({}, this.defaultOptions, column);
      const label = typeof updatedColumn.label === 'function'
        ? updatedColumn.label()
        : updatedColumn.label;

      return (
        <div className={`feed-info-column ${updatedColumn.options.formType}`} key={`column-${index}`}>
          <label className={`feed-info-label ${updatedColumn.options.formType}`}>{label}</label>
          {this.renderColumn(updatedColumn, value)}
        </div>
      );
    });
  }

  renderRows(rows) {
    return rows.map((row, index) => {
      return (
        <div className="feed-info-row" key={`row-${index}`}>
          {this.renderRow(row)}
        </div>
      );
    });
  }

  renderSections() {
    return this.feedInfoFormTemplate.map((section, index) => {
      return (
        <div className="feed-info-section" key={`section-${index}`}>
          <label>{section.title}</label>
          {this.renderRows(section.rows)}
        </div>
      );
    });
  }

  render() {
    const { button } = this.props;
    return (
      <div className="feed-info-container">
        {this.renderSections()}
        <div className="save-button-container">
          <LoaderButton
            value={button}
            onClickWithStatus={this.handleFormSubmit}
          />
        </div>
      </div>
    );
  }

  /* CommonForm Contents */

  defaultOptions = {
    options: {
      formType: HTML_TAGS.INPUT,
      inputType: HTML_TAGS.TEXT,
    },
  };

  feedInfoFormInputByField: FeedInfoFormInputByField = {
    country_codes: {
      label: 'Country Codes',
      field: 'country_codes',
    },
    feed_code: {
      label: 'Feed Code',
      field: 'feed_code',
      options: {
        disabled: !!this.props.feedInfo,
        required: true,
      },
    },
    feed_id: {
      label: 'Feed ID',
      field: 'feed_id',
      options: {
        inputType: HTML_TAGS.NUMBER,
        disabled: true,
      },
    },
    feed_location: {
      label: 'Feed Location',
      field: 'feed_location',
      options: {
        formType: HTML_TAGS.DATALIST,
        datalistItems: (feedList) => {
          return [...new Set(feedList.map(feed => feed.feed_location))];
        },
        required: true,
      },
    },
    feed_name: {
      label: 'Name',
      field: 'feed_name',
      options: {
        required: true,
      },
    },
    feed_network_name: {
      label: 'Default Network Name',
      field: 'feed_network_name',
    },
    service_change_site_url: {
      label: 'Service Change Site URL',
      field: 'service_change_site_url',
    },
    service_change_site_date_range: {
      label: 'Service Change Date Range Picker',
      field: 'service_change_site_date_range',
      options: {
        formType: 'custom',
        validate: () => {
          if (!this.state.feedInfo.service_change_site_from_date || !this.state.feedInfo.service_change_site_to_date) {
            return true;
          }

          return this.state.feedInfo.service_change_site_from_date
            && this.state.feedInfo.service_change_site_to_date
            && moment(this.state.feedInfo.service_change_site_from_date)
                .isBefore(moment(this.state.feedInfo.service_change_site_to_date));
        },
        customRender: () => {
          return (
            <div key="service_change_site_from_date" className={'feed-info-input'}>
              <DateRangePicker
                startDate={moment(this.state.feedInfo.service_change_site_from_date || Date.now())}
                startDateId="service_change_site_from_date"
                endDate={moment(this.state.feedInfo.service_change_site_to_date || Date.now())}
                endDateId="service_change_site_to_date"
                onDatesChange={({ startDate, endDate }) => { // tslint:disable-line:jsx-no-lambda max-line-length
                  if (startDate) {
                    const startDateString = moment(startDate).format('YYYY-MM-DD');
                    this.handleChangeOnInput(
                      { target: { name: 'service_change_site_from_date', value: startDateString } },
                    );
                  }

                  if (endDate) {
                    const endDateString = moment(endDate).format('YYYY-MM-DD');
                    this.handleChangeOnInput(
                      { target: { name: 'service_change_site_to_date', value: endDateString } },
                    );
                  }
                }}
                focusedInput={this.state.focused}
                onFocusChange={focused => this.setState({ focused })}
                displayFormat="YYYY-MM-DD"
              />
            </div>
          );
        },
      },
    },
    in_beta: {
      label: 'Beta Status',
      field: 'in_beta',
      options: {
        defaultValue: 2,
        formType: HTML_TAGS.DROPDOWN,
        inputType: HTML_TAGS.NUMBER,
        itemLabelbyItemValue: {
          0: 'Production (in_beta: 0)',
          1: 'Beta  (in_beta: 1)',
          2: 'Alpha  (in_beta: 2)',
          3: 'Disabled  (in_beta: 3)',
        },
        dropdownItems: itemLabelbyItemValue => itemLabelbyItemValue,
        validate: this.validateInBetaStatus
      },
    },
    sub_country_codes: {
      label: 'Sub Country Codes',
      field: 'sub_country_codes',
    },
    stars: {
      label: 'Stars',
      field: 'stars',
      options: {
        formType: 'custom',
        customRender: () => {
          const { feedInfo } = this.state;
          const starsString = getFeedStarsString(feedInfo);

          return (
            <div className="feed-info-input">
              <input
                name={'stars'}
                type={HTML_TAGS.TEXT}
                value={starsString}
                disabled={true}
                onChange={this.handleChangeOnInput}
              />
            </div>
          );
        },
      },
    },
    timezone: {
      label: 'Time Zone',
      field: 'timezone',
    },
    hubspot_company_priority: {
      label: () => {
        const { hubspotFeedData, isLoadingHubspotData } = this.state;

        function renderHubspotButton(label, link) {
          return (
            <a href={link} target="_blank">
              <i className="fa fa-external-link edit-icon" aria-hidden="true"/>
              {label}
            </a>
          ) ;
        }

        const additionalInfoLink = hubspotFeedData
          ? renderHubspotButton('edit', `https://app.hubspot.com/contacts/5474468/company/${hubspotFeedData.companyId}`) // tslint:disable-line max-line-length
          : renderHubspotButton('create company', 'https://app.hubspot.com/contacts/5474468/companies'); // tslint:disable-line max-line-length

        return (
          <React.Fragment>
            <span className="custom-label">Hubspot Priority Level</span>
            {!isLoadingHubspotData && additionalInfoLink}
          </React.Fragment>
        );
      },
      options: {
        formType: 'custom',
        customRender: () => {
          const { hubspotFeedData, isLoadingHubspotData } = this.state;

          if (isLoadingHubspotData) {
            return <BarLoader color="#30b566"/>;
          }

          const isHubspotPriorityAvailable = hubspotFeedData
            && hubspotFeedData.partnershipType
            && hubspotFeedData.partnershipType.length;

          if (isHubspotPriorityAvailable) {
            return (
              <React.Fragment>
                {hubspotFeedData.partnershipType.map((priorityLevel: string) => ( // tslint:disable-line jsx-no-multiline-js max-line-length
                  <div className={'feed-info-input'}>
                    <input type="text" value={priorityLevel} disabled={true}/>
                  </div>
                ))}
              </React.Fragment>
            );
          }

          return (
            <div className={'feed-info-input'}>
              <input type="text" value={'N/A'} disabled={true}/>
            </div>
          );
        },
      },
    },
  };

  feedInfoFormTemplate: FeedInfoFormTemplate = [
    {
      title: 'STATIC',
      rows: [
        [ // Row 1
          this.feedInfoFormInputByField.feed_name,
          this.feedInfoFormInputByField.feed_network_name,
        ],
        [ // Row 2
          this.feedInfoFormInputByField.feed_location,
          [
            this.feedInfoFormInputByField.country_codes,
            this.feedInfoFormInputByField.sub_country_codes,
          ],
        ],
        [ // Row 3
          [
            this.feedInfoFormInputByField.timezone,
            this.feedInfoFormInputByField.stars,
          ],
          [
            this.feedInfoFormInputByField.feed_code,
            this.feedInfoFormInputByField.feed_id,
          ],
        ],
        [ // Row 5
          this.feedInfoFormInputByField.service_change_site_url,
          this.feedInfoFormInputByField.service_change_site_date_range,
        ],
      ],
    },
    {
      title: 'STATUS',
      rows: [
        [ // Row 1
          this.feedInfoFormInputByField.in_beta,
          this.feedInfoFormInputByField.hubspot_company_priority,
        ],
      ],
    },
  ];

}

export default withStore<FeedInfoFormProps>(FeedInfoForm);
