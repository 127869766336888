import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FormikProps } from 'formik';

import store from '@stores';
import { withStore } from '@stores/withStore';

import { FloatingLabelInput } from '@components/Input/FloatingLabelInput';
import { PasswordInput } from '@components/Input/PasswordInput';
import { FormSubmitButton } from '@components/Input/FormSubmitButton';
import { FullScreenForm } from '@components/Form/FullScreenForm';
import { SignupData } from '@stores/api/auth';

interface InvitationPageProps {
  store: typeof store;
}

export const InvitationPage = withStore((props: InvitationPageProps) => {
  const { router, api, auth } = props.store;
  const { params: { invitationId }, query } = router.getUrlData();
  const { t, i18n } = useTranslation('common');

  return (
    <FullScreenForm
      title={t('login.create_account').toUpperCase()}
      logoCaption={t('login.welcome').toUpperCase()}
      successMessage={t('login.password_reset_email_successful')}
      requestInitialData={async () => {
        try {
          const invitation = await api.auth.getInvitation(invitationId);

          if (invitation === undefined) {
            setTimeout(() => {
              window.location.replace('/login');
            }, 5000);
            return {
              error: 'Unable to fetch invitation. Will redirect user to signin page in a few seconds...',
            };
          }

          if (invitation?.language) {
            i18n.changeLanguage(invitation.language);
          }

          return {
            data: {
              email: invitation?.email,
            },
          };
        } catch (error) {
          /* no-op */
        }

        return {};
      }}
      request={async (data: SignupData) => {
        try {
          const invitation = await api.auth.getInvitation(invitationId);
          await api.auth.createAccount(data, invitationId);

          const { isLoggedIn } = await auth.loginWith('local', {
            username: data.username,
            password: data.password,
          });

          if (isLoggedIn) {
            const redirectionUrl = query.redirectTo
              || invitation.initialAuth.routerAccess[0]
              || '/';

            const isHttp = /https:\/\//.test(redirectionUrl);
            if (isHttp) {
              window.location.replace(redirectionUrl);
              return;
            }

            router.redirect(redirectionUrl);
            return;
          }

          return {
            error: t('login.error_generic'),
          };
        } catch (err) {
          const UserAlreadyExist = err?.response?.data === 'User already exists';
          if (UserAlreadyExist) {
            return {
              error: t('login.credentials_in_use_error', { href: '/auth/password/reset' }),
            };
          }

          return {
            error: err?.response?.data || t('login.error_generic'),
          };
        }
      }}
      renderFooter={() => (
        <a href={'/signin'}>
          <span className="text-sm text-silvergrey font-bold cursor-pointer">
            {t('login.already_have_account')} <a className="underline">{t('login.sign_in')}</a>
          </span>
        </a>
      )}
      renderForm={(onSubmit, initialData: SignupData) => (
        <SignUpForm
          onSubmit={onSubmit}
          initialData={{
            username: '',
            password: '',
            ...initialData,
          }}
        />
      )}
    />
  );
});

interface SignUpFormProps {
  initialData: SignupData;
  onSubmit?(formData: SignupData) : void;
}

const SignUpForm : React.FC<SignUpFormProps> = (props) => {
  const { t } = useTranslation('common');

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        ...props.initialData,
      }}
      onSubmit={(values) => {
        props.onSubmit(values);
      }}
      validationSchema={Yup.object({
        email: Yup.string().email().required(t('login.email_required')),
        password: Yup.string().required(t('login.password_required')),
      })}
    >
      {(formik: FormikProps<SignupData>) => {
        const errors = Object.values(formik.errors);
        return (
          <Form
            className="w-full text-left"
            autoComplete="off"
          >
            {
              errors.length === 0 ? null : (
                <div className="bg-red bg-opacity-10 rounded-md text-red text-center text-xs font-bold p-6 mb-8">
                  {errors.map(error => (
                    <div>{error}</div>
                  ))}
                </div>
              )
            }
            <FloatingLabelInput
              name="username"
              type="text"
              autocomplete={false}
              placeholder={t('login.email_hint')}
              value={formik.values.username}
              onChange={(value) => {
                formik.setFieldValue('username', value);
              }}
            />
            <PasswordInput
              name="password"
              autocomplete="new-password"
              placeholder={t('login.create_password')}
              value={formik.values.password}
              onChange={value => formik.setFieldValue('password', value)}
            />
            <FormSubmitButton
              value={t('login.lets_go')}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default InvitationPage;
