import React from 'react';
import Select from 'react-select';

interface SelectWithTagsProps {
  name: string;
  inputValue: any;
  onChange: any;
  options: any;
}

class SelectWithTags extends React.Component<SelectWithTagsProps> {

  render() {
    const { inputValue, options, name } = this.props;

    const tagOptions = Array.isArray(inputValue) ? inputValue
      : typeof inputValue === 'string' ? inputValue.split(',')
        : [];

    const filterValue = tagOptions.map((v) => {
      return { label: v, value: v };
    });

    const onChange = (filter: any) => {
      const newInputValue = filter
        ? filter.map(f => f.value)
        : [];

      return this.props.onChange(name, newInputValue);
    };

    return (
      <Select
        className="multi-dropdown"
        isMulti={true}
        options={options}
        onChange={onChange}
        value={filterValue}
      />
    );
  }
}

export default SelectWithTags;
