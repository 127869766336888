export enum FOCUS_ON {
  NEXT = 'next',
  PREVIOUS = 'previous',
  PREV = 'prev',

  UP = 'up',
  DOWN = 'down',

  LEFT = 'left',
  RIGHT = 'right',

  FIRST = 'first',
  LAST = 'last',
}
