import React from 'react';
import ReactTagsInput from 'react-tagsinput';

import { GenericToolUI } from '@components/ToolComponents/simpleUIs/common/GenericToolUI';
import KeyboardActiveList from '@components/KeyboardActiveList/KeyboardActiveList';

import './TagsInput.scss';

interface TagsInputProps {
  addOnBlur?: boolean;
  alwaysShowPlaceholder?: boolean;
  className?: string;
  delimiters?: string[];
  onChange(value: string[]): void;
  onChangeInput?(value: string, values: string[]): void;
  placeholder: string;
  value?: string[];
  inputValue?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  enableAutocomplete?: boolean;
  renderAutocomplete?: (inputRef: React.RefObject<KeyboardActiveList>, isDropdownVisible: boolean) => JSX.Element;
}

interface TagsInputState {
  value: string[];
  inputValue: string;
}

export default class TagsInput extends React.Component<TagsInputProps, TagsInputState> {
  state = {
    value: this.props.value || [],
    inputValue: this.props.inputValue || '',
  };

  componentDidUpdate(prevProps: Readonly<TagsInputProps>, prevState: Readonly<TagsInputState>) {
    const { inputValue } = this.props;

    if (inputValue !== undefined && this.state.inputValue !== inputValue) {
      this.setState({ inputValue });
    }
  }

  handleOnChange = (value) => {
    const { onChange } = this.props;

  // Sort the value array in alphabetical order
  const sortedValue = value.sort((a: string, b: string) => {
    const numA = Number(a);
    const numB = Number(b);

    if (Number.isNaN(numA) || Number.isNaN(numB)) {
      return a.localeCompare(b);
    } 
      return numA - numB;
    
  });
    this.setState({ value: sortedValue });
    onChange(sortedValue);
  }

  handleOnChangeInput = (inputValue) => {
    const { onChangeInput } = this.props;
    const { value } = this.state;

    this.setState({ inputValue });
    onChangeInput(inputValue, value);
  }

  handleOnDelete = (props) => {
    this.handleOnChange(this.props.value.slice(0, props.value.length - 1));
  }

  handleOnChangeFocus = () => {};

  setTags = (value) => {
    if (!value) {
      return;
    }

    if (!this.props.delimiters || this.props.delimiters.length === 0) {
      this.setState({ inputValue: '' });
      this.handleOnChange([...this.props.value, value]);
      return;
    }

    const splitValues = value.split(new RegExp(this.props.delimiters.join('|'))).map(d => d.trim());
    const cleanedValues = [];
    splitValues.forEach((splitValue) => {
      splitValue.split(/\s+/).forEach(cleanedValue => cleanedValues.push(cleanedValue));
    });
    this.handleOnChange([...this.props.value, ...cleanedValues]);
    this.setState({ inputValue: '' });
  }

  handleOnBlur = () => {
    const { inputValue } = this.state;
    if (inputValue === '') {
      return;
    }

    this.setTags(inputValue);
  }

  handleOnPaste = (event) => {
    // onPaste is called before onChange which means that the pasted value will the be added in the input.
    // To prevent this, we call event.preventDefault()
    event.preventDefault();
    this.setTags(event.clipboardData.getData('text'));
  }

  renderInput = (inputProps) => {
    const { alwaysShowPlaceholder, enableAutocomplete, renderAutocomplete, placeholder } = this.props;
    const { inputValue } = this.state;

    return (
      <GenericToolUI
        alwaysShowPlaceholder={alwaysShowPlaceholder}
        template={placeholder}
        value={inputValue}
        onComplete={(index, value) => { this.setTags(value); }}
        onChangeFocus={this.handleOnChangeFocus}
        onChange={this.handleOnChangeInput}
        onDelete={() => { this.handleOnDelete(inputProps); }}
        onBlur={this.handleOnBlur}
        onPaste={this.handleOnPaste}
        enableAutocomplete={enableAutocomplete}
        renderAutocomplete={renderAutocomplete}
      />
    );
  }

  render() {
    const { className, value } = this.props;
    const initialValue = value === undefined ? this.state.value : value;

    return (
      <ReactTagsInput
        ref={this.props.inputRef}
        className={`react-tagsinput ${className || ''}`}
        value={initialValue}
        onChange={this.handleOnChange}
        tagProps={{ className: 'react-tagsinput-tag' }}
        inputProps={{ className: 'react-tagsinput-input' }}
        renderInput={this.renderInput}
      />
    );
  }
}
