interface EmailConfig {
  subject: string;
  body: string;
}

const RoyaleEmailConfig = (t, i18n): EmailConfig => {

  const subject = t('royale.email_subject');
  const body = `
      <div class="wrapper">
        <img class="header" src="https://dashboard-v2.transitapp.com/images/transit-logo${i18n.language === 'en' ? '' : '-fr'}.png" />
          <div class="content">
            <p>${t('royale.email_title')}</p>
            <br>
            <p>${t('royale.email_body_1')}</p>
            <br>
            <p>${t('royale.email_body_2')}</p>
            <br>
            <ul>
              <li>${t('royale.email_body_3.list.item_1')}</li>
              <li>${t('royale.email_body_3.list.item_2')}</li>
            </ul>
            <br>
            <p>${t('royale.email_body_4')}</p>
            <br>
            <p>${t('royale.email_body_5')}</p>
            <br>
            <p></p>${t('royale.email_signature')}</p>
            </p>
       </div>
    `;

  return {
    subject,
    body,
  };
};

export default RoyaleEmailConfig;
