import { OPERATION_KEY_WORDS } from '@constants';

export default { // Ordering of the properties matters. This defines the order in which they will appear in the UI
  byFeedIds: {
    component: 'input',
    text: 'Search by feed_id',
    type: OPERATION_KEY_WORDS.OPERATION,
    filter: {
      operation: (feedIds, operation) => {
        if (feedIds.length === 0) {
          return true;
        }

        const { feed } = operation;
        if (!feed) {
          return false;
        }

        const feedIdString = String(feed.feed_id);
        return feedIds.some(feedId => String(feedId) === feedIdString);
      },
    },
  },

  byFeedCodes: {
    component: 'input',
    text: 'Search by feed_code',
    type: OPERATION_KEY_WORDS.OPERATION,
    filter: {
      operation: (feedCodes, operation) => {
        if (feedCodes.length === 0) {
          return true;
        }

        const { feed } = operation;
        if (!feed) {
          return false;
        }
        return feedCodes.some(feedCode => feed.feed_code.includes(feedCode.toUpperCase()));
      },
    },
  },

  byOperationIds: {
    component: 'input',
    text: 'Search by operation id',
    type: OPERATION_KEY_WORDS.OPERATION,
    filter: {
      operation: (operationIds, operation) => {
        if (operationIds.length === 0) {
          return true;
        }

        const { operation_id } = operation;
        if (!operation_id) {
          return false;
        }
        return operationIds.some(operationId => operationId === operation_id);
      },
    },
  },

  byNoticeIds: {
    component: 'input',
    text: 'Search by notice id',
    type: OPERATION_KEY_WORDS.NOTICE,
    filter: {
      notice: (noticeIds, notice) => {
        if (noticeIds.length === 0) {
          return true;
        }

        return noticeIds.some(noticeId => noticeId === notice.id);
      },
    },
  },

  byIsRunning: {
    component: 'switch',
    text: 'Show only running operations',
    type: OPERATION_KEY_WORDS.OPERATION,
    filter: {
      operation: (shouldKeep, operation) => {
        return operation.isRunning && shouldKeep;
      },
    },
  },

  byIsFinished: {
    component: 'switch',
    text: 'Show only completed operations',
    type: OPERATION_KEY_WORDS.OPERATION,
    filter: {
      operation: (shouldKeep, operation) => {
        return !operation.isRunning && shouldKeep;
      },
    },
  },

  byIsTransitAnalyst: {
    component: 'switch',
    text: 'Show only Transit Analyst',
    type: OPERATION_KEY_WORDS.NOTICE,
    filter: {
      notice: (shouldKeep, notice) => {
        return shouldKeep && notice.category === 'TransitAnalyst';
      },
    },
  },

  byIsStatic: {
    component: 'switch',
    text: 'Show only Static',
    type: OPERATION_KEY_WORDS.NOTICE,
    filter: {
      notice: (shouldKeep, notice) => {
        return shouldKeep && notice.category === 'Static';
      },
    },
  },

  byIsRealtime: {
    component: 'switch',
    text: 'Show only Realtime',
    type: OPERATION_KEY_WORDS.NOTICE,
    filter: {
      notice: (shouldKeep, notice) => {
        return shouldKeep && notice.category === 'Realtime';
      },
    },
  },

  byIsResolved: {
    component: 'switch',
    text: 'Show only unresolved notices',
    type: OPERATION_KEY_WORDS.NOTICE,
    filter: {
      notice: (shouldKeep, notice) => {
        return notice.status !== 'Resolved' && shouldKeep;
      },
    },
  },

  byIsInfo: {
    component: 'switch',
    text: 'Hide info notices',
    type: OPERATION_KEY_WORDS.NOTICE,
    filter: {
      notice: (shouldKeep, notice) => {
        return notice.severity !== 'Info' && shouldKeep;
      },
    },
  },

  byIsWarning: {
    component: 'switch',
    text: 'Hide warning notices',
    type: OPERATION_KEY_WORDS.NOTICE,
    filter: {
      notice: (shouldKeep, notice) => {
        return notice.severity !== 'Warning' && shouldKeep;
      },
    },
  },

  byIsError: {
    component: 'switch',
    text: 'Hide error notices',
    type: OPERATION_KEY_WORDS.NOTICE,
    filter: {
      notice: (shouldKeep, notice) => {
        return notice.severity !== 'Error' && shouldKeep;
      },
    },
  },

  byIsEmpty: {
    component: 'switch',
    text: 'Hide operation with zero notices',
    type: OPERATION_KEY_WORDS.OPERATION,
    filter: {
      operation: (shouldKeep, operation) => {
        return operation.notices.length && shouldKeep;
      },
    },
  },

  byIsBeta: {
    component: 'switch',
    text: 'Hide beta feeds',
    type: OPERATION_KEY_WORDS.OPERATION,
    filter: {
      operation: (shouldKeep, operation) => {
        const { feed } = operation;
        if (!feed) {
          return false;
        }

        return feed.in_beta === 0 && shouldKeep;
      },
    },
  },
};
