import React from 'react';
import { isEqual, merge } from 'lodash';
import cx from 'classnames';

import { FOCUS_ON, DATA_TYPES } from '@constants';
import { getRenderFunctionAndTypeOfValue } from '..//lib/renderValueWithConfig';
import StringToolUI from '../simpleUIs/StringToolUI';
import RegexToolUI from '../simpleUIs/RegexToolUI';
import '../styles/SimpleDynamicObjectUI.scss';
import BasicFunctionalities from '../simpleUIs/common/BasicFunctionalities';
import { getDeepTypeOfValue, getTypeOfValue } from '../lib/getTypeOfValue';
import { getFallbackValueOfTemplate } from '@components/ToolComponents/lib/getFallbackValueOfTemplate';

interface DynamicObjectToolUIProps {
  index?: [number, number] | number | string;
  value?: any;
  template: any;
  parentConfig: any;
  onChange(value, index?): void;
}

interface DynamicToolUIState {
  objectEntries: any[];
  entriesAsObject: object;
  expandedEntries: boolean[];
}

type InputCoordinate = string;
interface AddRowAtIndexConfig {
  value?: [string, any];
  focusOn?: FOCUS_ON;
  from?: InputCoordinate ;
}

export default class DynamicObjectToolUI
  extends React.Component<DynamicObjectToolUIProps, DynamicToolUIState>
  implements BasicFunctionalities {

  constructor(props) {
    super(props);

    const { objectEntries, entriesAsObject, expandedEntries } = this.getDefaultValue();

    this.state = {
      objectEntries,
      entriesAsObject,
      expandedEntries,
    };
  }

  focus() {
    const row = 0;
    const column = 0;
    // will focus on the first key of the object.
    this.focusOnRow(`${row}:${column}`, FOCUS_ON.FIRST);
  }

  getDefaultValue() {
    const { value } = this.props;
    const valueEntries = value && Object.entries(value);

    const objectEntries = valueEntries && valueEntries.length ? valueEntries : [[undefined, undefined]];
    const entriesAsObject = valueEntries && valueEntries.length ? value : {};
    const expandedEntries = []; // used to keep track of which row is expanded

    return {
      objectEntries,
      entriesAsObject,
      expandedEntries,
    };
  }

  addRowAtIndex = async (index: number, config: AddRowAtIndexConfig) => {
    const { objectEntries } = this.state;
    const newValue = config.value;

    objectEntries.splice(index, 1, newValue);
    await this.updateObjectEntries(objectEntries);

    if (config.focusOn) {
      this.focusOnRow(`[${ index }, 0]`, config.focusOn);
    }
  }

  deleteRowIndex = async (rowIndex) => {
    const { objectEntries, expandedEntries } = this.state;

    objectEntries.splice(rowIndex, 1); // remove object from the entries
    expandedEntries.splice(rowIndex, 1);  // make sure that expanded is not applyed to another tool

    await this.setState({ expandedEntries });
    await this.updateObjectEntries(objectEntries);
  }

  handleDeleteEvent = async (coordinate: InputCoordinate, config?: { focusPrevious?: boolean }) => {
    const { objectEntries } = this.state;

    const coord = this.deSerializeCoodinates(coordinate);
    const rowIndex = coord[0];
    const columnIndex = coord[1];

    const deleteOnKey = columnIndex === 0;
    const deleteOnValue = columnIndex === 1;

    const key = objectEntries[rowIndex][0];
    const value = objectEntries[rowIndex][1];

    if (deleteOnKey && key === '' && value === '') {
      await this.deleteRowIndex(rowIndex);
    }

    if (deleteOnValue && config.focusPrevious) {
      this.focusOnRow(coordinate , FOCUS_ON.PREVIOUS);
    }
  }

  handleElementComplete = async (coordinate: InputCoordinate) => {
    const coord = this.deSerializeCoodinates(coordinate);
    const rowIndex = coord[0];
    const columnIndex = coord[1];

    const completeOnKey = columnIndex === 0;
    if (completeOnKey) {
      await this.toggleExpandRow(rowIndex);
    }
  }

  addEmptyItemAtEndOfList = async () => {
    const { objectEntries } = this.state;

    // make sure that we can add a row when the object is empty;
    const noEntriesYet = objectEntries.length === 0;

    // check if the last item is not "empty"
    // so that we can only add items one by one.
    const lastRow = objectEntries[objectEntries.length - 1];
    const lastRowKey = lastRow && lastRow[0];
    const keyIsDefinedInLastRow = lastRowKey && lastRowKey !== '' ;

    if (noEntriesYet || keyIsDefinedInLastRow) {
      const emptyValue: [string, any] = [undefined, undefined];

      await this.addRowAtIndex(objectEntries.length, {
        focusOn: FOCUS_ON.LAST,
        value: emptyValue,
      });
    }
  }

  refs: { [index: string]: any };
  focusOnRow = (coordinateFrom: AddRowAtIndexConfig['from'], where: AddRowAtIndexConfig['focusOn']) => {
    const { objectEntries } = this.state;
    const coord = this.deSerializeCoodinates(coordinateFrom);
    const rowIndex = coord[0];
    const columnIndex = coord[1];
    const focusFromKey = columnIndex === 0;
    const focusFromValue = columnIndex === 1;

    switch (where) {
      case FOCUS_ON.FIRST:
        const firstRef = this.refs[`0:${columnIndex}`];
        firstRef && firstRef.focus();
        break;

      case FOCUS_ON.LAST:
        const lastRef = this.refs[`${objectEntries.length - 1}:${columnIndex}`];
        lastRef && lastRef.focus();
        break;

      case FOCUS_ON.PREVIOUS:
        if (focusFromValue) {
          this.refs[`${rowIndex}:0`].focus();
        } else if (focusFromKey) {
          if (rowIndex - 1 < 0) {
            this.focusOnRow(coordinateFrom, FOCUS_ON.LAST);
          } else {
            this.refs[`${rowIndex - 1}:1`].focus();
          }
        }
        break;

      case FOCUS_ON.NEXT:
        if (focusFromKey) {
          this.refs[`${rowIndex}:1`].focus();
        } else if (focusFromValue) {
          if (rowIndex + 1 > objectEntries.length - 1) {
            this.focusOnRow(coordinateFrom, FOCUS_ON.FIRST);
          } else {
            this.refs[`${rowIndex + 1}:0`].focus();
          }
        }
        break;

      case FOCUS_ON.DOWN:
      case FOCUS_ON.UP:
        const nextRowIndex = rowIndex + (where === FOCUS_ON.DOWN ? 1 : -1);
        if (nextRowIndex < 0) {
          this.focusOnRow(coordinateFrom, FOCUS_ON.LAST);
        } else if (nextRowIndex > objectEntries.length - 1) {
          this.focusOnRow(coordinateFrom, FOCUS_ON.FIRST);
        } else if (focusFromKey) {
          this.refs[`${nextRowIndex}:0`].focus();
        } else if (focusFromValue) {
          this.refs[`${nextRowIndex}:1`].focus();
        }

        break;
    }
  }

  updateObjectEntries = async (objectEntries, arrayAsObject?) => {
    const { value: object, onChange, index } = this.props;
    const fallbackInputValue = this.getFallbackValueForValueInput();

    const entriesAsObject = arrayAsObject || objectEntries.reduce((prev, [key, val]) => {
      if (typeof val === 'undefined' || typeof key === 'undefined') {
        return prev;
      }

      const acc = prev || {}; // create accumulator dynamically;
      acc[key] = val || fallbackInputValue; // if key, minimal value is {} or '';
      return acc;

    }, undefined);

    if (entriesAsObject !== object) {
      await onChange(entriesAsObject, index);
    }

    await this.setState({
      objectEntries,
      entriesAsObject: entriesAsObject || {},
    });
  }

  private getFallbackValueForValueInput() {
    const { template } = this.props;
    const deepType = getDeepTypeOfValue(template);
    const isDynamicOfStrings = deepType === DATA_TYPES.DYNAMIC_OBJECT_OF_STRINGS;

    if (isDynamicOfStrings) {
      return '';
    }

    return getFallbackValueOfTemplate(Object.values(template)[0]);
  }

  handleElementValueChange = async (value, coordinate) => {
    const { objectEntries } = this.state;

    const [rowIndex, columnIndex] = this.deSerializeCoodinates(coordinate);
    const fallbackInputValue = this.getFallbackValueForValueInput();

    const arrayAsObject = objectEntries.reduce((prev, [dkey, dval], index) => {
      const changeInKey = columnIndex === 0 && index === rowIndex;
      const changeInValue = columnIndex === 1 && index === rowIndex;

      const key = changeInKey ? value : dkey;
      const val = changeInValue ? value : dval || fallbackInputValue;

      objectEntries[index] = [key, val]; // update entries;

      if (typeof key === 'undefined') {
        return prev;
      }

      const acc = prev || {}; // create accumulator dynamicaly;
      acc[key] = val || fallbackInputValue;

      return prev;
    }, undefined);

    await this.updateObjectEntries(objectEntries, arrayAsObject);
  }

  private syncStateWithObject = async (object) => {
    const { entriesAsObject } = this.state;
    let { objectEntries } = this.state;

    if (!object) {
      objectEntries = [[undefined, undefined]];
      return this.updateObjectEntries(objectEntries, object);
    }

    // remove keys that don't exist anymore
    objectEntries = objectEntries.filter(entry => object[entry[0]] !== undefined);
    objectEntries = objectEntries.length ? objectEntries : [];

    // update/create keys and values
    Object.entries(object).forEach(([key, val], index) => {
      const valueAtKey = entriesAsObject[key];
      const valueExistAtKey = valueAtKey !== undefined;
      const lineIsSame = valueExistAtKey ? isEqual(valueAtKey, val) : false;

      if (lineIsSame) { return; }

      const shouldUpdateAt = valueExistAtKey
        ? objectEntries.findIndex(entry => (entry[0] === key)) // change in value column;
        : objectEntries.findIndex(entry => (entry[1] === val) && !object[entry[0]]); // change in key column

      if (shouldUpdateAt !== -1) {
        objectEntries[shouldUpdateAt] = [key, val];
      } else {
        objectEntries.splice(index, 0, [key, val]);
      }
    });

    this.updateObjectEntries(objectEntries, object);
  }

  async componentDidUpdate() {
    const { value: object } = this.props;
    const { entriesAsObject } = this.state;
    const isStateSynced = isEqual(object, entriesAsObject);
    if (isStateSynced) { return; }

    await this.syncStateWithObject(object);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !isEqual(this.props.value, nextProps.value);
    const stateWillSync = isEqual(nextState.entriesAsObject, nextProps.value);

    return propsChanged || stateWillSync;
  }

  private serializeCoodinates = JSON.stringify;
  private deSerializeCoodinates = JSON.parse;

  async toggleExpandRow(rowIndex: number) {
    const { expandedEntries } = this.state;
    expandedEntries[rowIndex] = !expandedEntries[rowIndex];
    await this.setState({ expandedEntries });
  }

  renderRow = ([key, toolValue], rowIndex) => {
    const { template, parentConfig } = this.props;
    const { expandedEntries } = this.state;

    const templateEntries = Object.entries(template);

    const config = (columnIndex, mergeProps?: any) => {
      const childConfig = {
        ref: `${rowIndex}:${columnIndex}`,
        keyName: templateEntries[0][columnIndex],
        template: templateEntries[0][columnIndex],
        index: this.serializeCoodinates([rowIndex, columnIndex]),
        onChange: this.handleElementValueChange,
        onComplete: this.handleElementComplete,
        onDelete: this.handleDeleteEvent,
        onChangeFocus: this.focusOnRow,
      };

      return merge({}, parentConfig, childConfig, mergeProps);
    };

    const keyProps = config(0, {
      errorIfEmpty: true,
    });

    let component = null;
    const deepType = getDeepTypeOfValue(template);
    const isDynamicOfStrings = deepType === DATA_TYPES.DYNAMIC_OBJECT_OF_STRINGS;
    const isExpanded = expandedEntries[rowIndex] || isDynamicOfStrings;

    if (isExpanded) {
      const toolProps = config(1);
      const { renderComponent } = getRenderFunctionAndTypeOfValue(toolProps.template, toolValue, toolProps);
      component = renderComponent();
    }

    const objectWrapperClassName = cx('simple-dynamic-object-wrapper', {
      'is-expanded': isExpanded,
      'is-dynamic-of-strings': isDynamicOfStrings,
    });

    const buttonListClassName = cx('button-list', {
      'hide-expand': isDynamicOfStrings,
    });

    const isRegexTool = getTypeOfValue(keyProps.template) === DATA_TYPES.REGEXP;
    const keyComponent = isRegexTool
      ? <RegexToolUI {...keyProps} value={key} />
      : <StringToolUI {...keyProps} value={key}/>;

    return (
      <div className={objectWrapperClassName} key={rowIndex + keyProps.keyName}>
        {keyComponent}
        {component}
        <div className={buttonListClassName}>
          <div className="expand-button button" onClick={this.toggleExpandRow.bind(this, rowIndex)}>
            <i className="fa fa-expand expand-icon" aria-hidden="true" />
          </div>
          <div className="trash-button button" onClick={this.deleteRowIndex.bind(this, rowIndex)}>
            <i className="fa fa-trash-o trash-icon" aria-hidden="true" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { objectEntries } = this.state;

    return (
      <div className="simple-dynamic-object-tool">
        <div className="simple-dynamic-object-container">
          {objectEntries.map(this.renderRow)}
        </div>
        <div className="add-item-button" onClick={this.addEmptyItemAtEndOfList}>
          <span>add item</span>
        </div>
      </div>
    );
  }
}
