import React from 'react';
import { BarLoader } from 'react-spinners';

import { withStore } from '@stores/withStore';
import store from '@stores';
import variables from '@styles/variables';

import BannersList from './components/BannersList/BannerList';

import './BannersPage.scss';
import '@styles/iframes.css';
import 'react-virtualized/styles.css';

interface BannersPageProps {
  store: typeof store;
  route: any;
  location: any;
  match: any;
}

interface BannersPageState {
  isLoadingBanners: boolean;
  isLoadingIframe: boolean;
  shouldRenderOldBanners: boolean;
}

class BannersPage extends React.Component<BannersPageProps, BannersPageState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingBanners: true,
      isLoadingIframe: true,
      shouldRenderOldBanners: true,
    };
  }

  async componentDidMount() {
    const { banners } = this.props.store;

    await banners.initBannerPageStore();

    await this.setState({
      isLoadingBanners: false,
    });

    window.addEventListener('message', (event) => {
      if (event.origin !== process.env.BANNERS_URL) {
        return;
      }

      window.history.pushState({}, 'Banners', event.data);
    });
  }

  renderLoading() {
    return (
      <div className="loader-wrapper">
        <BarLoader color="#30b566"/>
      </div>
    );
  }

  renderOldBanners() {
    return (
      <BannersList />
    );
  }

  renderNewBanners() {
    const { pathname } = store.router.getUrlData();

    return (
      <React.Fragment>
        {this.state.isLoadingIframe ? this.renderLoading() : null}
        <iframe
          id="banners-iframe"
          src={`${process.env.BANNERS_URL}${pathname}`}
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            border: 'none',
          }}
          onLoad={() => this.setState({ isLoadingIframe: false })}
        />
      </React.Fragment>
    );
  }

  renderBannerPage() {
    return (
      <div className="page-container iframe-wrapper">
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          {this.state.shouldRenderOldBanners ? 'Want to use the new banners page?' : 'Running into issues?'}
          <div
            style={{ color: variables.transitgreen, marginLeft: '3px', marginRight: '3px', cursor: 'pointer' }}
            onClick={() => this.setState({ shouldRenderOldBanners: !this.state.shouldRenderOldBanners })}
          >
            Click here
          </div>
          {this.state.shouldRenderOldBanners ? '' : ' to use the old banners page'}
        </div>
        {
          this.state.shouldRenderOldBanners
            ? this.renderOldBanners()
            : this.renderNewBanners()
        }
      </div>
    );
  }

  render() {
    return this.state.isLoadingBanners
      ? this.renderLoading()
      : this.renderBannerPage();
  }
}

export default withStore(BannersPage);
