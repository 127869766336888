import Tool from './Tool';

export default class Step {
  name: string;
  tools: Tool[];

  constructor(name, tools) {
    this.name = name;
    this.tools = tools;
  }
}
