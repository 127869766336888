import cx from 'classnames';
import moment from 'moment';
import { observer } from 'mobx-react';
import { pick } from 'lodash';
import React from 'react';
import { BarLoader } from 'react-spinners';
import { SortDirection } from 'react-virtualized';

import RealtimeStatusTable from '@pages/global/FeedsStatus/components/RealtimeStatusTable';
import TableDropdown from '@components/Table/components/TableDropdown';
import Table from '@components/Table/lib/Table';
import TableWrapper from '@components/Table/TableWrapper';
import { ColumnsSearchConfig } from '@components/Table/components/TableFilter';

import { OPERATION_STEP_STATUSES } from '@constants';
import store from '@stores';
import { FeedInfo } from '@stores/transit';
import { withStore } from '@stores/withStore';
import getFeedStarsString from '@utils/getFeedStarsString';

import './components/FeedsStatus.scss';
import { getCellClassesFromStatus } from './lib/styleHelpers';

interface FeedsStatusPageProps {
  store: typeof store;
  feedList: FeedInfo[];
}

interface FeedsStatusPageState {
  searchConfig: ColumnsSearchConfig;
  feedsTable: any;
}

const inOneWeek = moment().add(1, 'week').startOf('day');
const headerLabelByDataKey = {
  feed_id: 'Id',
  feed_code: 'Code',
  feed_name: 'Name',
  feed_location: 'Location',
  status: 'Status',
  stars: 'Stars',
  raw_data_end_at: 'Raw End Date',
  processed_data_end_at: 'Processed End Date',
  bgtfs_uploaded_at: 'Bgtfs Uploaded At',
  PredictionRequests: 'Prediction Status',
  VehicleLocations: 'Vehicle Status',
};

@observer class FeedsStatusPage extends React.Component<FeedsStatusPageProps, FeedsStatusPageState> {
  constructor(props) {
    super(props);

    this.state = {
      searchConfig: {},
      feedsTable: null,
    };
  }

  async componentDidMount() {
    const { feedList, store } = this.props;

    await store.operationAndNotices.init(null, false);

    const { feedOperationStatusByFeedId } = store.operationAndNotices;

    const feedsTable = new Table();
    const headers = Object.keys(headerLabelByDataKey);
    feedsTable.push(headers);

    feedList.forEach((feed) => {
      const row = Object.assign({}, feed);
      const feedRtStatus = store.transit.getRtStatusOfFeed(feed.feed_id);
      // @ts-ignore
      row.PredictionRequests = feedRtStatus.PredictionRequests.description;
      // @ts-ignore
      row.VehicleLocations = feedRtStatus.VehicleLocations.description;
      // @ts-ignore
      row.stars = getFeedStarsString(feed);
      // @ts-ignore
      row.status = feedOperationStatusByFeedId.get(feed.feed_id);

      feedsTable.push(Object.values(pick(row, headers)));
    });

    this.setState({
      feedsTable,
    });
  }

  handleOnSearchChange = (newSearchConfig: any) => {
    newSearchConfig.searchByAllColumns = newSearchConfig.search;
    this.setState({
      searchConfig: newSearchConfig,
    });
  }

  getRenderableFeedStatus = (index, table) => {
    const feed = table.getRow(index);

    if (!feed || feed && !feed.length) {
      return;
    }

    return feed;
  }

  renderColoredDate = (cell) => {
    const today = moment();
    const date = moment(cell.cellData);
    const isPast = date.isBefore(moment());
    const isWithinWeek = date.isAfter(today) && date.isBefore(inOneWeek);
    const dateClassName = cx('colored-cell', {
      'is-red': isPast,
      'is-yellow': isWithinWeek,
    });

    // ex: January 13, 2019
    const humanReadableDate = moment(cell.cellData).format('LL');

    return (
      <span className={dateClassName}>{humanReadableDate}</span>
    );
  }

  renderColoredRtStatus = (cell) => {
    const dateClassName = getCellClassesFromStatus(cell.cellData, 'colored-cell realtime-cell')

    const openRealtimeModal = () => this.openRealtimeModal(cell.rowData[0]);

    return (
      <span className={dateClassName} onClick={openRealtimeModal}>{cell.cellData}</span>
    );
  }

  renderFeedStatus = (cell) => {
    const { status, message } = cell.cellData;

    if (!message) {
      return '-';
    }

    return (
      <div className={`colored-cell ${status}`}>
        {message}
      </div>
    );
  }

  renderColoredCell = (cell, table) => {
    if (!cell.cellData) {
      return '-';
    }

    switch (table.headers[parseInt(cell.dataKey, 10)]) {
      case 'PredictionRequests':
      case 'VehicleLocations':
        return this.renderColoredRtStatus(cell);
      case 'raw_data_end_at':
      case 'processed_data_end_at':
        return this.renderColoredDate(cell);
      case 'status':
        return this.renderFeedStatus(cell);
      default:
        return cell.cellData;
    }
  }

  renderCell = (cell, table) => {
    const coloredCellText = this.renderColoredCell(cell, table);
    return (
      <span>
        {coloredCellText}
      </span>
    );
  }

  handleClickOnTableRow = (options) => {
    const { router } = this.props.store;
    const feedIdIndex = Object.keys(headerLabelByDataKey).indexOf('feed_id');
    const feedId = options.rowData[feedIdIndex];
    router.push(`/feed/${feedId}/feedInfo`);
  }

  openRealtimeModal(feedId) {
    const { modals, transit } = this.props.store;
    const feedRtStatus = transit.getRtStatusOfFeed(feedId);
    const vehicleStatuses = feedRtStatus.VehicleLocations.statuses || [];
    const tripStatuses = feedRtStatus.PredictionRequests.statuses || [];

    const rtStatuses = vehicleStatuses
      .concat(tripStatuses)
      .sort((statusA, statusB) => (
        new Date(statusB.updatedAt).getTime() - new Date(statusA.updatedAt).getTime()
      ));

    modals.showModal({
      title: `Summary Realtime (feedId: ${feedId})`,
      component: (
        <div className="rt-json-modal-wrapper">
          <RealtimeStatusTable statuses={rtStatuses}/>
        </div>
      ),
    });
  }

  render() {
    const { feedsTable, searchConfig } = this.state;
    const { isLoadingOperations } = this.props.store.operationAndNotices;

    if (isLoadingOperations) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          <BarLoader color="#30b566"/>
        </div>
      );
    }

    if (!feedsTable) {
      return null;
    }

    const defaultSortConfigs = [
      {
        sortBy: '7', // Processed End Date
        sortDirection: SortDirection.ASC,
        sortFunction: (rowIndexA, rowIndexB) => {
          const cellA = feedsTable.getCell(rowIndexA, 7);
          const cellB = feedsTable.getCell(rowIndexB, 7);

          const momentCellA = moment(cellA);
          const momentCellB = moment(cellB);
          if (momentCellA.isBefore(momentCellB, 'day')) {
            return -1;
          }

          if (momentCellA.isAfter(momentCellB, 'day')) {
            return 1;
          }

          return 0;
        },
      },
      {
        sortBy: '4', // Status
        sortDirection: SortDirection.ASC,
        sortFunction: (rowIndexA, rowIndexB) => {
          const cellA: { status: OPERATION_STEP_STATUSES } = feedsTable.getCell(rowIndexA, 4);
          const cellB: { status: OPERATION_STEP_STATUSES } = feedsTable.getCell(rowIndexB, 4);

          const cellStatusA: OPERATION_STEP_STATUSES = cellA && cellA.status
            ? cellA.status
            : OPERATION_STEP_STATUSES.FAILED;
          const cellStatusB: OPERATION_STEP_STATUSES = cellB && cellB.status
            ? cellB.status
            : OPERATION_STEP_STATUSES.FAILED;

          const statusByOrdering = {
            [OPERATION_STEP_STATUSES.FAILED]: 0,
            [OPERATION_STEP_STATUSES.KILLED]: 0,
            [OPERATION_STEP_STATUSES.REJECTED]: 0,
            [OPERATION_STEP_STATUSES.STARTED]: 1,
            [OPERATION_STEP_STATUSES.WAITING]: 2,
            [OPERATION_STEP_STATUSES.COMPLETED]: 3,
          };

          const orderingA: number = statusByOrdering[cellStatusA];
          const orderingB: number = statusByOrdering[cellStatusB];
          return orderingA - orderingB;
        },
      },
      {
        sortBy: '9', // Prediction Status
        sortDirection: SortDirection.ASC,
      },
      {
        sortBy: '10', // Vehicle Status
        sortDirection: SortDirection.ASC,
      },
      {
        sortBy: '5', // Stars
        sortDirection: SortDirection.ASC,
      },
    ];

    return (
      <React.Fragment>
        <div className="d-flex justify-content-end ">
          <TableDropdown<ColumnsSearchConfig>
            openDropdownOnHover={true}
            onChange={this.handleOnSearchChange}
            searchConfig={searchConfig}
            renderDropdownContent={(config, setConfig) => { // tslint:disable-line jsx-no-lambda
              const headerEntries = Object.entries(headerLabelByDataKey);
              const searchByColumn = config.searchByColumn || [];
              const hideByColumn = config.hideColumns || [];

              return (
                <React.Fragment>
                  {headerEntries.map(([headerKey, headerName], index) => {
                    return (
                      <TableDropdown.SearchColumn
                        key={headerKey}
                        columnName={headerName}
                        onHideToggle={(nextHidden) => {
                          // make sure to use immutable data
                          const nextHideColumns = [...hideByColumn];
                          nextHideColumns[index] = nextHidden;
                          setConfig({ hideColumns: nextHideColumns });
                        }}
                        onSearchChange={(nextSearchValues) => {
                          // make sure to use immutable data
                          const nextSearchByColumn = [...searchByColumn];
                          nextSearchByColumn[index] = nextSearchValues;
                          setConfig({ searchByColumn: nextSearchByColumn });
                        }}
                        value={{
                          hidden: hideByColumn[index],
                          search: searchByColumn[index],
                        }}
                      />
                    );
                  })}
                </React.Fragment>
              );
            }}
          />
        </div>
        <div className="feeds-status-table">
          <TableWrapper
            defaultSortConfigs={defaultSortConfigs}
            headers={Object.values(headerLabelByDataKey)}
            minColumnWidths={[
              40, // Feed Id
              75, // Feed Code
              200, // Feed Name
              100, // Location
              115, // Status
              100, // Stars
              120, // Raw End Date
              120, // Processed End Date
              150, // Bgtfs Uploaded At
              115, // Prediction Status
              115, // Vehicle Status
            ]}
            table={feedsTable}
            searchConfig={searchConfig}
            renderCell={this.renderCell}
            rowGetter={this.getRenderableFeedStatus}
            onRowDoubleClick={this.handleClickOnTableRow}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withStore<FeedsStatusPageProps>(FeedsStatusPage);
