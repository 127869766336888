import React from 'react';
import { observer } from 'mobx-react';
import { debounce, isEqual } from 'lodash';
import cx from 'classnames';

import { FeedInfo } from '@stores/transit';
import HighlightedText from '@components/HightlightedText/HighlightedText';
import SelectResultItem from '@components/SearchFeedsDropdown/components/SearchResultItem';
import TagsInput from '@components/TagsInput/TagsInput';
import KeyboardActiveList from '@components/KeyboardActiveList/KeyboardActiveList';
import operationAndNotices from '@stores/operationAndNotices';
import { KEYBOARD_KEYS } from '@constants';

import './styles/TagsSearchFeedsDropdown.scss';

interface TagsSearchFeedsDropdownProps {
  onChange(feedCodes: string[]): void;
  overflowSearchResults?: boolean;
  searchFeed(searchInput: string): Promise<FeedInfo[]>;
  selectedFeedCodes?: string[];
}

interface TagsSearchFeedsDropdownState {
  currentSearchResults: FeedInfo[];
  currentAgencySearch: string;
  selectedFeedCodes: string[];
}

class TagsSearchFeedsDropdown extends React.Component<TagsSearchFeedsDropdownProps, TagsSearchFeedsDropdownState> {

  constructor(props) {
    super(props);
    this.state = {
      currentAgencySearch: '',
      currentSearchResults: [],
      selectedFeedCodes: this.props.selectedFeedCodes || [],
    };
  }

  componentWillUnmount() {
    this.debouncedGetFeedSuggestions.cancel();
  }

  async componentDidUpdate(prevProps) {
    const propsChanged = !isEqual(prevProps.selectedFeedCodes, this.props.selectedFeedCodes);

    if (propsChanged) {
      await this.setState({
        selectedFeedCodes: this.props.selectedFeedCodes || [],
        currentAgencySearch: '',
        currentSearchResults: [],
      });
    }
  }

  debouncedGetFeedSuggestions = debounce(async(searchValue) => {
    const feeds = await this.props.searchFeed(searchValue);
    const filteredFeeds = feeds.filter(feed => !this.state.selectedFeedCodes.includes(feed.feed_code));

    this.setState({
      currentSearchResults: filteredFeeds,
    });
  }, 500, {
    leading: true,
    trailing: true,
    maxWait: 300,
  });

  // used a "proxy function" for debouncedGetFeedSuggestion because
  // react is throwing the event object to the garbage collector
  // before it can be used by the debounced function
  getFeedSuggestions = (searchValue) => {
    this.setState({
      currentAgencySearch: searchValue,
    });
    this.debouncedGetFeedSuggestions(searchValue);
  }

  handleOnChange = (newSearchTermsEvent: string[] = []) => {
    this.setState({
      selectedFeedCodes: newSearchTermsEvent,
    });

    this.props.onChange(newSearchTermsEvent);
    return;
  }

  handleSelect = (feed) => {
    if (!feed) {
      return;
    }

    this.debouncedGetFeedSuggestions.cancel();

    this.state.selectedFeedCodes.push(feed.feed_code);
    this.setState({
      selectedFeedCodes: this.state.selectedFeedCodes,
      currentAgencySearch: '',
      currentSearchResults: [],
    });

    this.props.onChange(this.state.selectedFeedCodes);
  }

  handleResetState = () => {
    this.setState({
      selectedFeedCodes: [],
      currentAgencySearch: '',
      currentSearchResults: [],
    });

    this.props.onChange([]);
  }

  renderSearchResultItem = (
    feed: FeedInfo,
    index: number,
    isActive: boolean,
    keyboardRef: React.RefObject<KeyboardActiveList>,
  ) => {
    operationAndNotices.setIsOperationsContainerInFocus(false);
    operationAndNotices.setIsNoticesContainerInFocus(false);
    return (
      <SelectResultItem
        isActive={isActive}
        key={Math.random().toString()}
        feed={feed}
        onSelect={this.handleSelect}
        onEnter={() => keyboardRef.current.handleOnEnter(index)} // tslint:disable-line:jsx-no-lambda
      />
    );
  }

  renderSearchIcon() {
    const { selectedFeedCodes } = this.state;
    if (selectedFeedCodes.length) {
      return (
        <div
          className="search-input-icon hover red"
          onClick={this.handleResetState}
          /* tslint:disable-next-line:jsx-no-lambda */
          onKeyUp={(event) => {
            if (event.keyCode === KEYBOARD_KEYS.ENTER) {
              event.stopPropagation();
              return this.handleResetState();
            }
          }}
          tabIndex={0}
        >
          <i className="fa fa-times" aria-hidden="true"/>
        </div>
      );
    }

    return (
      <div className="search-input-icon" tabIndex={0}>
        <i className="fa fa-search" aria-hidden="true"/>
      </div>
    );
  }

  renderAutocomplete = (keyboardRef: React.RefObject<KeyboardActiveList>, isDropdownVisible: boolean): JSX.Element => {
    const { currentAgencySearch, currentSearchResults } = this.state;

    const overflowSearchResults = `autocomplete-wrapper search-agency-results ${cx({
      'special-width': this.props.overflowSearchResults,
    })}`;

    if (currentSearchResults.length === 0 || !isDropdownVisible) {
      return null;
    }

    return (
      <div className={overflowSearchResults} style={{ display: 'block' }}>
        <HighlightedText matchString={currentAgencySearch}>
          <KeyboardActiveList
            list={currentSearchResults}
            renderItem={(listItem, index, isActive) => { // tslint:disable-line jsx-no-lambda
              return this.renderSearchResultItem(listItem, index, isActive, keyboardRef);
            }}
            onSelect={this.handleSelect}
            ref={keyboardRef}
          />
        </HighlightedText>
      </div>
    );
  }

  render() {
    const { currentAgencySearch, selectedFeedCodes } = this.state;

    return (
      <div className="select-feed-tags-list">
        <div className="search-input-wrapper">
          <TagsInput
            addOnBlur={false}
            alwaysShowPlaceholder={false}
            delimiters={[',\s*', ';\s*', '/\s*', ':\s*', '\\|\s*']}
            value={selectedFeedCodes}
            inputValue={currentAgencySearch}
            onChange={this.handleOnChange}
            onChangeInput={this.getFeedSuggestions}
            placeholder="ex: STM, STL ..."
            enableAutocomplete={true}
            renderAutocomplete={this.renderAutocomplete}
          />

          {this.renderSearchIcon()}
        </div>
      </div>
    );
  }
}

export default observer(TagsSearchFeedsDropdown);
