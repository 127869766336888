import React, { MouseEvent } from 'react';
import TagsInput from 'react-tagsinput';
import Switch from 'rc-switch';
import { CSSTransition } from 'react-transition-group';
import { WINDOW_EVENTS } from '@constants';

export interface ColumnsSearchConfig {
  searchByAllColumns?: string[];
  quickToggles?: any[];
}

interface UserSearchInputProps extends ColumnsSearchConfig {
  openDropdownOnHover?: boolean;
  onChange(columnsConfig: ColumnsSearchConfig): void;
}

interface UserSearchInputState {
  isDropdownVisible: boolean;
}

class UserSearchInput extends React.Component<UserSearchInputProps, UserSearchInputState> {

  state = {
    isDropdownVisible: false,
  };

  componentDidMount() {
    window.addEventListener(WINDOW_EVENTS.CLICK, this.hideDropdown);
  }

  componentWillUnmount() {
    window.removeEventListener(WINDOW_EVENTS.CLICK, this.hideDropdown);
  }

  hideDropdown = () => {
    this.updateDropdownVisibility(false);
  }

  handleNewGtfsViewConfig = (changedColumnsSearchConfig: ColumnsSearchConfig) => {
    this.props.onChange(Object.assign({},
      this.props,
      { ...changedColumnsSearchConfig },
    ));
  }

  handleOnSearchChange = (searchByAllColumns: string[]) => {
    this.handleNewGtfsViewConfig({ searchByAllColumns });
  }

  updateDropdownVisibility = (visibility: boolean) => {
    this.setState({
      isDropdownVisible: visibility,
    });
  }

  toggleDropdownVisibility = (event: React.MouseEvent) => {
    const { isDropdownVisible } = this.state;
    this.updateDropdownVisibility(!isDropdownVisible);
  }

  stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  getNumberOfColumnSpecificConfig = () => {
    const { quickToggles } = this.props;

    let configNumber = 0;
    quickToggles.forEach((quickToggle) => {
      if (quickToggle.value) {
        configNumber += 1;
      }
    });

    return configNumber;
  }

  focusOnInput = (event: MouseEvent) => {
    const inputClassName = 'react-tagsinput-input';
    const target: any = event.target;

    if (target.className === inputClassName) {
      target.focus();
      return;
    }
    // get the wrapped input
    const inputs = target.getElementsByClassName('react-tagsinput-input');
    if (inputs.length) {
      inputs[0].focus();
      return;
    }
  }

  renderDropdown = () => {
    const { quickToggles } = this.props;

    return (
      <div className={'users-search-dropdown'}>
          <div className="search-quick-config-wrapper">
            {quickToggles.map((quickToggle, index) => { // tslint:disable-line jsx-no-multiline-js

              const onChange = () => {
                quickToggle.onChange(!quickToggle.value);
              };

              return (
                <div
                  key={index}
                  className={`search-quick-config-row ${quickToggle.value ? 'active' : ''}`}
                  onClick={onChange}
                >
                  <Switch checked={quickToggle.value}/>
                  {quickToggle.text}
                </div>
              );
            })}
          </div>
      </div>
    );
  }

  renderNotificationBadge() {
    const numberOfConfigs = this.getNumberOfColumnSpecificConfig();

    return !numberOfConfigs
      ? null
      : (
      <div className="notification-badge">
        {numberOfConfigs}
      </div>
    );
  }

  render() {
    const { searchByAllColumns, openDropdownOnHover } = this.props;
    const { isDropdownVisible } = this.state;

    const optionalProps = {
      ...(openDropdownOnHover && {
        onMouseEnter: () => this.updateDropdownVisibility(true),
        onMouseLeave: () => this.updateDropdownVisibility(false),
      }),
    };

    return (
      <div className="users-search-wrapper" onClick={this.stopPropagation}>
        <div className="users-search-input-wrapper" onClick={this.focusOnInput}>
          <TagsInput
            value={searchByAllColumns}
            onChange={this.handleOnSearchChange}
            placeholder={'search all fields'}
            className={'users-search-input'}
          />
          <div className="users-search-config-wrapper" {...optionalProps} onClick={this.toggleDropdownVisibility}>
            <i className="fa fa-search" />
            <i className="fa fa-caret-down" />
          </div>
        </div>
        {this.renderNotificationBadge()}

        <CSSTransition in={isDropdownVisible} timeout={200} classNames="users-search-dropdown">
          {this.renderDropdown()}
        </CSSTransition>
      </div>
    );
  }
}

export default UserSearchInput;
