export const GTFS_KEYS = [
  'field_name',
  'language_code',
  'raw_stop_name',
  'raw_trip_headsign',
  'route_long_name',
  'route_short_name',
  'route_key',
  'route_type',
  'rt_trip_headsign',
  'stop_name',
  'trip_headsign',
  'direction_id',
];
