import React from 'react';
import GenericToggleButton from '@components/GenericToggleButton/GenericToggleButton';
import '../GenericToggleButton/ToggleButtons.scss';

interface CheckboxProps {
  label: string;
  checked?: boolean;
  hide?: boolean;
  onChange?(bool: boolean): void;
  style?: any;
}

export default class Checkbox extends React.Component<CheckboxProps> {
  render() {
    return (
        <GenericToggleButton
          onChange={this.props.onChange}
          active={this.props.checked}
          hide={this.props.hide}
          /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
          children={isTrue => (
              <React.Fragment>
                <div className="checkbox-square">
                  {isTrue && <i className="checkbox-icon fa fa-check"/>}
                </div>
                <span className="checkbox-label" style={this.props.style}>
                  {this.props.label}
                </span>
              </React.Fragment>
            )
          }
        />
    );
  }
}
