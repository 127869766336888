import React from 'react';
import { GoogleApiWrapper, Map } from 'google-maps-react';

import { withStore } from '@stores/withStore';
import store from '@stores';

import './FeedInfoForm.scss';
import variables from '@styles/variables';

interface FeedBoundMapProps {
  store?: typeof store;
  feedInfo?: any;
  google: any;
}

export class FeedBoundMap extends React.Component<FeedBoundMapProps, any> {

  state = {
    geojsonEnvelope: [],
    geojsonRoutes: [],
  };

  onMapReady = async ({ google }, map) => {
    await this.autoCenterMap(google, map);
    await this.loadGeoJson(map);
  }

  autoCenterMap = async (google, map) => {
    const bounds = new google.maps.LatLngBounds();

    this.getMapBounds().forEach(({ lat, lng }) => {
      const latLng = new google.maps.LatLng(lat, lng);
      bounds.extend(latLng);
    });
    map.fitBounds(bounds); // auto-zoom
    map.panToBounds(bounds); // auto-center
    await this.loadGeoJson(map);
  }

  private getMapBounds() {
    const { bounds } = this.props.feedInfo;

    if (!bounds.max_lat || !bounds.max_lon || !bounds.min_lat || !bounds.min_lon) {
      return;
    }

    return [
      { lat: bounds.max_lat, lng: bounds.min_lon }, // top-left
      { lat: bounds.max_lat, lng: bounds.max_lon }, // top-right
      { lat: bounds.min_lat, lng: bounds.max_lon }, // bottom-right
      { lat: bounds.min_lat, lng: bounds.min_lon }, // bottom-left
    ];
  }

  loadGeoJson = async (map) => {
    const { feedInfo, store } = this.props;
    const geojsonRoutes = await store.api.feeds.getRoutes(feedInfo.feed_code);
    const geojsonEnvelope = await store.api.feeds.getEnvelope(feedInfo.feed_code);

    if (!geojsonEnvelope && !geojsonRoutes) {
      return;
    }

    await this.setState({ geojsonEnvelope, geojsonRoutes });

    map.data.addGeoJson(geojsonEnvelope);
    map.data.addGeoJson(geojsonRoutes); // load geojson layer
    map.data.setStyle((feature) => {
      // tslint:disable-next-line:one-variable-per-declaration
      const routeType = feature.getProperty('route_type');
      const style = {
        strokeColor: variables.transitdarkgreen,
        strokeWeight: 0.5,
        strokeOpacity: 0.4,
        fillOpacity: 0.2,
        fillColor: variables.transitlightgreen,
        clickable: false,
        zIndex: 10 - routeType, // more frequent routes above less frequent
      };

      const isRailStyle = [0, 1, 2].includes(routeType); // more frequent routes are more visible
      if (isRailStyle) {
        style.strokeWeight = 4.0;
        style.strokeOpacity = 0.5;
      }
      return style;
    });
  }

  render() {
    const { geojsonRoutes, geojsonEnvelope } = this.state;

    if (!geojsonEnvelope && !geojsonRoutes) {
      return (
        <div className="feed-bound-map-error">
          PLEASE RUN FEED TO SEE FEED MAP
        </div>
      );
    }

    return (
      <Map
        google={this.props.google}
        onReady={this.onMapReady}
      />
    );
  }
}

const withGoogleMapApi = GoogleApiWrapper({ apiKey: process.env.GOOGLE_MAP_API_KEY });
export default withStore<FeedBoundMapProps>(withGoogleMapApi(FeedBoundMap));
