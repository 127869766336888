import { GenericToolUI, GenericToolUiProps } from './common/GenericToolUI';
import BasicFunctionalities from './common/BasicFunctionalities';
import { withStore } from '@application/stores/withStore';

class NumberToolUI extends GenericToolUI<number>
  implements BasicFunctionalities {

  handleArrowKeyUp = () => {
    const { value } = this.state;
    const inputValue = value ? value : 0;
    this.setNewValue(parseInt(String(inputValue), 10) + 1);
  }

  handleArrowKeyDown = () => {
    const { value } = this.state;
    const inputValue = value ? value : 0;
    this.setNewValue(parseInt(String(inputValue), 10) - 1);
  }

  handleOnChangeInput = (event) => {
    const value = event.target.value;
    const numberValue = parseInt(String(event.target.value), 10);

    const isEmptyValue = value === '' || typeof value === 'undefined';
    const isValid = isEmptyValue || !isNaN(numberValue);

    if (!isValid) {
      if (value === '-') {
        return this.setNewValue(0);
      }
      return;
    }

    const newValue = isEmptyValue ? undefined : numberValue;
    this.setNewValue(newValue);
  }

}

export default withStore<GenericToolUiProps<number>>(NumberToolUI);
