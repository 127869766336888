import archives from './archives';
import auth from './auth';
import emails from './emails';
import feeds from './feeds';
import gtfs from './gtfs';
import http from './lib/http';
import httpSocket from './lib/http_socket';
import tools from './tools';
import socket from './lib/socket';
import banners from './banners';
import users from './users';
import images from './image';

class ApiStore {
  public archives = archives;
  public auth = auth;
  public emails = emails;
  public feeds = feeds;
  public gtfs = gtfs;
  public http = http;
  public httpSocket = httpSocket;
  public tools = tools;
  public socket = socket;
  public banners = banners;
  public users = users;
  public images = images;
}

export default new ApiStore();
