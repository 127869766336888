import ErrorPage from './error/ErrorPage';
import AdminPage, { adminSubPages } from './admin/AdminPage';
import GlobalPage, { globalPageSubPages } from './global/GlobalPage';
import FeedPage, { feedPageSubPages } from './feed/FeedPage';
import BannersPage from '@pages/banners/BannersPage';
import LoginPage from './login/LoginPage';
import InvitationPage from './login/InvitationPage';
import LoadingPage from './login/LoadingPage';
import ForgotPasswordPage from './login/ForgotPasswordPage';
import ResetPasswordPage from './login/ResetPasswordPage';

import './index.scss';

export const pages = {
  ErrorPage,
  AdminPage, adminSubPages,
  GlobalPage, globalPageSubPages,
  BannersPage,
  FeedPage, feedPageSubPages,
  LoginPage,
  InvitationPage,
  LoadingPage,
  ForgotPasswordPage,
  ResetPasswordPage,
};
