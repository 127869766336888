import React from 'react';
import store from '@stores';
import { Route } from '@stores/router/routes';
import { withStore } from '@application/stores/withStore';

interface LayoutProps {
  store: typeof store;
  routes: Route[];
}

class FullScreenLayout extends React.Component<LayoutProps, null> {
  render() {
    const { routes, store: { router } } = this.props;

    return (
      <div className="page-full-screen login-page d-flex align-items-center justify-content-center">
      <div className="login-window">
        <div className="transit-dashboard-logo d-flex flex-wrap align-items-center justify-content-center">
          {router.renderRoute(routes, {})}
        </div>
      </div>
    </div>
    );
  }
}

export default withStore<LayoutProps>(FullScreenLayout);
