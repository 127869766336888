import React from 'react';
import { useTranslation } from 'react-i18next';

import store from '@stores';
import { withStore } from '@stores/withStore';
import badWords from '@utils/BadWords';

import AccessibleButton from '@components/AccessibleButton/AccessibleButton';
import { AutosizedBezierCurve } from '@components/BezierCurve/BezierCurve';
import Checkbox from '@components/Checkbox/Checkbox';
import Input from '@components/Input/Input';
import Tooltip from '@components/Tooltip/Tooltip';

import { PathwayInterface, StationMetadataInterface, StopInterface, PATHWAY_ERROR_CATEGORIES } from './PathwayTypes';
import StationAccordionList from '../../../pathways/components/StationAccordion/StationAccordionList';
import StopIdentifier from '../../../pathways/StopIdentifier/StopIdentifier';

import './PathwaysPage.scss';

interface PathwaysUIProps {
  onChange(stationMetadata: StationMetadataInterface): void;
  store: typeof store;
  stationMetadata: StationMetadataInterface;
  setError(
    stationStableId: number,
    fromStopId: string,
    toStopId: string,
    field: string,
    errorMessage: string,
    errorValue: any,
    errorCategory: PATHWAY_ERROR_CATEGORIES,
  ): void;
}

interface PathwayData extends PathwayInterface {
  onChange(pathway) : void;
}

const getEntrances = (stationMetadata): StopInterface[] => {
  return Object.values(stationMetadata.entranceByEntranceStableId || {});
};

const getPlatforms = (stationMetadata): StopInterface[] => {
  return Object.values(stationMetadata.stopByStopStableId);
};

const getPathwayIdFromStopToStop = (fromStop, toStop) => {
  return `${fromStop.stopStableId}:${toStop.stopStableId}`;
};

const createDefaultPathway = (fromStop, toStop) => {
  const fromStopId = fromStop.stopId;
  const toStopId = toStop.stopId;
  const pathwayId = getPathwayIdFromStopToStop(fromStop, toStop);

  return {
    pathwayId,
    fromStopId,
    toStopId,
    pathwayMode: '2', // 1 = walkway (wheelchair accessible); 2 = stairs (not wheelchair accessible)
    isBidirectional: '0', // it can only be used from fromStopId to toStopId
    traversalTime: '120',
    signpostedAs: toStop.stopName,
    isAccessible: true,
  };
};

const getPathwayFromStopToStop = (stationMetadata, fromStop, toStop): PathwayInterface => {
  const pathwayId = getPathwayIdFromStopToStop(fromStop, toStop);

  return stationMetadata.pathwayByPathwayId[pathwayId]
    || createDefaultPathway(fromStop, toStop);
};

const enhanceStationMetadata = (stationMetadata: StationMetadataInterface): StationMetadataInterface => {
  getEntrances(stationMetadata).forEach((entrance) => {
    getPlatforms(stationMetadata).forEach((stop) => {
      const pathwayIdFromEntranceToStop = getPathwayIdFromStopToStop(entrance, stop);
      if (!stationMetadata.pathwayByPathwayId[pathwayIdFromEntranceToStop]) {
        stationMetadata.pathwayByPathwayId[pathwayIdFromEntranceToStop] = getPathwayFromStopToStop(
          stationMetadata,
          entrance,
          stop,
        );
      }

      const pathwayIdFromStopToEntrance = getPathwayIdFromStopToStop(stop, entrance);
      if (!stationMetadata.pathwayByPathwayId[pathwayIdFromStopToEntrance]) {
        stationMetadata.pathwayByPathwayId[pathwayIdFromStopToEntrance] = getPathwayFromStopToStop(
          stationMetadata,
          stop,
          entrance,
        );
      }
    });
  });

  return stationMetadata;
};

// tslint:disable-next-line:variable-name
const PathwaysUI: React.FC<PathwaysUIProps> = (props) => {
  const { t, i18n } = useTranslation('common');

  const [entranceIndex, setEntranceIndex] = React.useState<number>(0);
  const [stationMetadata, setStationMetadata] = React.useState(props.stationMetadata);

  React.useEffect(() => {
    setStationMetadata(enhanceStationMetadata(stationMetadata));
  }, []);

  React.useEffect(() => {
    setEntranceIndex(0);
    setStationMetadata(enhanceStationMetadata(props.stationMetadata));
  }, [props.stationMetadata.stopStableId]);

  const updatePathwayFromStopToStop = (oldPathway: PathwayInterface, newPathwayData: Partial<PathwayInterface>) => {
    const newPathway = Object.assign({}, oldPathway, newPathwayData);
    const newPathwayId = newPathway.pathwayId;
    stationMetadata.pathwayByPathwayId[newPathwayId] = newPathway;

    props.onChange(stationMetadata);
    setStationMetadata(stationMetadata);
  };

  const renderBezierCurve = () => {
    const heightOfPlatformIdentifier = 120;
    const heightOfEntranceIdentifier = 52;

    return (
      <div className="pathways-bezier-curves">
        {getPlatforms(stationMetadata).map((stop, index) => {
          const originY = (heightOfEntranceIdentifier * entranceIndex) + heightOfEntranceIdentifier / 2;
          const targetY = (heightOfPlatformIdentifier * index) + 25;

          return (
            <AutosizedBezierCurve
              key={stop.stopId}
              originY={originY}
              targetY={targetY}
            />
          );
        })}
      </div>
    );
  };

  const renderStationAccordionList = () => {
    const hasEntrances = getEntrances(stationMetadata).length !== 0;
    if (!hasEntrances) {
      return (
        <div>
          <span>
            {t('pathways.no_entrances')}
          </span>
          <StationAccordionList
            stationMetadata={stationMetadata}
            defaultEntranceIndex={0}
            onActiveCardChange={setEntranceIndex}
            onChange={props.onChange}
          />
        </div>
      );
    }

    return (
      <div className="accordion-wrapper with-column-title margin-gutter">
        <p className="column-title">{t('pathways.from_entrance')}</p>
        <StationAccordionList
          stationMetadata={stationMetadata}
          defaultEntranceIndex={0}
          onActiveCardChange={setEntranceIndex}
          onChange={props.onChange}
        />
      </div>
    );
  };

  const renderIsAccessible = (label, style, { isAccessible, onChange }: PathwayData) => {
    return (
      <Checkbox
        label={label}
        checked={isAccessible}
        onChange={isAccessible => onChange({ isAccessible })} // tslint:disable-line:jsx-no-lambda
        style={style}
      />
    );
  };

  const renderWheelchairAccessible = ({ isAccessible, pathwayMode, onChange }: PathwayData) => {
    const isWalkway = pathwayMode === '1';
    return (
      <AccessibleButton
        active={isWalkway}
        className="margin-gutter"
        hide={!isAccessible}
        onChange={(isActive) => { // tslint:disable-line:jsx-no-lambda max-line-length
          onChange({
            pathwayMode: isActive ? '1' : '2',
          });
        }}
      />
    );
  };

  const renderFollowSignsTo = ({ pathwayId, fromStopId, toStopId, isAccessible, signpostedAs, onChange }: PathwayData, index?: number) => { // tslint:disable-line max-line-length
    const validateFollowSignsTo = (signpostedAs) => {
      let errorMessage = '';

      const isProfane = badWords.isProfane(signpostedAs);
      if (isProfane) {
        errorMessage = t('pathways.bad_words_error');
      }

      props.setError(
        stationMetadata.stopStableId,
        fromStopId,
        toStopId,
        'signpostedAs',
        errorMessage,
        signpostedAs,
        PATHWAY_ERROR_CATEGORIES.ERROR
      );

      return { error: errorMessage };
    };

    const followSignInput = (
      <Input
        key={entranceIndex}
        hide={!isAccessible}
        value={signpostedAs}
        onChange={signpostedAs => onChange({ signpostedAs })}
        validate={validateFollowSignsTo}
      />
    );

    if (index === 0) {
      return (
        <div className="with-column-title margin-gutter">
          <p className="column-title">{t('pathways.follow_signs')}</p>
          {followSignInput}
        </div>
      );
    }

    return (
      <div className="margin-gutter">
        {followSignInput}
      </div>
    );
  };

  const renderWalkTime = ({
                            fromStopId,
                            toStopId,
                            isAccessible,
                            traversalTime,
                            onChange,
                          }: PathwayData, index?: number) => {
    const validateTraversalTime = (traversalTime) => {
      let warningMessage = '';

      const isTooQuick = parseInt(traversalTime, 10) <= 15;
      if (isTooQuick) {
        warningMessage = t('pathways.fast_traversal_time_warning');
      }

      const isTooSlow = parseInt(traversalTime, 10) >= 240;
      if (isTooSlow) {
        warningMessage = t('pathways.slow_traversal_time_warning');
      }

      props.setError(
        stationMetadata.stopStableId,
        fromStopId,
        toStopId,
        'traversalTime',
        warningMessage,
        traversalTime,
        PATHWAY_ERROR_CATEGORIES.WARNING,
      );

      return { warning: warningMessage };
    };

    const traversalTimeInput = (
      <Input
        key={entranceIndex}
        hide={!isAccessible}
        value={traversalTime}
        onChange={(traversalTime: string) => { // tslint:disable-line:jsx-no-lambda
          const isNumberOrEmptyString = /^[0-9]+$|^$/; // only number or empty string;
          const isValid = isNumberOrEmptyString.test(traversalTime);
          if (isValid) {
            onChange({ traversalTime });
          }
        }}
        validate={validateTraversalTime}
      />
    );

    if (index === 0) {
      return (
        <div className="with-column-title margin-gutter">
          <div className="column-title">
            <i className="fa fa-clock-o clock-icon"/>
            <Tooltip
              message={t('pathways.tooltip.walk_time.pathway')}
              placement="top-start"
              /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
              render={() => (
                <div className="question-circle">
                  <i className="question-mark fa fa-question-circle"/>
                </div>
              )}
            />
          </div>
          <div className="walk-time">
            {traversalTimeInput}
          </div>
        </div>
      );
    }

    return (
      <div className="walk-time margin-gutter">
        {traversalTimeInput}
      </div>
    );
  };

  const renderPathwaysData = (stop: StopInterface, index: number) => {
    const entrance = getEntrances(stationMetadata)[entranceIndex];

    const pathwayFromEntranceToStop: PathwayInterface = getPathwayFromStopToStop(stationMetadata, entrance, stop);
    const pathwayDataFromEntranceToStop: PathwayData = {
      ...pathwayFromEntranceToStop,
      onChange: pathwayData => updatePathwayFromStopToStop(pathwayFromEntranceToStop, pathwayData),
    };

    const pathwayFromStopToEntrance: PathwayInterface = getPathwayFromStopToStop(stationMetadata, stop, entrance);
    const pathwayDataFromStopToEntrance: PathwayData = {
      ...pathwayFromStopToEntrance,
      onChange: pathwayData => updatePathwayFromStopToStop(pathwayFromStopToEntrance, pathwayData),
    };

    return (
      <div>
        <div className="pathway-data-row" style={{ paddingLeft: '15px' }}>
          {
            renderIsAccessible(
              t('pathways.enter_label'),
              {
                width: i18n.language === 'en' ? '95px' : '125px',
              },
              pathwayDataFromEntranceToStop
            )
          }
          {renderWheelchairAccessible(pathwayDataFromEntranceToStop)}
          {renderFollowSignsTo(pathwayDataFromEntranceToStop, index)}
          {renderWalkTime(pathwayDataFromEntranceToStop, index)}
        </div>
        <div className="pathway-data-row" style={{ paddingLeft: '15px' }}>
          {
            renderIsAccessible(
              t('pathways.exit_label'),
              { width: i18n.language === 'en' ? '95px' : '125px' },
              pathwayDataFromStopToEntrance
            )
          }
          {renderWheelchairAccessible(pathwayDataFromStopToEntrance)}
          {renderFollowSignsTo(pathwayDataFromStopToEntrance)}
          {renderWalkTime(pathwayDataFromStopToEntrance)}
        </div>
      </div>
    );
  };

  const renderPlatform = (stop: StopInterface, index: number, title: string) => {
    const icon = `https://data.transitapp.com/images/svgx/${stop.routeStopImage}-mono.svg`;

    const stationRegex = / ?station/i;
    const stopName = stationRegex.test(stop.stopName)
      ?
      t(
        'pathways.station_name',
        { station_name: stop.stopName.replace(stationRegex, '') },
      )
      : stop.stopName;

    if (index === 0) {
      return (
        <div className="with-column-title">
          <p className="column-title">{title}</p>
          <StopIdentifier
            backgroundColor={`#${stop.routeColor}`}
            textColor={`#${stop.routeTextColor}`}
            name={stopName}
            icon={icon}
          />
        </div>
      );
    }

    return (
      <div>
        <StopIdentifier
          backgroundColor={`#${stop.routeColor}`}
          textColor={`#${stop.routeTextColor}`}
          name={stopName}
          icon={icon}
        />
      </div>
    );
  };

  const renderPathways = () => {
    const hasEntrances = getEntrances(stationMetadata).length !== 0;
    if (!hasEntrances) {
      return (
        <div className="pathways-page-content">
          {renderStationAccordionList()}
        </div>
      );
    }

    return (
      <div className="pathways-page-content">
        {renderStationAccordionList()}
        {renderBezierCurve()}
        <div className="platforms-wrapper">
          {getPlatforms(stationMetadata).map((stop, index) => {
            return (
              <div className="platform-pathways-wrapper" key={index}>
                <div className="pathway-data-row">
                  {renderPlatform(stop, index, t('pathways.to_platform'))}
                  {renderPathwaysData(stop, index)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <div className="pathways-page-header">
        <div className="d-flex">
          <h2 className="title">
            {t('pathways.pathways_title')}
          </h2>
          <Tooltip
            message={t('pathways.tooltip.pathways_section')}
            placement="top"
            /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
            render={() => (
              <div className="question-circle">
                <i className="question-mark fa fa-question-circle"/>
              </div>
            )}
          />
        </div>
      </div>
      {renderPathways()}
    </div>
  );
};

export default withStore(PathwaysUI);
