import React from 'react';

// tslint:disable-next-line variable-name
export const CsvFileSystemButton = (props: { onFileImported(file: any) : void}) => {
  return (
    <div className="button csv-icon-control-button">
      <i className="fa fa-folder-open-o"/>
      <label className="input-label" htmlFor="file"/>
      <input
        hidden={true}
        id="file"
        type="file"
        multiple={false}
        onChange={props.onFileImported}
      />
    </div>
  );
};

// tslint:disable-next-line variable-name
export const CsvExplorerFullScreenButton = (props: { isFullScreen: boolean, onClick(event: any): void }) => {
  return (
    <div className="button csv-icon-control-button" onClick={props.onClick}>
      <i className={`fa ${ props.isFullScreen ? 'fa-minus' : 'fa-arrows-alt' }`}/>
    </div>
  );
};
