import React from 'react';

export const DropdownContext = React.createContext<any>({ count: 0 }); // tslint:disable-line variable-name

// tslint:disable-next-line max-line-length
export function useDropdownContext(defaultValue, prop, computeDiff: (prevValue, nextValue) => number, dependencies?: any[]) {
  const [count, setCount] = React.useContext(DropdownContext);
  const [state, setState] = React.useState(defaultValue);
  const [isMounted, setIsMounted] = React.useState(false);

  const effectDeps = dependencies || [prop];
  React.useEffect(() => {
    setIsMounted(true);
    setState(prop);

    const diffCount = computeDiff(state, prop);
    if (diffCount < 0 && !isMounted) {
      return;
    }

    setCount(diffCount);
  }, effectDeps);

  return state;
}
