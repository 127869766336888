import React from 'react';
import { isEqual , debounce } from 'lodash';
import BasicFunctionalities from './common/BasicFunctionalities';
import cx from 'classnames';

import '../styles/common.scss';
import '../styles/RegexToolUI.scss';

import { getRegexFromStringWithFlags } from '@components/ToolComponents/lib/getRegexFromStringsWithFlags';

export interface RegexToolUIProps {
  index?: [number, number] | number | string;
  value: undefined | string;
  keyName?: string;
  template?: string;
  onChange(value, index?): void;
}

export interface RegexToolUIState {
  regexInvalid: boolean;
  value: string;
}

export default class RegexToolUI
  extends React.Component<RegexToolUIProps, RegexToolUIState>
  implements BasicFunctionalities {

  constructor(props) {
    super(props);

    this.state = {
      value: this.getDefaultValue(),
      regexInvalid: false,
    };
  }

  inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  focus = () => {
    this.inputRef.current.focus();
  }

  getDefaultValue() {
    const { value } = this.props;
    return value || '';
  }

  isDefined() {
    const value = this.state.value;
    return !!getRegexFromStringWithFlags(value);
  }

  handleClick() {
    this.inputRef.current.focus();
  }

  emitOnChange = debounceInput((value) => {
    if (this.props.value === value) { return; }
    this.props.onChange(value, this.props.index);
  });

  componentWillUnmount() {
    this.emitOnChange.cancel();
  }

  handleChangeOnInput = async (event) => {
    const value = event.target.value;

    await this.setState({
      value,
      regexInvalid: false,
    });

    const isRegexValid = await this.validateRegexValue(value);
    if (isRegexValid) {
      await this.emitOnChange(value);
    }
  }

  handleOnFocusOnBlur = async (event: any) => {
    const value = event.target.value;
    await this.validateRegexValue(value);
  }

  private async validateRegexValue(value:string) {
    const isRegexValid = getRegexFromStringWithFlags(value);

    if (!isRegexValid) {
      await this.setState({ regexInvalid: true });
      return false;
    }

    return true;
  }

  openRegexrWebPage = () => {
    /**
     * TODO:
     * - open modal with the regexr, and test data
     */
    window.open('https://regexr.com/');
  }

  async componentDidUpdate(prevProps) {
    const stateNotSynced = !isEqual(this.state.value, this.props.value);
    const propsChanged = !isEqual(prevProps.value, this.props.value);

    const shouldSyncState = propsChanged && stateNotSynced;
    if (shouldSyncState) {
      await this.setState({ value: this.props.value });
    }
  }

  render() {
    const placeholder = this.props.keyName || this.props.template;
    const isRegexValidError = this.state.regexInvalid ? 'error' : '';
    const classNames = cx('string-tool', 'regex-tool', {
      'is-defined': !!this.state.value,
      'error': isRegexValidError,
    });

    return (
        <div className={classNames} onClick={this.focus}>
          <div className="placeholder">{placeholder}</div>
          <div className="string-tool-input">
            <input
              type="text"
              ref={this.inputRef}
              value={this.state.value}
              onChange={this.handleChangeOnInput}
              onFocus={this.handleOnFocusOnBlur}
              onBlur={this.handleOnFocusOnBlur}
            />
          </div>
          <div className="test-button" onClick={this.openRegexrWebPage}>
            <i className="fa fa-flask"/>
          </div>
        </div>
    );
  }
}

function debounceInput(fn) {
  return debounce(fn , 500, {
    leading: true,
    trailing: true,
  });
}
