import React, { ReactElement } from 'react';

import { LOADING_STATE } from '@constants';

import Button from '../Button/Button';

import variables from '@styles/variables';
import './LoaderButton.scss';

interface LoaderButtonProps {
  keyEvent?: any;
  errorMessage?: string;
  value: string;
  copied?: boolean;
  disable?: boolean;
  data?: any;
  className?: string;
  status?: string;
  imageLeft?: ReactElement<any>;
  imageRight?: ReactElement<any>;
  onClickWithStatus?(data?: LoaderButtonProps['data']): Promise<boolean>;
  style?: any;
}

interface LoaderButtonState {
  loadingStartAt: Date;
  loadingState: LOADING_STATE;
  value: string;
}

const STYLE_BY_LOADING_STATE = {
  [LOADING_STATE.INITIAL]: { opacity: 0 },
  [LOADING_STATE.PENDING]: { opacity: 1 },
  [LOADING_STATE.SUCCESS]: { opacity: 1, color: variables.transitgreen },
  [LOADING_STATE.COPIED]: { opacity: 1, color: variables.transitgreen },
  [LOADING_STATE.ERROR]: { opacity: 1, color: variables.transitred },
};

class LoaderButton extends React.PureComponent<LoaderButtonProps, LoaderButtonState> {

  state = {
    loadingStartAt: new Date(),
    loadingState: LOADING_STATE.INITIAL,
    value: this.props.value,
  };

  timeout;
  handleOnClickWithLoadingState = async (event, handler) => {
    const { disable } = this.props;
    if (disable) {
      return;
    }

    if (this.state.loadingState === LOADING_STATE.PENDING) {
      return;
    }

    clearTimeout(this.timeout);

    const timestamp = new Date();

    await this.setState({
      loadingState: LOADING_STATE.PENDING,
      loadingStartAt: timestamp,
    });

    const success = await handler(event);

    if (timestamp < this.state.loadingStartAt || this.isUnmounted) { return; }

    if (success && this.props.copied) {
      this.setState({
        loadingState: LOADING_STATE.COPIED,
        value: this.props.status || this.state.value,
      });
    } else if (success) {
      this.setState({
        loadingState: LOADING_STATE.SUCCESS,
        value: this.props.status || this.state.value,
      });
    } else {
      this.setState({
        loadingState: LOADING_STATE.ERROR,
      });
    }

    this.timeout = setTimeout(() => {
      this.setState({
        loadingState: LOADING_STATE.INITIAL,
      });
    }, 5000);
  }

  isUnmounted = false;
  componentWillUnmount() {
    this.isUnmounted = true;
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleOnClickWithStatus = async () => {
    const { onClickWithStatus, data } = this.props;
    return onClickWithStatus(data);
  }

  handleEnterKeyPress = async (event) => {
    const { keyEvent } = this.props;
    return keyEvent(event);
  }

  render() {
    const { className, errorMessage, disable, imageLeft, imageRight } = this.props;
    const { loadingState, value } = this.state;

    const classNames = `${ className ? className : '' }`;
    const style: any = Object.assign(
      {},
      {
        direction: 'rtl',
        whiteSpace: 'nowrap',
      },
      STYLE_BY_LOADING_STATE[loadingState],
    );

    const loadingMessage = loadingState === LOADING_STATE.ERROR && errorMessage
      ? errorMessage
      : loadingState;

    return (
      <div className="async-button-wrapper" style={this.props.style}>
        <Button
          keyEvent={event => this.handleOnClickWithLoadingState(event, this.handleEnterKeyPress)}
          className={classNames}
          disable={disable}
          value={value}
          onClick={event => this.handleOnClickWithLoadingState(event, this.handleOnClickWithStatus)}
          imageLeft={imageLeft}
          imageRight={imageRight}
        />
        <div style={style} className="status">{loadingMessage}</div>
      </div>
    );
  }
}

export default LoaderButton;
