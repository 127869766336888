/* tslint:disable:max-line-length */
import auth from '@stores/auth';
import transit from '@stores/transit';
import { UserProfile } from '@server/users/user.types';
import { ROLES } from '@constants';
import { pages } from '../../pages';

export interface Route {
  basePath?: string;
  path?: string;
  paths?: string[];
  key?: string;
  aliasOf?: string;
  fallbackPath?: string;
  getFallbackPath?() : string;
  checkShouldDisplay?(user: UserProfile) : boolean;
  layout?: string;
  onClick?(): void;
  linkName?: string;
  linkClassName?: string;
  canAccess?(user: UserProfile): boolean;
  component?: any;
  routes?: Route[];
}

const checkIsInternalStaff = (user: UserProfile) => user && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(user.auth.role);
const checkIsUser = (user: UserProfile) => user && [ROLES.PUBLIC_USER, ROLES.USER, ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(user.auth.role);

const routes: Route[] = [
  {
    path: '/global',
    linkName: 'GLOBAL',
    canAccess: checkIsInternalStaff,
    component: pages.GlobalPage,
    fallbackPath: '/global/operations',
    routes: [
      { path: '/status', linkName: 'STATUSES', component: pages.globalPageSubPages.FeedsStatusPage },
      {
        linkName: 'OPERATIONS',
        component: pages.globalPageSubPages.OperationsPage,
        paths: [
          '/operations',
          '/operations/:operation_id',
          '/operations/:operation_id/notices/:notice_id',
        ],
      },
    ],
  },
  {
    path: '/feed',
    linkName: 'FEED',
    canAccess: checkIsUser,
    component: pages.FeedPage,
    getFallbackPath: () => {
      const { user } = auth;
      if (!user) {
        return '/login';
      }

      const { feedAccess, routerAccess } = user.auth;
      if (!feedAccess.length) {
        return '/404';
      }

      const firstFeedAccess = feedAccess[0];
      if (firstFeedAccess === '*') {
        return '/feed/1/operations'; // STM feed page
      }

      const feed = transit.getFeedWithFeedCode(firstFeedAccess);
      if (!feed) {
        return '/404';
      }

      const firstFeedRouteAccess = routerAccess.find((route) => {
        return route.includes('/feed/:feed_id/');
      });

      return firstFeedRouteAccess.replace(':feed_id', `${feed.feed_id}`);
    },
    routes: [
      {
        linkName: 'OPERATIONS',
        component: pages.feedPageSubPages.OperationsPage,
        canAccess: checkIsInternalStaff,
        paths: [
          '/:feed_id/operations',
          '/:feed_id/operations/:operation_id',
          '/:feed_id/operations/:operation_id/notices/:notice_id',
        ],
      },
      { path: '/createFeed', component: pages.feedPageSubPages.FeedInfoPage, canAccess: checkIsInternalStaff },
      { path: '/:feed_id/operations/:operation_id/log', component: pages.feedPageSubPages.OperationLog, canAccess: checkIsInternalStaff },
      { path: '/:feed_id/feedInfo', linkName: 'FEED INFO', component: pages.feedPageSubPages.FeedInfoPage, canAccess: checkIsInternalStaff },
      { path: '/:feed_id/duplicateFeed', key: 'dup', component: pages.feedPageSubPages.FeedInfoPage, canAccess: checkIsInternalStaff },
      { path: '/:feed_id/csvExplorer', linkName: 'CSV EXPLORER', component: pages.feedPageSubPages.CsvExplorer, canAccess: checkIsInternalStaff },
      { path: '/:feed_id/visualize', linkName: 'VISUALIZER', component: pages.feedPageSubPages.GtfsVisualizer, canAccess: checkIsInternalStaff },
      { path: '/:feed_id/jsonExtension', linkName: 'JSON EXTENSION', component: pages.feedPageSubPages.JsonExtensionPage, canAccess: checkIsInternalStaff },
      {
        linkName: 'PATHWAYS',
        component: pages.feedPageSubPages.PathwaysPage,
        paths: [
          '/:feed_id/pathways',
          '/:feed_id/pathways/:station_stable_id',
        ],
        canAccess: checkIsUser,
      },
    ],
  },
  {
    path: '/banners',
    linkName: 'BANNERS',
    canAccess: checkIsInternalStaff,
    component: pages.BannersPage,
    routes: [
      { path: '/:banner_id', component: pages.BannersPage },
      { path: '/create', component: pages.BannersPage },
      { path: '/', component: pages.BannersPage },
    ],
  },
  {
    path: '/admin',
    linkName: 'ADMIN',
    canAccess: user => user && user.auth.role === ROLES.SUPER_ADMIN,
    checkShouldDisplay: user => user && user.auth.role === ROLES.SUPER_ADMIN,
    component: pages.AdminPage,
    fallbackPath: '/admin/users',
    routes: [
      { path: '/dispatcher_queue', linkName: 'USD QUEUE', component: pages.adminSubPages.DispatcherQueuePage },
      { path: '/munin', linkName: 'MUNIN', component: pages.adminSubPages.MuninPage },
      { path: '/prometheus', linkName: 'PROMETHEUS', component: pages.adminSubPages.PrometheusAlerts },
      {
        linkName: 'USERS',
        component: pages.adminSubPages.UsersPage,
        paths: [
          '/users',
          '/users/:user_id',
        ],
      },
    ],
  },
  {
    path: '/login',
    linkName: 'LOGOUT',
    linkClassName: 'danger',
    onClick: () => auth.logout(true),
    checkShouldDisplay: () => !!auth.isLoggedIn,
    getFallbackPath: () => {
      if (!auth.isLoggedIn) {
        return '/login';
      }
    },
    layout: 'fullscreen',
    component: pages.LoginPage,
  },
  {
    path: '/password/reset/:passwordResetId',
    checkShouldDisplay: () => false,
    layout: 'fullscreen',
    component: pages.ResetPasswordPage,
  },
  {
    path: '/password/reset',
    checkShouldDisplay: () => false,
    layout: 'fullscreen',
    component: pages.ForgotPasswordPage,
  },
  {
    path: '/invitations/:invitationId',
    layout: 'fullscreen',
    component: pages.InvitationPage,
  },
  {
  /**
   * this returns true when inside social login popup.
   * showing loading to the user while we close the popup.
   * will check if loading query param is present
   */
    path: '/loading',
    layout: 'fullscreen',
    component: pages.LoadingPage,
  },
  {
    path: '/401',
    component: () => 'ERROR 401: Unauthorised',
  },
  {
    path: '/404',
    component: () => 'ERROR 404: Not Found',
  },
  {
    path: '/',
    getFallbackPath: () => {
      if (!auth.isLoggedIn) {
        return '/login';
      }

      const isInternalStaff = checkIsInternalStaff(auth.user);
      if (isInternalStaff) {
        return '/global/operations';
      }

      return auth.user.auth.routerAccess[0];
    },
  },
  {
    path: '*',
    getFallbackPath: () => {
      if (!auth.isLoggedIn) {
        return '/login';
      }

      return '/404';
    },
  },
];

export default routes;
