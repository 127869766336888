import React from 'react';
import { BarLoader } from 'react-spinners';

import store from '@stores';
import { withStore } from '@stores/withStore';

import ActiveNavList from '@components/ActiveNavList/ActiveNavList';
import FeedsStatusPage from '@pages/global/FeedsStatus/FeedsStatusPage';
import OperationsPage from '@pages/global/OperationsPage/OperationsPage';

import './GlobalPage.scss';
import TransitLogo from '@components/TransitLogo/TransitLogo';

interface GlobalPageProps {
  store: typeof store;
  route: any;
  location: any;
  match: any;
}

interface GlobalPageState {
  isLoading: boolean;
  loadingTooSlow: boolean;
}

class GlobalPage extends React.Component<GlobalPageProps, GlobalPageState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      loadingTooSlow: false,
    };
  }

  async componentDidMount() {
    const { store } = this.props;

    setTimeout(() => {
      this.setState({
        loadingTooSlow: true,
      });
    }, 5000);

    await store.transit.getFeedList();
    await store.transit.updateRtStatusByFeedId();

    this.setState({
      isLoading: false,
    });
  }

  renderLoadingTooSlow() {
    const { loadingTooSlow } = this.state;

    if (!loadingTooSlow) {
      return;
    }

    return (
      <span>Please wait a bit longer.<br/>We're experiencing longer load times than usual.</span>
    );
  }

  renderLoading() {
    return (
      <div className="page-full-screen login-page d-flex align-items-center justify-content-center">
        <div className="login-window">
          <div className="transit-dashboard-logo d-flex flex-wrap align-items-center justify-content-center">
            <div className="form-header d-flex justify-content-center">
              <TransitLogo/>
            </div>

            <BarLoader color="#30b566"/>
            {this.renderLoadingTooSlow()}

          </div>
        </div>
      </div>
    );
  }

  renderGlobalPage() {
    const { router, transit: { feedList } } = this.props.store;

    return (
      <div className="page-container globalPage">
        <div className="transit-nav-container">
          <ActiveNavList
            routes={this.props.route.routes}
            className="current-transit-nav"
            linkClassName="current-transit-nav-link"
          />

        </div>
        <div className="transit-page-content">
          {router.renderRoute(this.props.route.routes, { feedList })}
        </div>
      </div>
    );
  }

  render() {
    return this.state.isLoading
      ? this.renderLoading()
      : this.renderGlobalPage();
  }
}

export default withStore(GlobalPage);
export const globalPageSubPages = {
  FeedsStatusPage,
  OperationsPage,
};
