import axios from 'axios';
import api from '@stores/api';
import { UserProfile } from '@server/users/user.types';
import { EmailConfig, Invitation } from '@server/auth/invitations/invitation.types';

const HOST_API_AUTH = `${window.location.origin}/api/auth`;
const adminInvitationUrl = `${window.location.origin}/api/auth/invitations`;

export interface Credentials {
  provider?: 'github';
  username?: string;
  password?: string;
  access_token?: string;
}

export interface SignupData {
  username: string;
  password: string;
  email: string;
}

export default {

  getPermissions: async () => {
    const url = `${HOST_API_AUTH}/permissions`;

    try {
      const { data: permissions } = await api.http.authenticatedGet(url);
      return permissions;
    } catch (err) {
      return [];
    }
  },

  getSelfUser: async () : Promise<UserProfile> => {
    const url = `${HOST_API_AUTH}/me`;
    const { data: user } = await api.http.authenticatedGet(url);
    return user;
  },

  /**
  * Retrieve a JSON Web Token (JWT) for the corresponding credentials if user authenticates
  * @param {Credentials} credentials
  * @returns Promise that resolves to a Jwtoken or false
  */
  getJsonWebToken: (type: 'local' | 'oauth', credentials: Credentials): Promise<any | boolean> => {
    let url;

    if (type === 'local') {
      url = `${HOST_API_AUTH}/login`;
    } else if (type === 'oauth') {
      url = `${HOST_API_AUTH}/${credentials.provider}`;
    } else {
      url = null;
    }

    return axios.post(url, credentials);
  },

  createSession: async (idToken: string) => {
    const url = `${HOST_API_AUTH}/session/sessionCreation`;
    return api.http.authenticatedPost(url, { idToken });
  },

  validateSession: async () => {
    const url = `${HOST_API_AUTH}/session/sessionValidation`;
    return api.http.authenticatedPost(url);
  },

  logoutSession: async () => {
    const url = `${HOST_API_AUTH}/session/sessionLogout`;
    return api.http.authenticatedPost(url);
  },

  createInvitation: async (invitation: Omit<Invitation, 'uid'>): Promise<Invitation | false> => {
    const res = await api.http.authenticatedPost(adminInvitationUrl, { invitation });

    return res && res.data;
  },

  sendInvitationEmail: async (invitationId: Invitation['uid'], emailConfig: EmailConfig) => {
    const sendInvitationEmailUrl = `${adminInvitationUrl}/${invitationId}/sendEmail`;
    const res = await api.http.authenticatedPost(sendInvitationEmailUrl, emailConfig);

    if (res && res.status === 200) {
      return true;
    }

    return false;
  },

  updateInvitation: async (invitationId: Invitation['uid'], invitation: Invitation): Promise<Invitation> => {
    const invitationUrl = `${adminInvitationUrl}/${invitationId}`;
    const res = await api.http.authenticatedPut(invitationUrl, { invitation });

    return res && res.data;
  },

  deleteInvitation: async (invitationId: Invitation['uid']) => {
    const invitationUrl = `${adminInvitationUrl}/${invitationId}`;
    const res = await api.http.authenticatedDelete(invitationUrl);

    if (res && res.status === 200) {
      return true;
    }

    return false;
  },

  getInvitations: async () => {
    const res = await api.http.authenticatedGet(adminInvitationUrl);

    if (res && res.status === 200) {
      return res.data;
    }

    return [];
  },

  getInvitation: async (invitationId) => {
    const res = await api.http.authenticatedGet(`${adminInvitationUrl}/${invitationId}`);

    if (res && res.status === 200) {
      return res.data;
    }

    return undefined;
  },

  createAccount: async (signupData: SignupData, invitationId: Invitation['uid']) : Promise<UserProfile> => {
    const sendInvitationEmailUrl = `${adminInvitationUrl}/${invitationId}/createAccount`;
    const { data: user } = await api.http.authenticatedPost(sendInvitationEmailUrl, signupData);
    return user;
  },

  requestResetPasswordLink: async (email: string) => {
    const resetPassworkURL = `${HOST_API_AUTH}/password/reset`;

    const res = await api.http.authenticatedPost(resetPassworkURL, {
      email,
    });

    const isEmailSent = res.status === 200 && res.data === 'email-sent';
    return isEmailSent;
  },

  resetPassword: async (passwordResetId: string, newPassword: string) => {
    const resetPassworkURL = `${HOST_API_AUTH}/password/reset/${passwordResetId}`;

    const res = await api.http.authenticatedPost(resetPassworkURL, {
      password: newPassword,
    });

    const isPasswordReset = res.status === 200 && res.data.username;
    if (!isPasswordReset) {
      return false;
    }

    const userName = res.data.username as string;
    return userName;
  },
};
