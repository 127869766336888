import React from 'react';

import './SideMenu.scss';

interface SideMenuItemProps {
  tool: any;
  active: boolean;
  onClick(tool: any): void;
}

class SideMenuItem extends React.Component<SideMenuItemProps, any> {

  onClick = () => {
    this.props.onClick(this.props.tool);
  }

  render() {
    const classList = `tool-name-wrapper ${this.props.active ? 'active' : ''}`;
    return (
    <div className={classList} key={this.props.tool.title} onClick={this.onClick}>
      <div className="is-used-light"/>
      <span className="tool-name">{`${this.props.tool.index} - ${this.props.tool.title}`}</span>
    </div>
    );
  }
}

export default SideMenuItem;
