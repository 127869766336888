import React from 'react';
import Mark from 'mark.js';

import './HighlightedText.scss';

interface HighlightedTextProps {
  matchString: string;
  style?: any;
}

class HighlightedText extends React.Component<HighlightedTextProps> {

  wrapperRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.highlightMatchedText();
  }

  componentDidUpdate() {
    this.highlightMatchedText();
  }

  highlightMatchedText() {
    const { current: domEl } = this.wrapperRef;
    const instance = new Mark(domEl);

    instance.unmark(this.props.matchString)
            .mark(this.props.matchString, {
              element: 'span',
              className: 'is-highlighted',
              filter: (element) => {
                return !element.parentNode.classList
                  .contains('disable-mark-highlight');
              },
            });
  }

  render() {
    return (
      <div ref={this.wrapperRef} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

export default HighlightedText;
