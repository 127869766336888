import React from 'react';
import { cloneDeep, merge } from 'lodash';
import cx from 'classnames';

import TagsInput from '@components/TagsInput/TagsInput';
import { FOCUS_ON , DATA_TYPES } from '@constants';
import BasicFunctionalities from '../simpleUIs/common/BasicFunctionalities';

import { getRenderFunctionAndTypeOfValue } from '../lib/renderValueWithConfig';
import { getDeepTypeOfValue } from '../lib/getTypeOfValue';
import { getFallbackValueOfTemplate } from '../lib/getFallbackValueOfTemplate';

import '../styles/ArrayToolUI.scss';

interface ArrayToolUIProps {
  index?: [number, number] | number | string;
  value?: any[];
  placeholder?: string;
  template: any[];
  parentConfig: any;
  onChange(value, index?): void;
}

interface AddRowAtIndexConfig {
  value: any;
  focusOn?: FOCUS_ON;
  from?: number;
}

export default class ArrayToolUI extends React.PureComponent<ArrayToolUIProps, null>
  implements BasicFunctionalities {

  isActive() {
    const array = this.getDefaultValue();
    return array && array.length > 0
      && array !== [undefined];
  }

  focus() {
    this.focusOnRow(0, FOCUS_ON.FIRST);
  }

  handleClick() {
    this.focus();
  }

  getDefaultValue() {
    const { value, template } = this.props;
    return Array.isArray(value) && value.length > 0 ? value : [getFallbackValueOfTemplate(template[0])];
  }

  private emitOnChange = async (rowsData: any[]) => {
    const { onChange, index } = this.props;
    const value = rowsData.length ? rowsData : undefined;
    await onChange(value, index);
  }

  addRowAtIndex = async (index: number, config: AddRowAtIndexConfig) => {
    const array = this.getDefaultValue();
    const newArray = cloneDeep(array);

    // auto-focus on the last row.
    const isLastRowAndEmpty = index >= newArray.length - 1
      && newArray[newArray.length - 1] === '';
    if (isLastRowAndEmpty) {
      this.focusOnRow(index, FOCUS_ON.LAST);
      return;
    }

    newArray.splice(index, 1, config.value);
    await this.emitOnChange(newArray);
    if (config.focusOn) {
      this.focusOnRow(config.from, config.focusOn);
    }
  }

  handleDeleteEvent = async (index, config?: { focusPrevious?: boolean }) => {
    const array = this.getDefaultValue();
    const newArray = cloneDeep(array);
    newArray.splice(index, 1);

    await this.emitOnChange(newArray);

    if (config && config.focusPrevious) {
      this.focusOnRow(index, FOCUS_ON.PREVIOUS);
    }
  }

  handleElementComplete = (index) => {
    const array = this.getDefaultValue();
    const isLastRow = index === array.length - 1;
    if (isLastRow) {
      this.addEmptyItemAtEndOfList();
    } else {
      this.focusOnRow(index, FOCUS_ON.NEXT);
    }
  }

  addEmptyItemAtEndOfList = () => {
    const array = this.getDefaultValue();
    const { template } = this.props;

    this.addRowAtIndex(array.length, {
      value: getFallbackValueOfTemplate(template[0]),
      focusOn: FOCUS_ON.LAST,
    });
  }

  refs: { [index: string]: any};
  focusOnRow = (from :AddRowAtIndexConfig['from'], index: AddRowAtIndexConfig['focusOn']) => {
    const array = this.getDefaultValue();

    switch (index){
      case FOCUS_ON.FIRST:
        const firstRef = this.refs[0];
        firstRef && firstRef.focus();
        break;

      case FOCUS_ON.LAST:
        const lastRef = this.refs[array.length - 1];
        lastRef && lastRef.focus();
        break;

      case FOCUS_ON.NEXT:
        if (from + 1 > array.length - 1) {
          this.focusOnRow(from, FOCUS_ON.FIRST);
        } else {
          const nextRef = this.refs[from + 1];
          nextRef && nextRef.focus();
        }
        break;

      case FOCUS_ON.PREVIOUS:
        if (from - 1 < 0) {
          this.focusOnRow(from , FOCUS_ON.LAST);
        } else {
          const prev = this.refs[from - 1];
          prev && prev.focus();
        }
        break;
    }
  }

  handleElementValueChange = async (value, index) => {
    const array = this.getDefaultValue();
    const newArray = cloneDeep(array);
    newArray.splice(index, 1, value);
    await this.emitOnChange(newArray);
  }

  handleStringListChange = async (value: string[]) => {
    await this.emitOnChange(value);
  }

  handleStringInputChange = () => {};

  renderRow = (value, index, array) => {
    const { template, parentConfig } = this.props;

    const childConfig = {
      index,
      ref: index,
      onChange: this.handleElementValueChange,
      onComplete: this.handleElementComplete,
      onDelete: this.handleDeleteEvent,
      onChangeFocus: this.focusOnRow,
    };

    const config = merge({}, parentConfig, childConfig);
    const { renderComponent } = getRenderFunctionAndTypeOfValue(template[0], value, config);

    return (
      <div className="array-element-wrapper" key={`${index}-${array.length}`}>
        {renderComponent()}
        <div className="trash-button" onClick={this.handleDeleteEvent.bind(this, index)}>
          <i className="fa fa-trash-o trash-icon" aria-hidden="true" />
        </div>
      </div>
    );
  }

  renderArrayOfComponent(array) {
    return (
      <React.Fragment>
        <div className="array-container">
          {array.map(this.renderRow)}
        </div>
        <div className="add-item-button" onClick={this.addEmptyItemAtEndOfList}>
          <span>add item</span>
        </div>
      </React.Fragment>
    );
  }

  renderArrayOfStrings(defaultValue) {
    const array = defaultValue.filter(value => !!value);
    const tagsPlaceholder = this.props.template.join(', ');

    return (
      <TagsInput
        value={array}
        onChange={this.handleStringListChange}
        onChangeInput={this.handleStringInputChange}
        placeholder={tagsPlaceholder}
        enableAutocomplete={true}
      />
    );
  }

  render() {
    const array = this.getDefaultValue();

    const dataType = getDeepTypeOfValue(this.props.template);
    const isArrayOfStrings = dataType === DATA_TYPES.ARRAY_OF_STRINGS;
    const isArrayOfStaticObject = dataType === DATA_TYPES.ARRAY_OF_STATIC_OBJECT;

    const arrayComponent = isArrayOfStrings
      ? this.renderArrayOfStrings(array)
      : this.renderArrayOfComponent(array);

    const classNames = cx({
      'is-array-of-static-object': isArrayOfStaticObject,
      'is-active': array && array.length > 0,
    });

    return (
      <div className={`array-tool ${classNames}`}>
        {arrayComponent}
      </div>
    );
  }
}
