import React from 'react';
import Switch from 'rc-switch';
import './BannersList.scss';

export interface SearchConfig {
  searchInput?: any;
  areArchivedBannersHidden?: boolean;
}

interface SearchBannerProps {
  openDropdownOnHover?: boolean;
  searchInput?: any;
  areArchivedBannersHidden?: boolean;
  onChange(columnsConfig: SearchConfig): void;
}

class SearchBanner extends React.Component<SearchBannerProps, any> {

  handleFilteringBanners = (changedSearchConfig: SearchConfig) => {
    this.props.onChange(Object.assign({},
      this.props,
      { ...changedSearchConfig },
    ));
  }

  handleOnSearchChange = (event) => {
    const search = event.target.value;
    this.handleFilteringBanners({ searchInput: search });
  }

  toggleActiveBanner = () => {
    const { areArchivedBannersHidden } = this.props;

    this.handleFilteringBanners({
      areArchivedBannersHidden: !areArchivedBannersHidden,
    });
  }

  renderDropdown = () => {
    const { areArchivedBannersHidden } = this.props;

    return (
      <div className="banner-search-dropdown">
        <div className="search-quick-config-wrapper">
          <div
            className={`search-quick-config-row ${areArchivedBannersHidden ? '' : 'active'}`}
            onClick={this.toggleActiveBanner}
          >
            <Switch checked={areArchivedBannersHidden}/>
            Hide archived
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { searchInput } = this.props;

    return (
      <div
        className="banner-search-wrapper"
      >
        <div className="banner-search-input-wrapper">
          <input
            type="text"
            value={searchInput || ''}
            onChange={this.handleOnSearchChange}
            placeholder="Search banners"
            className={'banner-search-input'}
          />
          <div className="banner-search-config-wrapper">
            <i className="fa fa-search"/>
          </div>
        </div>
          {this.renderDropdown()}
      </div>
    );
  }
}

export default SearchBanner;
