import api from '@stores/api';
import { AutocompleteStore } from '@stores/autocomplete';
import CacheManager from '@utils/CacheManager';
import { action, computed, observable } from 'mobx';

export interface Feed {
  feed_name: string;
  feed_code: string;
  feed_location: string;
  feed_id: number;
}

export interface FeedInfo {
  bgtfs_uploaded_at: string;
  bgtfs_version: string;
  bgtfs_feed_version: string;
  feed_name: string;
  feed_code: string;
  feed_id: number;
  feed_network_name: string;
  feed_location: string;
  bounds?: {
    max_lat: number;
    min_lat: number;
    max_lon: number;
    min_lon: number;
  };
  in_beta: number;
  country_codes: string;
  sub_country_codes: string;
  timezone: string;
  stars?: number;
  status?: any;
  raw_data_end_at?: string;
  processed_data_end_at?: string;
  service_change_site_url?: string;
  service_change_site_from_date?: string;
  service_change_site_to_date?: string;
}

export enum RequestType {
  PredictionRequests = 'PredictionRequests',
  VehicleLocations = 'VehicleLocations',
  ServiceAlerts = 'ServiceAlerts',
}

enum RealtimeStatus {
  UP = 'UP',
  DOWN = 'DOWN',
  REMOVED = 'REMOVED',
}

export interface RealtimeStatusInfo {
  feedId: string;
  recipeId: string;
  requestType: RequestType;
  source: string;
  status: RealtimeStatus;
  report: Object;
  updatedAt: string;
}

class TransitStore {
  @observable public rtStatusByFeedId = new Map();
  @observable public sourcesByFeedId: Map<number, any[]> = new Map();
  @observable public codeHashByFeedId: Map<number, string> = new Map();
  @observable public feedList = [];
  public cachedFeedSearch = new CacheManager();

  /*
  * resolve to the full list of feeds, calls feeds_complete
  **/
  public async getFeedList(forceUpdate = false): Promise<any[]> {
    if (!this.feedList.length || forceUpdate) {
      const feedList:any = await api.feeds.getFeedList();
      this.feedList = feedList ? feedList : this.feedList;
    }

    return this.feedList;
  }

  public async updateRtStatusByFeedId() {
    const rtStatus = await api.feeds.getRtStatus();

    this.rtStatusByFeedId.clear();
    this.feedList.forEach((feed) => {
      const feedId = parseInt(feed.feed_id, 10);
      const rtStatusOfFeedId = rtStatus[feed.feed_id] || {};

      if (!rtStatusOfFeedId.PredictionRequests) {
        rtStatusOfFeedId.PredictionRequests = { description: '-' };
      }
      if (!rtStatusOfFeedId.VehicleLocations) {
        rtStatusOfFeedId.VehicleLocations = { description: '-' };
      }

      this.rtStatusByFeedId.set(feedId, rtStatusOfFeedId);
    });

    return this.rtStatusByFeedId;
  }

  public getRtStatusOfFeed(feedId: string | number) {
    const feedIdInt = parseInt(feedId as string, 10);
    return this.rtStatusByFeedId.get(feedIdInt);
  }

  public async initSourcesOfFeed(feedId: number): Promise<void> {
    if (this.sourcesByFeedId.has(feedId)) {
      return;
    }

    const sources = await api.archives.getSources(feedId);
    this.sourcesByFeedId.set(feedId, sources);
  }

  public getSourcesOfFeed(feedId: number): any[] {
    return this.sourcesByFeedId.get(feedId) || [];
  }

  @action public setSourceAtIndexOfFeed(feedId: number, source, index: number) {
    const sources = this.getSourcesOfFeed(feedId);
    sources[index] = source;
    this.sourcesByFeedId.set(feedId, sources);
  }

  public async initCodeHashOfFeed(feed: any): Promise<void> {
    if (this.codeHashByFeedId.has(feed.feed_id)) {
      return;
    }

    const { data: { codeHash } } = await api.feeds.getCodeHash(feed.feed_code);
    this.codeHashByFeedId.set(feed.feed_id, codeHash);
  }

  public getCodeHashOfFeedId(feedId: number): string {
    return this.codeHashByFeedId.get(feedId);
  }

  /**
   * Search Transit Feed list
   * Promise that resolves to an array of feed
   * @param searchValue keywords to look for
   * @param limit max-length of the result array, default is 10
   */
  @action public searchFeed = async (searchValue: string, limit = 10): Promise<any[]> => {
    if (!searchValue) {
      return [];
    }

    const cachedSearch = this.cachedFeedSearch.get(searchValue);

    if (cachedSearch) {
      return cachedSearch.data.slice(0, limit);
    }

    const timestamp = Date.now();

    const data = AutocompleteStore.searchStaticIndex(this.feedList, {
      searchOptions: [{
        normalizeRow: row => `${row.feed_code} ${row.feed_id} ${row.feed_name} ${row.feed_location} ${row.in_beta}`,
        searchValues: searchValue.split(' '),
        compareStringOptions: {
          searchNormalizedCharacters: true,
          searchCaseInsensitive: true,
        },
      }],
      getRelevanceOfRow: (row, searchTerm) => {
        const upperCaseSearchTerm = searchTerm.toUpperCase();
        const upperCaseFeedCode = row.feed_code ? row.feed_code.toUpperCase() : '';
        const upperCaseFeedName = row.feed_name ? row.feed_name.toUpperCase() : '';

        const isExactMatch = upperCaseFeedCode === upperCaseSearchTerm || upperCaseFeedName === upperCaseSearchTerm;
        if (isExactMatch) {
          return Infinity;
        }

        let relevanceScore = 0;

        const isFeedCodeStartingWithTerm = upperCaseFeedCode.startsWith(upperCaseSearchTerm);
        if (isFeedCodeStartingWithTerm) {
          relevanceScore += 1;
        }

        const isFeedNameStartingWithTerm = upperCaseFeedName.startsWith(upperCaseSearchTerm);
        if (isFeedNameStartingWithTerm) {
          relevanceScore += 1;
        }

        const upperCaseFeedNetworkName = row.feed_network_name ? row.feed_network_name.toUpperCase() : '';
        const isFeedNetworkNameStartingWithTerm = upperCaseFeedNetworkName.startsWith(upperCaseSearchTerm);
        if (isFeedNetworkNameStartingWithTerm) {
          relevanceScore += 1;
        }

        const upperCaseFeedLocation = row.feed_location ? row.feed_location.toUpperCase() : '';
        const isFeedLocationStartingWithTerm = upperCaseFeedLocation.startsWith(upperCaseSearchTerm);
        if (isFeedLocationStartingWithTerm) {
          relevanceScore += 1;
        }

        switch (row.in_beta) {
          case 0:
            relevanceScore += 3;
            break;
          case 1:
            relevanceScore += 2;
            break;
          case 2:
            relevanceScore += 1;
            break;
        }

        return relevanceScore;
      },
    });

    this.cachedFeedSearch.set(searchValue, timestamp, data);
    return data.slice(0, limit);
  }

  public async getHubspotDataOfFeedId(feedId: string | number): Promise<any> {
    const feedHubspotData = await api.feeds.getHubspotDataOfFeedId(feedId);
    return feedHubspotData;
  }

  @computed get feedByFeedId(): Map<number, FeedInfo> {
    const feedByFeedId = new Map();
    for (const feed of this.feedList) {
      feedByFeedId.set(feed.feed_id, feed);
    }

    return feedByFeedId;
  }

  @computed get feedByFeedCode(): Map<string, FeedInfo> {
    const feedByFeedCode = new Map();
    for (const feed of this.feedList) {
      feedByFeedCode.set(feed.feed_code, feed);
    }

    return feedByFeedCode;
  }

  /*
  * search transit feed list by Id
  **/
  public getFeedWithFeedId(feedId: number): FeedInfo {
    return this.feedByFeedId.get(Number(feedId));
  }

  /*
  * search transit feed list by feedCode
  **/
  public getFeedWithFeedCode(feedCode: string): FeedInfo {
    return this.feedByFeedCode.get(feedCode);
  }

  public findAllByFeedCodeRegex(feedCodeRegex: RegExp): FeedInfo[] {
    return this.feedList.filter(feed => feedCodeRegex.test(feed.feed_code));
  }

  public getSearchResultFor(key: string = ''): any {
    const rawResult = this.cachedFeedSearch.get(key);
    return rawResult ? rawResult.data : [];
  }
}

export default new TransitStore();
