import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import './StopIdentifier.scss';

interface StopIdentifierProps {
  name: string;
  icon: string;
  backgroundColor: string;
  textColor: string;
  onClick?(): void;
  style?: any;
}

// tslint:disable-next-line:variable-name
const StopIdentifier = (props: StopIdentifierProps) => {
  const { t } = useTranslation('common');

  const divStyle = {
    backgroundColor: props.backgroundColor,
    color: props.textColor,
    ...props.style,
  };

  const imageClassName = cx('route-stop-image', {
    'invert-color': props.textColor === 'white' || props.textColor === '#FFFFFF',
  });

  return (
    <div style={divStyle} className="stop-identifier" onClick={props.onClick}>
      <img src={props.icon} className={imageClassName} alt={t('pathways.alt_route_image')}/>
      <span className="stop-name">{props.name}</span>
    </div>
  );
};

export default StopIdentifier;
