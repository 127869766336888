import api from '@stores/api';

const toolsUrl = `${process.env.UPDATE_STATIC_DATA_URL}/tools`;

export default {
 /**
  * GET complete list of tools grouped by respectiive step;
  */
  getJsonExtensionToolsListByStep: async (): Promise<any> => {
    const { status , data } = await api.http.authenticatedGet(toolsUrl);

    if (status === 200 && data) {
      return data;
    }
  },
};
