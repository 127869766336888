import React from 'react';
import cx from 'classnames';

import '@components/Form/CommonForm.scss';
import '@pages/banners/BannersPage.scss';

interface BannerPreviewProps {
  banner: any;
  index: number;
}

class BannerPreview extends React.Component<BannerPreviewProps> {

  render() {
    const { banner, index } = this.props;
    const { content } = this.props.banner;

    const divStyle = {
      backgroundColor: `#${banner.color}`,
      color: `#${banner.text_color}`,
    };

    const optionalClassName = cx({
      'hide-image': !content[index].image,
      'fill': divStyle.color,
    });

    const bannerTitle = content[index].title || 'Lorem ipsum dolor sit amet';
    const bannerBody = content[index].body || 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt';

    return (
      <div style={divStyle} className="in-app-banner-preview">
        <img
          src={`https://data-cdn.transitapp.com/images/svgx/${content[index].image}.svg`}
          alt=""
          className={`preview-icon ${optionalClassName}`}
        />
      <div style={divStyle} className="banner-content">
        <span className="banner-preview-title">{bannerTitle}</span>
        <span className="banner-preview-body">{bannerBody}</span>
      </div>
      </div>
    );
  }
}

export default BannerPreview;
