import { observable } from 'mobx';

export default class Tool {
  isDone: boolean; // temporary
  type: string;
  title: string;
  name: string;
  definition: string;
  template: object;
  private: boolean;
  index: number;
  hide: boolean;
  step: string;

  @observable show: boolean = false; // should it be displayed;
  @observable value: object;

  constructor(toolData, value?) {
    this.type = toolData.type;
    this.title = toolData.title;
    this.name = toolData.name;
    this.definition = toolData.definition;
    this.template = toolData.template || toolData.description;
    this.private = toolData.private;
    this.index = toolData.index;
    this.value = value;
    this.hide = toolData.hide;
  }
}
