import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { UserProfile } from '@server/users/user.types';
import OAuth from 'hellojs';
import { action, observable, runInAction } from 'mobx';

import api from '@stores/api';
import router from '@stores/router';
import { Credentials } from '@stores/api/auth';
import StorageManager from '@utils/StorageManager';
import { STORAGE_TYPES, ROLES } from '@constants';

const oauthConfig = {
  github: {
    key: process.env.GITHUB_CLIENT_ID, // github Oauth app public key;
    scope: 'read:user,user:email,read:org', // scopes separated by a comma;
  },
};

const localStorage = new StorageManager(STORAGE_TYPES.LOCAL, 'auth');

type OAUTH_PROVIDER = 'github';

export class AuthStore {
  @observable public isLoggedIn : boolean = undefined;
  @observable public user: UserProfile = localStorage.get('user');

  authConfig = Object.entries(oauthConfig)
  .reduce((acc, [provider, { key }]) => {
    return Object.assign(acc, { [provider]: key });
  }, {});

  public async initialize() {
    OAuth.init(this.authConfig, {
      oauth_proxy: '/api/auth/oauth',
      redirect_uri: `${window.location.origin}/login?loading`,
    });

    const firebaseConfig = process.env.FIREBASE_FRONTEND_SDK as any;
    firebase.initializeApp(firebaseConfig);

    const isSessionValid = await this.validateAndUpdateSession();
    const isUserRoleValid = this.user && this.user.auth
      && Object.values(ROLES).includes(this.user.auth.role);
    if (!isUserRoleValid) {
      this.logout();
    }

    return isSessionValid;
  }

  @action private setIsLoggedIn(isLoggedIn: boolean) { this.isLoggedIn = isLoggedIn; }

  private async validateAndUpdateSession() {
    const isSessionValid = await this.validateSession();
    this.setIsLoggedIn(isSessionValid);

    await this.getAndUpdateCurrentUser();
    return isSessionValid;
  }

  private async getOauthCredentials(provider: OAUTH_PROVIDER) {
    const scope = oauthConfig[provider].scope;
    const { authResponse: { access_token } } = await OAuth.login(provider, { scope });

    const oauthCredentials = {
      access_token,
      provider,
    };

    return oauthCredentials;
  }

  public async loginWith(provider: OAUTH_PROVIDER | 'local', loginCredentials?: Credentials) : Promise<{ error: any, isLoggedIn: boolean }> { // tslint:disable-line max-line-length
    try {
      const loginMethod = provider === 'local' ? 'local' : 'oauth';
      const credentials = loginMethod === 'oauth'
        ? await this.getOauthCredentials(provider as OAUTH_PROVIDER)
        : loginCredentials;

      const { data } = await api.auth.getJsonWebToken(loginMethod, credentials);
      await this.createSession(data.token);
      router.updateAuthorizedRoutes();
      return {
        error: null,
        isLoggedIn: this.isLoggedIn,
      };
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data
        : err.error_message
          ? err.error_message
          : JSON.stringify(err);

      return {
        error: errorMessage,
        isLoggedIn: false,
      };
    }
  }

  public async getAndUpdateCurrentUser() {
    if (!this.isLoggedIn) {
      this.updateUser(undefined);
      return;
    }

    const completeUser = await api.auth.getSelfUser();
    const permissions = await api.auth.getPermissions();

    runInAction(() => this.updateUser({
      ...completeUser,
      permissions,
    }));

    return completeUser;
  }

  updateUser(user) {
    localStorage.set('user', user);
    this.user = user;
  }

  /**
   * Will logout user, and all server calls won't be authenticated after that
   * if redirectToLogin is set to false, it will silently logout user
   * otherwise the user will be redirected to login page
   * @param redirectToLogin default to true
   */
  @action public async logout(forceRedirect: boolean = false): Promise<any> {
    this.user = null;
    this.isLoggedIn = false;
    localStorage.remove('user');

    await api.auth.logoutSession();

    const canStayOnRoute = router.canAccessCurrentLocation();
    if (forceRedirect || !canStayOnRoute) {
      router.redirect('/login');
    }
  }

  public canSeeResource(authorizedRoles: ROLES[] | ROLES) {
    const currentUserRole = this.getUserRole();

    const requiredRoles = Array.isArray(authorizedRoles)
      ? authorizedRoles
      : [authorizedRoles];

    const cleanedRoles = requiredRoles.filter(role => !!role);
    const canBeAccessedByEveryone = !cleanedRoles.length;
    if (canBeAccessedByEveryone) {
      return true;
    }

    const canBeAccessedByEveryAuthenticated = cleanedRoles.some(role => typeof role === 'boolean');
    if (this.isLoggedIn && canBeAccessedByEveryAuthenticated) {
      return true;
    }

    const isUserAuthorized = requiredRoles.some((authorizedRole) => {
      return currentUserRole === authorizedRole;
    });

    return isUserAuthorized;
  }

  // --------- //
  //   Utils   //
  // --------- //

  private getUserRole() {
    return this.user && this.user.auth
      ? this.user.auth.role
      : null;
  }

  private async createSession(jsonWebToken: string) {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

    const { user } = await firebase.auth().signInWithCustomToken(jsonWebToken);
    const firebaseIdToken = await user.getIdToken();
    await api.auth.createSession(firebaseIdToken); // Sets an HttpOnly cookie containing the session data

    await this.validateAndUpdateSession(); // make sure we have user data available in the browser

    await firebase.auth().signOut();
  }

  /**
   * Will only validate user
   * it will recursively look to validate the global user or user in the cookies
   * if the user is validated, it will resolve to the user associated otherwise it will reject
   */
  private async validateSession(): Promise<boolean> {
    try {
      const response = await api.auth.validateSession();

      if (response.status !== 200) {
        return false;
      }
    } catch (err) {
      return false;
    }

    return true;
  }
}

export default new AuthStore();
