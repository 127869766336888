import cx from 'classnames';

export function getCellClassesFromStatus(status: string, existingClasses: string) {
    if (status.startsWith('DOWN')) {
        return cx(existingClasses, 'is-red');
    } 
    if (status.startsWith('UP') || status.startsWith('OK')) {
        return cx(existingClasses,  'is-green');
    } 
    if (status.startsWith('UNSTABLE')) {
        return cx(existingClasses, 'is-orange');
    } 
    return cx(existingClasses);
}
