import { action } from 'mobx';
import { ReactElement } from 'react';

export interface ModalStoreInitialState {
  modals: ModalConfig[];
  lifeCycleConfig: ModalLifecycleConfig;
}

export interface ModalConfig {
  title: string;
  component: ReactElement<any> | React.FC;
}

export interface ModalLifecycleConfig {
  confirmOnClose?: any; // anything that can be rendered;
  onShow?(): void;
  onHide?(): void;
}

class ModalStore {

  static initialState = {
    modals: [],
    lifeCycleConfig: {},
  };

  public modalContainerRef: any = undefined;
  public modalConfigs = new Map();

  /**
   * once connectSetState is called, ModalStore can controle the component
   * @param modalContainerRef - "this" of ModalContainer
   */
  public connectRef(modalContainerRef): ModalStoreInitialState {
    this.modalContainerRef = modalContainerRef;
    return Object.assign({}, ModalStore.initialState);
  }

  /**
   * Show modal with according to the provided config
   * @param modalConfig - this of ModalContainer
   * @returns function used to hide modal
   */
  @action public showModal = (modalConfig: ModalConfig, lifeCycleConfig: ModalLifecycleConfig = {}) => {
    return this.showModals([modalConfig], lifeCycleConfig);
  }

  @action public showModals = (modalConfigs: ModalConfig[], lifeCycleConfig: ModalLifecycleConfig = {}) => {
    modalConfigs.forEach((modalConfig) => {
      if (!modalConfig.title || !modalConfig.component) {
        throw new Error('modal needs a title and a component to render');
      }
    });

    if (lifeCycleConfig.onShow) {
      lifeCycleConfig.onShow();
    }

    this.modalContainerRef.setState({
      lifeCycleConfig,
      modals: modalConfigs,
    });

    return this.hideModal.bind(this);
  }

  /**
   * hide current modal
   */
  @action public hideModal = (): void => {
    const currentLifeCycleConfig = this.modalContainerRef.state.lifeCycleConfig;
    if (currentLifeCycleConfig && currentLifeCycleConfig.onHide) {
      currentLifeCycleConfig.onHide();
    }

    this.modalContainerRef.setState(ModalStore.initialState);
  }
}

export default new ModalStore();
