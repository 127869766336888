import React from 'react';

import { DATA_TYPES } from '@constants';
import ArrayToolUI from '@components/ToolComponents/complexUIs/ArrayToolUI';
import BooleanToolUI from '@components/ToolComponents/simpleUIs/BooleanToolUI';
import RegexToolUI from '@components/ToolComponents/simpleUIs/RegexToolUI';
import DateToolUI from '@components/ToolComponents/simpleUIs/DateToolUI';
import DynamicObjectToolUI from '@components/ToolComponents/complexUIs/DynamicObjectToolUI';
import StaticObjectToolUI from '@components/ToolComponents/complexUIs/StaticObjectToolUI';
import StringToolUI from '@components/ToolComponents/simpleUIs/StringToolUI';
import NumberToolUI from '@components/ToolComponents/simpleUIs/NumberToolUI';
import UploadDownloadUI from '@application/common/components/ToolComponents/simpleUIs/UploadDownloadUI';
import { getTypeOfValue } from './getTypeOfValue';
import GenericToolUI from '@components/ToolComponents/simpleUIs/common/GenericToolUI';

export function getRenderFunctionAndTypeOfValue(template: any, value: any, props) {
  const typeOfValue = getTypeOfValue(template);
  const renderComponent = () => getRenderedComponentWithConfig(typeOfValue, template, value, props);
  return {
    typeOfValue,
    renderComponent,
  };
}

function getRenderedComponentWithConfig(typeOfValue: any, template: any, value: any, props) {
  switch (typeOfValue){
    case DATA_TYPES.FILE_URL:
      return (
        <UploadDownloadUI
          index={props.index}
          value={value}
          template={template}
          onChange={props.onChange}
        />
      );
    case DATA_TYPES.ARRAY:
      return (
        <ArrayToolUI
          index={props.index}
          value={value}
          placeholder={props.keyName}
          template={template}
          parentConfig={props}
          onChange={props.onChange}
        />
      );
    case DATA_TYPES.BOOLEAN:
      return (
        <BooleanToolUI
          index={props.index}
          ref={props.ref}
          value={value}
          onChange={props.onChange}
        />
      );
    case DATA_TYPES.DATE:
      return (
        <DateToolUI
          ref={props.ref}
          index={props.index}
          value={value}
          keyName={props.keyName}
          template={template}
          onChange={props.onChange}
        />
      );
    case DATA_TYPES.REGEXP:
      return (
        <RegexToolUI
          index={props.index}
          value={value}
          onChange={props.onChange}
        />
      );
    case DATA_TYPES.DYNAMIC_OBJECT:
      return (
        <DynamicObjectToolUI
          index={props.index}
          value={value}
          template={template}
          parentConfig={props}
          onChange={props.onChange}
        />
      );
    case DATA_TYPES.STATIC_OBJECT:
      return (
        <StaticObjectToolUI
          index={props.index}
          value={value}
          template={template}
          parentConfig={props}
          onChange={props.onChange}
        />
      );
    case DATA_TYPES.STRING:
      return (
        <StringToolUI
          index={props.index}
          value={value}
          keyName={props.keyName}
          ref={props.ref}
          template={template}
          onChange={props.onChange}
          onComplete={props.onComplete}
          onDelete={props.onDelete}
          onChangeFocus={props.onChangeFocus}
        />
      );
    case DATA_TYPES.NUMBER:
      return (
        <NumberToolUI
          index={props.index}
          value={value}
          keyName={props.keyName}
          ref={props.ref}
          template={template}
          onChange={props.onChange}
          onComplete={props.onComplete}
          onDelete={props.onDelete}
          onChangeFocus={props.onChangeFocus}
        />
      );
    default: return props.onUnavailableUI(); // render no UI
  }
}
