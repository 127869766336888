import React from 'react';
import { BarLoader } from 'react-spinners';

import store from '@stores';
import api from '@stores/api';
import { withStore } from '@stores/withStore';

import '../styles/OperationLog.scss';
import '../styles/FeedOperation.scss';

interface OperationLogProps {
  feedCode: string;
  operationId: string | number;
  store: typeof store;
}

interface OperationLogState {
  isLoading: boolean;
  log: string;
  socketLog: any;
}

class OperationLog extends React.Component<OperationLogProps, OperationLogState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      log: '',
      socketLog: undefined,
    };
  }

  async componentDidMount() {
    const { router, transit } = this.props.store;
    const { params } = router.getUrlData();

    const operationId = params.operation_id;
    const feedId = parseInt(params.feed_id, 10);
    const feed = transit.getFeedWithFeedId(feedId);
    const feedCode = feed.feed_code;

    const socket = await api.httpSocket.init({
      httpUrl: `${process.env.UPDATE_STATIC_DATA_URL}/gtfs/${feedCode}/${operationId}/log`,
      httpCallback: (response) => {
        this.setState({
          isLoading: false,
          log: response ? response.data : '',
        });
      },
      socketUrl: process.env.UPDATE_STATIC_DATA_URL,
      socketEmitEvent: 'watchOperation',
      socketEmitEventArgs: [feedCode, operationId],
      socketOnEvent: `operationLog/${feedCode}/${operationId}`,
      socketOnEventCallback: (line) => {
        this.setState({
          log: `${this.state.log}\n${line}`,
        });
      },
    });

    this.setState({
      socketLog: socket,
    });
  }

  async componentWillUnmount() {
    const { socketLog } = this.state;
    socketLog && socketLog.emit('unwatchOperation');
  }

  renderLog() {
    const { isLoading, log } = this.state;

    if (isLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          <BarLoader color="#30b566"/>
        </div>
      );
    }

    if (!log) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          Unable to get the log for this feed. Please rerun the feed.
        </div>
      );
    }

    return log;
  }

  render() {
    const { router } = this.props.store;
    const { params } = router.getUrlData();

    const operationId = params.operation_id;

    return (
      <div className="operation-container">
        <div className="operation-log-wrapper">
          {/*<div className="operation-log">*/}
            <div className="operation-title">Operation Id: {operationId}</div>
            <div className="operation-log">
              {this.renderLog()}</div>
            </div>
        {/*</div>*/}
      </div>
    );
  }
}

export default withStore<OperationLogProps>(OperationLog);
