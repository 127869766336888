import React from 'react';

interface MuninPageState {}

interface MuninPageProps {}

class MuninPage extends React.Component<MuninPageProps, MuninPageState> {
  renderIFrame() {
    return (
      <iframe
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        src={process.env.MUNIN_URL}
      />
    );
  }

  render() {
    return (
      <div style={{ width: '100%', height: '95vh' }}>
        {this.renderIFrame()}
      </div>
    );
  }
}

export default MuninPage;
