import React from 'react';
import * as Yup from 'yup';

import { withStore } from '@stores/withStore';
import store from '@stores';
import { Formik, Form, FormikProps } from 'formik';

import * as i18n from 'react-i18next';
import { FloatingLabelInput } from '@components/Input/FloatingLabelInput';
import { FormSubmitButton } from '@components/Input/FormSubmitButton';
import { FullScreenForm } from '@components/Form/FullScreenForm';

interface ForgotPassordPageProps {
  store: typeof store;
}

interface ResetPasswordFormData {
  email: string;
}

const RequestPasswordResetPage = withStore((props: ForgotPassordPageProps) => {
  const { api } = props.store;
  const { t } = i18n.useTranslation('common');
  const [isEmailSent, setIsEmailSent] = React.useState(false);

  const formTitle = isEmailSent
    ? t('login.send_password_reset_email_success_title').toUpperCase()
    : t('login.reset_your_password').toUpperCase();

  const renderForm = (onSubmit, initialData: ResetPasswordFormData) => isEmailSent ? (
    <React.Fragment>
      <p className="text-rainygrey text-sm">
        {t('login.send_password_reset_email_success_instructions')}
      </p>
      <FormSubmitButton
        value={t('login.send_password_reset_email_again')}
        onClick={() => onSubmit(initialData)}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <p className="mb-8 text-rainygrey text-sm">
        {t('login.send_password_reset_email_instructions')}
      </p>
      <RequestPasswordResetForm onSubmit={onSubmit} />
    </React.Fragment>
  );

  return (
    <FullScreenForm
      title={formTitle}
      renderForm={renderForm}
      request={async (data: ResetPasswordFormData) => {
        try {
          const isResetPasswordLinkSent = await api.auth.requestResetPasswordLink(data.email);
          setIsEmailSent(isResetPasswordLinkSent);
          if (isResetPasswordLinkSent) {
            return {};
          }
        } catch (err) {}

        return {
          error: t('login.error_generic'),
        };
      }}
      renderFooter={() => (
        <a href={'/signin'}>
          <span className="text-sm text-silvergrey font-bold cursor-pointer">
            {t('login.already_have_account')} <a className="underline">{t('login.sign_in')}</a>
          </span>
        </a>
      )}
    />
  );
});

interface RequestPasswordResetFormProps {
  onSubmit(data: ResetPasswordFormData) : void;
}

const RequestPasswordResetForm : React.FC<RequestPasswordResetFormProps> = (props) => {
  const { t } = i18n.useTranslation('common');

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={(values: ResetPasswordFormData) => {
        props.onSubmit(values);
      }}
      validationSchema={Yup.object({
        email: Yup.string().email().required(t('login.email_required')),
      })}
    >
      {(formik: FormikProps<ResetPasswordFormData>) => {
        const errors = Object.keys(formik.errors)
          .filter(fieldWithError => formik.touched[fieldWithError])
          .map(fieldWithErrorAndTouched => formik.errors[fieldWithErrorAndTouched]);

        return (
          <Form
            className="w-full text-left"
            autoComplete="off"
          >
            {
              errors.length === 0 ? null : (
                <div className="bg-red bg-opacity-10 rounded-md text-red text-center text-xs font-bold p-6 mb-8">
                  {errors.map(error => (
                    <div>{error}</div>
                  ))}
                </div>
              )
            }
            <FloatingLabelInput
              name="email"
              type="text"
              autocomplete={true}
              placeholder={t('login.email_hint')}
              value={formik.values.email}
              onBlur={() => formik.setFieldTouched('email')}
              onFocus={() => formik.setFieldTouched('email', false)}
              onChange={(value) => {
                formik.setFieldValue('email', value);
              }}
            />
            <FormSubmitButton
              value={t('login.send_password_reset_email')}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default RequestPasswordResetPage;
