import React from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import './Dropdown.scss';

interface DropDownRow {
  isDisabled: boolean;
  text: string;
}

interface DropDownProps {
  onChange(index: number): any;
  selectedIndex: number;
  items: DropDownRow[];
}

interface DropDownState {
  isDropdownVisible: boolean;
}

export default class DropDown extends React.Component<DropDownProps, DropDownState> {

  state = {
    isDropdownVisible: false,
  };

  toggleDropdownVisibility = () => {
    const { isDropdownVisible } = this.state;

    this.setState({
      isDropdownVisible: !isDropdownVisible,
    });
  }

  hideDropdown = () => {
    this.setState({
      isDropdownVisible: false,
    });
  }

  handleClickOnDropDownRow = (index) => {
    this.props.onChange(Number(index));
  }

  renderDropDownRow = (rowData: DropDownRow, index) => {
    const rowClassName = cx('dropdown-content-row', {
      'disabled': rowData.isDisabled,
    });

    return (
      <li
        key={JSON.stringify(rowData)}
        className={rowClassName}
        onClick={() => this.handleClickOnDropDownRow(index)} // tslint:disable-line jsx-no-lambda
      >
       {this.getTextOfRow(index)}
      </li>
    );
  }

  getTextOfRow = (index) => {
    const { items } = this.props;
    return items[index].text;
  }

  render() {
    const { items, selectedIndex } = this.props;
    const { isDropdownVisible } = this.state;

    const noChoiceAvailable = !items.length;
    if (noChoiceAvailable) {
      return (
        <div className="dropdown-wrapper disabled">
          <div className="dropdown-select">( empty )</div>
        </div>
      );
    }

    return (
      <div className="dropdown-wrapper" onClick={this.toggleDropdownVisibility} onMouseLeave={this.hideDropdown}>
        <div className="dropdown-select">{this.getTextOfRow(selectedIndex)}</div>

        <CSSTransition in={isDropdownVisible} timeout={200} classNames="transition">
          <div className="dropdown-content">
            <ul>
              {items.map(this.renderDropDownRow)}
            </ul>
          </div>
        </CSSTransition>
      </div>
    );
  }
}
