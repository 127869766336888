import { isPlainObject } from 'lodash';
import { DATA_TYPES, GTFS_KEYS } from '@constants';

import { getRegexFromStringWithFlags } from './getRegexFromStringsWithFlags';

 /*
  * Get custom type of tool template.
  * @param {object} value - Object holding general contents of tool
  * @return {string | undefined} - Custom type for array
  */
export function getTypeOfValue(value: any) : DATA_TYPES {
  const isBoolean = typeof value === DATA_TYPES.BOOLEAN;
  if (isBoolean) {
    return DATA_TYPES.BOOLEAN;
  }

  const isRegexp = isRegex(value); // need to test before STRING because we accept regexes as string formats
  if (isRegexp) {
    return DATA_TYPES.REGEXP;
  }

  const isANumber = typeof value === DATA_TYPES.NUMBER;
  if (isANumber) {
    return DATA_TYPES.NUMBER;
  }

  const isString = typeof value === DATA_TYPES.STRING;
  const isFileUpload = isString && value.startsWith('file:');
  if (isFileUpload) {
    return DATA_TYPES.FILE_URL;
  }

  const isDate = ['YYYYMMDD', 'YYYY-MM-DD'].includes(value);
  if (isDate) {
    return DATA_TYPES.DATE;
  }

  if (isString) {
    return DATA_TYPES.STRING;
  }

  const isArray = Array.isArray(value);
  if (isArray) {
    for (const element of value) {
      const typeOfElement = getTypeOfValue(element);
      if (!typeOfElement) {
        return undefined;
      }
    }

    return DATA_TYPES.ARRAY;
  }

  const isObject = isPlainObject(value);
  if (isObject) {
    for (const element of Object.values(value)) {
      const typeOfElement = getTypeOfValue(element);
      if (!typeOfElement) {
        return undefined;
      }
    }

    const objectType = getTypeOfObject(value);
    return objectType;
  }
}

export function getDeepTypeOfValue(template: any) : DATA_TYPES {
  const typeOfValue = getTypeOfValue(template);
  const isArray = typeOfValue === DATA_TYPES.ARRAY;
  if (isArray) {
    const childTypeOfValue = getTypeOfValue(template[0]);

    const isArrayOfStrings = childTypeOfValue === DATA_TYPES.STRING;
    if (isArrayOfStrings) {
      return DATA_TYPES.ARRAY_OF_STRINGS;
    }

    const isArrayOfStatic = childTypeOfValue === DATA_TYPES.STATIC_OBJECT;
    if (isArrayOfStatic) {
      return DATA_TYPES.ARRAY_OF_STATIC_OBJECT;
    }
  }

  const isDynamic = typeOfValue === DATA_TYPES.DYNAMIC_OBJECT;
  if (isDynamic) {
    const toolTemplate = Object.values(template)[0];
    const childTypeOfValue = getTypeOfValue(toolTemplate);
    const isDynamicOfString = childTypeOfValue === DATA_TYPES.STRING;
    if (isDynamicOfString) {
      return DATA_TYPES.DYNAMIC_OBJECT_OF_STRINGS;
    }
  }

  return undefined;
}

/*
 * Get custom type of object from a tool template.
 * @param {object} object - Object holding general contents of tool
 * @return {string | undefined} - Custom type for object
 */
function getTypeOfObject(object: object) {
  const objectKeys = Object.keys(object);

  if (isStaticObject(objectKeys)) {
    return DATA_TYPES.STATIC_OBJECT;
  }

  return DATA_TYPES.DYNAMIC_OBJECT;
}

/*
 * Checks if object is of dynamic size or of static size
 * @param {array} objectKeys - Keys of an object
 * @return {boolean} - Flag stating if object is of static size or not
 */
function isStaticObject(objectKeys: any[]) {
  for (const key of objectKeys) {
    const isEditableKey = GTFS_KEYS.includes(key) || key.startsWith('/') && key.endsWith('/');
    if (isEditableKey) {
      return false;
    }
  }

  return true;
}

/*
 * Checks if object entries has dynamic size and only containing primitive data types
 * @param {array} objectEntries - Entries of an object
 * @return {boolean} - Flag stating if object is of static dynamic or not
 */

function isRegex(regexAsString: string) {
  const isRegexValid = getRegexFromStringWithFlags(regexAsString);

  return !!isRegexValid;
}
