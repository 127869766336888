import socketIO from 'socket.io-client';

export default {
  init,
};

function init(url) {
  const socket = socketIO(url, { transports: ['websocket'] });
  return socket;
}
