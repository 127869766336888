import { getTypeOfValue } from './getTypeOfValue';
import { DATA_TYPES } from '../../../constants';

export function getFallbackValueOfTemplate(template: any) {
  const typeOfValue = getTypeOfValue(template);

  switch (typeOfValue){
    case DATA_TYPES.ARRAY:
      return [];
    case DATA_TYPES.BOOLEAN:
      return false;
    case DATA_TYPES.REGEXP:
      return '//';
    case DATA_TYPES.NUMBER:
      return 0;
    case DATA_TYPES.DATE:
      return '';
    case DATA_TYPES.STRING:
      return '';
    case DATA_TYPES.STATIC_OBJECT:
      return {};
    case DATA_TYPES.DYNAMIC_OBJECT:
      return {};
    default: return undefined;
  }
}
