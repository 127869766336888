import transit from '@stores/transit';
import { ENVIRONMENTS } from '@constants';
import { action, observable } from 'mobx';
import JsonExtensionBuilderStore from './jsonExtensionPage/JsonExtensionBuilderStore';
import { cloneDeep } from 'lodash';

class FeedPageStore {
  @observable public currentFeed = undefined;
  @observable public currentEnvironment: ENVIRONMENTS = undefined;
  @observable public jsonExtension = JsonExtensionBuilderStore; // subpage store

  /*
   * Initializes store with feed and environment.
   * All async operations should be done in this function so as to be called
   * in the componentDidMount function of the render.
   */
  @action public initFeedPageStore = async (
    id: number = 1,
    environment: ENVIRONMENTS = process.env.NODE_ENV === ENVIRONMENTS.PRODUCTION
      ? ENVIRONMENTS.PRODUCTION
      : ENVIRONMENTS.STAGING,
  ): Promise<any> => {
    await transit.getFeedList();

    this.setCurrentFeed(id);
    this.setCurrentEnvironment(environment);
  }

  /*
   * Reset current transit feed
   */
  @action public resetCurrentFeed = () => {
    this.currentFeed = undefined;
  }

  /**
   * Sets current transit feed object
   * @param id - feed id, defaults to 1
   */
  @action public setCurrentFeed = (id: number) => {
    const feedId = id ? id : 1;
    const agencyById = transit.getFeedWithFeedId(feedId);
    this.currentFeed = cloneDeep(agencyById || transit.feedList[0]); // removes reference
  }

  /**
   * Gets the current Feed in the webpage
   * @return {feed} - Feed Object containing information about the feed
   */
  public getCurrentFeed = (): any => {
    return this.currentFeed;
  }

  /**
   * Gets the current Feed Id in the webpage
   * @return {number} Feed Id
   */
  public getCurrentFeedId = (): any => {
    const currentFeed = this.currentFeed;
    return currentFeed && currentFeed.feed_id;
  }

  /**
   * Set current environment
   * @param {string} environment - defaults to production
   */
  @action public setCurrentEnvironment = (environment: ENVIRONMENTS = ENVIRONMENTS.PRODUCTION) => {
    this.currentEnvironment = environment;
  }

  /**
   * Get current environment
   * @return {string} environment
   */
  public getCurrentEnvironment = (): ENVIRONMENTS => {
    return this.currentEnvironment;
  }
}

export default new FeedPageStore();
