import cx from 'classnames';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import moment from 'moment';
import React from 'react';
import { BarLoader } from 'react-spinners';
import { Column, Table } from 'react-virtualized';

import store from '@stores';
import { withStore } from '@stores/withStore';

import Button from '@components/Button/Button';
import LoaderButton from '@components/LoaderButton/LoaderButton';

import '../styles/FeedOperation.scss';
import '../../FeedsStatus/components/FeedsStatus.scss';
import Tooltip from '@components/Tooltip/Tooltip';
import router from '@stores/router';
import operationAndNotices from '@stores/operationAndNotices';
import sharedFunctions from '../lib/sharedFunctions';

interface NoticeUIProps {
  store?: typeof store;
}

interface NoticeUIState {}

@observer class NoticeUI extends React.Component<NoticeUIProps, NoticeUIState> {

  handleResolveNotice = async () => {
    const { store } = this.props;
    const { operationInFocus, noticeInFocus, updateNotice } = store.operationAndNotices;

    const notice = toJS(noticeInFocus);
    notice.last_handled_by = store.auth.user.displayName;
    notice.status = 'Resolved';

    updateNotice(operationInFocus.operation_id, notice);
    return store.api.feeds.updateNotice(notice);
  }

  handleGapsInFeedNotice = async () => {
    const { api, transit } = store;
    const { noticeInFocus } = store.operationAndNotices;

    const sources = transit.getSourcesOfFeed(noticeInFocus.feed_id);
    const sourceHashes = sources.map(source => source.source_hash);

    const matchingSourceHash = sourceHashes.find((sourceHash) => {
      const hashRegExp = new RegExp(sourceHash);
      return noticeInFocus.content.match(hashRegExp);
    });

    const indexOfSource = sources.findIndex((source) => {
      return source.source_hash === matchingSourceHash;
    });

    const source = indexOfSource >= 0 && toJS(sources[indexOfSource]);

    if (!source) {
      return false;
    }

    source.gaps_verified = transit.getCodeHashOfFeedId(noticeInFocus.feed_id);
    transit.setSourceAtIndexOfFeed(noticeInFocus.feed_id, source, indexOfSource);

    await api.archives.updateSource(source);

    // after gaps are verified, rerun failed steps
    sharedFunctions.handleRerunOperation(noticeInFocus.operation_id, true);
    return this.handleResolveNotice();
  }

  async handleCopyUrl() {
    if (operationAndNotices.noticeInFocus && operationAndNotices.noticeInFocus.id) {
      const route = router.getCurrentRouteConfig();

      if (!operationAndNotices.isOperationsUiMounted) {
        return;
      }

      let path = `operations/${operationAndNotices.operationInFocus.operation_id}`;

      if (operationAndNotices.noticeInFocus) {
        path += `/notices/${operationAndNotices.noticeInFocus.id}`;
      }

      path = (/global/).test(route.basePath)
        ? path
        : `${operationAndNotices.operationInFocus.feed_id}/${path}`;

      navigator.clipboard.writeText(`${window.location.origin}${route.basePath}/${path}`);
      return true;
    }
  }

  renderNoticeUrlButton() {
    return (
      <div className="resolve-container">
        <LoaderButton
          className="operation-container-button"
          copied={true}
          value={'Copy Notice Url'}
          onClickWithStatus={this.handleCopyUrl}
          key={'url'}
        />
      </div>
    );
  }

  renderResolveNoticeButton() {
    const { noticeInFocus } = this.props.store.operationAndNotices;

    const value = noticeInFocus.status === 'Resolved' ? 'Resolved' : 'Resolve';
    const shouldDisable = (value === 'Resolved');

    return (
      <div className="resolve-container">
      <LoaderButton
        className="operation-container-button"
        imageLeft={<i className="fa fa-check-circle" aria-hidden="true" />}
        value={value}
        onClickWithStatus={this.handleResolveNotice}
        key={value}
        disable={shouldDisable}
      />
        <Tooltip
          message={'You can also press R to resolve notice'}
          placement="top-end"
          /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
          render={() => (
            <div className="question-circle">
              <i className="question-mark fa fa-question-circle"/>
            </div>
          )}
        />
      </div>
    );
  }

  renderGapsInFeedNoticeButton() {
    const { store } = this.props;
    const { noticeInFocus } = store.operationAndNotices;

    const sources = store.transit.getSourcesOfFeed(noticeInFocus.feed_id);
    const sourceHashes = sources.map(source => source.source_hash);

    const matchingSourceHash = sourceHashes.find((sourceHash) => {
      const hashRegExp = new RegExp(sourceHash);
      return noticeInFocus.content.match(hashRegExp);
    });

    const sourceOfNotice = sources.find((source) => {
      return source.source_hash === matchingSourceHash;
    });

    const codeHash = store.transit.getCodeHashOfFeedId(noticeInFocus.feed_id);
    if (sourceOfNotice && codeHash !== undefined && sourceOfNotice.gaps_verified === codeHash) {
      return (
        <div className="operation-container-header-group">
          <Button
            className="operation-container-button pink"
            value="Gaps Verified!"
            onClick={() => {}} // tslint:disable-line jsx-no-lambda
            key="Gaps Verified"
          />
          {this.renderResolveNoticeButton()}
        </div>
      );
    }

    if (sourceOfNotice && codeHash !== undefined && sourceOfNotice.gaps_verified !== codeHash) {
      return (
        <div className="operation-container-header-group">
          <LoaderButton
            className="operation-container-button pink"
            value="Mark As Gaps Verified"
            status="Gaps Verified!"
            onClickWithStatus={this.handleGapsInFeedNotice}
            key="Gaps Verified"
          />
          {this.renderResolveNoticeButton()}
        </div>
      );
    }

    return this.renderResolveNoticeButton();
  }

  renderNoticeHeader() {
    const { noticeInFocus, noticeInFocusIsGapsInFeed } = this.props.store.operationAndNotices;
    if (!noticeInFocus) {
      return;
    }

    const noticeTitle = (
      <div>
        <span className={`${noticeInFocus.severity.toLowerCase()}`}>
          {`${noticeInFocus.step_code}: `}
        </span>
        {noticeInFocus.title}
      </div>
    );

    if (noticeInFocusIsGapsInFeed) {
      return (
        <div className="operation-container-header-group">
          <label>Notice</label>
          {noticeTitle}
          {this.renderGapsInFeedNoticeButton()}
        </div>
      );
    }

    return (
      <div className="operation-container-header-group">
        <label>Notice</label>
        {noticeTitle}
        <div className="operation-container-header-group">
          {this.renderNoticeUrlButton()}
          {this.renderResolveNoticeButton()}
        </div>
      </div>
    );
  }

  rowGetter = ({ index }: { index: number }) => {
    const { operationInFocus } = this.props.store.operationAndNotices;
    return operationInFocus.notices[index];
  }

  rowClassName = ({ index }: { index: number }) => {
    const { isOperationsContainerInFocus, noticeInFocus, operationInFocus } = this.props.store.operationAndNotices;
    const noticeAtIndex = operationInFocus.notices[index];

    if (!noticeInFocus || !noticeAtIndex) {
      return;
    }

    const inFocus = cx({
      focus: (noticeInFocus && noticeAtIndex.id === noticeInFocus.id),
      selected: (noticeInFocus && noticeAtIndex.id === noticeInFocus.id) && isOperationsContainerInFocus,
    });

    return `operation-row ${inFocus} ${cx({
      [noticeAtIndex.severity.toLowerCase()]: noticeAtIndex.severity,
    })}`;
  }

  renderNoNotice(children) {
    return (
      <div className="operation-container">
        <div className="operation-container-header-group">
          <label>Notice</label>
        </div>
        {children}
      </div>
    );
  }

  renderColumn(key) {
    const { noticeInFocus } = this.props.store.operationAndNotices;

    if (!noticeInFocus[key]) {
      return;
    }

    const colWidthToPx = colWidth => colWidth * 10 + 20;
    return (
      <Column
        key={key}
        dataKey={key}
        minWidth={colWidthToPx(noticeInFocus[key].length)}
        width={colWidthToPx(noticeInFocus[key].length)}
      />
    );
  }
  renderColumns() {
    const noticeKeysToRender = ['step_code', 'severity', 'title'];
    return noticeKeysToRender.map(key => this.renderColumn(key));
  }

  renderTable() {
    const { changeNoticeInFocus, operationInFocus } = this.props.store.operationAndNotices;

    if (operationInFocus.notices.length === 0) {
      return null;
    }

    const operationsRowHeight = 45;
    const operationsHeight = operationInFocus.notices.length * operationsRowHeight;

    const width = ['STEP', 'SEVERITY', 'CONTENT'].reduce((accumulator, label) => {
      return accumulator + label.length;
    }, 0);

    return (
      <Table
        disableHeader={true}
        height={operationsHeight}
        headerHeight={operationsRowHeight}
        onRowClick={({ index }) => changeNoticeInFocus(index)} // tslint:disable-line jsx-no-lambda
        rowClassName={this.rowClassName}
        rowCount={operationInFocus.notices.length}
        rowGetter={this.rowGetter}
        rowHeight={operationsRowHeight}
        rowStyle={{ overflow: 'scroll' }}
        width={width}
      >
        {this.renderColumns()}
      </Table>
    );
  }

  renderColoredDate = (timestamp) => {
    const today = moment();
    const date = moment(timestamp);
    const isPast = date.isBefore(moment());
    const inOneWeek = moment().add(1, 'week').startOf('day');
    const isWithinWeek = date.isAfter(today) && date.isBefore(inOneWeek);
    const dateClassName = cx('colored-cell', {
      'is-red': isPast,
      'is-yellow': isWithinWeek,
    });

    // ex: Jan 13, 2019
    const humanReadableDate = date.format('MMM D, YYYY');

    return (
      <span className={dateClassName}>{humanReadableDate}</span>
    );
  }

  renderFeedMetadata() {
    const { feedOperationStatusByFeedId, operationInFocus } = this.props.store.operationAndNotices;

    const { message, status } = feedOperationStatusByFeedId.get(operationInFocus.feed_id);
    return (
      <div
        className="operation-container solid-border"
        key="feed-metadata"
      >
        <div className="d-flex justify-content-between" style={{ minWidth: 'fit-content', width: '100%' }}>
          <div style={{ marginRight: '5px' }}>
            Feed Status:
          </div>
          <div className={`${status} reverse`}>
            {message}
          </div>
        </div>
        <div className="d-flex justify-content-between" style={{ minWidth: 'fit-content', width: '100%' }}>
          <div style={{ marginRight: '5px' }}>
            Raw Expiry:
          </div>
          {this.renderColoredDate(operationInFocus.feed.raw_data_end_at)}
        </div>
        <div className="d-flex justify-content-between" style={{ minWidth: 'fit-content', width: '100%' }}>
          <div style={{ marginRight: '5px' }}>
            Processed Expiry:
          </div>
          {this.renderColoredDate(operationInFocus.feed.processed_data_end_at)}
        </div>
        <div className="d-flex justify-content-between" style={{ minWidth: 'fit-content', width: '100%' }}>
          <div style={{ marginRight: '5px' }}>
            Bgtfs Uploaded:
          </div>
          {moment(operationInFocus.feed.bgtfs_uploaded_at).format('MMM D, YYYY')}
        </div>
      </div>
    );
  }

  renderLoadInfoNotices() {
    const {
      operationInFocus,
      updateNoticesOfOperationId,
    } = this.props.store.operationAndNotices;

    if (operationInFocus.loadedAllNotices) {
      return null;
    }

    const updateOperationNotices = async (): Promise<boolean> => {
      try {
        await updateNoticesOfOperationId(operationInFocus);
        return true;
      } catch (error) {
        console.log(error); // tslint:disable-line:no-console
        return false;
      }
    };

    return (
      <LoaderButton
        className="operation-container-button marginRight0"
        value="... Load All Notices"
        status=""
        onClickWithStatus={updateOperationNotices}
        key="load_info_notices"
        style={{ marginTop: '10px' }}
      />
    );
  }

  renderNoticeContent() {
    const { noticeInFocus } = this.props.store.operationAndNotices;

    return (
      <div
        className="operation-container-text notice"
        dangerouslySetInnerHTML={{ __html: noticeInFocus && noticeInFocus.content || '' }}
      />
    );
  }

  render() {
    const { isLoadingNotices, noticeInFocus, operationInFocus } = this.props.store.operationAndNotices;

    if (isLoadingNotices) {
      const loadingBar = (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          <BarLoader color="#30b566"/>
        </div>
      );
      return this.renderNoNotice(loadingBar);
    }

    if (!operationInFocus || (operationInFocus.loadedAllNotices && (!noticeInFocus || !noticeInFocus.content))) {
      return this.renderNoNotice((
        <div className="operation-container-text">
          No notices to show
        </div>
      ));
    }

    return (
      <div
        className="operation-container"
        key={operationInFocus.operation_id}
        tabIndex={-1}
      >
        {this.renderNoticeHeader()}
        <div className="d-flex">
          <div className="col" style={{ minWidth: 'fit-content' }}>
            {this.renderFeedMetadata()}
            {this.renderTable()}
            {this.renderLoadInfoNotices()}
          </div>
          {this.renderNoticeContent()}
        </div>
      </div>
    );
  }
}

export default withStore<NoticeUIProps>(NoticeUI);
