import api from '@stores/api';
import { observable } from 'mobx';
import moment from 'moment';

export interface BannerFormContent {
  body: string;
  image: string;
  locale: string;
  title: string;
  url: string;
  tracking?: boolean;
}

export interface BannerFormInterface {
  app_filter: string;
  city: string;
  color: string;
  content: BannerFormContent[];
  created_at: string;
  end_at: string;
  id: number;
  in_beta: number;
  is_archived: number;
  latitude: number;
  location?: string;
  longitude: number;
  max_app_version?: null;
  min_app_version?: null;
  priority: number;
  radius: number;
  start_at: string;
  text_color: string;
  url: string;
  uuids: number;
  uuid_filter: string; // this is the amplitude cohort id
}

class BannersStore {
  @observable public banners = [];

  public initBannerPageStore = async (): Promise<any> => {
    const banners = await api.banners.getBanners();

    const byEndDateAscending = (left, right) : number => moment(left.end_at).unix() - moment(right.end_at).unix();
    this.banners = banners.sort(byEndDateAscending);
  }

  public getBanners(): any[] {
    return this.banners;
  }

  public async getBanner(bannerId): Promise<BannerFormInterface> {
    return api.banners.getBanner(bannerId);
  }

  public async addBanner(banner): Promise<boolean> {
    try {
      const bannerId: any = await api.banners.addBanner(banner);
      if (bannerId) {
        const newBanner = {
          id: bannerId,
          title: banner.content[0].title,
          city:  banner.city,
          started_at:  banner.started_at,
          end_at:  banner.end_at,
          in_beta:  banner.in_beta,
        };

        this.banners.push(newBanner);
      }

      return true;
    } catch (error) {
      console.log(error); // tslint:disable-line no-console
      return false;
    }
  }

  public async updateBanner(banner): Promise<boolean> {
    const bannerId = banner.id;
    try {
      const isBannerUpdated: any = await api.banners.uploadBannerInfo(banner);
      if (isBannerUpdated) {
        const bannerIndex = this.banners.findIndex(banner => banner.id === bannerId);
        this.banners[bannerIndex].title = banner.content[0].title;
        this.banners[bannerIndex].city = banner.city;
        this.banners[bannerIndex].started_at = banner.started_at;
        this.banners[bannerIndex].end_at = banner.end_at;
        this.banners[bannerIndex].in_beta = banner.in_beta;
      }

      return isBannerUpdated;
    } catch (error) {
      console.log(error); // tslint:disable-line no-console
      return false;
    }
  }

  public async archiveBanner(banner): Promise<boolean> {
    const bannerId = banner.id;
    try {
      const isBannerArchived: any = await api.banners.archiveBanner(banner);
      if (isBannerArchived) {
        const bannerIndex = this.banners.findIndex(banner => banner.id === bannerId);
        this.banners[bannerIndex].is_archived = this.banners[bannerIndex].is_archived ? 0 : 1;
      }

      return isBannerArchived;
    } catch (error) {
      console.log(error); // tslint:disable-line no-console
      return false;
    }
  }
}

export default new BannersStore();
