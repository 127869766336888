import api from '@stores/api';
import { EmailConfig } from '@server/auth/invitations/invitation.types';

const HOST_API_URL = `${window.location.origin}/api/emails`;

export default {
  send: async (emailConfig: EmailConfig): Promise<boolean> => {
    const url = `${HOST_API_URL}/send`;
    const response = await api.http.authenticatedPost(url, emailConfig);

    return !!response;
  },
};
