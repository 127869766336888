import axios from 'axios';

const APP_SLUG = 'T024H9PDB/B018PLV7FD4/mnhikOBJLlGLLa4q1bmDNdnY';
const SLACK_API_URL = `https://hooks.slack.com/services/${APP_SLUG}`;

export async function sendFeedSlackMessage(slackPayload) {
  try {
    await axios.post(SLACK_API_URL, JSON.stringify(slackPayload));
  } catch (error) {
    console.log(`Unable to send error message to slack: ${slackPayload.attachments[0].text}`); // tslint:disable-line max-line-length no-console
  }
}
