import { ROLES, ROLE_DESCRIPTIONS } from '@constants';
import router from '@stores/router';
import transit from '@stores/transit';

export interface IndexRow {
  value: any;
  description?: string;
}

interface StaticIndex {
  [key: string]: IndexRow[];
}

export const staticIndex: StaticIndex = {
  route_type: [
    { value: '0', description: 'Tram, Streetcar, Light rail. Any street level system within a metropolitan area.' },
    { value: '1', description: 'Subway, Metro. An underground rail system within a metropolitan area.' },
    { value: '2', description: 'Rail. Used for intercity or long distance travel.' },
    { value: '3', description: 'Bus. Used for bus routes.' },
    { value: '4', description: 'Ferry. Used for boat service' },
    { value: '5', description: 'Cable car. Used for street - The cable runs beneath the car.' },
    { value: '6', description: 'Gondola, Suspended cable car. Car is suspended from the cable.' },
    { value: '7', description: 'Funicular. Any rail system designed for steep inclines.' },
  ],

  languageCode: [
    { value: 'en', description: 'English' },
    { value: 'fr', description: 'French' },
  ],

  role: Object.entries(ROLES).map(([roleKey, roleValue]) => {
    return { value: roleValue, description: ROLE_DESCRIPTIONS[roleKey] };
  }),

  get router() {
    return router.routes.reduce((routeAccumulator, route) => {
      const basePath = route.path;
      routeAccumulator.push({
        value: basePath,
        description: basePath,
      });

      if (route.routes) {
        route.routes.forEach((subRoute) => {
          if (subRoute.path) {
            routeAccumulator.push(({
              value: subRoute.path,
              description: subRoute.path,
            }));
          }

          if (subRoute.paths) {
            subRoute.paths.forEach((subRoutePath) => {
              routeAccumulator.push(({
                value: subRoutePath,
                description: subRoutePath,
              }));
            });
          }
        });
      }

      return routeAccumulator;
    }, []);
  },

  get feed_code() {
    return transit.feedList.map((feed) => {
      return { value: feed.feed_code, description: `feed_id: ${feed.feed_id} (${feed.feed_location})` };
    });
  },
};
