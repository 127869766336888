import { OPERATION_KEY_WORDS } from '@constants';
import filterConfig from '@stores/operationAndNotices/lib/filterConfig';
import transit from '@stores/transit';

export {
  filterOperationByOperationId as operationByOperationId,
  hasNoticeFilter,
  hasOperationFilter,
  toMap,
};

/*
   * Filter out operations that don't have a relationship with a feed from the transit feed list.
   * This can happen if a feed is moved from an active in_beta status to archived.
   * Returns a Map<operationId, operation>
   */
function toMap(operationByOperationIdEntries) {
  const operationByOperationId = new Map();
  for (const [operationId, operation] of operationByOperationIdEntries) {
    const feedId = operation.feed_id;
    const feed = transit.getFeedWithFeedId(feedId);

    if (feed) {
      operation.feed = feed;
      operationByOperationId.set(operationId, operation);
    }
    // Initialize notices array. Dispatcher does not serve notices from the
    // operations api but downstream code expects this array to be initialized.
    operation.notices ??= [];
  }

  return operationByOperationId;
}

function filterOperationByOperationId(operationByOperationId, filtersObject) {
  if (!hasNoticeFilter(filtersObject) && !hasOperationFilter(filtersObject)
    || !operationByOperationId.size) {
    return operationByOperationId;
  }

  const filteredOperationByOperationId = new Map();
  for (const [operationId, operation] of operationByOperationId) {
    const filteredOperation = filterOperation(operation, filtersObject);

    if (filteredOperation) {
      filteredOperationByOperationId.set(operationId, filteredOperation);
    }
  }

  return filteredOperationByOperationId;
}

function filterOperation(operation, filtersObject) {
  const operationFiltersObject = filtersObject[OPERATION_KEY_WORDS.OPERATION];

  for (const [filterFunction, filterWithValues] of Object.entries(operationFiltersObject.default)) {
    const shouldKeepOperation = filterConfig[filterFunction].filter.operation(filterWithValues, operation);

    if (!shouldKeepOperation) {
      return;
    }
  }

  for (const [filterFunction, filterWithValues] of Object.entries(operationFiltersObject.specific)) {
    const shouldKeepOperation = filterConfig[filterFunction].filter.operation(filterWithValues, operation);

    if (!shouldKeepOperation) {
      return;
    }
  }

  if (filtersObject.general.length) {
    const operationStringified = JSON.stringify(operation);
    const shouldKeepOperation = filtersObject.general.some((generalFilter) => {
      const generalFilterRegex = new RegExp(generalFilter);
      return generalFilterRegex.test(operationStringified);
    });

    if (!shouldKeepOperation) {
      return;
    }
  }

  const filteredOperation = Object.assign({}, operation);
  // loadedAllNotices is set by the NoticesUI manually.
  // When it's set, regardless of the notices filters applied, the user wants to see all the notices for an operation.
  // So in this case, simply return all the notices that exist for the operation
  filteredOperation.notices = operation.loadedAllNotices
    ? operation.notices
    : filterNotices(operation.notices, filtersObject);

  // if there's no notices after applying filter, we should remove the operation
  if (!filteredOperation.notices.length && operationFiltersObject.specific.byIsEmpty) {
    return;
  }

  return filteredOperation;
}

/*
 * Private Functions
 */

function filterNotices(notices, filtersObject) {
  if (!hasNoticeFilter(filtersObject) || !notices.length) {
    return notices;
  }

  const noticeFiltersObject = filtersObject[OPERATION_KEY_WORDS.NOTICE];

  return notices.filter((notice) => {
    for (const [filterFunction, filterWithValues] of Object.entries(noticeFiltersObject.default)) {
      const shouldKeepNotice = filterConfig[filterFunction].filter.notice(filterWithValues, notice);

      if (!shouldKeepNotice) {
        return;
      }
    }

    for (const [filterFunction, filterWithValues] of Object.entries(noticeFiltersObject.specific)) {
      const shouldKeepNotice = filterConfig[filterFunction].filter.notice(filterWithValues, notice);

      if (!shouldKeepNotice) {
        return;
      }
    }

    if (filtersObject.general.length) {
      const noticeStringified = JSON.stringify(notice);
      const shouldKeepOperation = filtersObject.general.some((generalFilter) => {
        const generalFilterRegex = new RegExp(generalFilter);
        return generalFilterRegex.test(noticeStringified);
      });

      if (!shouldKeepOperation) {
        return;
      }
    }

    return true;
  });
}

function hasNoticeFilter(filtersObject) {
  const noticeFiltersObject = filtersObject[OPERATION_KEY_WORDS.NOTICE];

  return Object.entries(noticeFiltersObject.default).length
    || Object.entries(noticeFiltersObject.specific).length
    || filtersObject.general.length;
}

function hasOperationFilter(filtersObject) {
  const operationFiltersObject = filtersObject[OPERATION_KEY_WORDS.OPERATION];

  return Object.entries(operationFiltersObject.default).length
    || Object.entries(operationFiltersObject.specific).length
    || filtersObject.general.length;
}
