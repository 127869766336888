import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Button/Button';
import variables from '@styles/variables';

export interface LanguageSelectorProps {
  className?: string;
  useSilverGreyGlobe?: unknown;
  style?: React.CSSProperties;
}

const LanguageSelector = (props: LanguageSelectorProps) => { // tslint:disable-line:variable-name
  const { t, i18n } = useTranslation('common');

  const style = Object.assign({}, { backgroundColor: 'unset', color: variables.transitdarkgrey }, props.style || {});
  const src = props.useSilverGreyGlobe ? "/images/globe-silvergrey.svg" : "/images/globe.svg";

  const handleOnClick = async () => {
    const language = i18n.language === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(language);
  };
  return (
    <Button
      className={`navbar-navlinks-wrapper header-link ${props.className}`}
      key="translation"
      onClick={handleOnClick}
      value={i18n.language === 'en' ? t('language.french').toUpperCase() : t('language.english').toUpperCase()}
      imageLeft={<img height="18px" src={src} alt="" style={{ verticalAlign: 'sub' }} />}
      style={style}
    />
  );
};

export default LanguageSelector;
