import React from 'react';
import store from '@stores';
import { withStore } from '@stores/withStore';
import LoaderButton from '@components/LoaderButton/LoaderButton';

import './CommitForm.scss';
import { ENVIRONMENTS } from '@application/common/constants';

interface CommitFormProps {
  feedId: number;
  handleSubmit(commitEnv: ENVIRONMENTS, commitTitle: string) : Promise<boolean>;
  disable: boolean;
  store: typeof store;
}

interface CommitformState {
  commitState: string;
  commitInitTimestamp: Date;
  commitTitle: string;
}

class CommitForm extends React.Component<CommitFormProps, CommitformState> {

  constructor(props) {
    super(props);

    this.state = {
      commitTitle: '',
      commitState: undefined,
      commitInitTimestamp: undefined,
    };
  }

  private handleSubmit = async (commitEnv) : Promise<boolean> => {
    const isTitleValid = this.validateCommitTitle();
    if (!isTitleValid) { return false; }

    const commitTitle = this.state.commitTitle;
    const succeed = this.props.handleSubmit(commitEnv, commitTitle);
    return succeed;
  }

  /**
   * Will validate title if title is not an empty string;
   */
  private validateCommitTitle() : boolean {
    const { commitTitle } = this.state;
    const title = commitTitle.trim();

    const isEmpty = title.length === 0;
    if (!isEmpty) {
      return true;
    }

    return false;
  }

  private validateCommitTitleViaBlur = (event) => {
    return this.validateCommitTitle();
  }

  private handleChangeInput = (event: any) => {
    this.setState({
      commitTitle: event.target.value,
    });
  }

  // --------------------------- //
  //     RENDERING FUNCTIONS     //
  // --------------------------- //

  renderButton(isDisabled: boolean, commitEnv: string, value: string) {
    return (
      <LoaderButton
        disable={isDisabled}
        className={commitEnv}
        value={value}
        data={commitEnv}
        onClickWithStatus={this.handleSubmit}
      />
    );
  }

  render() {
    const { transit } = this.props.store;
    const { commitTitle } = this.state;
    const { feed_code } = transit.getFeedWithFeedId(this.props.feedId);

    const isTitleValid = this.validateCommitTitle();

    return (
      <div className="commit-info-form">
        <div className={`commit-form-control ${ !isTitleValid ? 'error' : '' }`}>
          <label>{feed_code}:</label>
          <div className="input-wrapper">
            <input
              name="commit_title"
              value={commitTitle}
              disabled={this.props.disable}
              onChange={this.handleChangeInput}
              onBlur={this.validateCommitTitleViaBlur}
              placeholder="Commit title ....."
              type="text"
            />
          </div>
        </div>
        <div className="save-button-wrapper" onClick={this.validateCommitTitleViaBlur}>
          {this.renderButton(!isTitleValid || this.props.disable, 'staging', 'Upload To Staging')}
          {this.renderButton(!isTitleValid || this.props.disable, 'production', 'Upload To Production')}

          <div className={`commit-status  ${ this.state.commitState }`}>
            <span>{this.state.commitState}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withStore<CommitFormProps>(CommitForm);
