import React from 'react';
import { observer } from 'mobx-react';
import md5 from 'md5';
import { List, AutoSizer } from 'react-virtualized';
import { useTranslation } from 'react-i18next';

import { withStore } from '@stores/withStore';
import store from '@stores';
import { AutocompleteStore } from '@stores/autocomplete';
import api from '@stores/api';

import { EmailConfig, Invitation } from '@server/auth/invitations/invitation.types';
import { UserProfile } from '@server/users/user.types';
import { ROLES } from '@constants';
import Button from '@components/Button/Button';
import LoaderButton from '@components/LoaderButton/LoaderButton';
import UserSearchInput from './UserSearchInput';
import UserProfileForm from './UserProfileForm';
import InvitationForm from './InvitationForm';

import './UsersPage.scss';

interface UsersPageProps {
  store: typeof store;
  location: any;
  route: any;
  match: any;
}

interface UserProfileViewProps {
  user: UserProfile;
  onSubmit(user: UserProfile): Promise<boolean>;
  onChange?(): void;
}

const UserProfileView: React.FC<UserProfileViewProps> = (props) => {
  return (
    <div className="user-profile-view-wrapper row no-gutters">
      <div className="col-4">
        <div
          className="user-profile-avatar"
          style={{ backgroundImage: `url(${props.user.photoURL})` }}
        />
      </div>
      <div className="col-8">
        <div className="user-profile-form-wrapper">
          <UserProfileForm
            userInfo={props.user}
            onSubmit={props.onSubmit}
            onChange={props.onChange}
          />
        </div>
      </div>
    </div>
  );
};

const UsersPage: React.FC<UsersPageProps> = (props) => {
  const [users, setUsers] = React.useState([]);
  const [invitations, setInvitations] = React.useState<Invitation[]>([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [searchOptions, setSearchOptions] = React.useState({
    searchByAllColumns: [],
    quickToggles: [
      {
        value: false,
        text: 'Show pending invitations',
        onChange: (newValue) => {
          searchOptions.quickToggles[0].value = newValue;
          setSearchOptions(searchOptions);
        },
        isMatching: (data: UserProfile & Invitation) => {
          return isInvitation(data);
        },
      },
      {
        value: false,
        text: 'Show unauthorized users',
        onChange: (newValue) => {
          searchOptions.quickToggles[1].value = newValue;
          setSearchOptions(searchOptions);
        },
        isMatching: (data: UserProfile & Invitation) => {
          return isUser(data) && !data.auth.isAuthorized;
        },
      },
      ...Object.values(ROLES).map((role, index) => ({
        value: false,
        text: `Show users with role: ${role}`,
        onChange: (newValue) => {
          searchOptions.quickToggles[index + 2].value = newValue;
          setSearchOptions(searchOptions);
        },
        isMatching: (data: UserProfile & Invitation) => {
          return isUser(data) && data.auth.role === role;
        },
      })),
    ],
  });

  let listRef: List;
  let hideModal: any;
  const { t } = useTranslation('common');

  React.useEffect(() => {
    const { params: { user_id } } = props.store.router.getUrlData();

    const initUsers = async () => {
      await updateUsers();

      const user = users.find(user => user.uid === user_id);
      if (user) {
        openItemModal(user);
      }
    };

    initUsers();

    return function cleanup() {
      if (hideModal) {
        hideModal();
      }
    };
  }, []);

  React.useEffect(() => {
    const staticSearchOptions = {
      limit: Infinity,
      searchOptions: [{
        normalizeRow: user => Object.values(user).join(' '),
        searchValues: searchOptions.searchByAllColumns,
      }],
      compareStringOptions: {
        searchNormalizedCharacters: true,
        searchCaseInsensitive: true,
      },
      additionalCondition: (user) => {
        return searchOptions.quickToggles.every((quickToggle) => {
          return !quickToggle.value || quickToggle.isMatching(user);
        });
      },
    };

    const listData = invitations.concat(users); // invitations appear on top of the list by default
    const filteredData = AutocompleteStore.searchStaticIndex(listData, staticSearchOptions);

    setFilteredData(filteredData);

    if (listRef) {
      listRef.forceUpdateGrid();
    }
  }, [users, searchOptions.searchByAllColumns]);

  const isUser = (data: UserProfile & Invitation) => {
    return !!data.auth;
  };

  const isInvitation = (data: UserProfile & Invitation) => {
    return !!data.initialAuth || !!data.expiryDate;
  };

  const updateUsers = async () => {
    const users = await api.users.getUsers();
    const invitations = await api.auth.getInvitations();

    setUsers(users);
    setInvitations(invitations);
  };

  const openItemModal = (data: UserProfile & Invitation) => {
    if (isUser(data)) {
      return openUserModal(data);
    }

    if (isInvitation(data)) {
      return openInvitationModal(data);
    }
  };

  const openUserModal = (user: UserProfile) => {
    const { modals, router } = props.store;

    const onSubmit = async (newUser) => {
      const updatedUser = await api.users.updateUser(newUser.uid, newUser);
      const isSuccess = !!updatedUser;

      if (isSuccess) {
        hideModal && hideModal();
        updateUsers();
      }

      return isSuccess;
    };

    const modalLifeCycleConfig = {
      confirmOnClose: undefined,
      onShow: () => { router.redirect(`/admin/users/${user.uid}`); },
      onHide: () => { router.redirect('/admin/users'); },
    };

    const openModalWithLifeCycle = (modalLifeCycleConfig) => {
      const onChange = () => openModalWithLifeCycle({
        ...modalLifeCycleConfig,
        confirmOnClose: 'Warning: Your changes will be lost if you don’t save them',
      });

      hideModal = modals.showModal({
        title: user.uid,
        component: (
          <UserProfileView
            key={user.uid}
            user={user}
            onSubmit={onSubmit}
            onChange={onChange}
          />
        ),
      }, modalLifeCycleConfig);
    };

    openModalWithLifeCycle(modalLifeCycleConfig);
  };

  const getEmailConfig = (invitationData): EmailConfig => {
    const subject = t('invitation.email_subject', { lng: invitationData.language });
    const body = `
      <div class="wrapper">
        <img class="header" src="https://dashboard-v2.transitapp.com/images/transit-logo.png" />
          <div class="content">
            <p>
              ${t('invitation.email_title', { lng: invitationData.language })}
              <br>
              <br>
              ${t('invitation.email_body', { lng: invitationData.language })}
            </p>
            <a href="${window.location.origin}/invitations/${invitationData.uid}" class="button">
              ${t('invitation.cta_button', { lng: invitationData.language })}
            </a>
            ${invitationData.expiryDate
              ? `<p class="centered">${t('invitation.email_expiry', { lng: invitationData.language })} <span class="green">${t('invitation.days', { count: 3, lng: invitationData.language })}</span></p>`
              : ''}
            <div class="separator"></div>
            <p>${t('invitation.email_ignore', { lng: invitationData.language })}</p>
           </div>
           <div class="footer">
            <p>${t('invitation.email_issues', { lng: invitationData.language })} <a href="mailto:data@transit.app">${t('invitation.email_contact_us', { lng: invitationData.language })}</a></p>
          </div>
       </div>
    `;

    return {
      subject,
      body,
    };
  };

  const openInvitationModal = (invitation: Invitation) => {
    const { modals, api } = props.store;

    const updateInvitation = async (invitationData: Invitation): Promise<Invitation> => {
      const updatedInvitation = await api.auth.updateInvitation(invitation.uid, invitationData);

      if (!!updatedInvitation) {
        hideModal && hideModal();
        await updateUsers();
      }

      return updatedInvitation;
    };

    const sendEmail = async (invitationData: Invitation): Promise<boolean> => {
      const updatedInvitation = await updateInvitation(invitationData);
      const emailConfig = getEmailConfig(updatedInvitation);
      return api.auth.sendInvitationEmail(updatedInvitation.uid, emailConfig);
    };

    hideModal = modals.showModal({
      title: 'Update Invitation',
      component: (
        <InvitationForm
          existingUsers={users}
          formData={invitation}
          onSubmit={updateInvitation}
          invitationExists={true}
        >
          {(form) => {
            const canSubmit = form.dirty // dirty === form has been touched
              && Object.values(form.values).every(value => value !== undefined)
              && Object.keys(form.errors).length === 0;

            return (
              <div className="dialog-modal-button-list">
                <Button value="Cancel" className="no-background" onClick={() => hideModal()/* tslint:disable-line jsx-no-lambda max-line-length */}/>
                <LoaderButton
                  value="Update"
                  errorMessage="Error occurred! Please update items with errors before submitting"
                  disable={!canSubmit}
                  onClickWithStatus={form.submitForm}
                />
                <LoaderButton
                  value="Update & Send"
                  errorMessage="Error occurred! Email hasn't been sent"
                  disable={!canSubmit}
                  onClickWithStatus={() => sendEmail(form.values)/* tslint:disable-line jsx-no-lambda */}
                />
              </div>
            );
          }}
        </InvitationForm>
      ),
    });
  };

  const onConfirmDeleteModal = (data: UserProfile & Invitation) => {
    const { modals } = props.store;

    const cancelModal = () => {
      hideModal && hideModal();
    };

    const deleteItem = async () => {
      const deleted = isUser(data)
        ? await api.users.deleteUser(data.uid)
        : await api.auth.deleteInvitation(data.uid);

      if (deleted) {
        await updateUsers();
        cancelModal();
      }

      return deleted;
    };

    const entityName = isUser(data) ? 'User' : 'Invitation';
    const warningMsg = isUser(data)
      ? 'Warning: Deleting this user will delete the resources that belong to this user. If you want to suspend their access to the dashboard and API, consider unauthorizing the user instead.' // tslint:disable-line max-line-length
      : 'Are you sure?';

    hideModal = modals.showModal({
      title: `Delete ${entityName}`,
      component: (
        <div className="dialog-modal-wrapper">
          <p>{warningMsg}</p>
          <div className="dialog-modal-button-list">
            <Button value="Cancel" className="no-background" onClick={cancelModal}/>

            {isUser(data) && (
              <React.Fragment>
                <Button value="Unauthorize User" className="warning" onClick={() => openItemModal(data)}/>
                <LoaderButton value="Delete User" className="danger" onClickWithStatus={deleteItem}/>
              </React.Fragment>
            )}

            {isInvitation(data) && (
              <React.Fragment>
                <Button value="Modify Invitation" className="warning" onClick={() => openItemModal(data)}/>
                <LoaderButton value="Delete Invitation" className="danger" onClickWithStatus={deleteItem}/>
              </React.Fragment>
            )}

          </div>
        </div>
      ),
    });
  };

  const handleOnSearchChange = (searchOptions) => {
    setSearchOptions(searchOptions);
  };

  const openCreateInvitationModal = () => {
    const { modals, api } = props.store;

    const createOrRecreateInvitation = async (invitationToCreate: Invitation) => {
      const existingInvite = invitations.find(i => i.email === invitationToCreate.email)
      if (existingInvite) {
        // not sure why this is nullable in the first place, but let's check it
        if (!existingInvite.uid) {
          // might be better to throw, but i don't think there's any error
          // boundaries set up, so if we did it wouldn't be surfaced in the ui.
          console.error(`found existing invite to address ${existingInvite.email}, but it has no uid. skipping deletion before recreate.`)
        } else {
          const res = await api.auth.deleteInvitation(existingInvite.uid);
          if (!res) {
            // we only get a success boolean back -- we can't surface a more
            // detailed error :(  not really a helpful api abstraction.
            console.error(`tried to delete existing invite to address ${existingInvite.email}, but it failed... for reasons.`)
          }
        }
      }

      const createdInvitation = await api.auth.createInvitation(invitationToCreate);
      if(!createdInvitation) {
        console.error(`failed to create invite for address ${invitationToCreate.email}`)
        return;
      }

      const emailConfig = getEmailConfig(createdInvitation);
      const isSuccess = await api.auth.sendInvitationEmail(createdInvitation.uid, emailConfig);

      if (isSuccess) {
        // FIXME: this should probably only get called after the entire bulk
        // invite operation resolves; it might cause glitchy behaviour to
        // dismiss the modal repeatedly (eg. if someone queues up hundreds of
        // invites and tries to open the modal again while this is running in
        // the background).
        hideModal?.();

        // this also probably hammers the api -- it seems like it would refetch
        // all invitations and users for every invite that's sent. if we're
        // lucky react batches the dom updates since we're in a handler, but we
        // might still hit rate limit errors or something.
        await updateUsers();
      } else {
        console.error(`failed to send email for newly-created invite to address ${createdInvitation.email}.`)
      }
    };

    hideModal = modals.showModal({
      title: 'Send Invitation',
      component: (
        <InvitationForm
          existingUsers={users}
          formData={{}}
          onSubmit={createOrRecreateInvitation}
          invitationExists={false}
        >
          {(form) => {
            const canSubmit = form.dirty // dirty === form has been touched
              && Object.values(form.values).every(value => value !== undefined)
              && Object.keys(form.errors).length === 0;

            return (
              <div className="dialog-modal-button-list">
                <Button value="Cancel" className="no-background" onClick={() => hideModal()/* tslint:disable-line jsx-no-lambda max-line-length */}/>
                <LoaderButton value="Create Invitation" disable={!canSubmit} onClickWithStatus={form.submitForm}/>
              </div>
            );
          }}
        </InvitationForm>
      ),
    });
  };

  const getAvatarURL = (data: UserProfile & Invitation) => {
    if (isUser(data)) {
      return data.photoURL;
    }

    if (!data.email) {
      return undefined;
    }

    const gravatarEmailHash = md5(data.email.trim().toLowerCase());
    return `https://www.gravatar.com/avatar/${gravatarEmailHash}`;
  };

  const rowRenderer = ({ index, key, style }) => {
    const data = filteredData[index];

    const avatarUrl = getAvatarURL(data);
    const avatarStyle = avatarUrl
      ? { backgroundImage: `url(${getAvatarURL(data)})` }
      : {};

    return (
      <div className="row" key={key} style={style}>
        <div className="list-data-wrapper">
          <div className="user-avatar-small" style={avatarStyle}/>
          <div className="user-display-name">{data.displayName} ({data.uid})</div>
          {isInvitation(data) && <div className="user-badge">pending-invitation</div>}
        </div>
        <div className="list-quick-actions-wrapper">
          <Button value="EDIT" onClick={() => openItemModal(data)}/>
          <Button value="DELETE" className="danger" onClick={() => onConfirmDeleteModal(data)}/>
        </div>
      </div>
    );
  };

  return (
    <div className="users-header">
      <div className="users-filters-container">
        <UserSearchInput
          openDropdownOnHover={false}
          onChange={handleOnSearchChange}
          {...searchOptions}
        />

        <div className="list-header-button" onClick={openCreateInvitationModal}>
          <i className="fa fa-user-plus" aria-hidden="true"/>
        </div>
      </div>
      <div>
        <AutoSizer
          children={({ width }) => (
            <List // tslint:disable jsx-no-multiline-js jsx-no-lambda
              ref={ref => listRef = ref}
              className="List"
              height={1000}
              rowHeight={100}
              rowCount={filteredData.length}
              rowRenderer={rowRenderer}
              width={width}
            />
          )}
        />
      </div>
    </div>
  );
};

export default observer(withStore(UsersPage));
