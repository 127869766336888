import React from 'react';

import './SearchInput.scss';

export interface GenericToolUiProps {
  onChange(value): void;
}

export class SearchInput extends React.Component<GenericToolUiProps> {

  inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  focus = () => {
    this.inputRef.current.focus();
  }

  handleClick = (event: React.SyntheticEvent) => {
    event && event.stopPropagation();
    this.focus();
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.props.onChange(value);
  }

  render() {
    return (
      <div className="search-input" onClick={this.handleClick}>
        <input
          ref={this.inputRef}
          type="text"
          placeholder="ex: wheelchair, zoom, override"
          autoComplete="off"
          onChange={this.handleChange}
        />

        <div className="search-input-icon">
          <i className="fa fa-search"/>
        </div>
      </div>
    );
  }
}

export default SearchInput;
