import React from 'react';
import Header from '@components/Header/Header';
import { Route } from '@stores/router/routes';
import store from '@stores';
import { withStore } from '@application/stores/withStore';

interface LayoutProps {
  store: typeof store;
  routes: Route[];
}

class TransitLayout extends React.Component<LayoutProps, null> {
  render() {
    const { routes, store: { router } } = this.props;

    return (
      <div className="default-layout">
        <div className="header">
          <Header routes={routes}/>
        </div>
        <div className="layout-max-width">
          {router.renderRoute(routes, {})}
        </div>
      </div>
    );
  }
}

export default withStore<LayoutProps>(TransitLayout);
