import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { cloneDeep, isEqual } from 'lodash';
import cx from 'classnames';
import store from '@stores';
import { withStore } from '@stores/withStore';
import CsvExplorerView, { CsvConfig } from './CsvExplorerView';
import 'react-tabs/style/react-tabs.css';
import 'react-virtualized/styles.css';
import './CsvExplorerPage.scss';

interface CsvExplorerPageProps {
  store: typeof store;
  feed: any;
}

interface CsvExplorerPageState {
  tabs: any[];
  activeTabIndex: number;
  isPageFullScreen: boolean;
}

class CsvExplorerPage extends React.Component<CsvExplorerPageProps, CsvExplorerPageState> {

  state = {
    isPageFullScreen: false,
    activeTabIndex: 0,
    tabs: [],
  };

  componentDidMount() {
    const { router } = this.props.store;
    const tabData = router.getNameSpaceQueryData('gtfs-explorer');
    this.addNewTab(tabData);
  }

  componentDidUpdate(prevProps, prevState) {
    const { router } = this.props.store;
    const { tabs, activeTabIndex } = this.state;

    const previousActiveTab = tabs[prevState.activeTabIndex];
    const activeTab = tabs[activeTabIndex];

    if (!activeTab) {
      const previousTab = tabs[tabs.length - 1];
      this.setState({ activeTabIndex: tabs.length - 1 });
      router.setNameSpaceQueryData('gtfs-explorer', previousTab.tabConfig);
      return;
    }

    const activeTabChanged = activeTabIndex !== prevState.activeTabIndex;
    if (activeTabChanged) {
      router.setNameSpaceQueryData('gtfs-explorer', activeTab.tabConfig);
      return;
    }

    const currentTabConfigChanged = !previousActiveTab
      || isEqual(activeTab.tabConfig, previousActiveTab.tabConfig);

    if (currentTabConfigChanged) {
      router.setNameSpaceQueryData('gtfs-explorer', activeTab.tabConfig);
      return;
    }
  }

  tabRefs = React.createRef();

  createTab(tabName: string, tabConfig?: CsvConfig) {
    const newTabConfig = tabConfig || {
      fileConfig: {},
      searchConfig: {},
    };

    return {
      name: tabName,
      tabConfig: newTabConfig,
    };
  }

  addNewTab = (tabdata?: CsvConfig) => {
    const { tabs } = this.state;
    const newTab = this.createTab('new tab', tabdata);
    tabs.push(newTab);

    this.setState({ tabs });
  }

  removeTabAtIndex = (index: number) => {
    const { tabs } = this.state;
    const tabsClone = cloneDeep(tabs);
    tabsClone.splice(index, 1);
    this.setState({ tabs: tabsClone, activeTabIndex: tabsClone.length - 1 });
  }

  toggleFullScreen = () => {
    const { isPageFullScreen } = this.state;
    this.setState({ isPageFullScreen: !isPageFullScreen });
  }

  renderTab = (tab, index) => {
    const { tabs } = this.state;
    const tabName = tab.name;

    const isFirstTab = index === 0;
    const otherTabsAvailable = tabs.length > 1;
    const shouldShowCloseButton = otherTabsAvailable || !isFirstTab;
    const removeTab = () => this.removeTabAtIndex(index);

    const closeTabButton = shouldShowCloseButton && (
      <div className="close-tab-icon" onClick={removeTab}>
        <i className="fa fa-close" />
      </div>
     );

    return (
      <Tab key={index}>
        <span>{tabName}</span>
        {closeTabButton}
      </Tab>
    );
  }

  onActiveTabChange = (index: number, lastIndex: number, event: Event) => {
    this.setState({
      activeTabIndex: index,
    });
  }

  handleTabConfigChange = async (tabIndex, tabConfig) => {
    const { tabs } = this.state;
    const tab = JSON.parse(JSON.stringify(tabs[tabIndex]));
    const { step, filename } = tabConfig.fileConfig;

    const uuid = `${step}/${filename}`;
    tab.tabConfig = tabConfig;
    tab.tabConfig.fileConfig.uuid = uuid;
    tab.name = uuid;

    tabs[tabIndex] = tab;
    await this.setState({ tabs });
  }

  render() {
    const { tabs, isPageFullScreen, activeTabIndex } = this.state;

    const wrapperClass = cx('csv-full-height', {
      'is-fullscreen': isPageFullScreen,
    });

    const addNewTab = () => this.addNewTab();

    return (
      <div className={wrapperClass} style={{ display: 'flex', flexDirection: 'column' }}>
        <Tabs
          selectedIndex={activeTabIndex}
          onSelect={this.onActiveTabChange}
          forceRenderTabPanel={true} /* prevents CsvExplorers to unmount and loose state */
        >
          <div>
            <TabList className="Tabs-TabList-wrapper">
              {tabs.map(this.renderTab)}
            </TabList>
            <div className="Tabs-TabList-button" onClick={addNewTab}>+</div>
          </div>

          {tabs.map((tab, tabIndex) => { // tslint:disable-line jsx-no-multiline-js
            const updateTabConfig = csvConfig => this.handleTabConfigChange(tabIndex, csvConfig);
            return (
              <TabPanel key={tabIndex}>
                <CsvExplorerView
                  feed={this.props.feed}
                  isFullScreen={isPageFullScreen}
                  toggleFullScreen={this.toggleFullScreen}
                  csvConfig={tab.tabConfig}
                  onCsvConfigChange={updateTabConfig}
                />
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

export default withStore(CsvExplorerPage);
