import React from 'react';
import { GoogleApiWrapper, IMapProps, Map, Marker } from 'google-maps-react';

import { StopInterface } from '@pages/feed/components/PathwaysPage/PathwayTypes';
import { FeedInfo } from '@stores/transit';

interface StationMapProps extends IMapProps {
  activeMarker?: StopInterface;
  activeMarkerImageUrl: string;
  feed: FeedInfo;
  markers: StopInterface[];
  markerImageUrl: string;
  onMarkerClick?(index: number): void;
  onRef?(stationMap?: StationMap): void;
}

interface StationMapState {
  activeMarker: StopInterface;
}

class StationMap extends React.Component<StationMapProps, StationMapState> {
  mapRef: any = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      activeMarker: props.activeMarker || props.markers && props.markers[0] || null,
    };
  }

  private getDefaultMapBounds() {
    const { bounds } = this.props.feed;

    if (!bounds.max_lat || !bounds.max_lon || !bounds.min_lat || !bounds.min_lon) {
      return;
    }

    return [
      { lat: bounds.max_lat, lng: bounds.min_lon }, // top-left
      { lat: bounds.max_lat, lng: bounds.max_lon }, // top-right
      { lat: bounds.min_lat, lng: bounds.max_lon }, // bottom-right
      { lat: bounds.min_lat, lng: bounds.min_lon }, // bottom-left
    ];
  }

  updateMapZoom = () => {
    if (this.props.initialCenter || this.props.center || this.props.bounds) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    if (this.props.markers.length === 0) {
      this.getDefaultMapBounds().forEach(({ lat, lng }) => {
        const latLng = new google.maps.LatLng(lat, lng);
        bounds.extend(latLng);
      });

      this.mapRef.map.fitBounds(bounds);
      return;
    }

    this.props.markers && this.props.markers.forEach(({ stopLat, stopLon }, index) => {

      bounds.extend(new google.maps.LatLng(stopLat, stopLon));
    });

    this.mapRef.map.fitBounds(bounds);
  }

  addTransitLayer = () => {
    const transitLayer = new google.maps.TransitLayer();
    transitLayer.setMap(this.mapRef.map);
  }

  updateActiveMarker = (activeMarker) => {
    this.setState({
      activeMarker,
    });
  }

  componentDidMount() {
    this.updateMapZoom();
    this.addTransitLayer();
    this.props.onRef && this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.markers.length === this.props.markers.length) {
      return;
    }

    this.updateMapZoom();
  }

  render() {
    const {
      activeMarkerImageUrl,
      markers,
      markerImageUrl,
    } = this.props;

    return (
      <Map
        ref={ref => this.mapRef = ref}
        {...this.props}
      >
        {markers && markers.map((marker, index) => {
          const isActiveMarker = marker === this.state.activeMarker;

          return (
            <Marker
              key={`${marker.stopLat}:${marker.stopLon}`}
              position={{ lat: marker.stopLat, lng: marker.stopLon }}
              zIndex={isActiveMarker ? 1 : 0} // ensure the active marker is displayed on top of the others
              icon={{
                url: isActiveMarker ? activeMarkerImageUrl : markerImageUrl,
                scaledSize: new this.props.google.maps.Size(20, 26),
              }}
              onClick={() => { // tslint:disable-line jsx-no-lambda
                if (this.props.onMarkerClick) {
                  this.props.onMarkerClick(index);
                }

                this.updateActiveMarker(markers[index]);
              }}
            />
          );
        })}
      </Map>
    );
  }
}

const withGoogleMapApi = GoogleApiWrapper({ apiKey: process.env.GOOGLE_MAP_API_KEY });
export default withGoogleMapApi<any>(StationMap);
