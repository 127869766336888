import api from '@stores/api';
import auth from '@stores/auth';
import autocomplete from '@stores/autocomplete';
import banners from '@stores/banners';
import modals from '@stores/modals';
import gtfs from '@stores/pages/gtfs';
import operationAndNotices from '@stores/operationAndNotices';
import router from '@stores/router';
import transit from '@stores/transit';

const stores = {
  api,
  auth,
  autocomplete,
  banners,
  modals,
  operationAndNotices,
  router,
  transit,
  pages: {
    gtfs,
  },
};

export default stores;
