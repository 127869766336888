import React from 'react';
import { observer } from 'mobx-react';
import { isEqual } from 'lodash';

import JsonUI from '@components/ToolComponents/JsonUI';
import Tool from '@stores/pages/gtfs/jsonExtensionPage/lib/Tool';
import './ToolForm.scss';

interface ToolFormProps {
  remove(tool: any): void;
  updateJsonExtensionInLocalStorage(): void;
  tool: Tool;
  showToolInfo(toolName: string): void;
  showDiffView(toolName: string, currentValue: any, initialValue: any): void;
}

interface ToolFormState {
  value: any;
  initialValue: any;
  isJsonEditorMode: boolean;
}

class ToolForm extends React.Component<ToolFormProps, ToolFormState> {
  constructor(props: ToolFormProps) {
    super(props);

    this.state = {
      initialValue: props.tool.value,
      value: props.tool.value || JsonUI.getJsonConfig(this.props.tool.template).fallbackJson,
      isJsonEditorMode: false,
    };
  }

  handleCloseEvent = () => {
    this.props.remove(this.props.tool);
  }

  handleToggleDebugMode = () => {
    this.setState({ isJsonEditorMode: !this.state.isJsonEditorMode });
  }

  setJsonEditorMode = () => {
    if (this.state.isJsonEditorMode) { return; }
    this.setState({ isJsonEditorMode: true });
  }

  handleChange = async (emittedValue) => {
    const toolValue = { [this.props.tool.name] : emittedValue };
    const valueChanged = !isEqual(toolValue, this.state.value);

    if (!valueChanged) { return; }

    await this.setState({ value: toolValue });
    this.props.updateJsonExtensionInLocalStorage();
  }

  handleShowToolInfo = () => {
    this.props.showToolInfo(this.props.tool.name);
  }

  handleShowJsonDiffView = () => {
    const { value, initialValue } = this.state;

    this.props.showDiffView(this.props.tool.name, value, initialValue);
  }

  // --------------------------- //
  //     RENDERING FUNCTIONS     //
  // --------------------------- //

  renderActionBtnList() {
    const { isJsonEditorMode } = this.state;

    return (
      <div className="tool-action-list">
        <div className="tool-action-button" onClick={this.handleShowJsonDiffView}>
          <i className="fa fa-code-fork icon code-icon" aria-hidden="true"/>
        </div>
        <div className="tool-action-button" onClick={this.handleShowToolInfo}>
          <i className="fa fa-info icon code-icon" aria-hidden="true"/>
        </div>
        <div className="tool-action-button" onClick={this.handleToggleDebugMode}>
          <i className={`fa fa-code icon code-icon ${isJsonEditorMode ? 'is-active' : ''}`} aria-hidden="true"/>
        </div>
        <div className="tool-action-button" onClick={this.handleCloseEvent}>
          <i className="fa fa-times icon close-icon" aria-hidden="true"/>
        </div>
      </div>
    );
  }

  renderJsonExtensionTool(tool) {
    const { value, isJsonEditorMode } = this.state;
    const initialJsonObject = value;

    return (
      <div className="selected-tool-config-box">
        <div className={'selected-tool-config-content'}>
          <JsonUI
            value={initialJsonObject}
            template={tool.template}
            isJsonEditorMode={isJsonEditorMode}
            onChange={this.handleChange}
            onUnavailableUI={this.setJsonEditorMode}
          />
        </div>
      </div>
    );
  }

  render() {
    const { tool } = this.props;

    if (tool.hide) {
      return null;
    }

//  <MergeCodeTextArea value={value} compareWith={value}/>
    return (
      <div className="tool-config-container">
        {this.renderActionBtnList()}
        <div className="tool-config-wrapper">
          <div className="selected-tool-title">{`${tool.index} - ${tool.title}`}</div>
          <div className="selected-tool-definition"> {tool.definition} </div>
          {this.renderJsonExtensionTool(tool)}
        </div>
      </div>
    );
  }
}

export default observer(ToolForm);
