import * as React from 'react';
import 'leaflet/dist/leaflet.css'; // from the Doc: leaflet.css has to be imported before leaflet.
import L from 'leaflet';
import { pick } from 'lodash';
import validator from 'validator';
import { WINDOW_EVENTS, KEYBOARD_KEYS } from '@constants';
import { GoogleApiWrapper } from 'google-maps-react';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

interface BannerGeoFencing {
  radius?: number;
  latitude?: number;
  longitude?: number;
  geojson?: string | object;
}

interface GeoJsonMapProps extends BannerGeoFencing {
  google: any;
  defaultCenter: [number, number];
  defaultZoom: number;
  onChange(change: BannerGeoFencing) : void;
}

export class GeoJsonMap extends React.Component<GeoJsonMapProps> {
  mapRef: HTMLDivElement;
  autocompleteInputRef: HTMLDivElement;

  componentDidMount() {
    this.initializeMap();
    this.initializeGeoJsonControl();
    this.initializeGoogleAutocomplete();

    const map = this.getMap();
    map.whenReady(() => {
      setImmediate(() => {
        // when map is inside a modal the map is buggy
        // invalidateSize solves the isse
        // source: https://stackoverflow.com/questions/20400713/leaflet-map-not-showing-properly-in-bootstrap-3-0-modal
        map.invalidateSize();
        this.addInitialGeoJSON();
        this.fitGeoJsonBounds();
      });
    });

    window.addEventListener(WINDOW_EVENTS.KEYDOWN, this.finishEditingMode);
  }

  componentWillUnmount() {
    window.removeEventListener(WINDOW_EVENTS.KEYDOWN, this.finishEditingMode);
  }

  finishEditingMode = (event) => {
    switch (event.keyCode) {
      case KEYBOARD_KEYS.ESCAPE:
        const finishActionBtn = document.querySelector('.activeChild .active .action-finishMode');
        if (finishActionBtn) {
          (finishActionBtn as any).click();
        }
        break;
    }
  }

  componentDidUpdate(prevProps) {
    const map = this.getMap();

    const featureGroup = this.getGeoJSONFeatureGroup();
    featureGroup.eachLayer((layer) => {
      map.removeLayer(layer);
    });

    this.addInitialGeoJSON();
    this.fitGeoJsonBounds();
  }

  shouldComponentUpdate(prevProps) {
    return prevProps.radius !== this.props.radius
      || prevProps.latitude !== this.props.latitude
      || prevProps.longitude !== this.props.longitude
      || prevProps.geojson !== this.props.geojson;
  }

  fitGeoJsonBounds() {
    const map = this.getMap();
    const featureGroup = this.getGeoJSONFeatureGroup();
    const geoJsonBounds = featureGroup.getBounds();

    if (geoJsonBounds.isValid()) {
      map.fitBounds(featureGroup.getBounds());
    }
  }

  getGeoJSONFeatureGroup() {
    const map = this.getMap();
    const featureGroup = L.featureGroup();

    map.eachLayer((layer) => {
      const isGeoJSon = layer instanceof L.Polygon || layer instanceof L.Circle;
      if (isGeoJSon) {
        featureGroup.addLayer(layer);
      }
    });

    return featureGroup;
  }

  getGeoJson() {
    const featureGroup = this.getGeoJSONFeatureGroup();
    const geojson = {
      type: 'FeatureCollection',
      features: [],
    };

    featureGroup.eachLayer((layer) => {
      const layerGeoJson = layer.toGeoJSON();
      if (layer instanceof L.Circle) {
        // circle is not part of the geoJSON spec
        const center = layer.getLatLng();
        Object.assign(layerGeoJson.properties, {
          type: 'Circle',
          radius: layer._mRadius,
          latitude: center.lat,
          longitude: center.lng,
        });
      }

      geojson.features.push(layerGeoJson);
    });
    return geojson;
  }

  onGeoJsonChange() {
    const geojson = this.getGeoJson();

    const isOnlyCircle = geojson.features.length > 0
      && this.isGeoJsonCircle(geojson.features[0]);
    if (isOnlyCircle) {
      const circleProps = geojson.features[0].properties;
      const change = pick(circleProps, ['radius', 'longitude', 'latitude']);
      this.props.onChange(change);
      return;
    }

    this.props.onChange({ geojson });
  }

  // use WeakMap to retrive Leaflet instance
  // without needing to worry about memory leaks
  // if the ref changes over time
  map = new WeakMap();
  getMap() {
    if (this.map.has(this.mapRef)) {
      return this.map.get(this.mapRef);
    }

    const leafLetInstance = L.map(this.mapRef);
    this.map.set(this.mapRef, leafLetInstance);
    return leafLetInstance;
  }

  initializeMap() {
    const map = this.getMap();
    map.setView(
      this.props.defaultCenter,
      this.props.defaultZoom,
    );

    // use google tiles
    const mapLayer = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    });
    mapLayer.addTo(map);

    map.on('pm:create', (event) => {
      this.onGeoJsonChange();
      this.disableAddCircleLayer();

      event.layer.on('pm:edit', (event) => {
        this.onGeoJsonChange();
      });
    });

    map.on('pm:remove', (event) => {
      this.onGeoJsonChange();
      this.enableAddCircleLayer();
    });
  }

  initializeGeoJsonControl() {
    const map = this.getMap();
    map.pm.addControls({
      drawMarker: false,
      drawPolyline: false,
      drawCircleMarker: false,
      drawRectangle: false,
      drawPolygon: false,
      cutPolygon: false,

      // at the moment, only circles are
      // supported by the app.
      drawCircle: false,
      editMode: false,
      dragMode: false,
      removalMode: false,
    });
  }

  enableEdit() {
    const map = this.getMap();
    map.pm.addControls({
      editMode: true,
      dragMode: true,
      removalMode: true,
    });
  }

  disableEdit() {
    const map = this.getMap();
    map.pm.addControls({
      editMode: false,
      dragMode: false,
      removalMode: false,
    });
  }

  enableAddCircleLayer() {
    const map = this.getMap();
    this.disableEdit();
    map.pm.addControls({
      drawCircle: true,
    });
  }

  disableAddCircleLayer() {
    const map = this.getMap();
    this.enableEdit();
    map.pm.addControls({
      drawCircle: false,
    });
  }

  initializeGoogleAutocomplete() {
    const { google } = this.props;

    const autocomplete = new google.maps.places.Autocomplete(this.autocompleteInputRef);
    autocomplete.setFields(['place_id', 'geometry', 'formatted_address']);
    autocomplete.addListener('place_changed', async () => {
      const map = this.getMap();

      const place = autocomplete.getPlace();
      const isLatLongString = !place.place_id && validator.isLatLong(place.name);
      const newCenterObjectLatLng = isLatLongString
        ? strToLatLng(place.name)
        : await getPlaceLatLng(place);

      const latLng = newCenterObjectLatLng.toJSON();
      map.setView(
        [latLng.lat, latLng.lng],
        this.props.defaultZoom,
      );
    });

    function strToLatLng(str) {
      const latLng = str.split(/,\s?/);
      const lat = parseFloat(latLng[0]);
      const lng = parseFloat(latLng[1]);
      const mapLatLng = new google.maps.LatLng(lat, lng);
      return mapLatLng;
    }

    function getPlaceLatLng(place) {
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder;
        geocoder.geocode({ 'placeId': place.place_id }, (results, status) => {
          if (status !== 'OK') {
            window.alert(`Geocoder failed due to:${status}`);
            return reject(status);
          }

          const centerLatLng = results[0].geometry.location;
          resolve(centerLatLng);
        });
      });
    }
  }

  isGeoJsonCircle(geoJsonFeature) {
    return geoJsonFeature.geometry.type === 'Point'
      && geoJsonFeature.properties.type === 'Circle';
  }

  addInitialGeoJSON() {
    const isUsingGeoJson = !!this.props.geojson;
    if (isUsingGeoJson) {
      const geojsonObj = typeof this.props.geojson === 'string'
        ? JSON.parse(this.props.geojson)
        : this.props.geojson;

      geojsonObj.features.forEach((geoJsonFeature) => {
        const isCircle = this.isGeoJsonCircle(geoJsonFeature);
        if (isCircle) {
          const circle = geoJsonFeature.properties;
          const circleLayer = this.getCircleLayer(circle.latitude, circle.longitude, circle.radius);
          this.addLayer(circleLayer);
        } else {
          const geoJsonLayer = L.geoJSON(geoJsonFeature);
          this.addLayer(geoJsonLayer);
        }
      });
      return;
    }

    const isUsingCircleGeoFencing = typeof this.props.radius === 'number';
    if (isUsingCircleGeoFencing) {
      const circleLayer = this.getCircleLayer(
        this.props.latitude,
        this.props.longitude,
        this.props.radius,
      );

      this.addLayer(circleLayer);
      return;
    }

    // no layer in props;
    this.enableAddCircleLayer();
  }

  addLayer(layer) {
    const map = this.getMap();

    layer.on('pm:edit', (event) => {
      this.onGeoJsonChange();
    });

    layer.addTo(map);
    this.disableAddCircleLayer();
  }

  getCircleLayer(latitude, longitude, radius) {
    const circleCenter = [latitude, longitude];
    const circleLayer = L.circle(circleCenter, { radius });
    return circleLayer;
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
        <input
          ref={ref => this.autocompleteInputRef = ref}
          style={{
            position: 'absolute',
            width: 300,
            top: 10,
            right: 10,
            zIndex: 1,
            borderRadius: 4,
            border: '1px solid grey',
            padding:'0px 5px' }}
        />
        <div
          ref={ref => this.mapRef = ref}
          style={{ width: '100%', height: '100%', position: 'relative', zIndex: 0 }}
        />
      </div>
    );
  }
}

const withGoogleMapApi = GoogleApiWrapper({ apiKey: process.env.GOOGLE_MAP_API_KEY });
export default withGoogleMapApi<GeoJsonMapProps>(GeoJsonMap);
