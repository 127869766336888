import React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { i18n } = useTranslation('common');

  return (
    <img src={`/images/transit-logo${i18n.language === 'en' ? '' : '-fr'}.svg`} alt="Transit Dashboard" style={{ height: '2.5rem' }}/>
  );
};
