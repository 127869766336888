import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import store from '@stores';
import routes from '@stores/router/routes';
import App from './application/App';
import common_en from '../public/static/translations/en/common.json';
import common_fr from '../public/static/translations/fr/common.json';

import './index.scss';

configure({ enforceActions: true });

// this doesn't appear to work -- i've cleaned it up slightly a bit from another
// method which _also_ didn't work, and which was additionally causing
// client-side authentication issues and playing into a major security problem.
// given a choice between "broken" and "broken and on fire" i've elected to go
// with the former for now.
i18next.use(LanguageDetector).init({
  detection: {
    // order and from where user language should be detected
    order: ['localStorage'],

    // cache user language on
    caches: ['localStorage'],
  },
  interpolation: { escapeValue: false },  // React already does escaping
  fallbackLng: 'en',                      // language to use if translations in user language are not available
  resources: {
    en: {
      common: common_en,                   // 'common' is our custom namespace
    },
    fr: {
      common: common_fr,
    },
  },
});

const rootDomElement = document.getElementById('root');
const application = React.createElement(BrowserRouter, {},
    renderRoutes([{ component: App }], { store, routes })
);

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    {application}
  </I18nextProvider>,
  rootDomElement,
);
