import React from 'react';
import { observer } from 'mobx-react';
import store from '@stores';
import { Route } from '@stores/router/routes';
import { storeContext } from '@stores/withStore';
import ModalContainer from '@components/ModalContainer/ModalContainer';
import LoadingPage from './pages/login/LoadingPage';
import TransitDashboardLayout from '@components/Layouts/TransitDashboardLayout';
import FullScreenLayout from '@components/Layouts/FullScreenLayout';

interface AppProps {
  route: any;
  store: typeof store;
  history: any;
  location: any;
  routes: Route[];
}

interface AppState {
  isLoading: boolean;
  authorizedRoutes: any;
}

class App extends React.Component<AppProps, AppState> {

  state = {
    isLoading: true,
    authorizedRoutes: undefined,
  };

  async componentDidMount() {
    await this.initializeApp();
  }

  async initializeApp() {
    await this.props.store.auth.initialize();
    this.props.store.router.initialize({
      history: this.props.history,
      routes: this.props.routes,
      onAuthorizedRoutesChange: (authorizedRoutes) => {
        this.setState({
          authorizedRoutes,
          isLoading: false,
        });
      },
    });
  }

  renderWithLayout() {
    const { authorizedRoutes } = this.state;
    const { layout } = store.router.getCurrentRouteConfig();

    switch (layout){
      case 'fullscreen': return <FullScreenLayout routes={authorizedRoutes}/>;
      default: return <TransitDashboardLayout routes={authorizedRoutes}/>;
    }
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <LoadingPage />;
    }

    return (
      <storeContext.Provider value={this.props.store}>
        {this.renderWithLayout()}
        <ModalContainer/>
      </storeContext.Provider>
    );
  }
}

export default observer(App);
