import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

import './Tooltip.scss';

export interface TooltipProps {
  message: string | any;
  render: any;
  theme?: string;
  placement: string | any;
}

interface TooltipState {}

class Tooltip extends React.Component<TooltipProps, TooltipState> {

  render() {
    const { render, message, theme, placement } = this.props;

    return (
        <Tippy
          content={message}
          theme={theme}
          placement={placement}
          offset={[20, 10]}
        >
          {render()}
        </Tippy>
    );
  }
}

export default Tooltip;
