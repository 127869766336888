import React from 'react';
import { debounce } from 'lodash';
import { BarLoader } from 'react-spinners';

import store from '@stores';
import { withStore } from '@stores/withStore';
import HighlightedText from '@components/HightlightedText/HighlightedText';
import KeyboardActiveList from '@components/KeyboardActiveList/KeyboardActiveList';

import '../../styles/common.scss';

export interface AutocompleteDropdownProps {
  store: typeof store;
  fieldType: string;
  query: string;
  onSelect: (value: string) => void;
  onCloseDropdown: () => void;
  inputRef?: React.RefObject<KeyboardActiveList>;
}

export interface AutocompleteDropdownState {
  isLoading: boolean;
  autocompleteQuery: string;
  autocompleteResults: any;
}

class AutocompleteDropdown extends React.PureComponent<AutocompleteDropdownProps, AutocompleteDropdownState> {

  constructor(props: AutocompleteDropdownProps) {
    super(props);
    this.state = {
      isLoading: true,
      autocompleteQuery: '',
      autocompleteResults: undefined,
    };
  }

  async componentDidMount() {
    await this.initQuery();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      await this.initQuery();
    }
  }

  initQuery = async () => {
    await this.setState({
      isLoading: true,
    });

    this.debouncedAutocomplete();
  }

  debouncedAutocomplete = debounce(this.triggerAutocompleteSearch , 300, {
    leading: true,
    trailing: true,
  });

  async triggerAutocompleteSearch() {
    const { fieldType, query } = this.props;
    const { autocomplete } = this.props.store;

    const autocompleteResults = await autocomplete.getAutocomplete({
      query,
      fieldType,
    });

    const queryChanged = query !== this.props.query;
    if (queryChanged) { return; } // handle race condition

    await this.setState({
      autocompleteResults,
      isLoading: false,
    });
  }

  renderLoading() {
    return (
      <BarLoader color="#30b566"/>
    );
  }

  renderNothingFound() {
    const { query } = this.props;

    return (
      <span className="not-found-result">
        <i className="warning-icon fa fa-exclamation-circle"/>
        Nothing found for
        <span className="query"> "{query}"</span>
      </span>
    );
  }

  renderResult() {
    const { onSelect, inputRef } = this.props;
    const { autocompleteResults } = this.state;

    const hasNoResult = autocompleteResults.length === 0;
    if (hasNoResult) {
      return this.renderNothingFound();
    }

    return (
      <KeyboardActiveList
        list={autocompleteResults}
        onSelect={selection => onSelect(selection.value)} // tslint:disable-line:jsx-no-lambda
        ref={inputRef}
      />
    );
  }

  render() {
    const { onCloseDropdown } = this.props;
    const { isLoading } = this.state;

    const autoCompleteContent = isLoading
      ? this.renderLoading()
      : this.renderResult();

    let listenEvent = false;
    setTimeout(() => {
      listenEvent = true;
    }, 300);

    return (
      <div
        className="autocomplete-wrapper"
        onMouseLeave={() => listenEvent && onCloseDropdown()} // tslint:disable-line:jsx-no-lambda
      >
        <HighlightedText matchString={this.props.query}>
          {autoCompleteContent}
        </HighlightedText>
      </div>
    );
  }
}

export default withStore<AutocompleteDropdownProps>(AutocompleteDropdown);
