import React from 'react';

interface FormSubmitButtonProps {
  value: string;
  onClick?() : void;
  imageLeft?: React.ReactElement<any>;
  className?: string;
}

export const FormSubmitButton: React.FC<FormSubmitButtonProps> = (props) => {
  const className = `d-flex border-none rounded-lg bg-transit shadow-signIn mt-8 mb-4 text-white w-full justify-center ${props.className ? props.className : ''}`;
  return (
    <button
      className={className}
      type="submit"
      onClick={props.onClick}
    >
      {props.imageLeft ? props.imageLeft : null}
      <div style={{ padding: 10 }}>
        {props.value}
      </div>
    </button>
  );
};
