import cx from 'classnames';
import React from 'react';
import { PortalWithState } from 'react-portal';

import Button from '@components/Button/Button';

import '../styles/TableCellDropdown.scss';

export interface TableCellDropdownOption {
  value: string;
  onClick(): void;
}

interface TableCellDropdownProps {
  node: HTMLElement;
  options: TableCellDropdownOption[];
  alignRight?: boolean;
}

interface TableCellDropdownState {
  active: boolean;
}

export default class TableCellDropdown extends React.Component<TableCellDropdownProps, TableCellDropdownState> {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  onOpen = () => {
    this.setState({
      active: true,
    });
  }

  onClose = () => {
    this.setState({
      active: false,
    });
  }

  renderDropdownOption = (option) => {
    const { onClick, value } = option;

    return (
      <Button
        className="table-cell-dropdown-button option darkgreen"
        key={value}
        value={value}
        onClick={onClick}
      />
    );
  }

  getPortalStyle = (): any => {
    const { alignRight, options, node } = this.props;

    const portalStyle: any = {};
    const nodeRect = node && node.getBoundingClientRect();
    if (nodeRect) {
      portalStyle.top = nodeRect.top + nodeRect.height + window.scrollY;
      portalStyle.height = nodeRect.height * options.length - 1;

      if (alignRight) {
        portalStyle.right = window.innerWidth - (nodeRect.left + nodeRect.width);
      } else {
        portalStyle.left = nodeRect.left + window.scrollX;
      }
    }

    return portalStyle;
  }

  render() {
    const { options } = this.props;
    const { active } = this.state;

    const classname = cx('table-cell-dropdown-button grey', {
      active,
    });

    const portalStyle: any = this.getPortalStyle();
    const optionHeight = portalStyle.height && portalStyle.height / options.length;

    return (
      <PortalWithState node={document.body} onOpen={this.onOpen} onClose={this.onClose}>
        {({ openPortal, closePortal, portal }) => (
          <div
            onMouseLeave={closePortal}
          >
            <Button
              className={classname}
              key="Rerun"
              value="Rerun"
              onClick={openPortal}
              imageRight={(<i className="fa fa-caret-down" aria-hidden="true"/>)}
            />
            {portal(
              <div
                className="table-cell-dropdown-wrapper"
                style={portalStyle}
              >
                {options.map((option, index) => {
                  return (
                    <div key={index} style={{ height: optionHeight }}>
                      {this.renderDropdownOption(option)}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PortalWithState>
    );
  }
}
