import React from 'react';
import { observer } from 'mobx-react';

import store from '@stores';
import { AutocompleteStore } from '@stores/autocomplete';
import Tool from '@stores/pages/gtfs/jsonExtensionPage/lib/Tool';
import Step from '@stores/pages/gtfs/jsonExtensionPage/lib/Step';
import { withStore } from '@stores/withStore';

import HighlightedText from '@components/HightlightedText/HighlightedText';

import SideMenuItem from './SideMenuItem';
import './SideMenu.scss';

import Input from './SearchInput';

interface SideMenuProps {
  store: typeof store;
}

interface SideMenuState {
  filterString: string;
}

class SideMenu extends React.Component<SideMenuProps, SideMenuState> {

  state = {
    filterString: '',
  };
  public handleSideMenuToolClick = (tool) => {
    const { jsonExtension } = this.props.store.pages.gtfs;
    jsonExtension.toggleTool(tool);
  }

  // --------------------------- //
  //     RENDERING FUNCTIONS     //
  // --------------------------- //

  private renderToolListItem = (tool) => {
    const { jsonExtension } = this.props.store.pages.gtfs;
    const isActive = jsonExtension.isToolSelected(tool.name);

    return (
        <SideMenuItem
          key={tool.name}
          active={isActive}
          tool={tool}
          onClick={this.handleSideMenuToolClick}
        />
    );
  }

  private renderStep = (step: Step) => {
    const { filterString } = this.state;

    const filteredTools = filterString && AutocompleteStore.searchStaticIndex(step.tools, {
      searchValue: this.state.filterString,
      normalizeRow: (tool: Tool) => `${tool.title}${step.name}`,
    });

    if (filterString && filteredTools.length === 0) {
      return null;
    }

    return (
      <div className="tool-step-selector" key={step.name}>
      <div className="tool-step-title" >
        <span className="tool-step-icon-wrapper">
          <span className="tool-step-icon"> {step.name.slice(0, 3).toUpperCase()} </span>
        </span>
        <span className="tool-step-name"> {step.name} </span>
      </div>

      <div className="tool-step-list">
        <HighlightedText matchString={filterString}>
          {(filteredTools || step.tools).map(this.renderToolListItem)}
        </HighlightedText>
      </div>
    </div>
    );
  }

  onFilterChange = (filterString: string) => {
    this.setState({ filterString });
  }

  private renderSearchToolInput = () => {

    return (
      <div className="filter-tools-input">
        <Input onChange={this.onFilterChange} />
      </div>
    );
  }

  public render() {
    const { jsonExtension: { steps } } = this.props.store.pages.gtfs;

    return (
      <div className="side-menu-content tools-list col col-xl-4">
        <div className="menu-title">tools</div>
        {this.renderSearchToolInput()}
        {steps.map(this.renderStep)}
      </div>
    );
  }
}

export default withStore(observer(SideMenu));
