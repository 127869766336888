import React from 'react';
import cx from 'classnames';

import './RadioButtonList.scss';

interface RadioButtonListProps<T> {
  defaultActive: T;
  active?: T;
  options: T[];
  className?: string;
  itemClassName?: string;
  ref?: React.RefObject<RadioButtonList<string>>;
  renderItem: (item: T) => Element;
  onChange(option: T): void;
}

interface RadioButtonListState<T> {
  active: T;
}

/*
 * This class acts as a list of radio buttons responding to click events. It will call the passed onChange function when
 * a click occurs. By default, the radio buttons will appear as text. However, passing the prop, itemClassName, allows
 * to design the radio buttons as one wishes.
 */
class RadioButtonList<T> extends React.PureComponent<RadioButtonListProps<T>, RadioButtonListState<T>> {

  constructor(props) {
    super(props);
    this.state = {
      active: props.defaultActive,
    };
  }

  componentDidUpdate(prevProps): void {
    if (prevProps.active !== this.props.active) {
      this.setState({
        active: this.props.active,
      });
    }
  }

  clearRadioButtonList = () => {
    this.setState({
      active: undefined,
    });
  }

  resetRadioButtonList = () => {
    const { defaultActive } = this.props;

    this.setState({
      active: defaultActive,
    });
  }

  createHandleClickOnOptionItem = async (index: number) => {
    const { options } = this.props;
    const { active } = this.state;

    let selectedOptionName = options[index];
    if (selectedOptionName === active) {
      selectedOptionName = undefined; // clicking on active radio button deactivates it
    }

    await this.setState({
      active: selectedOptionName,
    });

    this.props.onChange(selectedOptionName);
  }

  renderItem = (option: T, index: number) => {
    const { active } = this.state;
    const { itemClassName, renderItem } = this.props;

    const optionName = String(option);
    const isActive = active === option;

    const classNames = cx('radio-button-list-item', {
      [itemClassName]: itemClassName,
      'active': isActive,
    });

    const item = renderItem ? renderItem(option) : optionName;

    return (
      <li
        key={optionName}
        className={classNames}
        onClick={() => this.createHandleClickOnOptionItem(index)} // tslint:disable-line jsx-no-lambda
        /* tslint:disable-next-line:jsx-no-lambda */
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            this.createHandleClickOnOptionItem(index);
          }
        }}
      >
        <span tabIndex={0}>{item}</span>
      </li>
    );
  }

  render() {
    const { options, className } = this.props;

    const wrapperClassName = cx('radio-button-list', {
      [className]: className,
    });

    return (
      <ul className={wrapperClassName}>
        {options.map(this.renderItem)}
      </ul>
    );
  }
}

export default RadioButtonList;
