import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import store from '@stores';
import { withStore } from '@stores/withStore';
import { FeedInfo } from '@stores/transit';
import keyboardEvents from './lib/keyboardEvents';
import { WINDOW_EVENTS } from '@constants';
import operationAndNotices from '@stores/operationAndNotices';

import NoticeUI from './components/NoticeUI';
import OperationsUI from './components/OperationsUI';

import './styles/FeedOperation.scss';
import OperationsUIHeader from '@pages/global/OperationsPage/components/OperationsUIHeader';

interface OperationsPageProps {
  store: typeof store;
  feed?: FeedInfo;
  environment?: string;
}

interface OperationsPageState {
  isFullScreen: boolean;
  isHeaderDropdownOpen: boolean;
}

@observer class OperationsPage extends React.Component<OperationsPageProps, OperationsPageState> {
  constructor(props) {
    super(props);

    this.state = {
      isFullScreen: false,
      isHeaderDropdownOpen: false,
    };
  }

  async componentDidMount() {
    const { handleKeyboardArrowPressed } = keyboardEvents;

    await operationAndNotices.init(this.props.store.pages.gtfs.getCurrentFeed());
    window.addEventListener(WINDOW_EVENTS.KEYDOWN, handleKeyboardArrowPressed);
  }

  componentWillUnmount() {
    const { handleKeyboardArrowPressed } = keyboardEvents;
    window.removeEventListener(WINDOW_EVENTS.KEYDOWN, handleKeyboardArrowPressed);
  }

  handleToggleFullscreen = () => {
    this.setState({
      isFullScreen: !this.state.isFullScreen,
    });
  }

  handleHeaderDropdown = (isOpen) => {
    this.setState({
      isHeaderDropdownOpen: isOpen,
    });
  }

  renderOperations() {
    const { isFullScreen, isHeaderDropdownOpen } = this.state;

    const marginTop = isHeaderDropdownOpen
      ? this.props.feed ? 170 : 230
      : 0;
    const style = {
      marginTop,
      height: isFullScreen ? '100%' : 420,
    };

    return (
      <OperationsUI style={style}/>
    );
  }

  render() {
    const { isFullScreen } = this.state;

    const isFullScreenClass = cx({
      'is-full-screen': isFullScreen,
    });

    return (
      <div className="operation-full-height">
        <div className={`operation-container ${isFullScreenClass}`}>
          <OperationsUIHeader
            feed={this.props.feed}
            toggleFullScreen={this.handleToggleFullscreen}
            onDropdown={this.handleHeaderDropdown}
          />
          {this.renderOperations()}
        </div>
        <NoticeUI/>
    </div>
    );
  }
}

export default withStore<OperationsPageProps>(OperationsPage);
