import React from 'react';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import cx from 'classnames';

import { WINDOW_EVENTS } from '@constants';
import { FeedInfo } from '@stores/transit';
import HighlightedText from '@components/HightlightedText/HighlightedText';
import KeyboardActiveList from '@components/KeyboardActiveList/KeyboardActiveList';
import SearchResultItem from '@components/SearchFeedsDropdown/components/SearchResultItem';

import './styles/SearchFeedsDropdown.scss';

interface SearchFeedsDropdownProps {
  searchFeed(searchInput: string, limit?: number): Promise<FeedInfo[]>;
  onSelect(feed: FeedInfo): void;
  overflowSearchResults?: boolean;
}

interface SearchFeedsDropdownState {
  currentSearchResults: FeedInfo[];
  currentAgencySearch: string;
}

class SearchFeedsDropdown extends React.Component<SearchFeedsDropdownProps, SearchFeedsDropdownState> {

  constructor(props) {
    super(props);
    this.state = {
      currentAgencySearch: '',
      currentSearchResults: [],
    };
  }

  keyboardRef: React.RefObject<KeyboardActiveList> = React.createRef();
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  componentDidMount() {
    window.addEventListener(WINDOW_EVENTS.KEYDOWN, this.forceWriteOnInputWindowListener);
    setImmediate(this.forceWriteOnInputWindowListener);
  }

  forceWriteOnInputWindowListener = (event) => {
    this.inputRef.current.focus();
    this.keyboardRef.current.handleKeyPress(event);
  }

  componentWillUnmount() {
    this.debouncedGetFeedSuggestions.cancel();
    window.removeEventListener(WINDOW_EVENTS.KEYDOWN, this.forceWriteOnInputWindowListener);
  }

  debouncedGetFeedSuggestions = debounce(async(searchValue) => {
    const limit = searchValue.length > 3 ? Infinity : 10;
    const searchResult = await this.props.searchFeed(searchValue, limit);
    this.setState({
      currentAgencySearch: searchValue,
      currentSearchResults: searchResult,
    });
  }, 500, {
    leading: true,
    trailing: true,
    maxWait: 300,
  });

  // used a "proxy function" for debouncedGetFeedSuggestion because
  // react is throwing the event object to the garbage collector
  // before it can be used by the debounced function
  getFeedSuggestions = (event) => {
    const searchValue = event.target.value;

    if (searchValue === '') {
      this.setState({
        currentAgencySearch: searchValue,
        currentSearchResults: [],
      });
      return;
    }

    this.debouncedGetFeedSuggestions(searchValue);
  }

  renderSearchResultItem = (feed: FeedInfo, index: number, isActive: boolean) => {
    const randomKey = Math.random().toString();
    return (
      <SearchResultItem
        isActive={isActive}
        key={randomKey}
        feed={feed}
        onSelect={this.props.onSelect}
        onEnter={() => { this.keyboardRef.current.handleOnEnter(index); }} // tslint:disable-line jsx-no-lambda
      />
    );
  }

  render() {
    const overflowSearchResults = `search-agency-results ${cx({
      'special-width': this.props.overflowSearchResults,
    })}`;

    return (
      <div>
        <div className="search-transit-input">

          <input
            ref={this.inputRef}
            type="text"
            autoFocus={true}
            placeholder="ex: STM, STL ..."
            autoComplete="off"
            onChange={this.getFeedSuggestions}
          />

          <div className="search-input-icon">
            <i className="fa fa-search" aria-hidden="true"/>
          </div>
        </div>

        <div className={overflowSearchResults}>
          <HighlightedText matchString={this.state.currentAgencySearch}>
            <KeyboardActiveList
             list={this.state.currentSearchResults}
             renderItem={this.renderSearchResultItem}
             onSelect={this.props.onSelect}
             ref={this.keyboardRef}
            />
          </HighlightedText>
        </div>
      </div>
    );
  }
}

export default observer(SearchFeedsDropdown);
