import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { observer } from 'mobx-react';
import { BarLoader } from 'react-spinners';
import {
  AutoSizer,
  Column,
  defaultTableRowRenderer,
  defaultTableHeaderRowRenderer,
  SortDirection,
  Table,
} from 'react-virtualized';

import { OPERATION_STEP_STATUSES, STEPS } from '@constants';
import { withStore } from '@stores/withStore';
import store from '@stores';
import Button from '@components/Button/Button';
import TableCellDropdown, { TableCellDropdownOption } from '@components/Table/components/TableCellDropdown';
import { hasNoticeFilter } from '@stores/operationAndNotices/lib/filterHelpers';
import Tooltip from '@components/Tooltip/Tooltip';
import sharedFunctions from '../lib/sharedFunctions';

import '../styles/FeedOperation.scss';
import 'react-virtualized/styles.css';
import '@components/Tooltip/Tooltip.scss';

interface OperationsUIProps {
  store?: typeof store;
  style?: any;
}

interface OperationsUIState {}

@observer class OperationsUI extends React.Component<OperationsUIProps, OperationsUIState> {

  componentDidMount() {
    const { operationAndNotices } = this.props.store;
    operationAndNotices.setIsOperationsUiMounted(true);
  }

  componentWillUnmount() {
    const { operationAndNotices } = this.props.store;
    operationAndNotices.setIsOperationsUiMounted(false);
  }

  tableRef: any;
  rowRefs: any[] = [];
  scrollLeft: number = 0;
  columnDataByDataKey = {
    'feed': {
      label: 'Feed',
      width: 115,
      tooltip: undefined,
    },
    'stars': {
      label: 'Stars',
      width: 115,
      tooltip: undefined,
    },
    'started_by': {
      label: 'Started By',
      width: 115,
      tooltip: undefined,
    },
    'started_at': {
      label: 'Started At',
      width: 115,
      tooltip: undefined,
    },
    'GET': {
      label: 'GET',
      width: 60,
      tooltip: 'Get the GTFS from source',
    },
    'PRE': {
      label: 'PRE',
      width: 60,
      tooltip: 'Process the GTFS',
    },
    'TRA': {
      label: 'TRA',
      width: 60,
      tooltip: 'Transcode the GTFS to tGTFS',
    },
    'EXT': {
      label: 'EXT',
      width: 60,
      tooltip: 'Extend elements of the tGTFS',
    },
    'ENV': {
      label: 'ENV',
      width: 60,
      tooltip: 'Build geojson envelopes for all routes of a feed',
    },
    'FLX': {
      label: 'FLX',
      width: 60,
      tooltip: 'Extract the GTFS-Flex data',
    },
    'CLE': {
      label: 'CLE',
      width: 60,
      tooltip: 'Clean the tGTFS so that info looks good in app',
    },
    'ENH': {
      label: 'ENH',
      width: 60,
      tooltip: 'Enhance tGTFS data',
    },
    'CTN': {
      label: 'CTN',
      width: 60,
      tooltip: 'Convert blocks to trip-to-trip transfers',
    },
    'VAL': {
      label: 'VAL',
      width: 60,
      tooltip: 'Validate the information in the tGTFS',
    },
    'COM': {
      label: 'COM',
      width: 60,
      tooltip: 'Compress the tGTFS into a bGTFS to save space',
    },
    'UPF': {
      label: 'UPF',
      width: 60,
      tooltip: 'Upload bGTFS to the server',
    },
    'BSM': {
      label: 'BSM',
      width: 60,
      tooltip: 'Update the bosm files covered by this feed envelope',
    },
    'MAP': {
      label: 'MAP',
      width: 60,
      tooltip: 'Build TransitMaps',
    },
    'UPM': {
      label: 'UPM',
      width: 60,
      tooltip: 'Upload the TransitMaps',
    },
    'FIC': {
      label: 'FIC',
      width: 60,
      tooltip: 'Feed integrity check which runs nightly, by default',
    },
    'log': {
      label: 'Log',
      width: 50,
      tooltip: undefined,
    },
    'action': {
      label: 'Action',
      width: 85,
      tooltip: undefined,
    },
  };

  handleKillOperation = async (operationId: number, shouldKillAll?: boolean) => {
    const { api, auth, operationAndNotices } = this.props.store;
    const { operationByOperationId } = operationAndNotices;

    const killOperationConfig = {
      operationIds: [],
      user: auth.user.displayName,
    };

    if (!shouldKillAll) {
      killOperationConfig.operationIds = [operationId];
    } else {
      operationByOperationId.forEach((operation, operationId) => {
        if (operation.isRunning) {
          killOperationConfig.operationIds.push(operationId);
        }
      });
    }

    if (!killOperationConfig.operationIds.length) {
      return false;
    }

    return api.feeds.killOperation(killOperationConfig);
  }

  redirectToFeedOperations = ({ index }): { index: number} => {
    const { router, operationAndNotices } = this.props.store;
    const { operationByOperationId, operationIds } = operationAndNotices;

    const operationId = operationIds[index];
    const { feed } = operationByOperationId.get(operationId);

    return router.redirect(`/feed/${feed.feed_id}/operations/${operationId}`);
  }

  toggleSortDirection = ({ sortBy }) => {
    const { setSortOptions, sortDirection } = this.props.store.operationAndNotices;

    const isAscending = sortDirection === SortDirection.ASC;
    const newSortDirection = isAscending // if ASC
      ? SortDirection.DESC // change it to DESC
      : SortDirection.ASC; // if DESC, change to ASC

    setSortOptions(sortBy, newSortDirection);
  }

  rowGetter = ({ index }: { index: number }) => {
    const { operationByOperationId, operationIds } = this.props.store.operationAndNotices;

    const operationId = operationIds[index];
    const { operationSteps, feed, started_by, started_at } = operationByOperationId.get(operationId);

    const firstStep = operationSteps[0];

    return {
      feed,
      firstStep,
      operationId,
      operationSteps,
      started_by,
      started_at: moment(started_at).format('MM-DD HH:mm'),
      in_beta: feed.in_beta,
      stars: this.getStars(feed),
    };
  }

  getStars = (feed) => {
    if (feed.in_beta !== 0) {
      return 'β';
    }

    let starsString = '';
    Array.from({ length: 5 }).forEach((star, index) => {
      if (index < parseInt(feed.stars, 10)) {
        starsString += '★';
      } else {
        starsString += '☆';
      }
    });

    return starsString;
  }

  rowClassName = ({ index }: { index: number }) => {
    const { isOperationsContainerInFocus, operationInFocus, operationIds } = this.props.store.operationAndNotices;

    if (!operationIds.length || !operationInFocus) {
      return;
    }

    const operationId = operationIds[index];
    const operationIdInFocus = operationInFocus.operation_id;

    const inFocus = cx({
      focus: (operationId === operationIdInFocus),
      selected: (operationId === operationIdInFocus) && isOperationsContainerInFocus,
    });

    return inFocus;
  }

  renderOperationStep = ({ dataKey, rowIndex }) => {
    const { operationByOperationId, operationIds } = this.props.store.operationAndNotices;

    const operationId = operationIds[rowIndex];
    const { operationSteps } = operationByOperationId.get(operationId);

    const operationStep = operationSteps.find(operationStep => operationStep.step === dataKey);
    let className = 'table-cell operation-step ';
    if (operationStep) {
      const operationStepStatus = operationStep.status;
      className += operationStepStatus;
    } else {
      className += 'is-hidden';
    }

    return (
      <div
        className={className}
        key={dataKey}
        role="progressbar"
      >
        {dataKey}
      </div>
    );
  }

  renderCell = ({ rowData, dataKey }) => {
    return (
      <div className="table-cell">
        {rowData[dataKey]}
      </div>
    );
  }

  renderFeed = (cell) => {
    return (
      <div className="table-cell">
        {`${cell.rowData[cell.dataKey].feed_code} (${cell.rowData[cell.dataKey].feed_id})`}
      </div>
    );
  }

  renderKillAll = () => {
    const { operationByOperationId } = this.props.store.operationAndNotices;

    for (const [, operation] of operationByOperationId) {
      if (operation.isRunning) {
        return (
          <Button
            className="operation-container-button red"
            key="Kill All"
            value="Kill All"
            onClick={() => this.handleKillOperation(null, true)} // tslint:disable-line:jsx-no-lambda
          />
        );
      }
    }

    return null;
  }

  renderActionButton = ({ rowIndex }: { rowIndex: number }) => {
    const { operationByOperationId, operationIds } = this.props.store.operationAndNotices;

    const operationId = operationIds[rowIndex];
    const operation = operationByOperationId.get(operationId);

    if (operation.isRunning) {
      return (
        <Button
          className="operation-container-button orange"
          key="Kill"
          value="Kill"
          onClick={() => this.handleKillOperation(operationId)} // tslint:disable-line:jsx-no-lambda
        />
      );
    }

    const operationFailed = operation.operationSteps.some((operationStep) => {
      return operationStep.status === OPERATION_STEP_STATUSES.FAILED
        || operationStep.status === OPERATION_STEP_STATUSES.FAILED_BEFORE
        || operationStep.status === OPERATION_STEP_STATUSES.REJECTED
        || operationStep.status === OPERATION_STEP_STATUSES.REJECTED_BEFORE
        || operationStep.status === OPERATION_STEP_STATUSES.KILLED
        || operationStep.status === OPERATION_STEP_STATUSES.KILLED_BEFORE;
    });

    if (!operationFailed) {
      return (
        <Button
          className="operation-container-button darkgreen"
          key="Rerun All"
          value="Rerun All"
          onClick={() => sharedFunctions.handleRerunOperation(operationId)} // tslint:disable-line:jsx-no-lambda
        />
      );
    }

    const rerunFailed: TableCellDropdownOption = {
      onClick: () => sharedFunctions.handleRerunOperation(operationId, true),
      value: 'Rerun Failed',
    };
    const rerunAll: TableCellDropdownOption = {
      onClick: () => sharedFunctions.handleRerunOperation(operationId),
      value: 'Rerun All',
    };

    const actionButtonId = `actionButton-${rowIndex}`;
    const actionButtonNode: HTMLElement = document && document.getElementById(actionButtonId);

    return (
      <div id={actionButtonId}>
        <TableCellDropdown
          alignRight={true}
          node={actionButtonNode}
          options={[rerunFailed, rerunAll]}
        />
      </div>
    );
  }

  openOperationLog = (operation) => {
    const { router } = this.props.store;

    const feedId = operation.operationSteps[0].feed_id;

    router.push(`/feed/${feedId}/operations/${operation.operation_id}/log`);
  }

  renderOperationLog = ({ rowIndex }: { rowIndex: number }) => {
    const { operationByOperationId, operationIds } = this.props.store.operationAndNotices;

    const operationId = operationIds[rowIndex];
    const operation = operationByOperationId.get(operationId);

    return (
      <Button
        className="operation-container-button"
        value="Log"
        onClick={() => this.openOperationLog(operation)} // tslint:disable-line:jsx-no-lambda
        key="Log"
      />
    );
  }

  renderColumnHeader = ({ dataKey, label, tooltip }) => {
    const { sortBy, sortDirection } = this.props.store.operationAndNotices;

    const isColumnSortedActive = sortBy === dataKey;
    const isAscending = sortDirection === SortDirection.ASC;
    const caretByDirection = <i className={`fa caret fa-caret-${ isAscending ? 'up' : 'down'}`} />;

    if (tooltip) {
      return (
    <div className="table-cell tooltip-container" tabIndex={-1}>
      <Tooltip
        message={tooltip}
        placement="top-end"
        /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
        render={() => (
          <div className="hover-position">
            {label}
          </div>
        )}
      />
      <span className="banner-column-header-caret">{isColumnSortedActive && caretByDirection}</span>
    </div>
      );
    }

    return (
    <div className="table-cell" tabIndex={-1}>
            {label}
      <span className="banner-column-header-caret">{isColumnSortedActive && caretByDirection}</span>
    </div>
    );

  }

  renderNoRows = () => {
    const {
      filtersObject,
      isLoadingNotices,
      operationInFocus,
      validOperationByOperationId,
    } = this.props.store.operationAndNotices;

    let message = '';
    if (!validOperationByOperationId.size) {
      message = 'No operations exist';
    } else if (isLoadingNotices && hasNoticeFilter(filtersObject)) {
      message = `A filter removed all the operations before the notices finished loading.
Please wait until they're loaded for the filter to properly work.`;
    } else if (!operationInFocus) {
      message = 'Filter removed all operations';
    }

    return (
      <div className="operation-container-text">
        {message}
      </div>
    );
  }

  getDataKey = (index: number) => {
    const dataKeys = Object.keys(this.columnDataByDataKey);
    return dataKeys[index];
  }

  renderRow = (rowIndex, row, rowRendererFunction) => {
    const { width: totalWidth } = row.style;

    const operationSteps = Object.keys(STEPS);
    const fixedWidth = row.columns.reduce((accumulator, column, index) => {
      const dataKey = this.getDataKey(index);
      if (operationSteps.includes(dataKey)) {
        return accumulator;
      }

      const { width } = this.columnDataByDataKey[dataKey];
      return accumulator + width;
    }, 0);

    const scrollableWidth = totalWidth - fixedWidth;
    const rowId = `row-${rowIndex}`;

    row.columns = [
      row.columns[0],
      row.columns[1],
      row.columns[2],
      row.columns[3],
      (
        <div
          id={rowId}
          key={rowId}
          className="d-flex"
          ref={(ref) => {
            if (ref) {
              this.rowRefs[rowIndex] = ref;
              this.rowRefs[rowIndex].scrollLeft = this.scrollLeft;
            }
          }}
          style={{
            alignItems: 'center',
            height: '100%',
            marginRight: '10px',
            overflow: 'scroll',
            width: scrollableWidth,
          }}
          onScroll={(event) => { //  tslint:disable-line:jsx-no-lambda
            this.scrollLeft = event.currentTarget.scrollLeft;
            for (const ref of this.rowRefs) {
              if (ref) {
                ref.scrollLeft = event.currentTarget.scrollLeft;
              }
            }
          }}
        >
          {row.columns[4]}
          {row.columns[5]}
          {row.columns[6]}
          {row.columns[7]}
          {row.columns[8]}
          {row.columns[9]}
          {row.columns[10]}
          {row.columns[11]}
          {row.columns[12]}
          {row.columns[13]}
          {row.columns[14]}
          {row.columns[15]}
          {row.columns[16]}
          {row.columns[17]}
          {row.columns[18]}
          {row.columns[19]}
        </div>
      ),
      row.columns[20],
      row.columns[21],
    ];

    return rowRendererFunction(row);
  }

  headerRowRenderer = (row) => {
    return this.renderRow(0, row, defaultTableHeaderRowRenderer);
  }

  rowRenderer = (row) => {
    return this.renderRow(row.index + 1, row, defaultTableRowRenderer);
  }

  getTable = (): JSX.Element => {
    const {
      changeOperationInFocus,
      isLoadingOperations,
      operationIds,
      operationInFocusIndex,
      sortBy,
    } = this.props.store.operationAndNotices;

    if (isLoadingOperations) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          <BarLoader color="#30b566"/>
        </div>
      );
    }

    const operationsRowHeight = 35;

    const tableProps = (height: number, width: number) => {
      const newHeight = height - operationsRowHeight;

      return {
        operationInFocusIndex, // used to force an update every time this changes value
        sortBy,
        width,
        headerRowRenderer: this.headerRowRenderer,
        height: newHeight > 0 ? newHeight : 0,
        headerHeight: operationsRowHeight,
        noRowsRenderer: this.renderNoRows,
        onRowClick: ({ index }) => changeOperationInFocus(index),
        onRowDoubleClick: this.redirectToFeedOperations,
        ref: ref => this.tableRef = ref,
        rowClassName: this.rowClassName,
        rowCount: operationIds.length,
        rowGetter: this.rowGetter,
        rowHeight: operationsRowHeight,
        rowRenderer: this.rowRenderer,
        sort: this.toggleSortDirection,
      };
    };

    const tableColumns = Object.entries(this.columnDataByDataKey).map(([dataKey, { label, width, tooltip }]) => {
      switch (dataKey) {
        case 'feed':
          return (
            <Column
              key={dataKey}
              dataKey={dataKey}
              width={width}
              label={label}
              headerRenderer={this.renderColumnHeader}
              cellRenderer={this.renderFeed}
            />
          );
        case 'started_by':
        case 'started_at':
        case 'stars':
          return (
            <Column
              key={dataKey}
              dataKey={dataKey}
              width={width}
              label={label}
              headerRenderer={this.renderColumnHeader}
              cellRenderer={this.renderCell}
            />
          );
        case 'action':
          return (
            <Column
              key={dataKey}
              dataKey={dataKey}
              width={width}
              label={''}
              headerRenderer={this.renderKillAll}
              cellRenderer={this.renderActionButton}
            />
          );
        case 'log':
          return (
            <Column
              key={dataKey}
              dataKey={dataKey}
              width={width}
              label={''}
              headerRenderer={this.renderColumnHeader}
              cellRenderer={this.renderOperationLog}
            />
          );
        default:
          return (
            <Column
              className="operation-step-wrapper"
              key={dataKey}
              dataKey={dataKey}
              width={width}
              label={label}
              /* tslint:disable-next-line:jsx-no-lambda */
              headerRenderer={() => this.renderColumnHeader({ dataKey, label, tooltip })}
              cellRenderer={this.renderOperationStep}
            />
          );
      }
    });

    return (
      <AutoSizer
        className="operation-container-table"
        children={({ height, width }) => { // tslint:disable jsx-no-multiline-js jsx-no-lambda
          return (
            <Table {...tableProps(height, width)} children={tableColumns}/>
          );
        }}
      />
    );
  }

  render() {
    const { style } = this.props;

    return (
      <div style={style}>
        {this.getTable()}
      </div>
    );
  }
}

export default withStore<OperationsUIProps>(OperationsUI);
