import { FeedInfo } from '@stores/transit';

export default function getFeedStarsString(feed: FeedInfo) {
  if (feed.in_beta !== 0) {
    return 'β';
  }

  let starsString = '';
  Array.from({ length: 5 }).forEach((star, index) => {
    if (index < feed.stars) {
      starsString += '★';
    } else {
      starsString += '☆';
    }
  });

  return starsString;
}
