import CacheManager from '@utils/CacheManager';
import { staticIndex } from './staticIndex';

type AutocompleteQuery = {
  feedId?: string;
  fieldType: string;
  query: string;
  limit?: number;
};

type CompareStringOptions = {
  searchNormalizedCharacters?: boolean;
  searchCaseInsensitive?: boolean;
};

type SearchOption = {
  getValueToMatch(row: any): string,
  searchValues: string[];
};

type QueryIndexOptions = {
  limit?: number;
  compareStringOptions?: CompareStringOptions,
  getRelevanceOfRow?(row: any, searchTerm: string): number;
  searchOptions: SearchOption[]
};

export class AutocompleteStore {
  public cachedSearches = new CacheManager();

  isAutocompleteAvailable(fieldType: string) {
    return Object.keys(staticIndex).includes(fieldType);
  }

  getAutocomplete = async (options: AutocompleteQuery) => {
    const { fieldType, query, limit = 50 } = options;

    // is already cached
    const cacheKey = JSON.stringify(options);
    const isQueryAlreadyCached = this.cachedSearches.has(cacheKey);
    if (isQueryAlreadyCached) {
      const { data } = this.cachedSearches.get(cacheKey);
      return data;
    }

    let results = undefined;

    // is new StaticSearch
    const staticIndexOfField = staticIndex[fieldType];
    const isStaticSearch = !!staticIndexOfField;
    if (isStaticSearch) {
      results = await AutocompleteStore.searchStaticIndex(staticIndexOfField , {
        limit,
        searchValue: query,
      });

      const timestamp = Date.now();
      this.cachedSearches.set(cacheKey, timestamp, results);
    }

    return results;
  }

  /**
    * Will lowercase & remove remove accents
    * @param str
    */
  static normalizeAndLowercaseString(str : string, compareStringOptions? : CompareStringOptions) {
    if (!compareStringOptions) {
      return str;
    }

    let normalizedString = String(str);

    if (compareStringOptions.searchNormalizedCharacters) {
      normalizedString = normalizedString.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    }

    if (compareStringOptions.searchCaseInsensitive) {
      normalizedString = normalizedString.toLowerCase();
    }

    return normalizedString;
  }

  private static createMatchingAlgorithmWithConfig(options: any) {
    const {
      additionalCondition,
      compareStringOptions,
      getValueToMatch,
      searchValues,
      shouldIntersectSearchValues = true,
    } = options;

    if (!searchValues) {
      return undefined;
    }

    const currentCompareStringOptions = compareStringOptions || {
      searchNormalizedCharacters: true,
      searchCaseInsensitive: true,
    };

    const createIsMatch = getValueToMatch => (row: any) => {
      // TODO: - add ability to memoize already matched "valueToMatch"
      // some datasets have redundant string, this can lead to increased performance.
      // Because we won't need to normalize redundant strings, and test if the redundant string matches.

      if (additionalCondition && !additionalCondition(row)) {
        return false;
      }

      const valueToMatch = getValueToMatch(row);
      const leftString = AutocompleteStore.normalizeAndLowercaseString(valueToMatch, currentCompareStringOptions);

      function isRowMatchingGlobalSearchTerm(searchTerm: string) {
        const rightString = AutocompleteStore.normalizeAndLowercaseString(searchTerm, currentCompareStringOptions);
        const isRegex = /(^\/).{0,}(\/([(gimsy)]*)$)/.test(searchTerm);

        const isMatchingSearchTerm = isRegex
          ? (new RegExp(searchTerm.slice(1, searchTerm.length - 1))).test(valueToMatch)
          : leftString.includes(rightString, 0);

        return isMatchingSearchTerm;
      }

      return shouldIntersectSearchValues
        ? searchValues.every(isRowMatchingGlobalSearchTerm)
        : searchValues.some(isRowMatchingGlobalSearchTerm);
    };

    return Array.isArray(getValueToMatch)
      ? row => getValueToMatch.some(getValue => createIsMatch(getValue)(row))
      : createIsMatch(getValueToMatch);
  }

  static createFilterFunction = (options: QueryIndexOptions | any) => {
    const {
      searchOptions = [],
      shouldIntersectSearchOptions = true,
      compareStringOptions,
      searchValue = '',
      normalizeRow,
      additionalCondition,
    } = options;

    if (searchValue) {
      searchOptions.push({
        searchValues: searchValue.split(' '),
        shouldIntersectSearchValues: true,
        normalizeRow: normalizeRow || (row => Object.values(row).join(' ')),
        compareStringOptions: {
          searchNormalizedCharacters: true,
          searchCaseInsensitive: true,
        },
      });
    }

    const matchers = searchOptions.map((searchOption) => {
      const getValueToMatch = searchOption.getValueToMatch
         || searchOption.matchAnyValues
         || searchOption.normalizeRow;

      return AutocompleteStore.createMatchingAlgorithmWithConfig({
        getValueToMatch,
        additionalCondition,
        compareStringOptions: searchOption.compareStringOptions || compareStringOptions,
        searchValues: searchOption.searchValues,
        shouldIntersectSearchValues: searchOption.shouldIntersectSearchValues,
      });
    });

    const isRowMatching = (row) => {
      if (matchers.length === 1) {
        return matchers[0](row);
      }

      return shouldIntersectSearchOptions
       ? matchers.every(matchingFunction => matchingFunction(row))
       : matchers.some(matchingFunction => matchingFunction(row));
    };

    return isRowMatching;
  }

  static searchStaticIndex = (staticIndex: Iterable<any>, options: QueryIndexOptions | any) => {
    const { limit = Infinity, getRelevanceOfRow, map, searchOptions = [], searchValue } = options;

    const isRowMatching = AutocompleteStore.createFilterFunction(options);
    const allSearchTerms = searchValue
      ? searchValue.split(' ')
      : searchOptions.reduce((acc, searchOption) => {
        acc.push(...searchOption.searchValues);
        return acc;
      }, []);

    const results = [];
    let rowIndex = 0;
    for (const row of staticIndex) {
      if (results.length === limit) { break; }

      if (isRowMatching(row)) {
        // if the row is matched, give it an optional relevance score.
        // the relevance score will be used to sort matches DESC.
        const relevance = getRelevanceOfRow && allSearchTerms.reduce((relevanceScore, searchTerm) => {
          return relevanceScore + (getRelevanceOfRow(row, searchTerm) || 0);
        }, 0) || 0;

        results.push({ row, relevance, rowIndex });
      }

      rowIndex += 1;
    }

    results.sort((left, right) => {
      return right.relevance - left.relevance;
    });

    return results.map(result => map
      ? map(result.row, result.rowIndex)
      : result.row
    );
  }
}

export default new AutocompleteStore();
