import React from 'react';

interface DispatcherQueuePageState {}

interface DispatcherQueuePageProps {}

class DispatcherQueuePage extends React.Component<DispatcherQueuePageProps, DispatcherQueuePageState> {
  renderIFrame() {
    return (
      <iframe
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        src={`${process.env.UPDATE_STATIC_DATA_DISPATCHER_URL}/queue`}
      />
    );
  }

  render() {
    return (
      <div style={{ width: '100%', height: '95vh' }}>
        {this.renderIFrame()}
      </div>
    );
  }
}

export default DispatcherQueuePage;
