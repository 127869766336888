import React from 'react';
import GenericToggleButton from '@components/GenericToggleButton/GenericToggleButton';
import '../GenericToggleButton/ToggleButtons.scss';

interface AccessibleButtonProps {
  active?: boolean;
  className?: string;
  hide?: boolean;
  onChange?(bool: boolean): void;
}

export default class AccessibleButton extends React.Component<AccessibleButtonProps> {

  renderAccessibleButton() {
    return (
      <img src="/images/wheelchair-enabled.svg" alt="" />
    );
  }

  renderInaccessibleButton() {
    return (
      <img src="/images/wheelchair-disabled.svg" alt="" />
    );
  }

  render() {

    return (
      <GenericToggleButton
        className={this.props.className}
        onChange={this.props.onChange}
        active={this.props.active}
        hide={this.props.hide}
        children={(isTrue) => { /* tslint:disable-line:jsx-no-lambda */
          if (isTrue) {
            return this.renderAccessibleButton();
          }

          return this.renderInaccessibleButton();
        }}
      />
    );
  }
}
