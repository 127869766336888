export enum WINDOW_EVENTS {
  KEYDOWN = 'keydown',
  CLICK = 'click',
}

export enum KEYBOARD_KEYS {
  ARROW_UP = 38,
  ARROW_DOWN = 40,
  ENTER = 13,
  BACKSPACE = 8,
  ESCAPE = 27,
}
