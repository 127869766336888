import React from 'react';
import cx from 'classnames';

export interface FloatingLabelInputProps {
  required?: boolean;
  name: string;
  autocomplete?: boolean | string;
  type?: 'password' | 'text' | 'textarea';
  placeholder: string;
  value: string;
  onBlur?(): void;
  onFocus?(): void;
  onChange(value: string) : void;
  style?: any;
}

import './FloatingLabelInput.scss';

interface SharedProps extends FloatingLabelInputProps{
  isFloatingPlaceholder: boolean;
  isFocused: boolean;
  setIsFocused(value: boolean): void;
  isAutoFill: boolean;
  setIsAutoFill(value: boolean): void;
}

interface InputProps extends SharedProps {
  inputRef: React.Ref<HTMLInputElement>;
}

interface TextAreaProps extends SharedProps {
  inputRef: React.Ref<HTMLTextAreaElement>;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    isFloatingPlaceholder,
    inputRef,
    value,
    setIsAutoFill,
    setIsFocused,
    isAutoFill,
  } = props;
  return (
    <input
      ref={inputRef}
      required={props.required}
      className={cx('FloatingLabelInput', {
        'not-active': !isFloatingPlaceholder,
        'active': isFloatingPlaceholder,
      })}
      name={props.name}
      autoComplete={
        typeof props.autocomplete === 'string' ? props.autocomplete
          : !!props.autocomplete ? 'on' : 'off'
      }
      type={props.type ?? 'text'}
      placeholder={''}
      value={value}
      onFocus={() => {
        setIsFocused(true);

        if (props.onFocus) {
          props.onFocus();
        }
      }}
      onBlur={() => {
        setIsFocused(false);

        if (props.onBlur) {
          props.onBlur();
        }
      }}
      onAnimationStart={(event) => {
        // the browser can prefill the form with values
        // it can create an issue with the form displaying the floating labels on top of the values
        // we fix this by using css to detect if the input is autofilled.
        // We have to do this because browsers don't always dispatch a change event when they autofill the input
        if (!isAutoFill && event.animationName === 'onAutoFillStart') {
          setIsAutoFill(true);
        } else if (isAutoFill && event.animationName === 'onAutoFillCancel') {
          setIsAutoFill(false);
        }
      }}
      onChange={(event) => {
        const { value } = event.target;
        props.onChange(value);
      }}
    />
  );
};

const TextArea: React.FC<TextAreaProps> = (props) => {
  const {
    isFloatingPlaceholder,
    inputRef,
    value,
    setIsAutoFill,
    setIsFocused,
    isAutoFill,
  } = props;

  const [rows, setRows] = React.useState(2);

  return (
    <textarea
      rows={rows}
      ref={inputRef}
      required={props.required}
      className={cx('FloatingLabelInput', {
        'not-active': !isFloatingPlaceholder,
        'active': isFloatingPlaceholder,
        'textarea': true,
      })}
      name={props.name}
      autoComplete={
        typeof props.autocomplete === 'string' ? props.autocomplete
          : !!props.autocomplete ? 'on' : 'off'
      }
      placeholder={''}
      value={value}
      onFocus={() => {
        setIsFocused(true);

        if (props.onFocus) {
          props.onFocus();
        }
      }}
      onBlur={() => {
        setIsFocused(false);

        if (props.onBlur) {
          props.onBlur();
        }
      }}
      onAnimationStart={(event) => {
        // the browser can prefill the form with values
        // it can create an issue with the form displaying the floating labels on top of the values
        // we fix this by using css to detect if the input is autofilled.
        // We have to do this because browsers don't always dispatch a change event when they autofill the input
        if (!isAutoFill && event.animationName === 'onAutoFillStart') {
          setIsAutoFill(true);
        } else if (isAutoFill && event.animationName === 'onAutoFillCancel') {
          setIsAutoFill(false);
        }
      }}
      onChange={(event) => {
        const { value } = event.target;
        const lineBreak = /\n/g;
        const numberOfLineBreaks = ((value || '').match(lineBreak) || []).length;
        if (numberOfLineBreaks > 1) {
          setRows(numberOfLineBreaks + 1);
        }
        props.onChange(value);
      }}
    />
  );
};

export const FloatingLabelInput : React.FC<FloatingLabelInputProps> = (props) => {
  const value = props.value || '';
  const [isFocused, setIsFocused] = React.useState(false);
  const [isAutoFill, setIsAutoFill] = React.useState(false);

  const isFloatingPlaceholder = isAutoFill || isFocused || value.length > 0;

  const innerProps = {
    isFocused,
    setIsFocused,
    isAutoFill,
    setIsAutoFill,
    isFloatingPlaceholder,
  };

  return (
    <div className="FloatingLabelInputWrapper" style={props.style}>
      <style>
      {`
        @keyframes onAutoFillStart {}
        @keyframes onAutoFillCancel {}

        .FloatingLabelInput:-webkit-autofill {
          animation-name: onAutoFillStart;
        }

        .FloatingLabelInput:not(:-webkit-autofill) {
          animation-name: onAutoFillCancel;
        }
      `}
      </style>
      {
        props.type === 'textarea'
          ? (
              <TextArea
                inputRef={React.createRef<HTMLTextAreaElement>()}
                {...innerProps}
                {...props}
              />
            )
          : (
              <Input
                inputRef={React.createRef<HTMLInputElement>()}
                {...innerProps}
                {...props}
              />
            )
      }
      <label
        className={cx('FloatingLabel', { 'active': isFloatingPlaceholder })}
        htmlFor={props.name}
      >
        {props.placeholder}
      </label>
      {props.children}
    </div>
  );
};
