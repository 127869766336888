import cx from 'classnames';
import { getIn } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { HTML_TAGS } from '@constants';
import { FormInputProps, FormSectionProps } from './CommonFormInterfaces';
import Tooltip from '@components/Tooltip/Tooltip';
import { formContext } from './CommonFormWrapper';

import './CommonForm.scss';

export class FormSection extends React.Component<FormSectionProps> {
  render() {
    const tooltipComponent = this.props.tooltip
      ? <Tooltip {...this.props.tooltip} />
      : null;

    return (
      <div className="common-form-section">
        <div className="d-inline-flex">
          <label>{this.props.title}</label>
          {tooltipComponent}
        </div>
        {this.props.children}
      </div>
    );
  }
}

export class FormRow extends React.Component<any> {
  render() {
    return (
      <div className="common-form-row">
        {this.props.children}
      </div>
    );
  }
}

interface FormColumnProps {
  style?: any;
}

export class FormColumn extends React.Component<FormColumnProps> {
  render() {
    const { children, style } = this.props;

    const columnCount = React.Children.count(children);
    const columnClassName = cx('common-form-column', {
      'inline-column': columnCount > 1,
    });

    return (
      <div className={columnClassName} style={style}>
        {children}
      </div>
    );
  }
}

export const FormInput = (props: FormInputProps) => { // tslint:disable-line variable-name
  const formik = React.useContext(formContext);
  const handleChange = (key: string, value: any) => {
    formik.setFieldTouched(key);

    if (props.onChange) {
      return props.onChange(key, value);
    }

    formik.setFieldValue(key, value);
  };

  const renderDropdownItems = (itemLabelByItemValue: object) => {
    return Object.entries(itemLabelByItemValue).map(([value, label], index) => {
      return (
        <option value={value} key={`dropdownItem-${index}`}>{label}</option>
      );
    });
  };

  const renderDatalistItems = (datalistItems: string[]) => {
    return datalistItems.map((item, index) => {
      return (
        <option value={item} key={`droplistItem-${index}`}>{item}</option>
      );
    });
  };

  const renderError = (isTouched, errors) => {
    if (isTouched && errors) {
      return (
        <div className="yup-error">
          {errors}
        </div>
      );
    }

    return null;
  };

  const getMaxCharacterCount = () => {
    const { field } = props;

    let maxCharacterCount: number = undefined;
    let validationSchemaOfField: any;

    try {
      validationSchemaOfField = Yup.reach(formik.validation, field);
    } catch {
      return maxCharacterCount;
    }

    validationSchemaOfField.tests && validationSchemaOfField.tests.forEach((test) => {
      if (test.OPTIONS.params && test.OPTIONS.params.max) {
        maxCharacterCount = test.OPTIONS.params.max;
      }
    });

    return maxCharacterCount;
  };

  const renderInput = (value) => {
    const {
      checkboxLabel,
      disabled,
      field,
      render,
      formType,
      inputList,
      inputType,
      itemLabelByItemValue,
      readOnly,
      datalistItems,
      defaultValue,
      onClick,
    } = props;

    // counts if the input has been touched OR if form has been submitted
    const isTouched = getIn(formik.touched, field) || formik.submitCount > 0;
    const errors = getIn(formik.errors, field);
    const optionalClassName = cx({
      'error': isTouched && errors,
    });

    switch (formType) {
      case HTML_TAGS.DATALIST:
        return (
          <div className={`common-form-input dropdown ${optionalClassName}`}>
            {renderError(isTouched, errors)}
            <input
              name={field}
              value={value}
              onClick={onClick}
              list={HTML_TAGS.DATALIST}
              onChange={event => handleChange(event.target.name, event.target.value)}  // tslint:disable-line:jsx-no-lambda max-line-length
              onBlur={event => formik.setFieldTouched(event.target.name)} // tslint:disable-line:jsx-no-lambda
            />
            <datalist id={HTML_TAGS.DATALIST}>
              {renderDatalistItems(datalistItems)}
            </datalist>
          </div>
        );
      case HTML_TAGS.DROPDOWN:
        const dropdownValue = String(value) ? String(value) : defaultValue;
        return (
          <div className={`common-form-input dropdown ${optionalClassName}`}>
            {renderError(isTouched, errors)}
            <select
              name={field}
              value={dropdownValue}
              onClick={onClick}
              onChange={event => handleChange(event.target.name, event.target.value)}  // tslint:disable-line:jsx-no-lambda max-line-length
              onBlur={event => formik.setFieldTouched(event.target.name)} // tslint:disable-line:jsx-no-lambda
            >
              {renderDropdownItems(itemLabelByItemValue)}
            </select>
          </div>
        );
      case HTML_TAGS.COLOR:
        const colorValue = String(value) ? String(value) : defaultValue;
        return (
          <div className={`common-form-input ${optionalClassName}`}>
            {renderError(isTouched, errors)}
            <input
              name={field}
              type={inputType}
              value={colorValue}
              list={inputList}
              readOnly={readOnly}
              onClick={onClick}
              onChange={event => handleChange(event.target.name, event.target.value)}  // tslint:disable-line:jsx-no-lambda max-line-length
              onBlur={event => formik.setFieldTouched(event.target.name)} // tslint:disable-line:jsx-no-lambda
            />
            <div>
              <datalist id="transit-colors">
                <option value="#FFA900" /> /* Major Service Alert */
                <option value="#E51304" /> /* Emergency Situation */
                <option value="#14446b" /> /* Dark blue */
                <option value="#1C7F49" /> /* Dark green */
                <option value="#91A5B0" /> /* Light grey */
                <option value="#30b566" /> /* Transit green */
                <option value="#FFFFFF" /> /* White */
                <option value="#000000" /> /* Black */
              </datalist>
            </div>
          </div>
        );
      case HTML_TAGS.CHECKBOX:
        return (
          <div className={`common-form-input checkbox ${optionalClassName}`}>
            {renderError(isTouched, errors)}
            <input
              type="checkbox"
              name={field}
              onClick={onClick}
              checked={value}
              onChange={event => handleChange(event.target.name, event.target.checked)}  // tslint:disable-line:jsx-no-lambda max-line-length
            />
            <div className="checkbox-label">
              {checkboxLabel}
            </div>
          </div>
        );
      case 'custom':
        return render(value, props, isTouched, errors, optionalClassName);
      default:
        const maxCharacterCount = getMaxCharacterCount();

        const characterCount = maxCharacterCount
          ? (
              <div className="char-count">
                {value.length}/{maxCharacterCount}
              </div>
            )
          : null;

        return (
          <div className={`common-form-input ${optionalClassName}`}>
            {renderError(isTouched, errors)}
            <input
              disabled={disabled}
              name={field}
              type={inputType}
              value={value}
              onClick={onClick}
              list={inputList}
              readOnly={readOnly}
              onChange={event => handleChange(event.target.name, event.target.value)}  // tslint:disable-line:jsx-no-lambda max-line-length
              onBlur={event => formik.setFieldTouched(event.target.name)} // tslint:disable-line:jsx-no-lambda
            />
            {characterCount}
          </div>
        );
    }
  };

  const isValueDefined = props.value === null || props.value === undefined;
  const value = isValueDefined ? '' : props.value;

  const labelComponent = props.label
    ? <label className={`common-form-label ${props.formType}`}>{props.label}</label>
    : null;
  const tooltipComponent = props.tooltip
    ? <Tooltip {...props.tooltip} />
    : null;

  return (
    <React.Fragment>
      <div className="d-inline-flex">
        {labelComponent}
        {tooltipComponent}
      </div>
      {renderInput(value)}
    </React.Fragment>
  );
};
