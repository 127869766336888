import React from 'react';
import { observer } from 'mobx-react';
import { NavLink, withRouter } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import auth from '@stores/auth';

interface ActiveNavListProps {
  routes: any[];
  match: any;
  location: any;
  className: any;
  history: any;
  linkClassName: any;
  forceShow?: boolean;
}

class ActiveNavList extends React.Component<ActiveNavListProps> {

  state = {
    pathname: undefined,
  };

  buildRoutesArray() {
    const linkClassName = `nav-item ${this.props.linkClassName || ''}`;

    return this.props.routes.reduce((result, route) => {
      if (!route.linkName) { return result; }
      if (route.checkShouldDisplay && !route.checkShouldDisplay(auth.user)) { return result; }

      const isRouteActive = this.isRouteActive(route) ? 'active' : '';
      const specialClassName = route.linkClassName || '';

      result.push(
        <NavLink
          key={route.linkName}
          className={`${linkClassName} ${specialClassName} ${isRouteActive}`}
          exact={route.exact === true}
          onClick={(event) => { // tslint:disable-line:jsx-no-lambda
            if (route.onClick) {
              event.preventDefault();
              route.onClick();
            }
          }}
          to={route.path.replace(this.props.match.path, this.props.match.url)}
        >
          {route.linkName}
        </NavLink>
      );
      return result;
    }, []);
  }

  historyListener: any;
  componentDidMount = () => {
    const { history } = this.props;
    this.historyListener = history.listen(({ pathname }) => {
      this.setState({ pathname });
    });
  }

  componentWillUnmount = () => {
    this.historyListener();
  }

  isRouteActive(route) {
    return matchRoutes([route], this.props.location.pathname).length;
  }

  render() {
    const routes = this.buildRoutesArray();

    if (routes.length === 1 && !this.props.forceShow) {
      return null;
    }

    return (
        <div className={this.props.className}>
          {routes}
        </div>
    );
  }
}

export default withRouter(observer(ActiveNavList));
