import { observer } from 'mobx-react';
import React from 'react';
import { cloneDeep, isEqual, set, unset } from 'lodash';
import { CSSTransition } from 'react-transition-group';
import { SortDirection, SortDirectionType } from 'react-virtualized';
import { toJS } from 'mobx';
import Switch from 'rc-switch';

import store from '@stores';
import { KEYBOARD_KEYS, OPERATION_KEY_WORDS } from '@constants';
import TagsInput from '@components/TagsInput/TagsInput';
import RadioButtonList from '@components/RadioButtonList/RadioButtonList';
import OperationRunner from '@pages/global/OperationsPage/components/OperationRunner';
import { withStore } from '@stores/withStore';
import { FeedInfo } from '@stores/transit';

import '../styles/OperationUIHeader.scss';
import operationAndNotices from '@stores/operationAndNotices';

interface OperationsUIHeaderProps {
  store?: typeof store;
  feed?: FeedInfo;
  toggleFullScreen(): void;
  onDropdown(isOpen): void;
}

interface OperationsUIHeaderState {
  isDropdownVisible: boolean;
  isFullScreen: boolean;
  searchObject: object;
}

@observer class OperationsUIHeader extends React.Component<OperationsUIHeaderProps, OperationsUIHeaderState> {
  state = {
    isDropdownVisible: false,
    isFullScreen: false,
    searchObject: {
      general: '',
      byFeedIds: '',
      byFeedCodes: '',
    },
  };

  getNumberOfFilters = () => {
    const { filtersObject } = this.props.store.operationAndNotices;

    let numberOfFilters = 0;

    Object.entries(filtersObject[OPERATION_KEY_WORDS.OPERATION].specific).forEach(([filterKey, filterValue]: [string, string[] | boolean]) => { // tslint:disable-line:max-line-length
     /*
      * Initially the notification badge had a count of 1 by default.
      * This is due to the filter byIsRunning being applied by default.
      * TA's found that weird, the issue what mitigated by creating "Active" and "Completed" presets
      * and by not counting byIsRunning and byIsFinished during the count.
      */
      if (filterKey === 'byIsRunning' || filterKey === 'byIsFinished') {
        return numberOfFilters;
      }

      if (Array.isArray(filterValue)) {
        numberOfFilters += filterValue.length;
      } else {
        numberOfFilters += 1;
      }
    });

    Object.values(filtersObject[OPERATION_KEY_WORDS.NOTICE].specific).forEach((value: string[] | boolean) => {
      if (Array.isArray(value)) {
        numberOfFilters += value.length;
      } else {
        numberOfFilters += 1;
      }
    });

    return numberOfFilters;
  }

  toggleDropdownVisibility = (event: React.MouseEvent) => {
    const { isDropdownVisible } = this.state;
    this.updateDropdownVisibility(!isDropdownVisible);
  }

  updateDropdownVisibility = (visibility: boolean) => {
    this.setState({
      isDropdownVisible: visibility,
    });
  }

  handleUpdateFiltersObject = (value, path) => {
    const { filtersObject, setFiltersObject } = this.props.store.operationAndNotices;
    const filtersObjectClone = cloneDeep(filtersObject);

    if (value === false || Array.isArray(value) && !value.length && !path.includes('general')) {
      unset(filtersObjectClone, path);
    } else {
      set(filtersObjectClone, path, value);
    }

    setFiltersObject(filtersObjectClone);
    if (path.includes('general')) {
      this.handleSearchInputChange('general', '');
    } else if (path.includes('byFeedIds')) {
      this.handleSearchInputChange('byFeedIds', '');
    } else {
      this.handleSearchInputChange('byFeedCodes', '');
    }
  }

  handleSearchInputChange = (filterKey, value) => {
    this.setState({ searchObject: { ...this.state.searchObject, [filterKey]: value } });
  }

  handleResetFiltersObject = () => {
    const { filtersObject, setFiltersObject, setSortOptions } = this.props.store.operationAndNotices;
    const clonedFiltersObject = cloneDeep(filtersObject);

    clonedFiltersObject.general = [];
    clonedFiltersObject.notice.specific = {};
    clonedFiltersObject.operation.specific = {};

    setFiltersObject(clonedFiltersObject);
    setSortOptions();
    this.defaultFilteringOptions.current.clearRadioButtonList();
  }

  renderDropdownRow = ([filterKey, { component, text, type }], index) => {
    const { filtersObject } = this.props.store.operationAndNotices;
    const { searchObject } = this.state;

    if (filterKey === 'byIsRunning' || filterKey === 'byIsFinished') {
      return null;
    }

    switch (component) {
      case 'switch':
        return (
          <div
            className={'operation-search-dropdown-option-wrapper'}
            tabIndex={1}
            key={filterKey}
            onClick={() => this.handleUpdateFiltersObject( // tslint:disable-line jsx-no-lambda jsx-no-multiline-js
                 !filtersObject[type].specific[filterKey],
                 `${type}.specific.${filterKey}`,
               )}
          >
              <Switch
                id={`dropdown-switch-${index}`}
                checked={filtersObject[type].specific[filterKey]}
              />
              {text}
          </div>
        );
      case 'input':
        return (
          <div
            className={'operation-search-dropdown-option-wrapper'}
            key={filterKey}
          >
            <TagsInput
              addOnBlur={false}
              alwaysShowPlaceholder={false}
              className={'operation-search-input'}
              onChange={(event) => {
                this.handleUpdateFiltersObject( // tslint:disable-line jsx-no-lambda jsx-no-multiline-js max-line-length
                event,
                `${type}.specific.${filterKey}`,
              );
              }}
              onChangeInput={(event) => this.handleSearchInputChange(filterKey, event)} // tslint:disable-line
              placeholder={`${text}`}
              value={toJS(filtersObject[type].specific[filterKey]) || []}
            />
          </div>
        );
    }
  }

  renderDropdown = () => {
    operationAndNotices.setIsOperationsContainerInFocus(false);
    operationAndNotices.setIsNoticesContainerInFocus(false);
    const { getFilterConfig } = this.props.store.operationAndNotices;
    const filtersConfig = getFilterConfig();

    let listenEvent = false;
    setTimeout(() => {
      listenEvent = true;
    }, 300);

    return (
      <div
        className={'operation-search-dropdown'}
        onMouseLeave={() => listenEvent && this.updateDropdownVisibility(false)} // tslint:disable-line jsx-no-lambda max-line-length
      >
        {Object.entries(filtersConfig).map(this.renderDropdownRow)}
      </div>
    );
  }

  renderNotificationBadge() {
    const numberOfFilters = this.getNumberOfFilters();

    return !numberOfFilters
      ? null
      : (
        <div className="notification-badge">
          {numberOfFilters}
        </div>
      );
  }

  renderSearchIcon() {
    const { filtersObject } = this.props.store.operationAndNotices;

    let numberOfFilters = this.getNumberOfFilters();
    numberOfFilters += filtersObject.general.length;

    if (numberOfFilters) {
      return (
        <div
          className="search-input-icon hover red"
          onClick={this.handleResetFiltersObject}
          /* tslint:disable-next-line:jsx-no-lambda ter-arrow-parens */
          onKeyUp={(event) => this.handleEnterKeyPress(event, this.handleResetFiltersObject)}
          tabIndex={0}
        >
          <i className="fa fa-times" aria-hidden="true"/>
        </div>
      );
    }

    return (
      <div className="search-input-icon" tabIndex={0}>
        <i className="fa fa-search" aria-hidden="true"/>
      </div>
    );
  }

  handleEnterKeyPress = (event, func) => {
    if (event.keyCode === KEYBOARD_KEYS.ENTER) {
      event.stopPropagation();
      return func(event);
    }
  }

  renderOptionsIcon() {
    return (
      <div
        id="options-icon"
        className="search-input-icon hover"
        style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
        onClick={this.toggleDropdownVisibility}
        /* tslint:disable-next-line:jsx-no-lambda ter-arrow-parens */
        onKeyUp={(event) => this.handleEnterKeyPress(event, this.toggleDropdownVisibility)}
        tabIndex={0}
      >
        <i className="fa fa-cog" aria-hidden="true"/>
      </div>
    );
  }

  renderSearchInput() {
    const { filtersObject } = this.props.store.operationAndNotices;
    const { searchObject } = this.state;

    return (
      <div className="select-feed-tags-list">
        <div className="search-input-wrapper">
          <TagsInput
            addOnBlur={false}
            alwaysShowPlaceholder={false}
            className={'search-input'}
            onChange={(event) => this.handleUpdateFiltersObject(event, 'general')} // tslint:disable-line
            onChangeInput={(input, value) => this.handleSearchInputChange('general', input)} // tslint:disable-line
            placeholder={'search operations and notices'}
            value={toJS(filtersObject.general)}
            inputValue={searchObject.general}
          />

          {this.renderOptionsIcon()}
          {this.renderSearchIcon()}
        </div>
      </div>

    );
  }

  renderOptionsMenu() {
    const { isDropdownVisible } = this.state;

    return (
      <CSSTransition
        in={isDropdownVisible}
        timeout={200}
        classNames="operation-search-dropdown"
      >
        {this.renderDropdown()}
      </CSSTransition>
    );
  }

  handleToggleFullscreen = async () => {
    await this.setState({
      isFullScreen: !this.state.isFullScreen,
    });

    this.props.toggleFullScreen();
  }

  renderToggleFullScreen() {
    const { isFullScreen } = this.state;

    return (
      <div
        className="operation-container-button square grey font20"
        onClick={this.handleToggleFullscreen}
        /* tslint:disable-next-line:jsx-no-lambda ter-arrow-parens */
        onKeyUp={(event) => this.handleEnterKeyPress(event, this.handleToggleFullscreen)}
        tabIndex={0}
      >
        <i className={`fa ${ isFullScreen ? 'fa-minus' : 'fa-arrows-alt' }`}/>
      </div>
    );
  }

  renderSearchMenu() {
    return (
      <div className="position-relative operation-search-wrapper">
        {this.renderSearchInput()}

        {this.renderNotificationBadge()}

        {this.renderOptionsMenu()}
      </div>
    );
  }

  getDefaultFilter = (filter) => {
    const { feed } = this.props;
    const { filtersObject, resetStore } = this.props.store.operationAndNotices;

    const clonedFiltersObject = cloneDeep(filtersObject);

    switch (filter) {
      case 'Static':
        clonedFiltersObject[OPERATION_KEY_WORDS.NOTICE].specific = {
          byIsInfo: true,
          byIsResolved: true,
          byIsStatic: true,
        };

        clonedFiltersObject[OPERATION_KEY_WORDS.OPERATION].specific = {
          byIsFinished: true,
          byIsEmpty: true,
        };

        break;

      case 'Analyst':
        clonedFiltersObject[OPERATION_KEY_WORDS.NOTICE].specific = {
          byIsInfo: true,
          byIsResolved: true,
          byIsTransitAnalyst: true,
        };

        clonedFiltersObject[OPERATION_KEY_WORDS.OPERATION].specific = {
          byIsFinished: true,
          byIsEmpty: true,
        };

        break;

      case 'Realtime':
        clonedFiltersObject[OPERATION_KEY_WORDS.NOTICE].specific = {
          byIsInfo: true,
          byIsResolved: true,
          byIsRealtime: true,
        };

        clonedFiltersObject[OPERATION_KEY_WORDS.OPERATION].specific = {
          byIsFinished: true,
          byIsEmpty: true,
        };

        break;

      case 'Active':
        clonedFiltersObject[OPERATION_KEY_WORDS.NOTICE].specific = {};
        clonedFiltersObject[OPERATION_KEY_WORDS.OPERATION].specific = {
          byIsRunning: true,
        };

        break;

      case 'Completed':
        clonedFiltersObject[OPERATION_KEY_WORDS.NOTICE].specific = {};
        clonedFiltersObject[OPERATION_KEY_WORDS.OPERATION].specific = {
          byIsFinished: true,
        };

        break;

      default:
        resetStore();
        return this.props.store.operationAndNotices.filtersObject;
    }

    if (feed) {
      clonedFiltersObject[OPERATION_KEY_WORDS.OPERATION].default = { 'byFeedIds': [feed.feed_id] };
    }

    return clonedFiltersObject;
  }

  handleFilterOptionsChange = (selectedFilteringOptions) => {
    const { setFiltersObject, setSortOptions } = this.props.store.operationAndNotices;

    let sortBy: string = 'stars';
    const sortDirection: SortDirectionType = SortDirection.DESC;

    if (selectedFilteringOptions === 'Active' || selectedFilteringOptions === 'Completed') {
      sortBy = 'started_at';
    }

    const clonedFiltersObject = this.getDefaultFilter(selectedFilteringOptions);
    setFiltersObject(clonedFiltersObject);
    setSortOptions(sortBy, sortDirection);
  }

  getActive(presetSearches) {
    const { filtersObject } = this.props.store.operationAndNotices;

    return presetSearches.find((presetSearch) => {
      return isEqual(filtersObject, this.getDefaultFilter(presetSearch));
    });
  }

  defaultFilteringOptions: React.RefObject<RadioButtonList<string>> = React.createRef();
  renderDefaultFilteringOptions() {
    const presetSearches = ['Active', 'Completed', 'Analyst', 'Static', 'Realtime'];
    const renderItem = item => item.toUpperCase();

    const active = this.getActive(presetSearches);

    return (
      <RadioButtonList<string>
        className="above"
        defaultActive={active}
        active={active}
        options={presetSearches}
        onChange={this.handleFilterOptionsChange}
        ref={this.defaultFilteringOptions}
        renderItem={renderItem}
      />
    );
  }

  render() {
    return (
      <div className="operation-container-header-group">
        <OperationRunner feed={this.props.feed} onDropdown={this.props.onDropdown}/>
        <div className="operation-container-header-group stacked">
          {this.renderDefaultFilteringOptions()}
          {this.renderSearchMenu()}
          {this.renderToggleFullScreen()}
        </div>
      </div>
    );
  }
}

export default withStore(OperationsUIHeader);
