import React from 'react';
import { isEqual , debounce } from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import BasicFunctionalities from './common/BasicFunctionalities';
import cx from 'classnames';

import '../styles/common.scss';
import '../styles/DateToolUI.scss';

export interface DateToolUIProps {
  index?: [number, number] | number | string;
  value: undefined | string;
  keyName?: string;
  template?: string;
  onChange(value, index?): void;
}

export interface DateToolUIState {
  isDateValid: boolean;
  value: string;
  touched: boolean;
}

export default class DateToolUI
  extends React.Component<DateToolUIProps, DateToolUIState>
  implements BasicFunctionalities {

  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      value: this.getDefaultValue(),
      isDateValid: false,
    };
  }

  inputRef: HTMLInputElement;
  focus = () => {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  }
  handleClick = this.focus;

  getDefaultValue() {
    const { value } = this.props;
    return value || '';
  }

  isDefined() {
    const { value } = this.state;
    return typeof value !== 'undefined' && String(value).length > 0;
  }

  emitOnChange = debounceInput((value) => {
    if (this.props.value === value) { return; }
    this.props.onChange(value, this.props.index);
  });

  componentWillUnmount() {
    this.emitOnChange.cancel();
  }

  handleChangeOnInput = async (event) => {
    const value = typeof event === 'string'
      ? event
      :event.target.value;

    await this.setState({
      value,
      isDateValid: false,
    });

    const isDateValid = await this.validateDateFormat(value);
    if (isDateValid) {
      await this.emitOnChange(value);
    }
  }

  handleOnFocusOnBlur = async (event: any) => {
    const value = event.target.value;
    await this.validateDateFormat(value);
  }

  private async validateDateFormat(value: string) {
    const isDateValid = moment(value);

    if (!isDateValid) {
      await this.setState({ isDateValid: true });
      return false;
    }

    return true;
  }

  async componentDidUpdate(prevProps) {
    const stateNotSynced = !isEqual(this.state.value, this.props.value);
    const propsChanged = !isEqual(prevProps.value, this.props.value);

    const shouldSyncState = propsChanged && stateNotSynced;
    if (shouldSyncState) {
      await this.setState({ value: this.props.value });
    }
  }

  renderError(isError) {
    if (!isError) {
      return null;
    }

    return (
      <div className="tool-error">
        Date is required
      </div>
    );
  }

  render() {
    const { keyName, template } = this.props;
    const placeholder = `${keyName} (${template})`;
    const isError = !this.isDefined() && this.state.touched;
    const classNames = cx('string-tool', 'regex-tool', {
      'is-defined': !!this.state.value,
      'error': isError,
    });

    const CustomInput = React.forwardRef((props: any, ref: any) => ( // tslint:disable-line variable-name max-line-length
      <input
        ref={ref}
        type="text"
        value={props.value}
        onClick={props.onClick}
        onChange={this.handleChangeOnInput}
        onFocus={(event) => { // tslint:disable-line:jsx-no-lambda
          props.onClick(event);
          this.handleOnFocusOnBlur(event);
        }}
        onBlur={this.handleOnFocusOnBlur}
      />
    ));

    const currentDate = this.state.value && moment(this.state.value).valueOf();
    return (
      // tslint:disable-next-line:jsx-no-lambda
      <div className={classNames} onClick={this.focus} onBlur={() => this.setState({ touched: true })}>
        {this.renderError(isError)}
        <div className="placeholder">{placeholder}</div>
        <div className="string-tool-input">
            <DatePicker
              selected={currentDate}
              ref={(ref) => { if (ref) { this.inputRef = ref.input; } }}
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              todayButton={'Current Date'}
              dateFormat="yyyy-MM-dd"
              onChange={(dateTime) => { /* tslint:disable-line:jsx-no-lambda jsx-no-multiline-js */
                const formatedData = moment(dateTime).format(template);
                this.handleChangeOnInput(formatedData);
                this.focus();
              }}
              customInput={<CustomInput />}
            />
        </div>
      </div>
    );
  }
}

function debounceInput(fn) {
  return debounce(fn , 500, {
    leading: true,
    trailing: true,
  });
}
