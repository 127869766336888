import { ROLES } from '@constants';

export interface UserProfile {
  uid: string;
  displayName: string;
  email?: string;
  photoURL?: string;
  githubToken?: string;
  password?:string;
  passwordResetId: string;
  language: string;
  groupId?: string;
  royaleEmailSent?: boolean;
  auth: {
    isAuthorized: boolean,
    feedAccess: string[],
    role: ROLES,
    routerAccess: string[],
  };
}

// WARNING: when updating this JSON template, make sure to also update UserController.validateAuthData
// NOTE: This is not default data. It's only the template JSON that will be used
// to populate the UI for handling the auth object.
export const userAuthTemplate = {
  isAuthorized: false,
  role: 'role',
  feedAccess: ['feed_code'],
  routerAccess: ['router'],
};
