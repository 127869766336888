import React from 'react';
import { SortDirection } from 'react-virtualized';

interface CsvSortedViewProps {
  list: any;
  children(list, sortConfig, toggleSort, sortNotice: string) : any;
}

export default class CsvSortedView extends React.Component<CsvSortedViewProps, any> {
  constructor(props) {
    super(props);

    this.state = {
      sortedRowsMap: undefined,
      sortNotice: undefined,
      sortConfig: {
        sortBy: undefined,
        sortDirection: undefined,
      },
    };
  }

  async componentDidUpdate(prevProps: Readonly<CsvSortedViewProps>) {
    const listChanged = prevProps.list.length !== this.props.list.length;
    if (listChanged) {
      await this.setState({ sortedRowsMap: undefined });
      await this.sort(this.state.sortConfig);
    }
  }

  sort = async ({ sortBy, sortDirection }) => {
    const { list } = this.props;
    const { sortedRowsMap } = this.state;

    await this.setState({
      sortNotice: 'Sorting Table ...',
    });

    const columnIndex = parseInt(sortBy, 10);
    const isListAlreadySorted = sortBy === columnIndex && sortedRowsMap;
    const sortStrategy = isListAlreadySorted
      // if the table is already sorted, then simply reversing the array will provide better performance;
      ? () => sortedRowsMap.reverse()
      : () => {
        const collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: 'base',
        });

        const ordering = (sortDirection === SortDirection.DESC ? -1 : 1);
        const sortFn = (leftRow, rightRow) => ordering * collator.compare(
          list.getCell(leftRow, columnIndex),
          list.getCell(rightRow, columnIndex),
        );

        return Array.from({ length: list.length })
          .map((v, i) => i)
          .sort(sortFn);
      };

    // doing heavy computation "blocks" the rendering.
    // setImmediate is used to make sure that the notice
    // is displayed before starting the computation
    setImmediate(() => {
      const sortedRows = sortStrategy();
      this.setState({
        sortedRowsMap: sortedRows,
        sortNotice: null,
        sortConfig: {
          sortDirection,
          sortBy: columnIndex,
        },
      });
    });
  }

  toggleSort = async ({ sortBy }) => {
    const { sortConfig } = this.state;

    const sortDirection = sortConfig.sortDirection === SortDirection.ASC // if ASC
      ? SortDirection.DESC // change it to DESC
      : SortDirection.ASC; // if DESC, change to ASC

    this.sort({ sortBy, sortDirection });
  }

  render() {
    const { list } = this.props;
    const { sortConfig, sortedRowsMap, sortNotice } = this.state;

    const sortedList = !sortedRowsMap ? list : {
      getRow: rowIndex => list.getRow(sortedRowsMap[rowIndex]),
      headers: list.headers,
      length: list.length,
    };

    return (
      this.props.children(
        sortedList,
        sortConfig,
        this.toggleSort,
        sortNotice,
      )
    );
  }
}
