
export enum OPERATION_STEP_STATUSES {
  COMPLETED = 'completed',
  FAILED = 'failed',
  FAILED_BEFORE = 'failed-before',
  KILLED = 'killed',
  KILLED_BEFORE = 'killed-before',
  REJECTED = 'rejected',
  REJECTED_BEFORE = 'rejected-before',
  STARTED = 'started',
  UNCHANGED = 'unchanged',
  UNCHANGED_BEFORE = 'unchanged-before',
  WAITING = 'waiting',
}
