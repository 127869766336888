import React from 'react';
import { Link } from 'react-router-dom';
import * as i18n from 'react-i18next';

import { Formik, Form, FormikProps } from 'formik';
import { FloatingLabelInput } from '@components/Input/FloatingLabelInput';
import { PasswordInput } from '@components/Input/PasswordInput';
import { FormSubmitButton } from '@components/Input/FormSubmitButton';
import { FullScreenForm } from '@components/Form/FullScreenForm';
import { GithubLogo } from '@components/icons/GithubLogo';
import * as yup from 'yup';

import { withStore } from '@stores/withStore';
import store from '@stores';

import './LoginPage.scss';

interface LoginPageProps {
  store: typeof store;
  location: any;
}

interface EmailPasswordData {
  email: string;
  password: string;
}

export const LoginPage = withStore((props: LoginPageProps) => {
  const { t } = i18n.useTranslation('common');
  const [forceIsLoading, setForceIsLoading] = React.useState(false);

  return (
    <FullScreenForm
      successMessage={''}
      forceIsLoading={forceIsLoading}
      request={async (data: EmailPasswordData) => {
        const { auth } = props.store;
        const credentials = {
          username: data.email,
          password: data.password,
        };

        try {
          const { isLoggedIn } = await auth.loginWith('local', credentials);
          const { router } = props.store;
          const { query } = router.getUrlData();

          if (isLoggedIn) {
            router.redirect(query.redirectTo || '/');
            return;
          }

          return {
            error: t('login.wrong_credentials_error'),
          };
        } catch (err) {
        }

        return {
          error: t('login.error_generic'),
        };
      }}
      renderFooter={() => (
        <FormSubmitButton
          className="bg-github-button"
          imageLeft={GithubLogo()}
          value={t('login.with_github')}
          onClick={async () => {
            const { auth, router } = props.store;
            const { query } = router.getUrlData();

            setForceIsLoading(true);

            try {
              const { error, isLoggedIn } = await auth.loginWith('github');
              if (isLoggedIn) {
                router.redirect(query.redirectTo || '/');
                return;
              }
            } catch (err) {}

            setForceIsLoading(false);
            return {
              error: t('login.error_generic'),
            };
          }}
        />
      )}
      renderForm={onSubmit => (
        <SignInForm
          onSubmit={onSubmit}
          initialData={{
            email: '',
            password: '',
          }}
        />
      )}
    />
  );
});

interface SignInFormProps {
  initialData: EmailPasswordData;
  onSubmit?(formData: EmailPasswordData) : void;
}

const SignInForm : React.FC<SignInFormProps> = (props) => {
  const { t } = i18n.useTranslation('common');

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={(values) => {
        props.onSubmit(values);
      }}
      validationSchema={yup.object({
        email: yup.string().email().required(t('login.email_required')),
        password: yup.string().required(t('login.password_required')),
      })}
    >
      {(formik: FormikProps<EmailPasswordData>) => {
        const errors = Object.keys(formik.errors)
          .filter(fieldWithError => formik.touched[fieldWithError])
          .map(fieldWithErrorAndTouched => formik.errors[fieldWithErrorAndTouched]);

        return (
          <Form
            className="w-full text-left"
          >
            {
              errors.length === 0 ? null : (
                <div className="bg-red bg-opacity-10 rounded-md text-red text-center text-xs font-bold p-6 mb-8">
                  {errors.map(error => (
                    <div>{error}</div>
                  ))}
                </div>
              )
            }
            <FloatingLabelInput
              name="email"
              type="text"
              placeholder={t('login.email')}
              value={formik.values.email}
              onBlur={() => formik.setFieldTouched('email')}
              onFocus={() => formik.setFieldTouched('email', false)}
              onChange={value => formik.setFieldValue('email', value)}
            />
            <PasswordInput
              name="password"
              placeholder={t('login.password')}
              value={formik.values.password}
              onBlur={() => formik.setFieldTouched('password')}
              onFocus={() => formik.setFieldTouched('password', false)}
              onChange={value => formik.setFieldValue('password', value)}
            />
            <FormSubmitButton
              value={t('login.lets_go')}
            />
            <Link
              to="/password/reset"
              className="text-sm text-silvergrey font-bold cursor-pointer"
            >
              {t('login.forgot_password')}
            </Link>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginPage;
