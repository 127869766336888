import React from 'react';
import Switch from 'rc-switch';

import '../styles/BooleanToolUI.scss';
import BasicFunctionalities from './common/BasicFunctionalities';

interface BooleanToolUIProps {
  index?: [number, number] | number | string;
  ref? : string;
  value: boolean;
  onChange(value, index?) : void;
}

interface BooleanToolUIState {
  boolean: boolean;
}

export default class BooleanToolUI
  extends React.PureComponent<BooleanToolUIProps, BooleanToolUIState>
  implements BasicFunctionalities {

  focus() {
    // do nothing
  }

  getDefaultValue() {
    const { value } = this.props;
    return value || false;
  }

  handleClick = (event?: React.SyntheticEvent) => {
    const { value: bool, onChange, index } = this.props;
    event && event.preventDefault();

    onChange(!bool, index);
  }

  render() {
    const bool = this.getDefaultValue();
    return (
      <div className="boolean-tool" onClick={this.handleClick}>
        <Switch checked={bool} />
      </div>
    );
  }
}
