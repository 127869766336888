import { distance, point } from '@turf/turf';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import badWords from '@utils/BadWords';
import store from '@stores';
import { HTML_TAGS } from '@constants';
import Button from '@components/Button/Button';
import LoaderButton from '@components/LoaderButton/LoaderButton';
import { FormSection, FormRow, FormColumn, FormInput } from '@components/Form/CommonForm';
import { StationMetadataInterface, StopInterface } from './PathwayTypes';
import CommonFormWrapper from '@components/Form/CommonFormWrapper';
import MapInput from '@components/MapInput/MapInput';

interface EntranceModalProps {
  entranceData: StopInterface;
  onChange(stationMetadata: StationMetadataInterface): void;
  stationMetadata: StationMetadataInterface;
}

// tslint:disable-next-line:variable-name
export const EntranceModal = React.memo((props: EntranceModalProps) => {
  const { t } = useTranslation('common');

  const handleOnSubmit = (entranceData) => {
    const { onChange, stationMetadata } = props;
    stationMetadata.entranceByEntranceStableId[entranceData.stopStableId] = entranceData;

    Object.values(stationMetadata.pathwayByPathwayId).forEach((pathway) => {
      if (pathway.toStopId === entranceData.stopId) {
        pathway.signpostedAs = entranceData.stopName;
      }
    });

    onChange(stationMetadata);
  };

  function testCoordinate() {
    try {
      const { stopLat: entranceLatitude, stopLon: entranceLongitude } = this.parent;
      const entranceCoordinate = point([entranceLatitude, entranceLongitude]);

      const { stopLat: stationLatitude, stopLon: stationLongitude } = props.stationMetadata;
      const stationCoordinate = point([stationLatitude, stationLongitude]);

      const distanceInMeters = distance(entranceCoordinate, stationCoordinate, { units: 'meters' });
      return distanceInMeters <= 1000;
    } catch (err) {
      return false;
    }
  }

  function testIsProfane() {
    const { stopName: entranceName } = this.parent;
    return !badWords.isProfane(entranceName);
  }

  return (
    <div className="update-entrance-modal">
      <CommonFormWrapper
        formInfo={props.entranceData || {}}
        onSubmit={handleOnSubmit}
        validationSchema={{
          stopCode: Yup.string()
            .max(2, t('pathways.entrance_modal.code.max_error', { max: 2 }))
            .nullable(),
          stopName: Yup.string()
            .test('name', t('pathways.bad_words_error'), testIsProfane)
            .max(50, t('pathways.entrance_modal.name.max_error', { max: 50 }))
            .required(t('pathways.entrance_modal.name.required_error')),
          stopLat: Yup.number()
            .typeError(t('pathways.entrance_modal.latitude.type_error'))
            .test('latitude', t('pathways.entrance_modal.latitude.test_error', { distance: 1000 }), testCoordinate)
            .required(t('pathways.entrance_modal.latitude.required_error')),
          stopLon: Yup.number()
            .typeError(t('pathways.entrance_modal.longitude.type_error'))
            .test('longitude', t('pathways.entrance_modal.longitude.test_error', { distance: 1000 }), testCoordinate)
            .required(t('pathways.entrance_modal.longitude.required_error')),
          wheelchairBoarding: Yup.number()
            .required(t('pathways.entrance_modal.wheelchair_boarding.required_error')),
        }}
      >
        {(formik) => {
          const otherEntrances = Object.values<StopInterface>(props.stationMetadata.entranceByEntranceStableId)
            .flatMap((entrance) => {
              const currentEntrance = entrance.stopStableId === props.entranceData.stopStableId;
              if (currentEntrance) {
                return [];
              }

              return [{
                latitude: entrance.stopLat,
                longitude: entrance.stopLon,
              }];
            });

          return (
            <React.Fragment>
              <FormSection
                title={t('pathways.entrance_modal.title')}
                children={(
                  <React.Fragment>
                    <FormRow >
                      <FormColumn>
                        <FormInput
                          label={t('pathways.entrance_modal.name.label')}
                          field="stopName"
                          value={formik.values.stopName}
                        />
                      </FormColumn>
                      <FormColumn>
                        <FormInput
                          label={t('pathways.entrance_modal.code.label')}
                          field="stopCode"
                          value={formik.values.stopCode}
                          tooltip={{
                            message: t('pathways.entrance_modal.code.tooltip'),
                            placement: 'top-end',
                            render: () => (
                              <div
                                className="question-circle d-flex align-items-center"
                                style={{ marginBottom: '0.7rem' }}
                              >
                                <i className="question-mark fa fa-question-circle"/>
                              </div>
                            ),
                          }}
                        />
                      </FormColumn>
                    </FormRow>
                    <FormRow>
                      <FormColumn>
                        <FormInput
                          field="wheelchairBoarding"
                          formType={HTML_TAGS.CUSTOM}
                          // tslint:disable-next-line:jsx-no-lambda
                          render={(value, props, isTouched, errors, optionalClassName) => {
                            const renderError = (isTouched, errors) => {
                              if (isTouched && errors) {
                                return (
                                  <div className="yup-error">
                                    {errors}
                                  </div>
                                );
                              }

                              return null;
                            };

                            return (
                              <div className={`common-form-input checkbox ${optionalClassName}`}>
                                {renderError(isTouched, errors)}
                                <div className="checkbox-label" style={{ padding: '0 14px' }}>
                                  {t('pathways.entrance_modal.wheelchair_boarding.label')}
                                </div>
                                <input
                                  type="checkbox"
                                  name={props.field}
                                  onClick={props.onClick}
                                  checked={formik.values.wheelchairBoarding === '1'}
                                  onChange={(event) => {
                                    event.target.checked
                                      ? formik.setFieldValue('wheelchairBoarding', '1')
                                      : formik.setFieldValue('wheelchairBoarding', '2');
                                  } }
                                />
                              </div>
                            );
                          }}
                        />
                      </FormColumn>
                    </FormRow>
                  </React.Fragment>
                )}
              />
              <div style={{ padding: 10 }}/>
              <FormSection
                title={t('pathways.entrance_modal.position_section_title')}
                tooltip={{
                  message: t('map.change_location_by_drag'),
                  placement: 'top',
                  render: () => (
                    <div
                      className="question-circle d-flex align-items-center"
                      style={{ marginBottom: '0.7rem' }}
                    >
                      <i className="question-mark fa fa-question-circle"/>
                    </div>
                  ),
                }}
                children={(
                  <FormRow>
                    <div className="entrance-map-wrapper">
                      <div
                        className="location-target"
                        style={{
                          backgroundImage: "url(/images/entrance-icon.png)",
                        }}
                      />
                      <MapInput
                        initialZoomLevel={16}
                        dragToChangeMapCenter={true}
                        latitude={formik.values.stopLat}
                        longitude={formik.values.stopLon}
                        isSatelliteView={false}
                        showCircle={false}
                        markers={otherEntrances}
                        showLocationInput={false}
                        onCenterChange={({ latitude, longitude }) => { // tslint:disable-line
                          formik.setFieldValue('stopLat', latitude);
                          formik.setFieldValue('stopLon', longitude);
                        }}
                      />
                    </div>
                  </FormRow>
                )}
              />

              <div className="save-button-row">
                <Button
                  className="rainygrey"
                  value={t('button.cancel')}
                  onClick={() => store.modals.hideModal()} // tslint:disable-line jsx-no-lambda
                />
                <LoaderButton
                  errorMessage={t('pathways.entrance_modal.save_error')}
                  onClickWithStatus={formik.submitForm}
                  value={t('button.save')}
                />
              </div>
          </React.Fragment>
          );
        }}
      </CommonFormWrapper>;
    </div >
  );
});
