export default class CacheManager implements Storage {
  cache = {};

  /*
  if there is a history on key, replace it only if we have newer
  if the history is empty at key, add a new one :)
  otherwise do nothing
  */
  set(key, timestamp, data) {
    const isEmptyAtKey = !this.cache[key];
    const isNewerAtKey = isEmptyAtKey
      ? false
      : this.cache[key] && this.cache[key].timestamp < timestamp;

    if (isEmptyAtKey || isNewerAtKey) {
      this.setValueAtKey(key, timestamp, data);
    }

    return this.get(key);
  }

  key(index: number) {
    return Object.keys(this.cache)[index] || null;
  }

  get(key) {
    return this.cache[key] || false;
  }

  setItem(key: string, data: any) {
    this.set(key, new Date(), data);
  }

  getItem = this.get;

  has(key): boolean {
    const cachedValue = this.cache[key];
    return typeof cachedValue !== 'undefined';
  }

  removeItem(key) {
    delete this.cache[key];
  }

  get length() {
    return Object.keys(this.cache).length;
  }

  clear() {
    this.cache = {};
  }

  getCache() {
    return this.cache;
  }

  private setValueAtKey(key, timestamp, data) {
    this.cache[key] = { timestamp, data };
  }
}
