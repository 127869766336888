import React from 'react';

import variables from '@styles/variables';

type BezierCurveArray = [number, number, number, number];
type Point = {x: number, y: number};
type BezierCurvePoints = [Point, Point, Point, Point];

interface BezierCurveProps {
  firstPoint: { x: number, y: number};
  lastPoint: { x: number, y: number};
  color: string;
  bezierCurve: BezierCurveArray;
}

interface BezierCurveState {
  height: number;
  width: number;
  isRTL: boolean;
}

class BezierCurve extends React.Component<BezierCurveProps, BezierCurveState> {

  static createSvgLine(curve: BezierCurvePoints) {
    return curve
      .map((point, i) => {
        let str = '';

        if (i === 0) {
          str = `M${str}`; // M for move to
        }

        if (i === 1) {
          str = `C${str}`; // C for cubic bezier curve
        }

        str = `${str}${point.x},${point.y}`;
        return str;
      })
      .join(' ');
  }

  createBezierCurvePoints(width) {
    const { firstPoint, lastPoint, bezierCurve } = this.props;
    const isRTL = lastPoint.x < firstPoint.x;

    const middlePoints = [
      { x: width * bezierCurve[0], y: firstPoint.y * bezierCurve[1] },
      { x: width * bezierCurve[2], y: lastPoint.y * bezierCurve[3] },
    ];

    if (isRTL) {
      middlePoints.reverse();
    }

    const points = [].concat(firstPoint, ...middlePoints, lastPoint) as BezierCurvePoints;
    return points;
  }

  getWidthAndHeight() {
    const { firstPoint, lastPoint } = this.props;
    const width = Math.abs(firstPoint.x - lastPoint.x);
    const height = Math.max(firstPoint.y, lastPoint.y);

    return {
      width,
      height,
    };
  }

  render() {
    const { color } = this.props;
    const { height, width } = this.getWidthAndHeight();

    const points = this.createBezierCurvePoints(width);
    const polyLinePoints = BezierCurve.createSvgLine(points);

    const wrapperStyle = {
      height,
      width,
      transformOrigin: 'center',
    };

    const svgStyle = {
      overflow: 'visible',
    };

    return (
      <div style={wrapperStyle}>
        <svg width={`${width}px`} height={`${height}px`} className="svg-line" style={svgStyle}>
          <path
            d={polyLinePoints}
            style={{ stroke: `${color}`, strokeWidth: 5, fill: 'none', strokeLinejoin: 'round' }}
            className="bezier-curve"
          />
        </svg>
      </div>
    );
  }
}

// tslint:disable-next-line:variable-name
export const AutosizedBezierCurve = (props: { originY: number, targetY: number }) => {
  const [width, setWidth] = React.useState(0);
  const bezierCurvesRef = React.useRef<HTMLDivElement>();

  function updateWidth() {
    const bezierCurveDiv = bezierCurvesRef.current;
    const newWidth = bezierCurveDiv ? bezierCurveDiv.offsetWidth : 0;
    if (newWidth !== width) {
      setWidth(newWidth);
    }
  }

  React.useEffect(() => {
    function handleResize() {
      updateWidth();
    }

    window.addEventListener('resize', handleResize);
    const timeoutId = setTimeout(updateWidth, 500);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div style={{ position:'absolute', width: '100%' }} ref={bezierCurvesRef}>
      <BezierCurve
        firstPoint={{ x: 0, y: props.originY }}
        lastPoint={{ x: width, y: props.targetY }}
        bezierCurve={[2 / 3, 1, 1 / 3, 1]}
        color={variables.transitsilvergrey}
      />
    </div>
  );
};

export default BezierCurve;
