import { isEqual } from 'lodash';
import React from 'react';

import { AutocompleteStore } from '@stores/autocomplete';

interface CsvFilterViewProps {
  list: any;
  listLength: number;
  searchConfig: any;
  children(list, isCellHightlighted, filterNotice: string) : any;
  updateRowCount(rowCount: number): void;
}

export default class CsvFilterView extends React.Component<CsvFilterViewProps, any> {

  constructor(props) {
    super(props);

    this.state = {
      filteredRowsMap: undefined,
      searchOptions: undefined,
      filterNotice: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    const filterConfigChanged = !isEqual(prevProps.searchConfig, this.props.searchConfig);

    /*
     * Passing props.list.length as a prop because when calling prevProps.list.length or this.props.list.length,
     * the result is always the same. Most likely happens because length is calculated on the fly instead of stored,
     * and thus the length when recalculated uses the new this.props.list instead of the previous list held in props.
     */
    const listChanged = prevProps.listLength !== this.props.listLength;

    if (filterConfigChanged || listChanged) {
      this.updateFilter();
    }
  }

  async updateFilter() {
    const { searchConfig, list } = this.props;

    const searchOptions = [];
    if (searchConfig.searchByColumn) {
      searchConfig.searchByColumn.forEach((searchTerms, columnIndex) => {
        if (searchTerms && searchTerms.length) {
          searchOptions.push({
            searchValues: searchTerms,
            shouldIntersectSearchValues: false,
            getValueToMatch: row => row[columnIndex],
          });
        }
      });
    }

    if (searchConfig.searchByAllColumns && searchConfig.searchByAllColumns.length) {
      searchOptions.push({
        searchValues: searchConfig.searchByAllColumns,
        shouldIntersectSearchValues: false,
        matchAnyValues: list.headers.map((_, columnIndex) => {
          return row => row[columnIndex];
        }),
      });
    }

    // The most optimal way would be filter the csv within a worker
    // But it would need extra engineering time. this is a quick way to
    // notify users that something is happening. If it doesn't suffice,
    // we'll implement the filtering in a worker.
    await this.setState({
      searchOptions,
      filterNotice: 'Filtering Table ...',
    });

    // doing heavy computation "blocks" the rendering.
    // setImmediate is used to make sure that the notice
    // is displayed before starting the computation.
    setImmediate(() => {
      const isDefaultSearchOptions = !searchOptions.length
       && !searchConfig.searchNormalizedCharacters
       && !searchConfig.searchCaseSensitive;

      if (isDefaultSearchOptions) {
        this.setState({
          filteredRowsMap: null,
          filterNotice: null,
        });
      }

      const filteredRowsMap = AutocompleteStore.searchStaticIndex(list, {
        searchOptions,
        map: (row, rowIndex) => rowIndex,
        compareStringOptions: {
          searchNormalizedCharacters: searchConfig.searchNormalizedCharacters,
          searchCaseInsensitive: !searchConfig.searchCaseSensitive,
        },
      });

      const filterNotice = filteredRowsMap.length
        ? null
        : '0 rows are matching are the filter criteria';

      this.setState({
        filteredRowsMap,
        filterNotice,
      });
    });
  }

  isCellHightlighted = (cell) => {
    const { searchConfig } = this.props;
    const { searchOptions = [] } = this.state;
    if (!searchOptions.length) {
      return false;
    }

    const { cellData, columnIndex } = cell;
    const isRowMatching = AutocompleteStore.createFilterFunction({
      searchOptions,
      shouldIntersectSearchOptions: false,
      compareStringOptions: { ...searchConfig, searchCaseInsensitive: !searchConfig.searchCaseSensitive },
    });

    const isCellHightlighted = isRowMatching({ [columnIndex]: cellData });
    return isCellHightlighted;
  }

  getFilteredList = () => {
    const { list } = this.props;
    const { filteredRowsMap } = this.state;

    const filteredList = !filteredRowsMap ? list : {
      getRow: rowIndex => list.getRow(filteredRowsMap[rowIndex]),
      getCell: (rowIndex, columnIndex) => list.getCell(filteredRowsMap[rowIndex], columnIndex),
      headers: list.headers,
      length: filteredRowsMap.length,
    };

    this.props.updateRowCount(filteredList.length);
    return filteredList;
  }

  render() {
    const { filterNotice } = this.state;
    const filteredList = this.getFilteredList();

    return (
      this.props.children(filteredList, this.isCellHightlighted, filterNotice)
    );
  }
}
