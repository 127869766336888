
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';
import modals, { ModalStoreInitialState } from '@stores/modals';
import Button from '../Button/Button';

import './ModalContainer.scss';

interface ModalContainerComponentState extends ModalStoreInitialState {
  showCloseConfirmModal: boolean;
}

@observer
export default class ModalContainerComponent extends React.Component<{}, ModalContainerComponentState> {
  constructor(props) {
    super(props);

    const storeControlledState = modals.connectRef(this);
    this.state = {
      ...storeControlledState,
      showCloseConfirmModal: false,
    };
  }

  closeAllModals = async () => {
    await this.closeConfirmModal();
    modals.hideModal();
  }

  closeConfirmModal = () => {
    return this.setState({
      showCloseConfirmModal: false,
    });
  }

  handleCloseModal = () => {
    const { lifeCycleConfig } = this.state;

    const shouldShowConfirmModal = !!lifeCycleConfig.confirmOnClose;
    if (shouldShowConfirmModal) {
      return this.setState({
        showCloseConfirmModal: true,
      });
    }

    this.closeAllModals();
  }

  renderConfirmModal = () => {
    const { lifeCycleConfig } = this.state;

    return (
      <div className="modal-content" key={'confirmModal'}>
        <div className="modal-content-container-inner">
          <div className="modal-content-wrapper">
            <div className="dialog-modal-wrapper">
              <p>{lifeCycleConfig.confirmOnClose}</p>
              <div className="dialog-modal-button-list">
                <Button value="Cancel" className="no-background" onClick={this.closeConfirmModal}/>
                <Button value="Close" className="danger" onClick={this.closeAllModals}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public renderModalContent = (modalConfig) => {
    return (
      <div className="modal-content" key={modalConfig.title}>
        <div className="modal-info-container">
          <div className="modal-content-title">
            {modalConfig.title}
          </div>
          <div className="close-modal-button" onClick={this.handleCloseModal}>
            <i className="fa fa-times close-icon" aria-hidden="true"/>
          </div>
        </div>
        <div className="modal-content-container-inner">
          <div className="modal-content-wrapper">
            {modalConfig.component}
          </div>
        </div>
      </div>
    );
  }

  public render() {
    const { modals, showCloseConfirmModal } = this.state;

    return (
      <React.Fragment>
        <CSSTransition in={!!modals.length} classNames="modal" timeout={400}>
          <div className="modal-wrapper" >
            <div className="modal-background" onClick={this.handleCloseModal}/>
            <div className="modal-content-container">
              {modals.map(this.renderModalContent)}
            </div>
          </div>
        </CSSTransition >

        <CSSTransition in={!!showCloseConfirmModal} classNames="modal" timeout={300}>
          <div className="modal-wrapper" >
            <div className="modal-background" onClick={this.closeConfirmModal}/>
            <div className="modal-content-container">
              {this.renderConfirmModal()}
            </div>
          </div>
        </CSSTransition >
      </React.Fragment>
    );
  }
}
