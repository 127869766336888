import React from 'react';
import { observer } from 'mobx-react';
import { BarLoader } from 'react-spinners';

import ActiveNavList from '@components/ActiveNavList/ActiveNavList';

import { ENVIRONMENTS, ROLES } from '@constants';
import store from '@stores';
import { Feed } from '@stores/transit';
import { withStore } from '@stores/withStore';
import SearchFeedsDropdown from '@components/SearchFeedsDropdown/SearchFeedsDropdown';
import JsonExtensionPage from './components/JsonExtensionPage/JsonExtensionPage';
import CsvExplorer from './components/CsvExplorePage/CsvExplorerPage';
import FeedInfoPage from '@pages/feed/components/FeedInfoPage/FeedInfoPage';
import GtfsVisualizer from './components/GtfsVisualizer/GtfsVisualizer';
import OperationLog from '@pages/global/OperationsPage/components/OperationLog';
import OperationsPage from '@pages/global/OperationsPage/OperationsPage';
import PathwaysPage from '@pages/feed/components/PathwaysPage/PathwaysPage';
import './FeedPage.scss';
import DropdownButtonInput from '@components/DropdownButton/DropdownButtonInput';
import TransitLogo from '@components/TransitLogo/TransitLogo';

interface FeedPageProps {
  store: typeof store;
  route: any;
  location: any;
  match: any;
}

interface FeedPageState {
  isLoading: boolean;
}

class FeedPage extends React.Component<FeedPageProps, FeedPageState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    await this.initFeedPageStore();
    this.ensureCurrentFeedIdIsInUrl();

    this.setState({
      isLoading: false,
    });
  }

  componentDidUpdate() {
    this.ensureCurrentFeedIdIsInUrl();
  }

  componentWillUnmount() {
    const { store } = this.props;
    store.pages.gtfs.resetCurrentFeed();

    if (this.hideModal) {
      this.hideModal();
    }
  }

  async initFeedPageStore() {
    const { store } = this.props;

    const currentEnvironment = store.pages.gtfs.getCurrentEnvironment();
    const { params } = store.router.getUrlData();
    const feedId = Number(params.feed_id);
    await store.pages.gtfs.initFeedPageStore(feedId, currentEnvironment);
    this.ensureCurrentFeedIdIsInUrl();
  }

  ensureCurrentFeedIdIsInUrl() {
    const { pages, router } = this.props.store;
    const currentFeedId = pages.gtfs.getCurrentFeedId();

    router.updateUrlData({
      params: {
        feed_id: currentFeedId,
      },
    });
  }

  handleCurrentFeedChange = (feed: Feed) => {
    const { store } = this.props;

    const feedId = feed.feed_id;
    const currentFeedId = store.pages.gtfs.getCurrentFeedId();

    if (this.hideModal) { this.hideModal(); }

    if (currentFeedId !== feedId) {
      store.pages.gtfs.setCurrentFeed(feedId);
      this.ensureCurrentFeedIdIsInUrl();
      store.operationAndNotices.resetStore(feed);
    }

    this.setState({ isLoading: false });
  }

  hideModal: any;
  openSelectFeedModal = () => {
    const { modals, transit } = this.props.store;

    this.hideModal = modals.showModal({
      title: 'Search transit',
      component: (
        <div className="search-agency-modal-content-wrapper">
          <SearchFeedsDropdown
            searchFeed={transit.searchFeed}
            onSelect={this.handleCurrentFeedChange}
          />
        </div>
      ),
    });
  }

  handleEnvironmentChange = (selectedEnvironment: ENVIRONMENTS) => {
    const { store } = this.props;
    const currentEnvironment = store.pages.gtfs.getCurrentEnvironment();

    if (selectedEnvironment !== currentEnvironment) {
      store.pages.gtfs.setCurrentEnvironment(selectedEnvironment);
    }

    this.setState({ isLoading: false });
  }

  renderLoading() {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
        <div className="w-100 d-flex justify-content-center" style={{ marginBottom: '30px' }}>
          <TransitLogo/>
        </div>
        <BarLoader color="#30b566"/>
      </div>
    );
  }

  renderFeedPage() {
    const { router, pages: { gtfs }, auth, transit } = this.props.store;
    const { currentFeed, currentEnvironment } = gtfs;

    const childRoute = router.renderRoute(this.props.route.routes, {
      key: currentFeed.feed_code + currentEnvironment, // make sure we unmount if the feed changed or env changed
      feed: currentFeed,
      environment: currentEnvironment,
    });

    const isTransitEmployee = auth.user.auth.role === ROLES.SUPER_ADMIN || auth.user.auth.role === ROLES.ADMIN;
    const transitEmployeePanel = () => {
      if (!isTransitEmployee) {
        return null;
      }

      return (
        <React.Fragment>
          <div className="new-feed-button">
            <DropdownButtonInput
              buttonTitle="Create Feed"
              dropdownTitles={[
                { title: 'New Feed', value: '/feed/createFeed' }
              ]}
              /* tslint:disable-next-line:jsx-no-lambda */
              onSelect={(title, value) => router.push(value)}
            />
          </div>
        </React.Fragment>
      );
    };

    const renderFeedSelector = () => {
      if (transit.feedList.length <= 1) {
        return null;
      }

      return (
        <React.Fragment>
          <div className="current-transit-menu">
            <div className="current-transit-name" onClick={this.openSelectFeedModal} style={{ cursor: 'pointer' }}>
              <span>{currentFeed.feed_code}</span>
              <span className="change-transit-button">
                <i className="fa fa-caret-down change-current-transit-icon" aria-hidden="true"/>
              </span>
            </div>
          </div>
          <span className="feed-name">{currentFeed.feed_name}</span>
        </React.Fragment>
      );
    };

    const renderNavigation = () => {
      if (this.props.route.routes && transit.feedList.length <= 1) {
        return null;
      }

      return (
        <div className="transit-nav-container d-flex justify-content-between">
          <div className="current-transit-display">
            {renderFeedSelector()}
            {transitEmployeePanel()}
          </div>
          <ActiveNavList
            routes={this.props.route.routes}
            className="current-transit-nav"
            linkClassName="current-transit-nav-link"
          />
        </div>
      );
    };

    return (
      <div className="page-container feedPage">
        {renderNavigation()}
        <div className="transit-page-content">
          {childRoute}
        </div>
      </div>
    );
  }

  render() {

    return this.state.isLoading
      ? this.renderLoading()
      : this.renderFeedPage();
  }
}

export default observer(withStore(FeedPage));
export const feedPageSubPages = {
  CsvExplorer,
  FeedInfoPage,
  GtfsVisualizer,
  OperationsPage,
  JsonExtensionPage,
  PathwaysPage,
  OperationLog,
};
