import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import auth from '@stores/auth';

export default {
  authenticatedRequest,
  authenticatedGet,
  authenticatedPost,
  authenticatedPut,
  authenticatedDelete,
};

const logoutOnStatusCodes = [
  401,
  403,
];

async function authenticatedRequest(requestConfig: AxiosRequestConfig) {
  try {
    const axiosRequestConfig = Object.assign({ withCredentials: true }, requestConfig);
    const response = await axios(axiosRequestConfig);

    return response;
  } catch (error) {
    console.log(error); // tslint:disable-line no-console

    if (logoutOnStatusCodes.includes(error.response.status)) {
      await auth.logout();
    }
  }
}

/*
 * A middleware for the GET method call. On a failed request, it logs out the user.
 *
 * @param {string} - URL
 * @param {object} - HTTP parameters to pass
 */
async function authenticatedGet(url: string, config?: object): Promise<any> {
  try {
    const getConfig = Object.assign({ withCredentials: true }, config);
    const response = await axios.get(url, getConfig);

    return response;
  } catch (error) {
    console.log(error); // tslint:disable-line no-console

    if (logoutOnStatusCodes.includes(error.response.status)) {
      await auth.logout();
    }
  }
}

/*
 * A middleware for the POST method call. On a failed request, it logs out the user.
 *
 * @param {string} - URL
 * @param {object} - HTTP parameters to pass
 */
async function authenticatedPost(url: string, data?: object) {
  try {
    const response = await axios.post(url, data, {
      withCredentials: true,
    });

    return response;
  } catch (error) {
    console.log(error); // tslint:disable-line no-console

    if (logoutOnStatusCodes.includes(error?.response?.status)) {
      await auth.logout();
    }
  }
}

/*
 * A middleware for the PUT method call. On a failed request, it logs out the user.
 *
 * @param {string} - URL
 * @param {object} - HTTP parameters to pass
 */
async function authenticatedPut(url: string, data?: object) {
  try {
    const response = await axios.put(url, data, {
      withCredentials: true,
    });

    return response;
  } catch (error) {
    console.log(error); // tslint:disable-line no-console

    if (logoutOnStatusCodes.includes(error.response.status)) {
      await auth.logout();
    }
  }
}

  /*
 * A middleware for the DELETE method call. On a failed request, it logs out the user.
 *
 * @param {string} - URL
 * @param {object} - HTTP parameters to pass
 */
async function authenticatedDelete(url: string) {
  try {
    const response = await axios.delete(url, {
      withCredentials: true,
    });

    return response;
  } catch (error) {
    console.log(error); // tslint:disable-line no-console

    if (logoutOnStatusCodes.includes(error.response.status)) {
      await auth.logout();
    }
  }
}
