import React from 'react';
import api from '@stores/api';
import { FeedInfo } from '@stores/transit';
import store from '@stores';
import { withStore } from '@stores/withStore';
import FeedInfoForm from './components/FeedInfoForm';
import FeedBoundMap from './components/FeedBoundMap';
import { sendFeedSlackMessage } from '@utils/slack_helper';

interface FeedInfoPageProps {
  store: typeof store;
  feed?: FeedInfo;
  environment?: string;
}

interface FeedInfoPageState {}

class FeedInfoPage extends React.Component<FeedInfoPageProps, FeedInfoPageState> {
  constructor(props) {
    super(props);
  }

  // --------------------------- //
  //     RENDERING FUNCTIONS     //
  // --------------------------- //

  redirectToSdd = async (feedInfo) => {
      `https://static-data-dashboard.transitapp.com/create-feed`;
    window.open(`https://static-data-dashboard.transitapp.com/feeds/${feedInfo.feed_code}`);
  }

  redirectToSddCreate = async (feedInfo) => {
    window.open('https://static-data-dashboard.transitapp.com/create-feed');
  }

  renderWithFeedMap() {
    const { feed, store, } = this.props;

    return (
      <div className="row">
        <div className="col col-xl-8">
          <FeedInfoForm
            feedInfo={feed}
            onSubmit={this.redirectToSdd}
            button="Use static-data-dashboard to update"
          />
        </div>
        <div className="col col-xl-4">
          <div className="feed-bound-map">
            <FeedBoundMap feedInfo={feed}/>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { feed, store } = this.props;
    const { params } = store.router.getUrlData();

    const checkIfNoParams = !Object.keys(params).length;

    if (!feed || checkIfNoParams) {
      return (
        <div className="row">
          <FeedInfoForm
            onSubmit={this.redirectToSddCreate}
            button="Use static-data-dashboard to create"
          />
        </div>
      );
    }

    return this.renderWithFeedMap();
  }
}

export default withStore(FeedInfoPage);
