import { FOCUS_ON } from '@constants';
import { toJS } from 'mobx';
import operationAndNotices from '@stores/operationAndNotices';
import store from '@stores';

function handleKeyboardArrowPressed(event) {
  switch (event.keyCode) {
    case 37:
      event.preventDefault(); // prevents window from scrolling when
      return handleToggleLeftRight(FOCUS_ON.LEFT);
    case 38:
      event.preventDefault(); // prevents window from scrolling when
      return handleToggleUpDown(FOCUS_ON.UP);
    case 39:
      event.preventDefault(); // prevents window from scrolling when
      return handleToggleLeftRight(FOCUS_ON.RIGHT);
    case 40:
      event.preventDefault(); // prevents window from scrolling when
      return handleToggleUpDown(FOCUS_ON.DOWN);
    case 82: // key 'R'
      if (operationAndNotices.isNoticesContainerInFocus) {
        return handleResolveNotice();
      }
  }
}

function handleToggleLeftRight(focusOn: FOCUS_ON) {
  if (focusOn === FOCUS_ON.LEFT && !operationAndNotices.isOperationsContainerInFocus) {
    return operationAndNotices.setIsOperationsContainerInFocus(true);
  }

  if (focusOn === FOCUS_ON.RIGHT && operationAndNotices.isOperationsContainerInFocus) {
    return operationAndNotices.setIsOperationsContainerInFocus(false);
  }
}

function handleToggleUpDown(focusOn: FOCUS_ON.UP | FOCUS_ON.DOWN) {
  if (operationAndNotices.isOperationsContainerInFocus) {
    return operationAndNotices.changeOperationInFocus(focusOn);
  }
  if (operationAndNotices.isNoticesContainerInFocus) {
    return operationAndNotices.changeNoticeInFocus(focusOn);
  }
  return;
}

function handleResolveNotice() {
  const { operationInFocus, noticeInFocus, updateNotice } = store.operationAndNotices;

  const notice = toJS(noticeInFocus);
  notice.last_handled_by = store.auth.user.displayName;
  notice.status = 'Resolved';

  updateNotice(operationInFocus.operation_id, notice);
  return store.api.feeds.updateNotice(notice);
}

export default {
  handleKeyboardArrowPressed,
};
