import React from 'react';
import { isEqual, merge } from 'lodash';
import cx from 'classnames';

import { FOCUS_ON, DATA_TYPES } from '@constants';
import { getRenderFunctionAndTypeOfValue } from '@components/ToolComponents/lib/renderValueWithConfig'; // tslint:disable-line max-line-length
import { getDeepTypeOfValue } from '../lib/getTypeOfValue';

import '../styles/SimpleStaticObjectUI.scss';
import BasicFunctionalities from '../simpleUIs/common/BasicFunctionalities';

interface SimpleStaticObjectToolUIProps {
  index?: [number, number] | number | string;
  value?: any;
  template: any;
  parentConfig: any;
  onChange(value, index?): void;
}

interface SimpleStaticToolUIState {
  objectEntries: any[];
  entriesAsObject: object;
}

type RowIndex = number;

type ComponentConfig = {
  rowIndex: number,
  component: JSX.Element,
  typeOfValue: DATA_TYPES,
};

export default class StaticObjectToolUI
  extends React.Component<SimpleStaticObjectToolUIProps, SimpleStaticToolUIState>
  implements BasicFunctionalities {

  constructor(props) {
    super(props);

    const { objectEntries, entriesAsObject } = this.getDefaultValue();

    this.state = {
      objectEntries,
      entriesAsObject,
    };
  }

  static mergeTemplateAndValueEntries(template, value) {
    const valueWithDefinedKeys = Object.assign({}, value);

    // add missing keys in valueWithDefinedKeys;
    Object.keys(template).forEach((key) => {
      valueWithDefinedKeys[key] = valueWithDefinedKeys[key];
    });

    // transform object to entries array
    const objectEntries = Object.entries(valueWithDefinedKeys);
    return objectEntries;
  }

  getDefaultValue() {
    const { template , value } = this.props;

    const objectEntries = StaticObjectToolUI.mergeTemplateAndValueEntries(template , value);
    const entriesAsObject = objectEntries.length ? value : {};

    return {
      objectEntries,
      entriesAsObject,
    };
  }

  handleDeleteEvent = async (rowIndex: RowIndex) => {
    this.focusOnRow(rowIndex , FOCUS_ON.PREVIOUS);
  }

  handleElementComplete = async (rowIndex: RowIndex, value) => {
    this.handleElementValueChange(value, rowIndex);
    this.focusOnRow(rowIndex, FOCUS_ON.NEXT);
  }

  refs: { [index: string]: any };
  focusOnRow = (rowIndex: RowIndex, where: FOCUS_ON) => {
    const { objectEntries } = this.state;

    const isFirstRow = rowIndex === 0;
    const isLastRow = rowIndex === objectEntries.length - 1;

    switch (where) {
      case FOCUS_ON.FIRST:
        const focusOnRef = this.refs['0'];
        if (focusOnRef !== undefined) {
          focusOnRef.focus();
        }
        break;

      case FOCUS_ON.UP:
      case FOCUS_ON.PREVIOUS:
        if (!isFirstRow) {
          const focusOnRef = this.refs[`${rowIndex - 1}`];
          if (focusOnRef !== undefined) {
            focusOnRef.focus();
          }
        }
        break;

      case FOCUS_ON.DOWN:
      case FOCUS_ON.NEXT:
        if (!isLastRow) {
          const focusOnRef = this.refs[`${rowIndex + 1}`];
          if (focusOnRef !== undefined) {
            focusOnRef.focus();
          }
        }
        break;
    }
  }

  updateObjectEntries = async (objectEntries, entriesAsObject) => {
    const { value: object, onChange, index } = this.props;

    await this.setState({
      objectEntries,
      entriesAsObject,
    });

    if (entriesAsObject !== object) {
      await onChange(entriesAsObject, index);
    }
  }

  handleElementValueChange = async (value, rowIndex: RowIndex) => {
    const { objectEntries } = this.state;

    const entriesAsObject = objectEntries.reduce((prev, [rowKey, rowValue], index) => {
      let newRowValue = rowValue;

      if (index === rowIndex) {
        newRowValue = value;
      }

      if (newRowValue !== undefined && newRowValue !== null) {
        prev = prev || {}; // tslint:disable-line no-parameter-reassignment
        prev[rowKey] = newRowValue;
      }

      objectEntries[index] = [rowKey, newRowValue];

      return prev;
    }, undefined);

    await this.updateObjectEntries(objectEntries, entriesAsObject);
  }

  private syncStateWithObject = async (object) => {
    const { template } = this.props;
    const { entriesAsObject } = this.state;

    if (!object || !entriesAsObject) {
      // if undefined we will show empty static object
      const { objectEntries, entriesAsObject } = this.getDefaultValue();
      return this.updateObjectEntries(objectEntries, entriesAsObject);
    }

    // merge empty object with empty template
    const objectEntries = StaticObjectToolUI.mergeTemplateAndValueEntries(template , object);

    this.updateObjectEntries(objectEntries, object);
  }

  async componentDidUpdate() {
    const { value: object } = this.props;
    const { entriesAsObject } = this.state;
    const isStateSynced = isEqual(object, entriesAsObject);
    if (isStateSynced) { return; }

    await this.syncStateWithObject(object);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !isEqual(this.props.value, nextProps.value);
    const stateWillSync = isEqual(nextState.entriesAsObject, nextProps.value);
    return propsChanged || stateWillSync;
  }

  onClick = (indexRef: number) => {
    const ref = this.refs && this.refs[indexRef];
    if (ref) {
      if (ref.handleClick) {
        ref.handleClick();
      } else if (ref.focus) {
        ref.focus();
      }
    }
  }

  getRowConfig = ([key, toolValue], rowIndex) : ComponentConfig => {
    const { STRING, NUMBER, BOOLEAN, ARRAY_OF_STRINGS, DATE } = DATA_TYPES;
    const { template, parentConfig } = this.props;

    const childConfig = {
      ref: `${rowIndex}`,
      index: rowIndex,
      keyName: key,
      onChange: this.handleElementValueChange,
      onComplete: this.handleElementComplete,
      onDelete: this.handleDeleteEvent,
      onChangeFocus: this.focusOnRow,
    };

    const toolTemplate = template[key];
    const toolProps = merge({}, parentConfig, childConfig);

    const deepType = getDeepTypeOfValue(toolTemplate);
    const { renderComponent, typeOfValue } = getRenderFunctionAndTypeOfValue(toolTemplate, toolValue, toolProps);

    const isArrayOfStrings = deepType === ARRAY_OF_STRINGS;
    const isBoolean = typeOfValue === BOOLEAN;
    const isStringPrimitive = [STRING, NUMBER, DATE].includes(typeOfValue);
    const isPrimitive = isBoolean || isStringPrimitive;

    const wrapperClass = `simple-static-object-wrapper ${cx({
      'is-array-of-strings': isArrayOfStrings,
      'is-primitive': isPrimitive,
      'is-boolean': isBoolean,
      'is-object-value': !isPrimitive,
      'is-defined': !!toolValue, // make row active if true;
    })}`;

    const keyClass = cx('simple-static-object-key', {
      'hide': isStringPrimitive, // hide key for all string primitives
    });

    const component = (
      <div className={wrapperClass} key={rowIndex} onClick={this.onClick.bind(this, rowIndex)}>
          <span className={keyClass}>{`${key}:`}</span>
          <div className="simple-static-object-value">
            {renderComponent()}
          </div>
      </div>
    );

    return {
      component,
      rowIndex,
      typeOfValue,
    };
  }

  static orderConfig : DATA_TYPES[] = [
    DATA_TYPES.NUMBER,
    DATA_TYPES.REGEXP,
    DATA_TYPES.STRING,
    DATA_TYPES.BOOLEAN,
  ];

  // this function will be used to sort the compoenent based on what value they display.
  // the order will be the same as described in StaticObjectToolUI.orderConfig
  static orderTools(left: ComponentConfig, right: ComponentConfig): number {
    const indexOfLeft = StaticObjectToolUI.orderConfig.indexOf(left.typeOfValue);
    const indexOfRight = StaticObjectToolUI.orderConfig.indexOf(right.typeOfValue);

    if (indexOfLeft === -1) { return 0; }
    if (indexOfRight === -1) { return -1; }

    return indexOfLeft - indexOfRight;
  }

  render() {
    const { objectEntries } = this.state;

    const reorderedComponents = objectEntries.map(this.getRowConfig)
      .sort(StaticObjectToolUI.orderTools)
      .map(v => v.component);

    return (
      <div className="simple-static-object-tool">
        <div className="simple-static-object-container">
          {reorderedComponents}
        </div>
      </div>
    );
  }
}
