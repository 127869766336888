import React from 'react';

export const EyeShownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
      <g fill="none" fill-rule="evenodd" transform="matrix(1 0 0 -1 .5 20)">
        <path fill="#C2CAD2" fill-rule="nonzero" d="M0.00177388521,7.07076952 C-0.000211572447,6.81731537 0.0746427212,6.5894193 0.229782308,6.40132292 C0.244447632,6.3835422 0.259114641,6.36579862 0.273783329,6.34809219 C0.302023875,6.31823529 0.332414703,6.28978819 0.364935185,6.2629657 C0.790997273,5.91155491 1.42126357,5.97207175 1.77267436,6.39813383 C1.95329354,6.61712252 2.13366553,6.82975316 2.31380692,7.03602565 C2.13366856,7.24228908 1.95330252,7.45491288 1.77268962,7.67389396 C1.42127883,8.09995604 0.791012532,8.16047288 0.364950444,7.80906209 C0.332335351,7.78216156 0.301862324,7.75362696 0.273552167,7.72367499 C0.258955464,7.70605506 0.244360423,7.68839836 0.22976705,7.67070487 C0.0842715867,7.49430135 0.00938993273,7.28289256 0.00183011631,7.07076348 C-0.000893951833,6.79144008 0.0917974922,6.54049398 0.273783329,6.34809219 C7.27989612,-2.10891903 14.6691444,-2.09207344 21.768048,6.39272999 C22.0787147,6.76404735 22.0787673,7.3045818 21.7681728,7.67595956 C14.6695622,16.1637658 7.28011397,16.1814116 0.273552167,7.72367499 C0.101884762,7.54205188 0.0097456593,7.308312 0.00177388521,7.07076952 Z M19.6821755,7.03455054 C13.7576215,0.343667494 8.15686038,0.345367009 2.31380431,7.03602266 C8.15713307,13.7269776 13.7578268,13.727949 19.6821755,7.03455054 Z"/>
        <circle cx="11" cy="7" r="3" fill="#C2CAD2"/>
      </g>
    </svg>
  );
};
