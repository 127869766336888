import React from 'react';
import copy from 'copy-to-clipboard';
import cx from 'classnames';

interface CellCopyButtonProps {
  value: string;
}

export default class CellCopyButton extends React.Component<CellCopyButtonProps> {

  state = {
    isCopied: false,
  };

  copyToClipboard = () => {
    const { value } = this.props;
    copy(value);

    this.setState({ isCopied: true });

    this.stateTimeout = setTimeout(() => {
      this.setState({ isCopied: false });
    }, 1000);
  }

  stateTimeout;
  componentWillUnmount() {
    clearTimeout(this.stateTimeout);
  }

  render() {
    const { isCopied } = this.state;

    const copyButtonClassName = cx('copy-button', {
      ['is-copied']: isCopied,
    });

    return (
      <div className={copyButtonClassName} onClick={this.copyToClipboard}>
        {isCopied ? 'copied' : 'copy'} <i className="fa fa-clone"/>
      </div>
    );
  }
}
