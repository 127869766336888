export enum HTML_TAGS {
  CHECKBOX = 'checkbox',
  DATALIST = 'datalist',
  CUSTOM = 'custom',
  DROPDOWN = 'dropdown',
  INPUT = 'input',
  NUMBER = 'number',
  TEXT = 'text',
  COLOR = 'color',
  DATE_TIME = 'datetime-local',
  FILE = 'file',
  URL = 'url',
  PASSWORD = 'password',
}
