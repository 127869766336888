import React from 'react';
import cx from 'classnames';
import { SortDirection, Column, AutoSizer, Table } from 'react-virtualized';

import { SortConfig } from '@components/Table/components/SortTable';

import '../styles/TableView.scss';

interface TableViewProps {
  headers: string[];
  hideColumns: boolean[];
  minColumnWidths: number[];
  isCellHighlighted(cell: any): boolean;
  onRowDoubleClick?(options: any): void;
  renderCell?(cell: any, table?: any): JSX.Element;
  rowClassName?(rowIndex: number): string;
  rowGetter?(rowIndex: number, table?: any): string[];
  sortConfigs: SortConfig[];
  sort(sortConfigs: SortConfig[]): void;
  table: any;
}

export default class TableView extends React.Component<TableViewProps, any> {
  renderCell = (cell) => {
    const { renderCell: propsRenderCell, table } = this.props;
    if (propsRenderCell) {
      return propsRenderCell(cell, table);
    }

    const { cellData } = cell;

    const cellClassName = cx('table-cell', {
      'is-highlighted': this.props.isCellHighlighted(cell),
    });

    return (
      <span className={cellClassName}>
        {cellData}
      </span>
    );
  }

  getRowClassName = ({ index }): any => {
    if (index % 2 === 0) {
      return 'even';
    }

    return '';
  }

  renderTableHeader = ({ dataKey }) => {
    const { headers, sortConfigs } = this.props;
    const isColumnSortedActive = sortConfigs.length > 0 && sortConfigs[0].sortBy === dataKey;

    const isAscending = sortConfigs.length > 0 && sortConfigs[0].sortDirection === SortDirection.ASC;
    const caretByDirection = <i className={`fa caret fa-caret-${ isAscending ? 'up' : 'down'}`} />;

    const columnClassName = cx('table-header', {
      'is-sorted-column': isColumnSortedActive,
    });

    return (
      <div className={columnClassName}>
        <span className="table-header-name">{headers[dataKey]}</span>
        <span className="table-header-caret">{isColumnSortedActive && caretByDirection}</span>
      </div>
    );
  }

  rowGetter = ({ index }) => {
    const { rowGetter: propsRowGetter, table } = this.props;
    if (propsRowGetter) {
      return propsRowGetter(index, table);
    }

    return table.getRow(index);
  }

  renderColumns = (columnWidths) => {
    const {
      headers,
      hideColumns = [],
      sortConfigs,
    } = this.props;

    return columnWidths.map((colWidth, index) => {
      const columnClassName = cx({
        'is-sorted-column': sortConfigs.length > 0 && sortConfigs[0].sortBy === index,
      });

      if (hideColumns[index]) {
        return null;
      }

      return (
        <Column
          disableSort={false}
          key={index}
          className={columnClassName}
          dataKey={`${index}`}
          label={headers}
          width={colWidth}
          headerRenderer={this.renderTableHeader}
          cellRenderer={this.renderCell}
        />
      );
    });
  }

  render() {
    const {
      minColumnWidths,
      onRowDoubleClick,
      rowClassName: propsRowClassName,
      sortConfigs,
      sort,
      table,
    } = this.props;

    const rowHeight = 35;

    const tableProps = (height: number, width: number) => {
      const newHeight = height - rowHeight;

      const minWidth = minColumnWidths.reduce((acc, cur, i) => {
        return acc + cur;
      }, 0);
      const newWidth = minWidth > width ? minWidth : width;
      const columnWidths = minColumnWidths.map((minColumnWidth) => {
        return (minColumnWidth / minWidth) * newWidth;
      });

      return {
        rowHeight,
        sort: (sortConfig: SortConfig) => sort([sortConfig]),
        height: newHeight > 0 ? newHeight : 0,
        headerHeight: rowHeight,
        onRowDoubleClick: options => onRowDoubleClick && onRowDoubleClick(options),
        rowClassName: options => propsRowClassName ? propsRowClassName(options) : this.getRowClassName(options),
        rowCount: table.length,
        rowGetter: this.rowGetter,
        sortDirection: sortConfigs.length > 0 ? sortConfigs[0].sortDirection : undefined,
        sortBy: sortConfigs.length > 0 ? sortConfigs[0].sortBy : undefined,
        width: newWidth,
        children: this.renderColumns(columnWidths),
      };
    };

    return (
      <AutoSizer
        className="table-view"
        children={({ height, width }) => { // tslint:disable jsx-no-multiline-js jsx-no-lambda
          return (
            <Table {...tableProps(height, width)}/>
          );
        }}
      />
    );
  }
}
