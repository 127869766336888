import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';

import store from '@stores';
import { KEYBOARD_KEYS, STEPS } from '@constants';
import { withStore } from '@stores/withStore';
import { FeedInfo } from '@stores/transit';
import StepButton from './StepButton';
import LoaderButton from '@components/LoaderButton/LoaderButton';
import TagsSearchFeedsDropdown from '@components/SearchFeedsDropdown/TagsSearchFeedsDropdown';

import '../styles/FeedOperation.scss';
import '../styles/OperationUIHeader.scss';
import '@components/SearchFeedsDropdown/styles/TagsSearchFeedsDropdown.scss';

interface OperationRunnerProps {
  store: typeof store;
  feed?: FeedInfo;
  onDropdown(isVisible: boolean): void;
}

interface OperationRunnerState {
  feedsRegexSearch: string;
  isDropdownVisible: boolean;
  selectedFeedCodes: Set<string>;
  selectedSteps: Set<string>;
  selectedOptions: any;
}

class OperationRunner extends React.Component<OperationRunnerProps, OperationRunnerState> {
  constructor(props) {
    super(props);

    this.state = {
      feedsRegexSearch: '',
      isDropdownVisible: false,
      selectedFeedCodes: props.feed ? new Set([props.feed.feed_code]) : new Set([]),
      selectedSteps: new Set([
        STEPS.GET.name,
        STEPS.PRE.name,
        STEPS.TRA.name,
        STEPS.EXT.name,
        STEPS.ENV.name,
        STEPS.FLX.name,
        STEPS.CLE.name,
        STEPS.ENH.name,
        STEPS.CTN.name,
        STEPS.VAL.name,
        STEPS.COM.name,
        STEPS.UPF.name,
      ]),
      selectedOptions: {
        priority: false,
        earlyStopIfDataUnchanged: false,
      },
    };
  }

  handleSelectedFeedCodes = async (feedCodes: string[], feedsRegexSearch: string = '') => {
    const feedCodesSet = new Set(feedCodes);
    await this.setState({
      feedsRegexSearch,
      selectedFeedCodes: feedCodesSet,
    });
  }

  handleOptionSelection = async (option, activated) => {
    const { selectedOptions } = this.state;
    selectedOptions[option] = activated;
    await this.setState({
      selectedOptions,
    });
  }

  handleSelectedStepButton = async (step, activated) => {
    const { selectedSteps } = this.state;

    if (activated) {
      selectedSteps.add(step);
    } else {
      this.state.selectedSteps.forEach((selectedStep) => {
        if (selectedStep === step) {
          selectedSteps.delete(selectedStep);
        }
      });
    }

    const sortedSelectedSteps = this.sortSelectedSteps(selectedSteps);

    await this.setState({
      selectedSteps: sortedSelectedSteps,
    });
  }

  sortSelectedSteps = (selectedSteps) => {
    return new Set([...selectedSteps].sort((stepA, stepB) => {
      const stepOrderA = STEPS[stepA].order;
      const stepOrderB = STEPS[stepB].order;
      return stepOrderA - stepOrderB;
    }));
  }

  handleSelectedRunOperationButton = async () => {
    const { api, auth, transit } = this.props.store;
    const { selectedFeedCodes, selectedOptions, selectedSteps } = this.state;

    for (const selectedFeedCode of selectedFeedCodes) {
      if (selectedSteps.size === 0) {
        continue;
      }

      const feed = transit.getFeedWithFeedCode(selectedFeedCode);
      const feedId = feed.feed_id;

      const addOperationConfig = {
        feed_id: feedId,
        feed_code: selectedFeedCode,
        priority: selectedOptions.priority,
        earlyStopIfDataUnchanged: selectedOptions.earlyStopIfDataUnchanged,
        steps: [...selectedSteps],
        user: auth.user.displayName,
      };

      try {
        await api.feeds.runOperationForFeed(addOperationConfig);
      } catch (error) {
        return false;
      }
    }

    return true;
  }

  handleBulkSelectedFeedCodesUsingRegexString = async (feedsRegexSearch: string) => {
    const { transit } = this.props.store;

    const feedsRegex = feedsRegexSearch ? RegExp(feedsRegexSearch, 'i') : undefined;

    if (feedsRegex === undefined) {
      return this.handleSelectedFeedCodes([]);
    }

    const feeds = transit.findAllByFeedCodeRegex(feedsRegex);
    const feedCodes = feeds.map(feed => feed.feed_code);

    await this.handleSelectedFeedCodes(feedCodes, feedsRegexSearch);
  }

  handleKeyDownOnInput = async (event: React.KeyboardEvent) => {
    if (event.keyCode === KEYBOARD_KEYS.ENTER) {
      await this.setState({
        feedsRegexSearch: '',
      });
    }
  }

  renderOperationStepSelection() {
    const { selectedSteps } = this.state;

    const stepButtons = Object.keys(STEPS).map((step) => {
      return (
        <div key={step}>
          <StepButton
            activateOnClick={true}
            isActive={selectedSteps.has(step)}
            value={step}
            onClick={this.handleSelectedStepButton}
          />
        </div>
      );
    });

    const clearSteps = (
      <div
        className="operation-container-button grey"
        onClick={() => { // tslint:disable-line jsx-no-lambda
          selectedSteps.clear();
          this.setState({ selectedSteps });
        }}
      >
        <span>Clear</span>
      </div>
    );

    const cleanToUploadSet = new Set([
      STEPS.CLE.name,
      STEPS.ENH.name,
      STEPS.CTN.name,
      STEPS.VAL.name,
      STEPS.COM.name,
      STEPS.UPF.name,
    ]);

    const getToUploadSet = new Set([
      STEPS.GET.name,
      STEPS.PRE.name,
      STEPS.FLX.name,
      STEPS.TRA.name,
      STEPS.EXT.name,
      STEPS.ENV.name,
      STEPS.CLE.name,
      STEPS.ENH.name,
      STEPS.CTN.name,
      STEPS.VAL.name,
      STEPS.COM.name,
      STEPS.UPF.name,
    ]);

    const cleanToUploadFeedSteps = (
      <div
        className="operation-container-button grey marginLeft5"
        tabIndex={0}
        /* tslint:disable-next-line:jsx-no-lambda */
        onKeyDown={(event) => {
          // @ts-ignore
          if (event.keyCode === KEYBOARD_KEYS.ENTER) {
            this.setState({ selectedSteps: cleanToUploadSet });
          }
        }}
        onClick={() => { // tslint:disable-line jsx-no-lambda
          this.setState({ selectedSteps: cleanToUploadSet });
        }}
      >
        <span>CLE to UPF</span>
      </div>
    );

    const getToUploadFeedSteps = (
      <div
        className="operation-container-button grey marginLeft5"
        tabIndex={0}
        /* tslint:disable-next-line:jsx-no-lambda */
        onKeyDown={(event) => {
          // @ts-ignore
          if (event.keyCode === KEYBOARD_KEYS.ENTER) {
            this.setState({ selectedSteps: getToUploadSet });
          }
        }}
        onClick={() => { // tslint:disable-line jsx-no-lambda
          this.setState({ selectedSteps: getToUploadSet });
        }}
      >
        <span>GET to UPF</span>
      </div>
    );

    return (
      <div>
        <label>Select the steps to run:</label>
        <div className="operation-search-dropdown-option-wrapper d-flex">
          {clearSteps}
          {getToUploadFeedSteps}
          {cleanToUploadFeedSteps}
        </div>
        <div className="operation-search-dropdown-option-wrapper d-flex">
          {stepButtons}
        </div>
      </div>
    );
  }

  renderRegexFeedSelection() {
    const { feedsRegexSearch } = this.state;

    return (
      <input
        type="text"
        autoComplete="off"
        className={'operation-runner-feed-input'}
        onChange={event => this.handleBulkSelectedFeedCodesUsingRegexString(event.target.value)} // tslint:disable-line jsx-no-lambda max-line-length
        onKeyDown={this.handleKeyDownOnInput}
        value={feedsRegexSearch}
      />
    );
  }

  renderAllFeedsSelection() {
    return (
      <div
        className="operation-container-button grey marginLeft5"
        onClick={() => this.handleBulkSelectedFeedCodesUsingRegexString('.*')} // tslint:disable-line jsx-no-lambda
        tabIndex={0}
        onKeyDown={this.handleKeyDownOnInput}
      >
        <span>select all feeds</span>
      </div>
    );
  }

  toggleDropdownVisibility = (event: React.MouseEvent) => {
    const { isDropdownVisible } = this.state;

    this.updateDropdownVisibility(!isDropdownVisible);
  }

  updateDropdownVisibility = (visibility: boolean) => {
    this.setState({
      isDropdownVisible: visibility,
    });

    this.props.onDropdown(visibility);
  }

  renderRun() {
    const { selectedFeedCodes, selectedSteps } = this.state;

    const disabled = !selectedFeedCodes.size || !selectedSteps.size;
    const allowedToRun = cx({
      'active': selectedFeedCodes.size && selectedSteps.size,
      'disabled': disabled,
    });

    return (
      <LoaderButton
        className={`runner-button ${allowedToRun}`}
        disable={disabled}
        onClickWithStatus={this.handleSelectedRunOperationButton}
        keyEvent={this.handleSelectedRunOperationButton}
        value="RUN"
      />
    );
  }

  renderBulkFeedsSelection() {
    if (this.props.feed) {
      return;
    }

    return (
      <div>
        <label style={{ paddingTop: '10px' }}>
          Need to run feeds in bulk? Use any of the following options to select a group of feeds:
        </label>
        <div className="d-flex align-items-center">
          <span style={{ marginRight: '5px' }}>Enter Regex:</span>
          {this.renderRegexFeedSelection()}
          <span style={{ margin: '0 5px' }}>OR</span>
          {this.renderAllFeedsSelection()}
        </div>
      </div>
    );
  }

  renderOperationOptionsSelection() {
    return (
      <div>
        <label style={{ paddingTop: '10px' }}>
          Click on any of the following options to enhance the operation:
        </label>
        <div className="d-flex align-items-center">
          <StepButton
            activateOnClick={true}
            label={'Priority'}
            value={'priority'}
            onClick={this.handleOptionSelection}
          />
          <StepButton
            activateOnClick={true}
            label={'New source only'}
            value={'earlyStopIfDataUnchanged'}
            onClick={this.handleOptionSelection}
          />
        </div>
      </div>
    );
  }

  renderOptionsMenu() {
    const { isDropdownVisible } = this.state;

    let listenEvent = false;
    setTimeout(() => {
      listenEvent = true;
    }, 300);

    return (
      <CSSTransition
        in={isDropdownVisible}
        timeout={200}
        classNames="operation-search-dropdown"
      >
        <div
          className={'operation-search-dropdown'}
          onMouseLeave={() => listenEvent && this.updateDropdownVisibility(false)} // tslint:disable-line jsx-no-lambda max-line-length
        >
          <div className="operation-search-dropdown-option-wrapper">
            {this.renderOperationStepSelection()}
            {this.renderBulkFeedsSelection()}
            {this.renderOperationOptionsSelection()}
          </div>
        </div>
      </CSSTransition>
    );
  }

  handleEnterKeyPress = (event, func) => {
    if (event.keyCode === KEYBOARD_KEYS.ENTER) {
      event.stopPropagation();
      return func(event);
    }
  }

  renderOptionsIcon() {
    return (
      <div
        className="runner-button"
        onClick={this.toggleDropdownVisibility}
        /* tslint:disable-next-line:jsx-no-lambda ter-arrow-parens */
        onKeyUp={(event) => this.handleEnterKeyPress(event, this.toggleDropdownVisibility)}
        tabIndex={0}
      >
        <i className="fa fa-bars" aria-hidden="true"/>
      </div>
    );
  }

  renderSearchFeedsDropdown() {
    const { selectedFeedCodes } = this.state;
    if (this.props.feed) {
      return;
    }

    return (
      <TagsSearchFeedsDropdown
        onChange={this.handleSelectedFeedCodes}
        overflowSearchResults={true}
        searchFeed={this.props.store.transit.searchFeed}
        selectedFeedCodes={[...selectedFeedCodes]}
      />
    );
  }

  renderFeedSelection() {
    return (
      <div className="operation-runner-feed stacked">
        {this.renderRun()}

        {this.renderSearchFeedsDropdown()}

        <div
          className="operation-search-wrapper"
        >
          {this.renderOptionsIcon()}
          {this.renderOptionsMenu()}
        </div>
      </div>
    );
  }

  render() {
    const { selectedSteps, selectedFeedCodes } = this.state;
    const { gitStatus } = this.props.store.operationAndNotices;

    const selectedStepsArray = [...selectedSteps];
    const firstStep = selectedStepsArray[0];
    const lastStep = selectedStepsArray[selectedStepsArray.length - 1];

    const operationMessage = firstStep
      ? firstStep === lastStep
        ? `Will run ${firstStep}`
        : `Will run ${firstStep} to ${lastStep}`
      : 'No steps selected';

    const feedSelectionMessage = !this.props.feed ? `${selectedFeedCodes.size} selected feeds` : '';
    const gitStatusMessage = gitStatus ? `(${gitStatus.date}) ${gitStatus.author} pushed "${gitStatus.message}"` : '';

    return (
      <div className="operation-runner-wrapper stacked">
        <div className="operation-runner">
          {this.renderFeedSelection()}
        </div>
        <div className="above">
          <div>
            {operationMessage}
          </div>
          <div>
            {feedSelectionMessage}
          </div>
        </div>
        <div className="below">
          {gitStatusMessage}
        </div>
      </div>
    );
  }
}

export default withStore<OperationRunnerProps>(observer(OperationRunner));
