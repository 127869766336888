import api from '@stores/api';

const URL = `${window.location.origin}/api/images/svgx`;

export default {
  /**
   * GET complete list of images
   */
  getImages: async (): Promise<any[]> => {
    const response = await api.http.authenticatedGet(URL);
    if (response.data) {
      return response.data;
    }
    return [];
  },
};
