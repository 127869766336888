import httpHelper from './http';
import socketHelper from './socket';

export default {
  init,
};

interface InitFunction {
  httpUrl: string;
  httpCallback(response): void;
  socketUrl: string;
  socketEmitEvent?: string;
  socketEmitEventArgs?: any[];
  socketOnEvent: string;
  socketOnEventCallback(...args): void;
}

/*
 * This function allows to initialize some data while opening a websocket connection to update said data in realtime.
 *
 * @param {string} httpUrl - Url to call which will initialize dataset
 * @param {function} httpCallback - Response handler
 * @param {string} socketUrl - Url to initialize websocket connectionD
 * @param {string} socketEmitEvent - Namespace of event which will be triggered when called
 * @param {array} socketEmitEventArgs - Arguments to be passed when triggering event
 * @param {string} socketOnEvent - Namespace of event listener
 * @param {function} socketOnEventCallback - Function to be called when event listener is triggered
 */
async function init({
                      httpUrl,
                      httpCallback,
                      socketUrl,
                      socketEmitEvent,
                      socketEmitEventArgs,
                      socketOnEvent,
                      socketOnEventCallback,
                    }: InitFunction) {
  const response = await httpHelper.authenticatedGet(httpUrl);
  httpCallback(response);

  const socket = socketHelper.init(socketUrl);

  if (socketEmitEvent) {
    socket.emit(socketEmitEvent, ...socketEmitEventArgs);
  }

  socket.on(socketOnEvent, socketOnEventCallback);

  return socket;
}
