import React, { ReactElement } from 'react';
import cx from 'classnames';

import './Button.scss';
import { KEYBOARD_KEYS } from '@constants';

interface ButtonProps {
  value: string;
  data?: any;
  keyEvent?: any;
  disable?: boolean;
  className?: string;
  imageLeft?: ReactElement<any>;
  imageRight?: ReactElement<any>;
  onClick(data: ButtonProps['data']): void;
  style?: any;
}

class Button extends React.PureComponent<ButtonProps> {

  handleEnterKeyPress = async (event) => {
    if (event.keyCode === KEYBOARD_KEYS.ENTER) {
      event.stopPropagation();
      return this.props.keyEvent(event);
    }
  }

  handleOnclick = () => {
    const { onClick, data, disable } = this.props;
    if (disable) {
      return;
    }

    onClick(data);
  }

  render() {
    const { value, className, disable, imageLeft, imageRight, style, keyEvent } = this.props;

    const classNames = cx('transit-button', {
      [className]: className,
      'disabled': disable,
    });

    return (
      <div
        className={classNames}
        onClick={this.handleOnclick}
        style={style}
        onKeyUp={(event) => this.handleEnterKeyPress(event)} // tslint:disable-line:ter-arrow-parens
        tabIndex={0}
      >
        <span>{imageLeft} {value} {imageRight}</span>
      </div>
    );
  }
}

export default Button;
