import { SortDirection } from 'react-virtualized';

export {
  sortOperationIds as operationIds,
};

/*
 * The operationAndNotices store is based off of a Map<number, Operation> validOperationByOperationId.
 * But the views are based off of the operationIds.
 * In order to sort the operationIds, we use the validOperationByOperationId Map and return the sorted operationIds.
 * @param {Map<number, Operation>} operationByOperationId - A Map of operations by their operationId
 * @param {string} sortBy - The column in the operationsUI table to be used to determine the which field to sort by
 * @param {string} sortDirection - The direction to sort. Can either be ascending or descending.
 */
function sortOperationIds(operationByOperationId, sortBy, sortDirection) {
  const option = {
    sortBy,
    collator: getCollator(),
    ordering: getOrdering(sortDirection),
  };

  const operationByOperationIdEntries = [...operationByOperationId];
  let sortedOperationByOperationIdEntries;

  switch (sortBy) {
    case 'feed':
      option.sortBy = 'feed_code';
      sortedOperationByOperationIdEntries = sortByFeedKey(operationByOperationIdEntries, option);
      break;
    case 'stars':
      sortedOperationByOperationIdEntries = sortByFeedKey(operationByOperationIdEntries, option);
      break;
    case 'started_by':
    case 'started_at':
      sortedOperationByOperationIdEntries = sortByOperationStepKey(operationByOperationIdEntries, option);
      break;
    case 'GET':
    case 'PRE':
    case 'TRA':
    case 'EXT':
    case 'ENV':
    case 'FLX':
    case 'CLE':
    case 'ENH':
    case 'CTN':
    case 'VAL':
    case 'COM':
    case 'BSM':
    case 'UPF':
    case 'MAP':
    case 'UPM':
      sortedOperationByOperationIdEntries = sortByStepCode(operationByOperationIdEntries, option);
      break;
    default:
      option.sortBy = 'started_at';
      option.ordering = -1; // Descending Direction
      sortedOperationByOperationIdEntries = sortByOperationStepKey(operationByOperationIdEntries, option);
  }

  return sortedOperationByOperationIdEntries.map(operationByOperationIdEntries => operationByOperationIdEntries[0]);
}

function sortByFeedKey(operationByOperationIdEntries, option) {
  return operationByOperationIdEntries.sort(([, operationA], [, operationB]) => {
    const compareResult = compareByFeedKey(operationA, operationB, option);
    if (compareResult === 0) {
      return defaultCompare(operationA, operationB, option);
    }

    return compareResult;
  });
}

function compareByFeedKey(operationA, operationB, { sortBy, collator, ordering }) {
  const feedA = operationA.feed;
  const feedB = operationB.feed;

  return ordering * collator.compare(feedA[sortBy], feedB[sortBy]);
}

function sortByOperationStepKey(operationByOperationIdEntries, option) {
  return operationByOperationIdEntries.sort(([, operationA], [, operationB]) => {
    const compareResult = compareByOperationStepKey(operationA, operationB, option);
    if (compareResult === 0) {
      return defaultCompare(operationA, operationB, option);
    }

    return compareResult;
  });
}

function compareByOperationStepKey(operationA, operationB, { sortBy, collator, ordering }) {
  return ordering * collator.compare(operationA[sortBy], operationB[sortBy]);
}

function sortByStepCode(operationByOperationIdEntries, option) {
  return operationByOperationIdEntries.sort(([, operationA], [, operationB]) => {
    const compareResult = compareByStepCode(operationA, operationB, option);
    if (compareResult === 0) {
      return defaultCompare(operationA, operationB, option);
    }

    return compareResult;
  });
}

const sortOrderByStatus = {
  'completed': 7,
  'started': 6,
  'rejected': 5,
  'failed': 4,
  'killed': 3,
  'unchanged': 2,
  'waiting': 1,
};

function compareByStepCode(operationA, operationB, { sortBy, ordering }) {
  const operationStepA = operationA.operationSteps.find(operationStep => operationStep.step === sortBy);
  const operationStepB = operationB.operationSteps.find(operationStep => operationStep.step === sortBy);

  if (!operationStepA) {
    return ordering * -1;
  }

  if (!operationStepB) {
    return ordering;
  }

  const orderOperationA = sortOrderByStatus[operationStepA.status] || 0;
  const orderOperationB = sortOrderByStatus[operationStepB.status] || 0;

  return ordering * (orderOperationA - orderOperationB);
}

/*
 * When comparing two operations, if they are equal based off the comparison key, the fallback behaviour for sorting the
 * operations should be:
 *    - try and order by priority (stars)
 *    - if the above fails, try and order by feed code (feed_code)
 *    - if the above fails try and order by timestamp (started_at)
 */
function defaultCompare(operationA, operationB, { collator }) {
  let compareResult = compareByFeedKey(
    operationA,
    operationB,
    {
      collator,
      ordering: -1, // Descending Direction
      sortBy: 'stars',
    },
  );

  if (compareResult !== 0) {
    return compareResult;
  }

  compareResult = compareByFeedKey(
    operationA,
    operationB,
    {
      collator,
      ordering: 1, // Ascending Direction
      sortBy: 'feed_code',
    },
  );

  if (compareResult !== 0) {
    return compareResult;
  }

  return compareByOperationStepKey(
    operationA,
    operationB,
    {
      collator,
      ordering: -1, // Descending Direction
      sortBy: 'started_at',
    },
  );
}

function getCollator() {
  return new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

function getOrdering(sortDirection) {
  return (sortDirection === SortDirection.DESC ? -1 : 1);
}
