import React from 'react';
import { Carousel } from 'react-bootstrap';

import './CarouselInput.scss';

interface CarouselInputProps {
  carouselSlides: any[];
  newItem(): any;
  handleIndex: any;
  carouselSlidesIndex: number;
  children: any;
}

class CarouselInput extends React.Component<CarouselInputProps> {
  renderContentChange = async (selectedIndex) => {
    await this.props.handleIndex(selectedIndex);
  }

  handleNewItem = () => {
    const { carouselSlidesIndex, handleIndex, newItem } = this.props;
    const newIndex = carouselSlidesIndex + 1;
    newItem(); // pushes empty config to array in the form's parent component
    handleIndex(newIndex); // changes index in Parent to update components that display form values
  }

  renderCarouselItem(content, index) {
    const { carouselSlides, carouselSlidesIndex } = this.props;
    const indexLength = carouselSlides.length - 1;

    if (carouselSlidesIndex === indexLength) {
      return (
        <Carousel.Item key={index}>
          <div className="content-row">
            {this.props.children(content)}
          </div>
          <div className="add-slide-button" onClick={this.handleNewItem}>+</div>
        </Carousel.Item>
      );
    }

    return (
        <Carousel.Item key={index}>
          <div className="content-row">
          {this.props.children(content)}
          </div>
        </Carousel.Item>
    );
  }

  renderCarouselItems(carouselSlides) {
    return carouselSlides.map((content, index) => this.renderCarouselItem(content, index));
  }

  render() {
    const { carouselSlides, carouselSlidesIndex } = this.props;

    return (
      <Carousel
        activeIndex={carouselSlidesIndex}
        interval={null}
        onSelect={this.renderContentChange}
        controls={true}
        wrap={false}
        slide={false}
        indicators={false}
      >
        {this.renderCarouselItems(carouselSlides)}
      </Carousel>
    );
  }
}

export default CarouselInput;
