import { STORAGE_TYPES } from '@constants';

export default class StorageManager {
  storage: Storage;
  namespace: string;

  constructor(storageType: STORAGE_TYPES, namespace: string) {
    if (namespace.length === 0) {
      throw new Error(`A namespace has to be provided. Namespace ${namespace} was provided.`);
    }

    this.namespace = `${namespace}-`;
    this.storage = window[storageType];
  }

  get(key) {
    const data = this.storage.getItem(`${this.namespace}${key}`);
    if (data !== 'undefined') {
      return JSON.parse(data);
    }

    return null;
  }

  set(key, data) {
    const isStorageAvailable = this.isStorageAvailable();
    if (isStorageAvailable) {
      this.storage.setItem(`${this.namespace}${key}`, JSON.stringify(data));
    }
  }

  remove(key) {
    this.storage.removeItem(`${this.namespace}${key}`);
  }

  private isStorageAvailable() {
    try {
      const storageTest = '__storage_test__';
      this.storage.setItem(storageTest, storageTest);
      this.storage.removeItem(storageTest);
      return true;
    } catch (error) {
      return error instanceof DOMException && (
          // everything except Firefox
        error.code === 22 ||
        // Firefox
        error.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        error.name === 'QuotaExceededError' ||
        // Firefox
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        this.storage.length !== 0;
    }
  }
}
