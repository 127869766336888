export const STEPS = {
  GET: {
    name: 'GET',
    order: 1,
  },
  PRE: {
    name: 'PRE',
    order: 2,
  },
  TRA: {
    name: 'TRA',
    order: 3,
  },
  EXT: {
    name: 'EXT',
    order: 5,
  },
  ENV: {
    name: 'ENV',
    order: 6,
  },
  FLX: {
    name: 'FLX',
    order: 7,
  },
  CLE: {
    name: 'CLE',
    order: 8,
  },
  ENH: {
    name: 'ENH',
    order: 9,
  },
  CTN: {
    name: 'CTN',
    order: 10,
  },
  VAL: {
    name: 'VAL',
    order: 11,
  },
  COM: {
    name: 'COM',
    order: 13,
  },
  UPF: {
    name: 'UPF',
    order: 14,
  },
  BSM: {
    name: 'BSM',
    order: 15,
  },
  MAP: {
    name: 'MAP',
    order: 16,
  },
  UPM: {
    name: 'UPM',
    order: 17,
  },
  FIC: {
    name: 'FIC',
    order: 18,
  },
};
