import { FormikProps, FormikValues } from 'formik';
import React from 'react';

import { HTML_TAGS } from '@constants';
import { FormColumn, FormInput, FormRow } from '@components/Form/CommonForm';

import { BannerFormContent } from '@stores/banners';
import store from '@stores';

import '@components/Form/CommonForm.scss';
import DropdownSvgSearch from '@components/Dropdown/DropdownSvgSearch';

interface BannerCarouselFormProps {
  store?: typeof store;
  content: BannerFormContent;
  contentIndex: number;
  formik: FormikProps<FormikValues>;
  svgList: any;
  onChange(key: string, value: any): void;
}

interface BannerCarouselFormState {}

class BannerCarouselForm extends React.Component<BannerCarouselFormProps, BannerCarouselFormState> {

  getPath(key) {
    const { contentIndex } = this.props;

    return `content[${contentIndex}].${key}`;
  }

  render() {
    const { content, contentIndex, formik, svgList } = this.props;

    return (
        <React.Fragment>
          <FormRow>
            <FormColumn>
              <FormInput
                field={this.getPath('locale')}
                label="Language"
                value={content.locale}
                formType={HTML_TAGS.DROPDOWN}
                defaultValue=""
                itemLabelByItemValue={{
                  '': 'Select...',
                  'en': 'English',
                  'fr': 'French',
                  'es': 'Spanish',
                  'de': 'German',
                  'it': 'Italian',
                  'pt': 'Portuguese',
                  'nl': 'Dutch',
                }}
              />
            </FormColumn>
            <FormColumn>
            {/*  <FormInput*/}
            {/*    field={this.getPath('image')}*/}
            {/*    label="Image"*/}
            {/*    value={content.image}*/}
            {/*    formType={HTML_TAGS.CUSTOM}*/}
            {/*    render={() => {*/}
            {/*      return (*/}
            {/*        <DropdownSvgSearch*/}
            {/*          svgList={svgList}*/}
            {/*          onChange={this.props.onChange}*/}
            {/*          field={this.getPath('image')}*/}
            {/*          label={[content.image]}*/}
            {/*        />*/}
            {/*      );*/}
            {/*    }}*/}
            {/*  />*/}
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormInput
                field={this.getPath('title')}
                label="Title"
                value={content.title}
                formType={HTML_TAGS.CUSTOM}
                render={(value, props, isTouched, errors, optionalClassName) => {
                  const { supported_layouts, content } = formik.values;
                  const maxCharacterCount = Array.isArray(supported_layouts) && supported_layouts.includes('royale')
                    ? 100
                    : 60;

                  const title = content[this.props.contentIndex] && content[this.props.contentIndex].title || '';
                  const characterCount = maxCharacterCount
                    ? (
                      <div className="char-count">
                        {title.length}/{maxCharacterCount}
                      </div>
                    )
                    : null;

                  const renderError = (isTouched, errors) => {
                    if (isTouched && errors) {
                      return (
                        <div className="yup-error">
                          {errors}
                        </div>
                      );
                    }

                    return null;
                  };

                  const handleChange = (key, value) => {
                    formik.setFieldValue(key, value);
                  };

                  return (
                    <div className={`common-form-input ${optionalClassName}`}>
                      {renderError(isTouched, errors)}
                      <input
                        name={props.field}
                        type={props.inputType}
                        value={value}
                        onClick={props.onClick}
                        list={props.inputList}
                        readOnly={props.readOnly}
                        onChange={event => handleChange(event.target.name, event.target.value)} // tslint:disable-line:jsx-no-lambda max-line-length
                        onBlur={event => formik.setFieldTouched(event.target.name)} // tslint:disable-line:jsx-no-lambda
                      />
                      {characterCount}
                    </div>
                  );
                }}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormInput
                field={this.getPath('body')}
                label="Body"
                value={content.body}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn
              style={{
                paddingBottom: 'unset',
              }}
            >
              <FormInput
                field={this.getPath('url')}
                label="URL"
                value={content.url}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn
              style={{
                paddingTop: 'unset',
              }}
            >
              <FormInput
                field={`content[${contentIndex}].tracking`}
                formType={HTML_TAGS.CHECKBOX}
                checkboxLabel="enable analytics tracking (out-of-app only)"
                value={content.tracking}
              />
            </FormColumn>
          </FormRow>
        </React.Fragment>
    );
  }
}

export default BannerCarouselForm;
