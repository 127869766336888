import React from 'react';
import * as Yup from 'yup';
import { cloneDeep } from 'lodash';
import Papa from 'papaparse';

import { BulkInvitation, Invitation } from '@server/auth/invitations/invitation.types';
import { userAuthTemplate, UserProfile } from '@server/users/user.types';
import { FormColumn, FormInput, FormRow, FormSection } from '@components/Form/CommonForm';
import CommonFormWrapper from '@components/Form/CommonFormWrapper';
import { HTML_TAGS } from '@constants';
import JsonUI from '@components/ToolComponents/JsonUI';
import Tooltip from '@components/Tooltip/Tooltip';

interface InvitationFormProps {
  existingUsers: UserProfile[];
  formData?: Partial<Invitation>;
  invitationExists: boolean;

  /**
   * Note that this doesn't behave like a typical form onSubmit -- it's invoked
   * multiple times, once for each invitation to be sent. (Not sure why it was
   * set up this way, but that's how it is.)
   */
  onSubmit: (form: Invitation) => Promise<void> | void;
  children: any;
}

type InvitationKeys = keyof BulkInvitation;
export default class InvitationForm extends React.Component<InvitationFormProps, any> {
  validationSchema: { [key in InvitationKeys]: any } = {
    userIdentifiers: Yup.array().of(Yup.object().shape({
      displayName: Yup.string().required('Name is required'),
      email: Yup
        .string()
        .email()
        .required('Email is required')
        .test('email', 'Email already exists', (email) => {
          // When we're updating an invitation, the invitation already exists. No need to check for this
          if (this.props.invitationExists) {
            return true;
          }

          const emailExists = this.props.existingUsers.find(user => user.email === email);
          if (emailExists) {
            return false;
          }

          return true;
        }),
      language: Yup.string().required(),
    })),
    groupId: Yup.string().nullable(),
    expiryDate: Yup.date().required('Date of expiry is required'),
    initialAuth: Yup.object().shape({
      isAuthorized: Yup.boolean(),
      feedAccess: Yup.array().of(Yup.string()),
      role: Yup.string().required(),
      routerAccess: Yup.array(Yup.string()),
    }),
    sendEmail: Yup.boolean().required('Send email confirmation is required'),
  };

  initialFormData: BulkInvitation = {
    userIdentifiers: [{
      displayName: this.props.formData.displayName || undefined,
      email: this.props.formData.email || undefined,
      language: this.props.formData.language || 'en',
    }],
    groupId: this.props.formData.groupId || undefined,
    expiryDate: this.props.formData.expiryDate || undefined,
    initialAuth: this.props.formData.initialAuth || undefined,
    sendEmail: this.props.formData.sendEmail || true,
  };

  getPath(key, index) {
    return `userIdentifiers[${index}].${key}`;
  }

  async addUsersUsingFile(event, formik) {
    const file = event.target.files[0];
    if (file) {
      await Papa.parse(file, {
        header: true,
        worker: true,
        skipEmptyLines: true,
        complete: (results) => {
          console.log('Adding users from imported file...');  // tslint:disable-line no-console
          const userIdentifiers = cloneDeep(formik.values.userIdentifiers);

          results.data.forEach((user) => {
            const displayName = `${user['First name']} ${user['Last name']}`;
            const email = user['Username'];

            if (!displayName || !email) {
              return;
            }

            const emailExists = this.props.existingUsers.find(user => user.email === email);
            if (emailExists) {
              console.log(`${displayName} (${email}) already exists. Will not add user...`); // tslint:disable-line no-console max-line-length
              return;
            }

            const lastUserIdentifier = userIdentifiers[userIdentifiers.length - 1];

            if (!lastUserIdentifier.displayName && !lastUserIdentifier.email) {
              lastUserIdentifier.displayName = displayName;
              lastUserIdentifier.email = email;
            } else {
              userIdentifiers.push({
                displayName,
                email,
                language: 'en',
              });
            }
          });

          formik.setFieldValue('userIdentifiers', userIdentifiers);
        },
      });
    }
  }

  renderUserIdentifiers(formik) {
    return formik.values.userIdentifiers.map((userIdentifier, index) => {
      return (
        <React.Fragment key={`userIdentifier-${index}`}>
          <FormRow>
            <div className="flex align-items-center">
              {index + 1}.
            </div>
            <FormColumn>
              <FormInput
                field={this.getPath('displayName', index)}
                label={index === 0 ? 'Full Name' : null}
                value={formik.values.userIdentifiers[index].displayName}
                formType={HTML_TAGS.INPUT}
              />
            </FormColumn>
            <FormColumn>
              <FormInput
                field={this.getPath('email', index)}
                label={index === 0 ? 'Email' : null}
                value={formik.values.userIdentifiers[index].email}
                formType={HTML_TAGS.INPUT}
              />
            </FormColumn>
            <FormColumn>
              <FormInput
                value={formik.values.userIdentifiers[index].language}
                label={index === 0 ? 'Language' : null}
                field={this.getPath('language', index)}
                defaultValue={'en'}
                formType={HTML_TAGS.DROPDOWN}
                /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
                itemLabelByItemValue={{
                  en: 'English',
                  fr: 'French',
                }}
              />
            </FormColumn>
            {
              formik.values.userIdentifiers.length > 1
                ? (
                    <div
                      className="d-flex items-center cursor-pointer"
                      onClick={() => {
                        const userIdentifiers = cloneDeep(formik.values.userIdentifiers);
                        userIdentifiers.splice(index, 1); // remove one element from index
                        formik.setFieldValue('userIdentifiers', userIdentifiers);
                      }}
                    >
                      <i className="fa fa-trash-o trash-icon" aria-hidden="true" />
                    </div>
                  )
                : null
            }
          </FormRow>
          {
            !this.props.invitationExists && index === formik.values.userIdentifiers.length - 1
              ? (
                <div className="flex justify-content-between cursor-pointer" style={{ marginTop: '15px' }}>
                  <div
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      const userIdentifiers = cloneDeep(formik.values.userIdentifiers);
                      userIdentifiers.push({
                        displayName: undefined,
                        email: undefined,
                        language: 'en',
                      });
                      formik.setFieldValue('userIdentifiers', userIdentifiers);
                    }}
                  >
                    Add another user
                  </div>
                  <div className="flex">
                    <input
                      id="file"
                      type="file"
                      multiple={false}
                      onChange={event => this.addUsersUsingFile(event, formik)}
                    />
                    <Tooltip
                      message="Import users using CSV file"
                      placement="top-end"
                      /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
                      render={() => (
                        <div className="question-circle">
                          <i className="question-mark fa fa-question-circle"/>
                        </div>
                      )}
                    />
                  </div>
                </div>
                )
              : null
          }
        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <CommonFormWrapper
        validationSchema={this.validationSchema}
        formInfo={this.initialFormData}
        onSubmit={async (invitationForm) => {
          const invitations: Invitation[] = [];
          invitationForm.userIdentifiers.forEach((userIdentifier) => {
            invitations.push({
              expiryDate: invitationForm.expiryDate,
              initialAuth: invitationForm.initialAuth,
              sendEmail: invitationForm.sendEmail,
              displayName: userIdentifier.displayName,
              email: userIdentifier.email,
              language: userIdentifier.language,
            });
          });

          for (const invitation of invitations) {
            await this.props.onSubmit(invitation);
          }
        }}
      >
      {(formik) => {
        return (
          <React.Fragment>
            <FormSection title="Invitation">
              {this.renderUserIdentifiers(formik)}
            </FormSection>
            <FormSection title="Other">
              <FormRow>
                <FormColumn>
                  <FormInput
                    formType={HTML_TAGS.CUSTOM}
                    render={() => {
                      return (
                        <JsonUI
                          value={{ data: { expiryDate: formik.values.expiryDate } }}
                          isJsonEditorMode={false}
                          onChange={({ expiryDate }) => { // tslint:disable jsx-no-lambda
                            formik.setFieldValue('expiryDate', expiryDate);
                          }}
                          template={{
                            data: {
                              expiryDate: 'YYYY-MM-DD',
                            },
                          }}
                        />
                      );
                    }}
                  />
                </FormColumn>
                <FormColumn>
                  <FormInput
                    formType={HTML_TAGS.CUSTOM}
                    render={() => {
                      return (
                        <JsonUI
                          value={{ data: { sendEmail: formik.values.sendEmail } }}
                          isJsonEditorMode={false}
                          onChange={({ sendEmail }) => { // tslint:disable jsx-no-lambda
                            formik.setFieldValue('sendEmail', !!sendEmail);
                          }}
                          template={{
                            data: {
                              sendEmail: false,
                            },
                          }}
                        />
                      );
                    }}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FormInput
                    field="groupId"
                    label="Group ID"
                    value={formik.values.groupId}
                    formType={HTML_TAGS.INPUT}
                  />
                </FormColumn>
                <FormColumn />
              </FormRow>
            </FormSection>
            <FormSection title="Authorization">
              <FormRow>
                <FormInput
                  formType={HTML_TAGS.CUSTOM}
                  render={() => {
                    return (
                      <JsonUI
                        value={{ data: formik.values.initialAuth }}
                        isJsonEditorMode={false}
                        onChange={(authConfig) => { // tslint:disable jsx-no-lambda
                          formik.setFieldValue('initialAuth', authConfig);
                        }}
                        template={{
                          data: userAuthTemplate,
                        }}
                      />
                    );
                  }}
                />
              </FormRow>
            </FormSection>
            {this.props.children(formik)}
          </React.Fragment>
        );
      }}
      </CommonFormWrapper>
    );
  }
}
