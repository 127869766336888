import React from 'react';
import cx from 'classnames';

import './Input.scss';
import Tooltip from '@components/Tooltip/Tooltip';

interface InputProps {
  hide?: boolean;
  originalValue?: string;
  value?: string;
  onChange?(value: string) : void;
  validate?(value: string): { warning?: string, error?: string };
}

interface InputState {
  originalValue: string;
  error: string;
  warning: string;
}

class Input extends React.PureComponent<InputProps, InputState> {

  inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(props) {
    super(props);

    const { warning, error } = props.validate ? props.validate(props.value) : { warning: '', error: '' };

    this.state = {
      warning,
      error,
      originalValue: props.originalValue,
    };
  }

  get value() {
    return this.inputRef.current
     ? this.inputRef.current.value
     : '';
  }

  handleInputClick = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  handleOnChange = () => {
    const { onChange, validate } = this.props;

    if (onChange) {
      onChange(this.value);
    }

    if (validate) {
      const { warning = '', error = '' } = validate(this.value);
      this.setState({ warning, error });
    }

    this.forceUpdate();
  }

  renderInput() {
    const { value } = this.props;
    const { error, originalValue, warning } = this.state;

    if (!error && !warning) {
      return (
        <input
          ref={this.inputRef}
          type="text"
          value={value}
          placeholder={originalValue}
          onChange={this.handleOnChange}
        />
      );
    }

    return (
      <React.Fragment>
        <input
          ref={this.inputRef}
          type="text"
          value={value}
          placeholder={originalValue}
          onChange={this.handleOnChange}
          style={{ maxWidth: '90%' }}
        />
        <div className="absolute" style={{ right: '5%' }}>
          <Tooltip
            message={error || warning}
            placement="top-end"
            /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
            render={() => (
              <div className="question-circle">
                <i className="question-mark fa fa-question-circle"/>
              </div>
            )}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { hide } = this.props;
    const { error, warning } = this.state;

    const inputClassName = cx('transit-input flex', {
      'is-hidden': hide,
      'is-error': error,
      'is-warning': warning,
    });

    return (
      <div className={inputClassName} onClick={this.handleInputClick}>
        {this.renderInput()}
      </div>
    );
  }
}

export default Input;
