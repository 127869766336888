import React from 'react';
import copy from 'copy-to-clipboard';
import cx from 'classnames';

import { FeedInfo } from '@stores/transit';
import variables from '@styles/variables';

import '../styles/SearchResultItem.scss';

interface SearchResultItemProps {
  feed: FeedInfo;
  isActive?: boolean;
  onSelect(feed): void;
  onEnter(): void;
}

interface SearchResultItemState {
  isFeedCodeCopied: boolean;
}

class SearchResultItem extends React.Component<SearchResultItemProps, SearchResultItemState> {

  state = {
    isFeedCodeCopied: false,
  };

  handleOnFeedSelect = () => {
    this.props.onSelect(this.props.feed);
  }

  resetCopyFeedbackTimeout: any;
  handleCopyFeedCode = async (event: any) => {
    event.stopPropagation();
    const { feed } = this.props;
    copy(feed.feed_code);

    await this.setState({ isFeedCodeCopied: true });
    this.resetCopyFeedbackTimeout = setTimeout(() => {
      this.setState({ isFeedCodeCopied: false });
    }, 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.resetCopyFeedbackTimeout);
  }

  render() {
    const { feed, isActive, onEnter } = this.props;
    const { isFeedCodeCopied } = this.state;

    const wrapperClass = cx({
      ['is-active']: isActive,
    });

    let status;
    switch (this.props.feed.in_beta) {
      case 0:
        status = (
          <div style={{ marginLeft: '5px', color: variables.transitgreen }}>
            Production
          </div>
        );
        break;
      case 1:
        status = (
          <div style={{ marginLeft: '5px', color: variables.transitorange }}>
            Beta
          </div>
        );
        break;
      case 2:
        status = (
          <div style={{ marginLeft: '5px', color: variables.transitred }}>
            Alpha
          </div>
        );
        break;
      case 3:
        status = (
          <div style={{ marginLeft: '5px', color: variables.transitred }}>
            Disabled
          </div>
        );
        break;
    }

    return (
        /*
         * Use onMouseDownCapture instead of onClick because of the use case when this is within a dropdown.
         * In this edge-case, the dropdown closes before the onClick event is fired.
         * To properly capture the onClick, we use onMouseDownCapture
         */
      <div
        className={`search-agency-list-item ${wrapperClass}`}
        onMouseDownCapture={this.handleOnFeedSelect}
        onMouseEnter={onEnter}
      >
        <div className="flex search-result-bold">
          {this.props.feed.feed_name}
          {status}
        </div>
        <div className="search-result-grey">
          <span>{feed.feed_code} ({feed.feed_location})</span>
        </div>
        <div className="copy-button-wrapper" onClick={this.handleCopyFeedCode}>
          <div className="copy-button disable-mark-highlight">
            <i className="fa fa-clone"/> {isFeedCodeCopied ? 'copied !' : feed.feed_code}
          </div>
        </div>
      </div>
    );
  }
}

export default SearchResultItem;
