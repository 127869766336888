/* tslint:disable:max-line-length */
import { matchRoutes, renderRoutes } from 'react-router-config';
import queryString from 'query-string';
import { isEqual } from 'lodash';
import anymatch from 'anymatch';
import auth from '@stores/auth';
import { Route } from './routes';
import { UserProfile } from '@server/users/user.types';

export interface RouterUrlData {
  pathname: string;
  query: { [key: string]: any };
  params: { [key: string]: string };
  fragment: string;
}

interface CreateUrlOptions {
  path: string;
  params?: object;
  query?: object;
  search?: string;
  fragment?: string;
}
export class RouterStore {
  public history;
  public routes: Route[];
  public authorizedRoutes: Route[] = [];
  private onAuthorizedRoutesChange = (authorizedRoutes) => {};

  get location() {
    return this.history && this.history.location;
  }

  /**
   * Use to render the appropriate route based on the routes provided.
   * @param routes all the routes that can be rendered,
   *  the router will make sure to render the correct one based on the URL
   * @param props props of the newly rendered route.
   *  should be used to populate the newly rendered route with specific data.
   * Note:
   *  - this function will make sure that we keep the same component
   *    when navigating to another route that renders the same component.
   *    This is useful when having to show a modal as child route.
   */
  public renderRoute(routes: Route[], props: object) {
    const components = new Map();

    routes.forEach((route: Route) => {
      if (!components.has(route.component)) {
        const key = route.key || route.path;
        components.set(route.component, key);
      }
      if (route.key) {
        return;
      }
      route.key = components.get(route.component);
    });

    return renderRoutes(routes, props);
  }

  /**
   * can be called only once.
   * give the router an instance of history object created by React-router
   * this let us control the history of the browser
   */
  public initialize({ history, routes, onAuthorizedRoutesChange }) {
    this.history = history;
    this.history.listen(this.onLocationChange.bind(this));
    this.onAuthorizedRoutesChange = onAuthorizedRoutesChange;

    const normalizedRoutes = this.normalizeRoutesWithMultiplePaths(routes);
    this.routes = this.ensureSpecificRoutesTakePrecedence(normalizedRoutes);
    this.updateAuthorizedRoutes();
  }

  ensureSpecificRoutesTakePrecedence(routes: Route[]) : Route[] {
    return routes.map((route) => {
      if (!route.routes) {
        return route;
      }

      route.routes = this.ensureSpecificRoutesTakePrecedence(route.routes).sort((subRouteA, subRouteB) => {
        const precedenceA = subRouteA.path.split('/').length;
        const precedenceB = subRouteB.path.split('/').length;

        return precedenceB - precedenceA;
      });

      return route;
    });
  }

  normalizeRoutesWithMultiplePaths(routes: Route[]) {
    return routes.map((route) => {
      if (!route.routes) {
        return route;
      }

      const basePath = route.path;

      route.routes = this.normalizeRoutesWithMultiplePaths(route.routes).reduce((acc: Route[], childRoute) => {
        if (childRoute.paths) {
          let linkNameDefined = false;
          childRoute.paths.forEach((path) => {
            const newChildRoute: Route = Object.assign({}, childRoute);
            delete newChildRoute.paths;

            // avoid adding duplicate links
            if (linkNameDefined) {
              delete newChildRoute.linkName;
            } else if (route.linkName) {
              linkNameDefined = true;
            }

            newChildRoute.basePath = basePath;
            newChildRoute.path = `${basePath}${path}`;
            acc.push(newChildRoute);
          });
        }

        if (childRoute.path) {
          childRoute.basePath = basePath;
          childRoute.path = `${basePath}${childRoute.path}`;
          acc.push(childRoute);
        }

        return acc;
      }, []);

      return route;
    });
  }

  /**
   * Listen to any change made in the history stack
   * if pathname changed, the router will enforce authentication and basic redirection if needed
   */
  private onLocationChange(newLocation) {
    return this.redirect(newLocation.pathname);
  }

  /**
   * Change current page by adding a new location to history stack
   * @param path url of the new page
   */
  public push(url: string) {
    return this.pushOrRedirect(url, 'push');
  }

  /**
   * Change current page by replacing current location in the history stack
   * @param path url of the new page
   *
   * Note forceRedirect should be used only if you want to resolve the currentPath
   * to a fallback path. The main use case if for the initial view,
   * when we want to make sure we on a viewable URL path
   */
  public redirect(url: string) {
    return this.pushOrRedirect(url, 'replace');
  }

  private pushOrRedirect(url, method: 'replace' | 'push') {
    if (!this.history) {
      return false;
    }

    const currentUrl = this.getCurrentUrl();
    const redirectTo = currentUrl === '/401' || currentUrl === '/404' || currentUrl === '/login' ? '/' : currentUrl;
    const resolvedUrlData = this.resolveUrl(url);

    const shouldAdjustDestinationPath = !url.startsWith('/404') && (!resolvedUrlData || resolvedUrlData.route.path === '/404');
    if (shouldAdjustDestinationPath) {
      const resolvedPathOnAllRoutes = this.resolveUrl(url, this.routes);
      const pathRequiredAuth = !resolvedUrlData || resolvedPathOnAllRoutes.route !== resolvedUrlData.route;

      const shouldGoToLoginPage = pathRequiredAuth && !auth.isLoggedIn;
      if (shouldGoToLoginPage) {
        const loginUrl = this.createUrlWithData({ path: '/login', query: { redirectTo } });
        return this.history[method](loginUrl);
      }

      const shouldGoToUnauthorized = pathRequiredAuth && auth.isLoggedIn;
      if (shouldGoToUnauthorized) {
        return this.history[method]('/401');
      }

      return this.history[method]('/404');
    }

    const { url: resolvedUrl, route } = resolvedUrlData;
    const isLocationAuthorized = this.canAccessRoute(route);

    if (!isLocationAuthorized) {
      const loginUrl = this.createUrlWithData({ path: '/login', query: { redirectTo } });
      return this.history[method](loginUrl);
    }

    const urlChanged = currentUrl !== resolvedUrl;
    if (!urlChanged) {
      return;
    }

    if (!auth.isLoggedIn && resolvedUrl === '/login') {
      const loginUrl = this.createUrlWithData({ path: '/login', query: { redirectTo } });
      return this.history[method](loginUrl);
    }

    return this.history[method](resolvedUrl);
  }

  private getCurrentUrl() : string {
    const { pathname, search, hash } = this.location;
    return pathname + search + hash;
  }

  /**
   * shorthand method for this.redirect(location.pathname, location)
   * @param location location to redirect to
   */
  public resolveToCurrentPath() {
    const currentURL = this.location.pathname;
    this.redirect(currentURL);
  }

  public getNameSpaceQueryData(namespace: string) {
    const { query } = this.getUrlData();
    return query[namespace];
  }

  public setNameSpaceQueryData(namespace: string, data: object | string | number) {
    const currentUrlData = this.getNameSpaceQueryData(namespace);
    const hasChanged = !isEqual(currentUrlData, data);
    if (!hasChanged) {
      return;
    }

    router.updateUrlData({
      query: {
        [namespace]: data,
      },
    });
  }

  public defaultParams: object = {};
  public updateDefaultParams(updatedParams) {
    Object.assign(this.defaultParams, updatedParams);
  }

  /**
   * update current URL with new Data
   */
  public updateUrlData(urlData: Partial<RouterUrlData>) {
    const currentUrlData = this.getUrlData();

    const newQuery = Object.assign({}, currentUrlData.query, urlData.query);
    const newParams = Object.assign({}, this.defaultParams, currentUrlData.params, urlData.params);

    const currentRoute = this.getCurrentRouteConfig();
    const newUrl = this.createUrlWithData({
      path: urlData.pathname || currentRoute.path,
      fragment: urlData.fragment || currentUrlData.fragment,
      params: newParams,
      query: newQuery,
    });

    this.redirect(newUrl);
  }

  /**
   * get the data held in the URL (ie: query, hash, params)
   */
  public getUrlData() : RouterUrlData {
    const { pathname, search = '', hash: stringHash } = this.location;

    const query = this.parseRouterUrlData(search);
    const fragment = stringHash.charAt(0) === '#' ? stringHash.slice(1) : stringHash; // from "#5" to "5"
    const resolvedUrlData = this.resolveUrl(pathname);
    const params = resolvedUrlData && resolvedUrlData.match && resolvedUrlData.match.params;

    const urlData = {
      pathname,
      query,
      fragment,
      params: params || {},
    };

    return urlData;
  }

  public updateAuthorizedRoutes() {
    this.authorizedRoutes = this.getAuthorizedRoutes(this.routes);
    this.resolveToCurrentPath();
    this.onAuthorizedRoutesChange(this.authorizedRoutes);
  }

  /**
   * will recursively filter the routes to show only what can be shown to the current role.
   */
  private getAuthorizedRoutes(routes?: Route[]) : Route[] {
    const routesToFilter = routes || this.authorizedRoutes;

    const authorizedRoutes = routesToFilter
      .filter((route) => {
        const routeCanBeDisplayed = this.canAccessRoute(route);

        if (routeCanBeDisplayed) {
          const hasChildRoutes = route.routes && route.routes.length;
          if (hasChildRoutes) {
            route.routes = this.getAuthorizedRoutes(route.routes || []);
          }

          return true;
        }
        return false;
      });

    return authorizedRoutes;
  }

  public getCurrentRouteConfig() : Route {
    const { route } = this.resolveUrl(this.location.pathname);
    return route;
  }

  /**
   * Will resolve to the final URL by recursively following fallback paths.
   * @param path ex: '/admin/users/user1' or 'admin/users/:id';
   * @param routes all routes used to match with path
   */
  private resolveUrl(url: string, routes: Route[] = this.authorizedRoutes) : { url: string, match: any, route: Route } {
    const { path, search, fragment } = this.getDataOfUrl(url);
    const matchedRoutes = this.getMatchedRoute(path, routes);

    for (const { match, route } of matchedRoutes) {
      const isMatch = match.isExact;
      if (isMatch) {
        const fallbackPath = this.getFallbackPathOfRoute(route);

        // make sure to not enter an infinite loop
        // when fallback path and path are the same.
        if (!fallbackPath || fallbackPath === route.path) {
          const url = this.createUrlWithData({
            path,
            params: this.defaultParams,
            search: search || this.location.search,
            fragment: fragment || (this.location.hash.charAt(0) === '#'
            ? this.location.hash.slice(1)
            : this.location.hash),
          });

          return {
            route,
            match,
            url,
          };
        }

        // ensure params are populated in the fallback URL
        const fallbackUrlWithData = this.createUrlWithData({
          path: fallbackPath,
          params: match.params,
        });

        return this.resolveUrl(fallbackUrlWithData, routes);
      }
    }
  }

  /**
   * Utility function used to create a clean URL using data as object
   * Note:
   *  - path can be a raw route like "/some/route/:route_id",
   *    the function will make to populate the parameter with the data provided
   */
  private QUERY_NAMESPACE = 'data';
  private createUrlWithData(config : CreateUrlOptions) : string {
    const { path, params = {}, query = {}, search = '', fragment = '' } = config;

    const pathWithParams = Object.entries(params)
      .reduce((path, [paramName, paramValue]) => {
        return path.replace(`:${paramName}`, paramValue);
      }, path);

    const searchAsObject = this.parseRouterUrlData(search);
    const urlSearchQuery = this.stringifySearch({ ...query, ...searchAsObject });

    let url = pathWithParams;
    if (urlSearchQuery) {
      url = `${url}?${this.QUERY_NAMESPACE}=${encodeURIComponent(urlSearchQuery)}`;
    }

    if (fragment) {
      const urlHash = fragment.charAt(0) === '#' ? fragment : `#${fragment}`;
      url = `${url}${urlHash}`;
    }

    return url;
  }

  private parseRouterUrlData(search: string) : object {
    const query = queryString.parse(search);

    // Urls must be decoded to escape certain characters
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent

    const urlRouterData = typeof query.data === 'string'
      ? JSON.parse(decodeURIComponent(query.data))
      : {};

    return urlRouterData;
  }

  private stringifySearch(query: object) : string {
    const isEmpty = Object.keys(query).length === 0;
    if (isEmpty) {
      return '';
    }

    const querystring = JSON.stringify(query);
    return querystring;
  }

  private getDataOfUrl(url: string) {
    const returnObj = {
      path: url,
      fragment: '',
      search: '',
    };

    const hashPosition = url.indexOf('#');
    const hasHash = hashPosition !== -1;
    if (hasHash) {
      returnObj.path = url.slice(0, hashPosition);
      returnObj.fragment = url.slice(hashPosition, url.length);
    }

    const queryPosition = url.indexOf('?');
    const hasQuery = queryPosition !== -1;
    if (hasQuery) {
      returnObj.path = url.slice(0, queryPosition);
      returnObj.search = url.slice(queryPosition, hasHash ? hashPosition : url.length);
    }

    return returnObj;
  }

  private getFallbackPathOfRoute(route: Route) {
    const fallbackPath = route.fallbackPath ? route.fallbackPath
        : route.getFallbackPath ? route.getFallbackPath()
        : undefined;

    return fallbackPath;
  }

  static canAccessRoute(user: UserProfile, route: Route) {
    const isRouteAccessRestricted = !!route.canAccess;
    if (!isRouteAccessRestricted) {
      return true;
    }

    const routerAccess = user
      && user.auth
      && user.auth.routerAccess
      && user.auth.routerAccess
      .map(value => value === '*' ? '**' : value); // ensure common 1 start glob is applied correctly with anymatch
    const isWhitelisted = anymatch(routerAccess || [], route.path);
    if (isWhitelisted) {
      return true;
    }

    return route.canAccess(user);
  }

  private canAccessRoute(route: Route) : boolean {
    const user = auth.user;
    return RouterStore.canAccessRoute(user, route);
  }

  public canAccessCurrentLocation() : boolean {
    const currentUrl = this.getCurrentUrl();
    const resolvedUrlData = this.resolveUrl(currentUrl, this.authorizedRoutes);
    if (!resolvedUrlData) {
      return false;
    }

    const canAccessRoute = this.canAccessRoute(resolvedUrlData.route);
    return canAccessRoute;
  }

  /*
  * Utility function to retrieve an array of all matching routes
  **/
  public getMatchedRoute(path: string , routes: any[]) {
    const matchedRoutes = matchRoutes(routes, path);
    return matchedRoutes;
  }
}

const router = new RouterStore();
export default router;
