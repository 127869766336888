import { OPERATION_STEP_STATUSES } from '@constants';
import store from '@stores';

async function handleRerunOperation(operationId: number, rerunFailedStepsOnly?: boolean) {
  const { api, auth, operationAndNotices } = store;
  const { operationByOperationId } = operationAndNotices;

  const operation = operationByOperationId.get(operationId);
  const operationSteps = operation.operationSteps;

  const steps = operationSteps.reduce((accumulator, operationStep) => {
    const operationStepFailed = operationStep.status === OPERATION_STEP_STATUSES.FAILED
      || operationStep.status === OPERATION_STEP_STATUSES.FAILED_BEFORE
      || operationStep.status === OPERATION_STEP_STATUSES.REJECTED
      || operationStep.status === OPERATION_STEP_STATUSES.REJECTED_BEFORE
      || operationStep.status === OPERATION_STEP_STATUSES.KILLED
      || operationStep.status === OPERATION_STEP_STATUSES.KILLED_BEFORE;

    if (rerunFailedStepsOnly && !operationStepFailed) {
      return accumulator;
    }

    accumulator.push(operationStep.step);
    return accumulator;
  }, []);

  const { feed_code, feed_id } = operation.feed;

  const addOperationConfig = {
    feed_code,
    feed_id,
    steps,
    priority: false,
    earlyStopIfDataUnchanged: false,
    user: auth.user.displayName,
  };

  return api.feeds.runOperationForFeed(addOperationConfig);
}

export default {
  handleRerunOperation,
};
