import api from '@stores/api';

const usersUrl = `${window.location.origin}/api/admin/users`;

export default {
 /**
  * GET complete list of users;
  */
  getUsers: async (): Promise<any> => {
    const { status , data: users } = await api.http.authenticatedGet(usersUrl);

    if (status === 200 && users) {
      return users;
    }

    return false;
  },

  /**
  * POST update a user
  */
  updateUser: async (uid: string, newUserData: any): Promise<any> => {
    const updateUserURL = `${usersUrl}/${uid}`;
    const { status , data: user } = await api.http.authenticatedPut(updateUserURL, newUserData);

    if (status === 200 && user) {
      return user;
    }

    return false;
  },

  deleteUser: async (uid: string) : Promise<boolean> => {
    const deleteUserUrl = `${usersUrl}/${uid}`;
    const { status } = await api.http.authenticatedDelete(deleteUserUrl);

    if (status === 200) {
      return true;
    }

    return false;
  },
};
