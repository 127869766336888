import api from '@stores/api';
import auth from '@stores/auth';

import { BannerFormInterface } from '@stores/banners';

const URL = `${window.location.origin}/api/banners/`;

export default {
  /**
   * GET complete list of banners
   */
  getBanners: async (): Promise<any[]> => {
    const response = await api.http.authenticatedGet(URL);
    if (response.data) {
      return response.data;
    }
    return [];
  },

  /**
   * GET singular banner
   */
  getBanner: async (bannerId): Promise<BannerFormInterface> => {
    const response = await api.http.authenticatedGet(`${URL}${bannerId}`);
    if (response.data) {
      return response.data;
    }
  },

  /**
   * DELETE archive a specific banner
   */
  archiveBanner: async (banner: any): Promise<any> => {
    let response;
    if (banner.is_archived) {
      response = await api.http.authenticatedPost(
        `${URL}${banner.id}/unarchive`,
        { username: auth.user.displayName },
      );
    } else {
      response = await api.http.authenticatedPost(
        `${URL}${banner.id}/archive`,
        { username: auth.user.displayName },
      );
    }
    return !!response;
  },

  /*
   * PATCH bannerInfo
   * @param {object} bannerInfo - Object containing information about the banner
   * @param number banner ID
   * @return {object} response - Response object from HTTP POST containing bannerInfo within property 'data'
   */
  uploadBannerInfo: async (bannerInfo: any): Promise<any> => {
    bannerInfo.username = auth.user.displayName;
    const response = await api.http.authenticatedPost(`${URL}${bannerInfo.id}`, bannerInfo);
    return !!response;
  },

  /*
  * POST bannerInfo
  * @param {object} bannerInfo - Object containing information about the banner
  * @return {number} bannerId - Banner id equivalent to the newly added banner's row id in the SQL database
  */

  addBanner: async (bannerInfo: any): Promise<any> => {
    bannerInfo.username = auth.user.displayName;
    const response = await api.http.authenticatedPost(`${URL}`, bannerInfo);
    if (response.data && response.data.id) {
      return response.data.id;
    }
    return !!response;
  },
};
