
export enum DATA_TYPES {

  // Primitives
  BOOLEAN = 'boolean',
  STRING = 'string',
  REGEXP = 'regExp',
  NUMBER = 'number',
  FILE_URL = 'file-url',
  DATE = 'date',

  // Object Types
  OBJECT = 'object',
  ARRAY = 'array',
  STATIC_OBJECT = 'static-object',
  DYNAMIC_OBJECT = 'dynamic-object',

  // Deep Types
  DYNAMIC_OBJECT_OF_STRINGS = 'dynamic-object-of-string',
  ARRAY_OF_STRINGS = 'array-of-strings',
  ARRAY_OF_STATIC_OBJECT = 'array-of-static-object',

}
