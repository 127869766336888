import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarLoader } from 'react-spinners';
import useAsyncEffect from 'use-async-effect';

import variables from '@styles/variables';
import LanguageSelector from '@components/LanguageSelector/LanguageSelector';

interface FullScreenFormProps<FormData> {
  request(formData: FormData) : Promise<{ error?: string }>;
  requestInitialData?() : Promise<{ error?: string, data?: FormData }>;
  forceIsLoading?: boolean;
  title?: string;
  logoCaption?: string;
  successMessage?: string;
  renderForm(submitForm: (fromData: FormData) => void, initialData: FormData) : JSX.Element;
  renderFooter: any;
}

import './FullScreenForm.scss';

export const FullScreenForm : React.FC<FullScreenFormProps<unknown>> = (props) => {
  const { t } = useTranslation('common');
  const [actionInitiated, setActionInitiated] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(!!props.requestInitialData);
  const [formError, setFormError] = React.useState<string | null>(null);
  const [initialData, setInitialData] = React.useState({});

  useAsyncEffect(async (isMounted) => {
    if (!props.requestInitialData) {
      return;
    }

    try {
      const { error, data } = await props.requestInitialData();
      if (!error && isMounted()) {
        const initialData = Object.assign({}, data);
        setInitialData(initialData);
        setIsLoading(false);
      } else {
        setFormError(error);
      }
    } catch (err) {
      /* no-op */
    }
  }, []);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    setFormError(null);
    setActionInitiated(true);

    const formData = {
      ...initialData,
      ...data,
    };

    setInitialData(formData);

    try {
      const { error } = await props.request(formData);
      if (error) {
        setFormError(error);
      }

    } catch (err) {
      const errMessage = t('error');
      setFormError(errMessage);
    }

    setIsLoading(false);
  };

  const forceLoading = typeof props.forceIsLoading === 'boolean' && props.forceIsLoading;
  if (isLoading || forceLoading) {
    return (
      <FullScreenPage>
        {
          !formError ? null : (
            <div
              className="bg-red bg-opacity-10 rounded-md text-red text-center text-xs font-bold p-6 mb-8"
              dangerouslySetInnerHTML={{ __html: formError }}
            />
          )
        }
        <BarLoader color="#30b566" />
      </FullScreenPage>
    );
  }

  if (actionInitiated && !formError && !!props.successMessage) {
    return (
      <FullScreenPage
        title={props.title}
        logoCaption={props.logoCaption}
      >
        <p className="text-sm text-transit mb-5">
          {props.successMessage}
        </p>
      </FullScreenPage>
    );
  }

  return (
    <FullScreenPage
      title={props.title}
      logoCaption={props.logoCaption}
      renderFooter={props.renderFooter}
    >
      {
        !formError ? null : (
          <div
            className="bg-red bg-opacity-10 rounded-md text-red text-center text-xs font-bold p-6 mb-8"
            dangerouslySetInnerHTML={{ __html: formError }}
          />
        )
      }

      {props.renderForm(onSubmit, initialData)}
    </FullScreenPage>
  );
};

interface FullScreenPageProps {
  title?: string;
  logoCaption?: string;
  renderFooter?: any;
}

const FullScreenPage : React.FC<FullScreenPageProps> = (props) => {
  const { t, i18n } = useTranslation('common');

  return (
    <div className="flex justify-center items-center h-full bg-offwhite">
      <div className="flex justify-center items-center h-full bg-offwhite">
        <div className="flex flex-col items-center text-center" style={{ width: '328px' }}>
          {props.logoCaption && (
            <p className="text-sm text-rainygrey mb-5">
              {props.logoCaption}
            </p>
          )}
          <img
            className="mb-12"
            src={`/images/transit-logo${i18n.language === 'en' ? '' : '-fr'}.svg`}
            alt="Transit Dashboard"
          />
          {props.title && (
            <p className="text-sm text-transit mb-5">
              {props.title}
            </p>
          )}
          {props.children}
          {!props.renderFooter ? null : (
            <React.Fragment>
              <hr className="w-full border-mercurygrey mt-8 mb-8" />
              <p className="text-sm text-silvergrey">
                {t('login.questions')} <a className="text-sm text-silvergrey underline" href="mailto:partners@transitapp.com" target="_blank" >
                {t('login.send_us_email')}
                </a>.
              </p>
              <div className="text-center flex flex-col align-items-center">
                {props.renderFooter()}
                <LanguageSelector
                  style={{ fontSize: '14px', color: variables.transitsilvergrey }}
                  useSilverGreyGlobe={true}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
