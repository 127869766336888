import api from '@stores/api';

const HOST_API_URL = `${window.location.origin}/api/archives`;

export default {
  getSources: async (feedId: number | string): Promise<any[]> => {
    const url = `${HOST_API_URL}/sources/${feedId}`;
    const response = await api.http.authenticatedGet(url);

    if (!response) {
      return [];
    }

    const { data: sources } = response;
    return sources;
  },

  updateSource: async (source): Promise<boolean> => {
    const url = `${HOST_API_URL}/source/update`;
    const response = await api.http.authenticatedPost(url, source);

    return !!response;
  },
};
