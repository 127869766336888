import React, { MouseEvent } from 'react';
import TagsInput from 'react-tagsinput';
import Switch from 'rc-switch';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

import { WINDOW_EVENTS } from '@constants';
import { DropdownContext, useDropdownContext } from '../lib/DropdownContext';

import '../styles/TableDropdown.scss';

type SearchValues = { search?: string[] };
type SearchConfig<T> = T & SearchValues;

interface TableDropdownProps<T> {
  openDropdownOnHover?: boolean;
  searchConfig: SearchConfig<T>;
  onChange(columnsConfig: T): void;
  renderDropdownContent(config: SearchConfig<T>, setConfig : (config: Partial<SearchConfig<T>>) => void);
}

interface TableDropdownState {
  isDropdownVisible: boolean;
  configCount: number;
}

export default class TableDropdown<T> extends React.Component<TableDropdownProps<T>, TableDropdownState> {

  state = {
    isDropdownVisible: false,
    configCount: 0,
  };

  componentDidMount() {
    window.addEventListener(WINDOW_EVENTS.CLICK, this.hideDropdown);
  }

  componentWillUnmount() {
    window.removeEventListener(WINDOW_EVENTS.CLICK, this.hideDropdown);
  }

  hideDropdown = () => {
    this.updateDropdownVisibility(false);
  }

  handleNewSearchConfig = (changedColumnsSearchConfig: any) => {
    const newSearchConfig = Object.assign({},
      this.props.searchConfig,
      changedColumnsSearchConfig,
    );

    this.props.onChange(newSearchConfig);
  }

  handleOnSearchChange = (search: string[]) => {
    this.handleNewSearchConfig({
      search,
    });
  }

  updateDropdownVisibility = (visibility: boolean) => {
    this.setState({
      isDropdownVisible: visibility,
    });
  }

  toggleDropdownVisibility = (event: React.MouseEvent) => {
    event.stopPropagation();

    const { isDropdownVisible } = this.state;
    this.updateDropdownVisibility(!isDropdownVisible);
  }

  focusOnInput = (event: MouseEvent) => {
    const inputClassName = 'react-tagsinput-input';
    const target: any = event.target;

    if (target.className === inputClassName) {
      target.focus();
      return;
    }
    // get the wrapped input
    const inputs = target.getElementsByClassName('react-tagsinput-input');
    if (inputs.length) {
      inputs[0].focus();
      return;
    }
  }

  renderDropdown = () => {
    const { searchConfig, renderDropdownContent } = this.props;

    return (
      <div className={'table-dropdown'}>
        <div className="table-dropdown-headers-wrapper">
          {renderDropdownContent(searchConfig, this.handleNewSearchConfig)}
        </div>
      </div>
    );
  }

  renderNotificationBadge() {
    const { configCount } = this.state;

    if (!configCount) {
      return null;
    }

    return (
      <div className="notification-badge">
        {configCount}
      </div>
    );
  }

  incrementCount = (incrementBy: number) => {
    this.setState(({ configCount }) => {
      const nextCount = configCount + incrementBy;
      return {
        configCount: nextCount < 0 ? 0 : nextCount,
      };
    });
  }

  render() {
    const { search = [] } = this.props.searchConfig;
    const { isDropdownVisible, configCount } = this.state;
    const contextValue = [configCount, this.incrementCount];

    return (
      <DropdownContext.Provider value={contextValue}>
        <div className="table-dropdown-container">
          <div className="table-dropdown-input" onClick={this.focusOnInput}>
            <TagsInput
              value={search}
              placeholder={'search all fields'}
              onChange={this.handleOnSearchChange}
              className={'table-dropdown-tagsinput'}
            />
            <div className="table-dropdown-input-config" onClick={this.toggleDropdownVisibility}>
              <i className="fa fa-search" />
              <i className="fa fa-caret-down" />
            </div>
          </div>
          {this.renderNotificationBadge()}

          <CSSTransition in={isDropdownVisible} timeout={200} classNames="table-dropdown">
            {this.renderDropdown()}
          </CSSTransition>
        </div>
      </DropdownContext.Provider>
    );
  }

  // Components and Context available to use within props.renderDropdownContent
  static ToggleBoolean = ToggleBoolean; // tslint:disable-next-line variable-name
  static SearchColumn = SearchColumn; // tslint:disable-line variable-name
}

// tslint:disable-next-line function-name
function ToggleBoolean(props: { value: boolean, onChange: (value: boolean) => void, desc: string }) {
  const bool = useDropdownContext(false, props.value, (prev, next) => {
    return next ? +1 : -1;
  });

  function toggleSwitch() {
    props.onChange(!props.value);
  }

  const className = cx('table-dropdown-toggle', {
    'active': bool,
  });

  return (
    <div className={className} onClick={toggleSwitch}>
      <Switch checked={bool}/> {props.desc}
    </div>
  );
}

interface SearchColumnProps {
  columnName: string;
  value: {
    hidden: boolean;
    search: string[];
  };
  onHideToggle?(isVisible: boolean) : void;
  onSearchChange(nextSearch: string[]) : void;
}

// tslint:disable-next-line function-name
function SearchColumn(props: SearchColumnProps) {
  const columnName = props.columnName;
  const isHidden = props.value.hidden;
  const searchTerms = props.value.search || [];

  const search = useDropdownContext([], searchTerms, (prev, next) => {
    return next.length - prev.length;
  }, [searchTerms.length]);

  const hidden = useDropdownContext(false, isHidden, (prev, next) => {
    return next ? +1 : -1;
  });

  const hideClassName = cx('hide-button', {
    'active': hidden,
  });

  function toggleColumnVisibility() {
    if (props.onHideToggle) {
      props.onHideToggle(!isHidden);
    }
  }

  function onSearchTermsChange(newSearchTerms: string[]) {
    props.onSearchChange(newSearchTerms);
  }

  return (
    <div className="table-dropdown-input">
      <TagsInput
        value={search}
        onChange={onSearchTermsChange}
        placeholder={columnName}
        className={'table-dropdown-tagsinput'}
      />
      <div className="table-dropdown-input-config" onClick={toggleColumnVisibility}>
        <span className={hideClassName}>HIDE</span>
      </div>
    </div>
  );
}
