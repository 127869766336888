import { AxiosResponse } from 'axios';

import { ENVIRONMENTS } from '@constants';
import api from '@stores/api';

import type { Extension } from './api.types';

const HOST_API_URL = `${window.location.origin}/api/feeds`;
const USD_API_URL = `${process.env.UPDATE_STATIC_DATA_URL}`;
const USD_DISPATCHER_API_URL = `${process.env.UPDATE_STATIC_DATA_DISPATCHER_URL}`;
const USD_API_URL_ROUTES = `${process.env.UPDATE_STATIC_DATA_URL}/authorized/api/routes`;
const USD_API_URL_ENVELOPE = `${process.env.UPDATE_STATIC_DATA_URL}/authorized/api/geojson`;
const USD_API_URL_GIT = `${process.env.UPDATE_STATIC_DATA_URL}/git`;

interface SaveJsonExtensionForFeedConfig {
  feedId: string | number;
  jsonExtension: Extension;
  commitTitle: string;
  commitEnv: ENVIRONMENTS;
}

interface GetJsonExtensionForFeedConfig {
  feedId: string | number;
  environment: ENVIRONMENTS;
}

interface RunOperationForFeedConfig {
  feed_id: string | number;
  feed_code: string;
  priority: boolean;
  earlyStopIfDataUnchanged: boolean;
  steps: string[];
  user: string;
}

interface KillOperationForKillConfig {
  operationIds: number[];
  user: string;
}

export default {
  getRoutes: async (feedId): Promise< any[] | boolean> => {
    const url = `${USD_API_URL_ROUTES}/${feedId}/`;
    try {
      const response = await api.http.authenticatedGet(url);
      return response.data;
    } catch (err) {
      console.error(err); // tslint:disable-line no-console
      return false;
    }
  },

  getRoutesFromDb: async (feedId) => {
    const url = `${USD_API_URL}/routes/get/${feedId}/`;

    try {
      const response = await api.http.authenticatedGet(url);
      return response.data;
    } catch (err) {
      console.error(err); // tslint:disable-line no-console
      return false;
    }
  },

  getEnvelope: async (feedId): Promise< any[] | boolean> => {
    const url = `${USD_API_URL_ENVELOPE}/${feedId}/`;
    try {
      const response = await api.http.authenticatedGet(url);
      return response.data;
    } catch (err) {
      console.error(err); // tslint:disable-line no-console
      return false;
    }
  },

 /**
  * GET complete list of transit Feeds
  */
  getFeedList: async (): Promise< any[] | boolean> => {
    const response = await api.http.authenticatedGet(HOST_API_URL);

    if (response?.data) {
      const feedList = response.data
        .sort((a, b) => a.id - b.id);
      return feedList;
    }

    return false;
  },

  getHubspotDataOfFeedId: async (feedId: string | number): Promise<any> => {
    const url = `${HOST_API_URL}/${feedId}/hubspot`;

    const response = await api.http.authenticatedGet(url);

    if (!response) {
      return undefined;
    }

    const { data: hubspotDataOfFeedId } = response;
    return hubspotDataOfFeedId;
  },

  getRtStatus: async () : Promise<any> => {
    const url = `${HOST_API_URL}/realtime`;
    const response = await api.http.authenticatedGet(url);

    if (!response) {
      return {};
    }

    const { data: realtimeStatuses } = response;
    return realtimeStatuses;
  },

  /**
   * GET JsonExtension used for feed by feed Code
   * @param config: {string} feedId - feed id ( ex: '1', '2' ), {string} environment - ( ex: 'production', 'staging' )
   */
  getJsonExtensionForFeed: async (config: GetJsonExtensionForFeedConfig): Promise<any> => {
    const url = `${USD_API_URL}/tools/feeds/${config.feedId}/${config.environment}`;

    const response = await api.http.authenticatedGet(url);
    return response.data;
  },

  killOperation: async (config: KillOperationForKillConfig): Promise<any> => {
    const url = `${USD_DISPATCHER_API_URL}/operation/kill`;

    const response = await api.http.authenticatedPost(url, config);
    return !!response;
  },

  updateNotice: async (notice): Promise<any> => {
    const url = `${USD_DISPATCHER_API_URL}/notice/update`;
    const response = await api.http.authenticatedPost(url, notice);
    return !!response;
  },

  runOperationForFeed: async (config: RunOperationForFeedConfig): Promise<any> => {
    const url = `${USD_DISPATCHER_API_URL}/operation/add`;

    const response = await api.http.authenticatedPost(url, config);
    return !!response;
  },

  duplicateFeed: async(feedInfo, environment) => {
    const url = `${USD_API_URL}/dupe/feeds/${feedInfo.feed_id}/duplicateFeed`;

    const data = {
      feedInfo,
      environment,
    };

    return await api.http.authenticatedPost(url, data);
  },
  /**
   * @param data - the following
   * @param feedId - ex: 1, 2 ,3
   * @param json_extension - object representing the jsonExtension
   * @param config - configure the api call
   * @param config.local if the request should update local jsonExension
   * @param config.commit_title quick description of the commit
   * @param config.commit_desc long description of the commit
   */
  saveJsonExtensionForFeed: async (data: SaveJsonExtensionForFeedConfig) : Promise<AxiosResponse> => {
    const url = `${USD_API_URL}/tools/feeds/${data.feedId}/saveJsonExtension`;

    try {
      return api.http.authenticatedPost(url, data);

    } catch (err) {
      console.error(err); // tslint:disable-line no-console
      return null;
    }
  },

  /*
   * POST feedInfo to a defined environment. This will either create a new feed or update an existing one.
   * @param {object} feedInfo - Object containing information about the feed
   * @param {string} environment - String defining which environment to push feedInfo to
   * @return {object} response - Response object from HTTP POST containing feedInfo within property 'data'
   */
  uploadFeedInfo: async (feedInfo: any, environment: string = process.env.NODE_ENV): Promise<any> => {
    const url = `${HOST_API_URL}/feed_info`;

    return await api.http.authenticatedPost(url, { feedInfo, environment });
  },

  /*
   * GET feed's code hash of a defined environment.
   * This will either get a hash of all the code that is used to run the feed.
   * @param {object} feedInfo - Object containing information about the feed
   * @param {string} environment - String defining which environment to push feedInfo to
   * @return {object} response - Response object from HTTP POST containing feedInfo within property 'data'
   */
  getCodeHash: async (feedCode: string): Promise<any> => {
    const targetEnv = process.env.NODE_ENV === ENVIRONMENTS.PRODUCTION
      ? ENVIRONMENTS.PRODUCTION
      : ENVIRONMENTS.STAGING;
    const url = `${USD_API_URL_GIT}/codeHash/${feedCode}/${targetEnv}`;

    return api.http.authenticatedGet(url);
  },
};
