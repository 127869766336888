import cx from 'classnames';
import React from 'react';
import { isEqual } from 'lodash';

import { FOCUS_ON, KEYBOARD_KEYS } from '@constants';
import './KeyboardActiveList.scss';

export interface KeyboardActiveListProps {
  list: any[];
  renderItem?: (listItem: any, index: number, isActive: boolean) => JSX.Element;
  onSelect: (value: any) => void;
}

export interface KeyboardActiveListState {
  activeIndex: number;
}

export default class KeyboardActiveList extends React.Component<KeyboardActiveListProps, KeyboardActiveListState> {
  constructor(props: KeyboardActiveListProps) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  componentDidUpdate(prevProps) {
    const listChanged = !isEqual(prevProps.list, this.props.list);
    if (listChanged) {
      this.resetActiveIndex();
    }
  }

  resetActiveIndex() {
    this.setState({
      activeIndex: 0,
    });
  }

  handleKeyPress = (event) => {
    switch (event && event.keyCode){
      case KEYBOARD_KEYS.ARROW_UP: return this.handleUpDownKeyPress(FOCUS_ON.UP);
      case KEYBOARD_KEYS.ARROW_DOWN: return this.handleUpDownKeyPress(FOCUS_ON.DOWN);
      case KEYBOARD_KEYS.ENTER: return this.handleEnterKeyPress();
    }
  }

  handleOnClick = (index) => {
    const { list } = this.props;

    this.props.onSelect(list[index]);
  }

  handleOnEnter = (index: number) => {
    this.setState({ activeIndex: index });
  }

  scrollIntoView = (index: number, position: ScrollLogicalPosition) => {
    const listItem = document.getElementById(`keyboard-active-list-${index}`);
    listItem && listItem.scrollIntoView({ behavior: 'smooth', block: position });
  }

  handleEnterKeyPress() {
    const { list } = this.props;
    const { activeIndex } = this.state;

    this.props.onSelect(list[activeIndex]);
  }

  handleUpDownKeyPress(direction: FOCUS_ON.UP | FOCUS_ON.DOWN) {
    const currentActiveIndex = this.state.activeIndex;
    let newActiveIndex;

    if (direction === FOCUS_ON.UP) {
      const decrementedIndex = currentActiveIndex - 1;
      newActiveIndex = decrementedIndex >= 0
        ? decrementedIndex
        : this.props.list.length - 1;

      this.setState({ activeIndex: newActiveIndex });
    } else if (direction === FOCUS_ON.DOWN) {
      const incrementedIndex = currentActiveIndex + 1;
      newActiveIndex = incrementedIndex < this.props.list.length
        ? incrementedIndex
        : 0;

      this.setState({ activeIndex: newActiveIndex });
    }

    this.scrollIntoView(newActiveIndex, 'end');
    return newActiveIndex;
  }

  renderRow(listItem, index) {
    const { renderItem } = this.props;
    const { activeIndex } = this.state;

    const isRowActive = index === activeIndex;

    if (renderItem) {
      return renderItem(listItem, index, isRowActive);
    }

    const className = cx({
      ['is-active']: isRowActive,
    });

    const rowText = (!listItem.description || listItem.description === listItem.value)
      ? listItem.value
      : `${listItem.value} - ${listItem.description}`;

    return (
      <div
        id={`keyboard-active-list-${index}`}
        key={`keyboard-active-list-${index}`}
        className={`keyboard-active-list-option ${className}`}
        /*
         * Use onMouseDownCapture instead of onClick because of the use case when this is within a dropdown.
         * In this edge-case, the dropdown closes before the onClick event is fired.
         * To properly capture the onClick, we use onMouseDownCapture
         */
        onMouseDownCapture={() => this.handleOnClick(index)} // tslint:disable-line jsx-no-lambda
        onMouseEnter={() => this.handleOnEnter(index)} // tslint:disable-line jsx-no-lambda
      >
        {rowText}
      </div>
    );
  }

  render() {
    const { list } = this.props;

    return (
      list.map((listItem, index) => this.renderRow(listItem, index))
    );
  }
}
