/* tslint:disable */
import React from 'react'

export const storeContext = React.createContext({})
export function withStore<PropsInterface>(Component, assignProps?: Partial<PropsInterface>) {

  // TODO: NumberToolUi and other components are complaining about their prop types
  // I hotfixed the issue by adding "any" to React.forwardRef for the moment
  // We will need to investigate the issue.

  return React.forwardRef((props: { ref?: any } | Omit<PropsInterface, 'store'> | any, ref) => {
    const componentProps = Object.assign({}, 
      {...props},
      {...assignProps}
    );

    return (
      <storeContext.Consumer>
        {store => <Component { ...componentProps } store={store} ref={ref}></Component>}
      </storeContext.Consumer>
    )
  })
}
