export enum ROLES {
  SUPER_ADMIN = 'super-admin',
  ADMIN = 'admin',
  USER = 'user',
  PUBLIC_USER = 'public-user',
}

export enum ROLE_DESCRIPTIONS {
  ADMIN = 'Transit employee',
  SUPER_ADMIN = 'Transit employee with permission to add, edit and delete users',
  USER = 'Agency (should only have access to select feeds)',
  PUBLIC_USER = 'Transit user (should only have access to select feeds)',
}
