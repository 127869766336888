import React from 'react';

import { HTML_TAGS } from '@constants';
import store from '@stores';
import { withStore } from '@stores/withStore';

import LoaderButton from '@components/LoaderButton/LoaderButton';
import { UserProfile, userAuthTemplate } from '@server/users/user.types';
import JsonUI from '@components/ToolComponents/JsonUI';
import CommonFormWrapper from '@components/Form/CommonFormWrapper';
import { FormColumn, FormInput, FormRow, FormSection } from '@components/Form/CommonForm';
import * as Yup from 'yup';

interface UserInfoFormProps {
  store?: typeof store;
  userInfo?: UserProfile;
  onChange?(user: UserProfile):void;
  onSubmit(user: UserProfile): Promise<any>;
}

export class UserInfoForm extends React.Component<UserInfoFormProps> {

  validationSchema = {
    displayName: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is required'),
    language: Yup.string().required(),
    groupId: Yup.string().nullable(),
    royaleEmailSent: Yup.boolean().nullable(),
    auth: Yup.object().shape({
      isAuthorized: Yup.boolean(),
      feedAccess: Yup.array().of(Yup.string()),
      role: Yup.string().required(),
      routerAccess: Yup.array(Yup.string()),
    }),
  };

  handleFormSubmit = async (userProfileForm) : Promise<boolean> => {
    const updatedUserInfo: UserProfile = await this.props.onSubmit(userProfileForm);
    return !!updatedUserInfo;
  }

  sendResetPasswordEmail = async (formik) => {
    const { api } = this.props.store;
    return api.auth.requestResetPasswordLink(formik.values.email);
  }

  renderButtons(formik) {
    return (
      <div className="save-button-container">
        {!formik.values.email ? null : (
          <LoaderButton
            value="Send Reset Password Email"
            onClickWithStatus={() => this.sendResetPasswordEmail(formik)}
          />
        )}
        <LoaderButton
          value="Save"
          onClickWithStatus={formik.submitForm}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="feed-info-container">
        <CommonFormWrapper
          formInfo={this.props.userInfo}
          onSubmit={this.handleFormSubmit}
          validationSchema={this.validationSchema}
        >
          {(formik) => {
            return (
              <React.Fragment>
                <FormSection title={'GENERAL INFORMATION'}>
                  <FormRow>
                    <FormColumn>
                      <FormInput
                        field="uid"
                        label="User ID"
                        value={formik.values.uid}
                        formType={HTML_TAGS.INPUT}
                        disabled={true}
                      />
                    </FormColumn>
                    <FormColumn>
                      <FormInput
                        field="displayName"
                        label="Display Name"
                        value={formik.values.displayName}
                        formType={HTML_TAGS.INPUT}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <FormInput
                        field="email"
                        label="Email"
                        value={formik.values.email}
                        formType={HTML_TAGS.INPUT}
                      />
                    </FormColumn>
                    <FormColumn>
                      <FormInput
                        value={formik.values.language}
                        label="Language"
                        field="language"
                        defaultValue={'en'}
                        formType={HTML_TAGS.DROPDOWN}
                        /* tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js */
                        itemLabelByItemValue={{
                          en: 'English',
                          fr: 'French',
                        }}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <FormInput
                        field="groupId"
                        label="Group ID"
                        value={formik.values.groupId}
                        formType={HTML_TAGS.INPUT}
                      />
                    </FormColumn>
                    <FormColumn>
                      <FormInput
                        field="royaleEmailSent"
                        label="Did User Receive Royale Email?"
                        value={formik.values.royaleEmailSent}
                        formType={HTML_TAGS.CHECKBOX}
                      />
                    </FormColumn>
                  </FormRow>
                </FormSection>
                <FormSection title="Authorization">
                  <FormRow>
                    <FormInput
                      formType={HTML_TAGS.CUSTOM}
                      render={() => {
                        return (
                          <JsonUI
                            value={{ data: formik.values.auth }}
                            isJsonEditorMode={false}
                            onChange={(authConfig) => { // tslint:disable jsx-no-lambda
                              formik.setFieldValue('auth', authConfig);
                            }}
                            template={{
                              data: userAuthTemplate,
                            }}
                          />
                        );
                      }}
                    />
                  </FormRow>
                </FormSection>
                {this.renderButtons(formik)}
              </React.Fragment>
            );
          }}
        </CommonFormWrapper>
      </div>
    );
  }
}

export default withStore<UserInfoFormProps>(UserInfoForm);
