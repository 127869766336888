import React from 'react';
import Dropzone from 'react-dropzone';

interface CsvDropFileZoneProps {
  children(isDropZoneActive): JSX.Element;
  onDropFile(acceptedFiles: any);
}

export default class CsvDropFileZone extends React.Component<CsvDropFileZoneProps, any> {
  state = {
    dropzoneActive: false,
  };

  onDrop = (acceptedFiles) => {
    this.setState({
      dropzoneActive: false,
    });

    if (acceptedFiles.length) {
      this.props.onDropFile(acceptedFiles);
    }
  }

  onDragEnter = () =>
    this.setState({
      dropzoneActive: true,
    })

  onDragLeave = () =>
    this.setState({
      dropzoneActive: false,
    })

  render() {
    const { dropzoneActive } = this.state;
    return (
      <Dropzone
        className="csv-dropzone"
        disableClick={true}
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
      >
        {this.props.children(dropzoneActive)}
      </Dropzone>
    );
  }
}
